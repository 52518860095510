import { AuthState, AUTH_STATE_KEY, defaultAuthState } from "./AuthStorageCommon";

export const createAuthStorage = () => {

    const webCookieAccess = {
        getAuthCookie: (): AuthState | null => {
            const cook = document.cookie.split(';').find(x => x.startsWith(AUTH_STATE_KEY + '='))?.split('=').slice(1).join('=').trim();
            const result = cook && JSON.parse(decodeURIComponent(cook)) as AuthState || undefined;
            return result || null;
        },
        setAuthCookie: (authState: AuthState | null) => {
            const json = JSON.stringify(authState);
            document.cookie = `${AUTH_STATE_KEY}=${encodeURIComponent(json)};max-age=${90 * 24 * 60 * 60}`;
        },
    };

    const storageAccess = {
        loadAuthState: async (): Promise<AuthState> => {
            try {
                const json = localStorage.getItem(AUTH_STATE_KEY);
                const result = json && JSON.parse(json) as AuthState || undefined;
                return result || webCookieAccess.getAuthCookie() || defaultAuthState;
            } catch {
                return defaultAuthState;
            }
        },
        saveAuthState: async (authState: AuthState | null) => {
            try {
                const json = JSON.stringify(authState);
                localStorage.setItem(AUTH_STATE_KEY, json);
                webCookieAccess.setAuthCookie(authState);
            } catch{ }
        },
    };

    return storageAccess;
};