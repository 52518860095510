import { UserCredentials } from "../data-types/SecurityDataTypes";
import { AppConfig_Client, appConfig_client } from "../_config/client/appConfig_client";
import { hashPassword_client } from "../utils/Password.client";
import { Timestamp } from "../utils/Time";
import { AppAuthApiAccess } from "./types/AppAuthApiAccess";
import { createAuthApiWebClient } from "../api/auth/client/AuthApiWebClient";
import { AppError } from "../utils/Errors";
import { createAuthStorage } from "./AuthStorage";
import { AuthState, defaultAuthState } from "./AuthStorageCommon";

const createAuthState = (onAuthStateChange: () => Promise<void>) => {
    const authStateValue = {
        value: null as null | AuthState,
    };
    const storageAccess = createAuthStorage();

    const loadAuthState = async () => {
        authStateValue.value = await storageAccess.loadAuthState();
    };

    const getAuthState = (): AuthState => {
        if (!authStateValue.value) {
            throw new AppError('AuthState is not loaded: call loadAuthState first');
        }

        // Timeout Auth
        if (!authStateValue.value._updateTimeUtc
            || Timestamp.now() > Timestamp.addSeconds(authStateValue.value._updateTimeUtc ?? 0, appConfig_client.authTimeoutSec)) {
            authStateValue.value = defaultAuthState;
        }

        return authStateValue.value;
    };

    const setAuthState = async (getValue: (s: AuthState) => AuthState) => {
        const oldValue = getAuthState();
        const value = getValue(oldValue);

        value._updateTimeUtc = Timestamp.now();

        authStateValue.value = value;
        await storageAccess.saveAuthState(value);

        if (value.email !== oldValue.email
            || value.phoneNumber !== oldValue.phoneNumber
            || value.state !== oldValue.state
        ) {
            await onAuthStateChange();
        }
    };

    const resetAuthState = async () => {
        await setAuthState(s => defaultAuthState);
    };

    return {
        loadAuthState,
        getAuthState,
        setAuthState,
        resetAuthState,
    };
};

export const createAuthApiAccess = (appConfig: AppConfig_Client, onAuthStateChange: () => Promise<void>): AppAuthApiAccess => {

    // Auth State
    const authState = createAuthState(onAuthStateChange);

    const authApi = createAuthApiWebClient(appConfig);

    const getClientSalt = async (email: string) => {
        // Just use email for the client-salt (for now)
        // Could request a random salt from server when creating a new account, or get client salt for email
        return Promise.resolve(email);
    };

    const getPasswordHashed = async (email: string, password: string) => {
        const salt = await getClientSalt(email);
        const passwordHashed = await hashPassword_client(password, salt);
        return passwordHashed;
    };

    const getUserCredentials = () => (authState.getAuthState())._userCredentials;
    const setUserCredentials = async (userCredentials: UserCredentials) => {
        await authState.setAuthState((s) => ({
            ...s,
            _userCredentials: userCredentials,
            state: userCredentials.authState,
        }));
    }

    const auth: AppAuthApiAccess = {
        getUserCredentials,
        setUserCredentials,

        reloadState: async () => {
            await authState.loadAuthState();

            const userCredentials = getUserCredentials();
            if (!userCredentials) { return; }

            try {
                const result = await authApi.checkAndRefreshUserCredentials({ userCredentials: getUserCredentials()!, ignoreVerification: true });
                if (!result.newUserCredentials) { return; }

                await setUserCredentials(result.newUserCredentials);
            } catch (err) {
                // Reset Auth State (credentials must be bad)
                await authState.resetAuthState();
            }
        },

        getAuthenticationState: () => authState.getAuthState(),
        logout: async () => {
            await authState.resetAuthState();
        },
        login: async (email, password) => {
            const passwordHashed = await getPasswordHashed(email, password);
            const result = await authApi.login({ email, password: passwordHashed });
            await authState.setAuthState(s => ({
                ...s,
                _userCredentials: result,
                email,
                state: result.authState,
            }));
        },
        createAccount: async (email, password) => {
            const passwordHashed = await getPasswordHashed(email, password);
            const result = await authApi.createAccount({ email, password: passwordHashed });

            await authState.setAuthState(s => ({
                ...s,
                _userCredentials: result,
                email,
                state: result.authState,
            }));
        },

        requestEmailVerificationCode: async (email) => { return await authApi.requestEmailVerificationCode({ userCredentials: getUserCredentials()!, email }) },
        requestPasswordReset: async (email, phone) => { return await authApi.requestPasswordReset({ email, phone }) },
        requestPhoneVerificationCode: async (phone) => { return await authApi.requestPhoneVerificationCode({ userCredentials: getUserCredentials()!, phone }) },
        verifyEmail: async (email, emailCode) => {
            const newUserCredentials = await authApi.verifyEmail({ userCredentials: getUserCredentials()!, email, emailCode });
            if (newUserCredentials) { await auth.setUserCredentials(newUserCredentials); }
            return !!newUserCredentials;
        },
        verifyPhone: async (phone, phoneCode) => {
            const newUserCredentials = await authApi.verifyPhone({ userCredentials: getUserCredentials()!, phone, phoneCode });
            if (newUserCredentials) { await auth.setUserCredentials(newUserCredentials); }

            console.log('createAuthApiAccess.verifyPhone', { newUserCredentials });
            return !!newUserCredentials;
        },

        changePassword: async (email, oldPassword, newPassword) => {
            const oldPasswordHashed = await getPasswordHashed(email, oldPassword);
            const newPasswordHashed = await getPasswordHashed(email, newPassword);
            const newUserCredentials = await authApi.changePassword({ email, oldPassword: oldPasswordHashed, newPassword: newPasswordHashed });
            if (newUserCredentials) { await auth.setUserCredentials(newUserCredentials); }
            return !!newUserCredentials;
        },
        resetPassword: async (email, resetCode, newPassword) => {
            const newPasswordHashed = await getPasswordHashed(email, newPassword);
            const newUserCredentials = await authApi.resetPassword({ email, resetCode, newPassword: newPasswordHashed });

            await auth.login(email, newPassword);
            return true;
            // if (newUserCredentials) { await auth.setUserCredentials(newUserCredentials); }
            // return !!newUserCredentials;
        },
    };

    return auth;
}