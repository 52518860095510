import React, { useState, useRef, useEffect } from 'react';
import { Store } from '../../store/Store';
import { PlanDetails_Edit, PlanModel } from '../../../data-types/ModelTypes';
import { MainLayout } from '../../components/Layout';
import { CampaignCard, BuilderDebugInfo } from '../Campaigns/EditCampaignComponents';
import { TextView, TextField, ButtonTextView, HintPopup, TextViewWithHint } from '../../components/TextView';
import { theme } from '../../style/Theme';
import { Builder, ValidValue, BuilderResult } from '../../../utils/Builder';
import { setValueWithCallback, SetValueWithCallback } from '../../../utils/Async';
import { View, Text, TouchableOpacity, ActivityIndicator } from 'react-native';
import { buildBuilder } from '../../../utils/BuilderFieldDefinitions';
import { useLoadData, useAutoLoadingError, useMounted } from '../../../utils/Hooks';
import { ErrorBox } from '../../components/ErrorBox';
import { AccountInfo } from '../../components/AccountInfo';
import { Price } from '../../../data-types/SimpleDataTypes';
import { useAlertModal } from '../../components/AlertModal';


const PlanSelector = (props: { store: Store, planId?: string, onChangePlanId: (planId: string) => void }) => {
    const { store, planId, onChangePlanId } = props;
    const { theme } = store;
    const { loading, error, doWork } = useAutoLoadingError();
    const plans = useLoadData(doWork, async (stopIfObsolete) => {
        const plans = await props.store.api.getPlans();
        stopIfObsolete();

        const accountState = props.store.api.getAccountState();

        const matchingPlan = plans.find(x => x.messagesMonthly === accountState.stats.planCredits);
        console.log('PlanSelector load', { plans, matchingPlan, accountState: accountState.stats });

        if (matchingPlan) {
            onChangePlanId(matchingPlan.id);
            stopIfObsolete();
        }

        const canDowngrade = !accountState.hasPhoneNumber || accountState.isImpersonatingAccount;
        const plansEqualOrBetter = !canDowngrade ? plans.filter(x => x.messagesMonthly >= accountState.stats.planCredits) : plans;

        return plansEqualOrBetter;
    });

    return (
        <>
            <View style={theme.grid.row}>
                {plans.data && plans.data.map(x => (
                    <View key={x.id} style={[theme.grid.cell, { margin: 8 }]}>
                        <TouchableOpacity onPress={() => onChangePlanId(x.id)}>
                            <View style={x.id === planId ? theme.card.view_selectionActive : theme.card.view_selectionInactive}>
                                <TextView style={theme.card.cardTitle} text={x.name} />
                                <TextView style={theme.card.cardSummary} text={`${x.messagesMonthly.toLocaleString()} credits per month`} />
                                <TextView style={theme.card.cardSummary} text={`$${x.priceMonthly.toLocaleString()} / month`} />
                                {/* <TextView style={theme.card.paragraphTextView} text={`${x.messagesMonthly} messages per month`} /> */}
                            </View>
                        </TouchableOpacity>
                    </View>
                ))}
            </View>
        </>
    );
}

const PlanDetailsEditor = (props: { store: Store, planDetails: Builder<PlanDetails_Edit>, setPlanDetailsValue: SetValueWithCallback }) => {

    const { planDetails, setPlanDetailsValue } = props;
    const { theme } = props.store;

    return (
        <>
            <PlanSelector store={props.store} planId={props.planDetails.planId?.value} onChangePlanId={setPlanDetailsValue(value => planDetails.planId = { value, isValid: true })} />
        </>
    );
}

const PlanDetailsEditorView = (props: { store: Store }) => {

    const [renderId, setRenderId] = useState(0);
    const planDetailsValue = useRef({} as Builder<PlanDetails_Edit>);
    const planDetailsResult = useRef({} as BuilderResult<PlanDetails_Edit>);

    const [hasChanged, setHasChanged] = useState(false);

    const { mounted } = useMounted();
    const changeTimoutId = useRef(0);
    const changePlan = () => {

        // Debounce
        clearTimeout(changeTimoutId.current);
        changeTimoutId.current = setTimeout(() => {
            if (!mounted) { return; }

            planDetailsValue.current = { ...planDetailsValue.current };
            planDetailsResult.current = buildBuilder(planDetailsValue.current, {} as any);

            setRenderId(Date.now());
        }, 50);

    };

    const planDetails = planDetailsValue.current;
    const setPlanDetailsValue = setValueWithCallback(changePlan);

    const { loading, error, doWork } = useAutoLoadingError();
    const savePlan = () => doWork(async (stopIfObsolete) => {
        await props.store.api.savePlan(planDetailsResult.current.value);
        stopIfObsolete();

        if (!props.store.api.getAccountState().hasAccountDetails) {
            showConfirmation();
        } else {
            showConfirmation_NewPlan();
        }
    });
    const { AlertHost, showAlert: showConfirmation } = useAlertModal({
        title: `Plan Selected`,
        message: `Your plan is saved.`,
        buttons: [
            { text: 'OK', onPress: () => { if (!props.store.api.getAccountState().hasAccountDetails) { props.store.nav.EditAccountPage.open(); } else { props.store.nav._refresh(); } } },
        ]
    });
    const { AlertHost: AlertHost_NewPlan, showAlert: showConfirmation_NewPlan } = useAlertModal({
        title: `Plan Changed`,
        message: `New plan will go into effect at end of the current cycle.`,
        buttons: [
            { text: 'OK', onPress: () => { if (!props.store.api.getAccountState().hasAccountDetails) { props.store.nav.EditAccountPage.open(); } else { props.store.nav._refresh(); } } },
        ]
    });

    return (
        <CampaignCard store={props.store}>
            <View style={theme.card.cardTitle.view}>
                <View style={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
                    <Text style={theme.card.cardTitle.text}>{props.store.api.getAccountState().hasPlan ? `Upgrade Plan` : 'Select Plan'}</Text>
                    <HintPopup hint={`If you need a specific plan that is not listed here, please call or text us at 610-688-6000. You can always change your plan later as your business grows.`} />
                </View>
            </View>
            <TextView style={theme.card.cardSummary} text='If you wish to upgrade your plan, please choose from one of the plans below. The upgrade will take place on your next monthly renewal date. To add more credits immediately, use the "Extra Credits" at the bottom of this page.' />
            <TextView style={theme.card.cardSummary} text={`• 1 SMS text message = ${props.store.config.creditsPerMessage.sms} Credit`} />
            <TextView style={theme.card.cardSummary} text={`• 1 MMS picture message = ${props.store.config.creditsPerMessage.mms} Credits`} />

            <PlanDetailsEditor store={props.store} planDetails={planDetails} setPlanDetailsValue={setPlanDetailsValue} />

            {/* Save */}
            <View style={theme.card.actionArea}>
                <TextView style={theme.card.cardSummary} text="Don’t see a package that fits your needs? Call or text us at 610-688-6000." />
                {/* {!planDetailsResult.current.isValid && (
                    <TextView style={theme.card.warnTextView} text='Enter All the Required Data' />
                )} */}
                {!!planDetailsResult.current.errors && planDetailsResult.current.errors.map(x => (
                    <TextView style={theme.card.warnTextView} text={x.error ?? ''} />
                ))}
                <View style={theme.card.buttonRow}>
                    <View style={theme.card.buttonSpacer} />
                    <ButtonTextView style={theme.card.buttonTextView_major} text="Enter Plan" onPress={savePlan} isDisabled={!planDetailsResult.current.isValid} />
                </View>
            </View>


            {loading && (<ActivityIndicator size='large' />)}
            {error && (<ErrorBox error={error} />)}
            <AlertHost useFixed={true} />
            <AlertHost_NewPlan useFixed={true} />

            {/* Debug */}
            {/* <BuilderDebugInfo store={props.store}
                builderResult={planDetailsResult.current}
                builder={planDetailsValue.current}
            /> */}

        </CampaignCard>
    );
};


export const PurchaseExtraCreditsView = (props: { store: Store }) => {
    const accountState = props.store.api.getAccountState();
    const isReady = accountState.hasPlan && accountState.hasAccountDetails && accountState.isPaymentsReady && accountState.hasPhoneNumber;

    if (!isReady) {
        return (<></>);
    }

    return (
        <CampaignCard store={props.store}>
            <AccountInfo store={props.store} />

            <TextViewWithHint style={theme.card.cardTitle} text="Need Extra Credits?"
                hint='Extra credits will expire upon the next billing cycle.' />
            <TextViewWithHint style={theme.card.cardSummary}
                text="If you need additional credits for this month, please purchase them below. Credits will be added immediately."
                hint='Extra credits are added immediately and will expire on your next monthly anniversary date.' />

            <ExtraCreditsSelector store={props.store} />
        </CampaignCard>
    );
}


const ExtraCreditsSelector = (props: { store: Store }) => {
    const { store } = props;
    const { theme } = store;
    const { loading, error, doWork } = useAutoLoadingError();
    const plans = useLoadData(doWork, async (stopIfObsolete) => {
        const plans = await props.store.api.getPlans_extraCredits();
        return plans;
    });

    const [extraCredits, setExtraCredits] = useState(0);
    const [cost, setCost] = useState(0 as Price);

    const purchaseExtraCredits = (credits: number, price: Price) => doWork(async (stopIfObsolete) => {
        await props.store.api.purchaseExtraCredits(credits, price);
        stopIfObsolete();

        showConfirmation();
    });
    const { AlertHost, showAlert: showConfirmation } = useAlertModal({
        title: `Extra Credits Purchased`,
        message: `You will be charged for $${cost}.`,
        buttons: [
            { text: 'OK', onPress: () => { if (!props.store.api.getAccountState().hasAccountDetails) { props.store.nav.EditAccountPage.open(); } else { props.store.nav._refresh(); } } },
        ]
    });

    return (
        <>
            <View style={theme.grid.row}>
                {plans.data && plans.data.map(x => (
                    <View key={x.messages} style={[theme.grid.cell, { margin: 8 }]}>
                        <TouchableOpacity onPress={() => { setExtraCredits(x.messages); setCost(x.price); }}>
                            <View style={x.messages === extraCredits ? theme.card.view_selectionActive : theme.card.view_selectionInactive}>
                                <TextView style={theme.card.cardTitle} text={'' + x.messages} />
                                <TextView style={theme.card.cardSummary} text={`${x.messages.toLocaleString()} extra credits`} />
                                <TextView style={theme.card.cardSummary} text={`$${x.price.toLocaleString()}`} />
                            </View>
                        </TouchableOpacity>
                    </View>
                ))}
            </View>

            {extraCredits > 0 && (
                <>
                    <View style={theme.card.buttonRow}>
                        <View style={theme.card.buttonSpacer} />
                        <TextView style={theme.card.cardSummary} text={`Purchase ${extraCredits} extra credits for $${cost}`} />
                    </View>
                </>
            )}

            <View style={theme.card.buttonRow}>
                <View style={theme.card.buttonSpacer} />
                <ButtonTextView style={theme.card.buttonTextView_major} text="Add Credits" onPress={() => purchaseExtraCredits(extraCredits, cost)} isDisabled={!extraCredits} loading={loading} error={error} />
            </View>
            <AlertHost useFixed={true} />
        </>
    );
}

export const PlanEditorPage = (props: { store: Store }) => {
    return (
        <MainLayout store={props.store}>
            <PlanDetailsEditorView store={props.store} />
            <PurchaseExtraCreditsView store={props.store} />

        </MainLayout>
    );
}