import { AppConfig_Client } from "../../../_config/client/appConfig_client";
import { UserCredentials, UserCredentialsProvider } from "../../../data-types/SecurityDataTypes";
import { PaymentClientApi } from "../../../lib/payments/common/types";
import { createJsonRpcClient } from "../../../lib/json-rpc/json-rpc-client-stack";
import { JsonRpcSessionToken } from "../../../lib/json-rpc/types";

export const createPaymentApiClient = (appConfig: AppConfig_Client, userCredentialsProvider: UserCredentialsProvider): PaymentClientApi => {

    const api = createJsonRpcClient<PaymentClientApi>({
        sendCookie: false,
        serverUrl: appConfig.paymentApiDomain,
        sessionTokenStorage: {
            getSessionToken: async () => { return userCredentialsProvider.getUserCredentials() as unknown as JsonRpcSessionToken; },
            setSessionToken: async (value) => { userCredentialsProvider.setUserCredentials(value as unknown as UserCredentials); },
            resetSessionToken: async () => { userCredentialsProvider.setUserCredentials(null); },
        },
        apiMethodNames: {
            setupSavedPaymentMethod: `setupSavedPaymentMethod`,
            saveSavedPaymentMethod: `saveSavedPaymentMethod`,
            getSavedPaymentMethods: `getSavedPaymentMethods`,
            deleteSavedPaymentMethod: `deleteSavedPaymentMethod`,
            debug_triggerPayment: `debug_triggerPayment`,
            getPayments: `getPayments`,
        },
    });

    return api;
}
