import { fetchWithTimeout } from "./Fetch";
import { ApiError } from "./Errors";

export async function webRequest(url: string, data: any, ) {

    const body = JSON.stringify(data);
    const reqData: RequestInit = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Content-Length': '' + body.length,
        },
        body,
    };
    const result = await fetchWithTimeout(url, reqData).catch((err) => { throw new ApiError('Request Failure', { url, data }, err); });
    if (!result.ok) {
        throw new ApiError('Api Error', { data: (await result.json().catch()) ?? {}, responseStatus: result.status, request: { url, data } });
    }
    const resultObj = await result.json().catch((err) => { throw new ApiError('Request Parse Failure', { url, data }, err); });
    return resultObj;
}
