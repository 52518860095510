import { AuthenticationState, UserCredentials } from "../data-types/SecurityDataTypes";
import { Timestamp } from "../utils/Time";

export const AUTH_STATE_KEY = 'authState';
export type AuthState = AuthenticationState & { _userCredentials?: UserCredentials, _updateTimeUtc: Timestamp };
export const defaultAuthState: AuthState = {
    state: 'loggedOut',
    email: undefined,
    phoneNumber: undefined,

    _updateTimeUtc: 0 as Timestamp,
    _userCredentials: undefined as undefined | UserCredentials,
};