import { ViewContactListsPage, ViewContactListPage, CreateContactListPage } from './ContactLists/ViewContactLists';
import { ViewContactListContactsPage, ViewContactPage, ViewAllContactsPage, QuickRemoveContactPage } from './ContactLists/ViewContacts';
import { EditCampaignPage_Standard } from './Campaigns/EditCampaign_Standard';
import { createViewData_create, createSimpleRoute, createRouteArgs } from './_Views';
import { DashboardView } from './Dashboard';
import { ActiveCampaignsView, CampaignHistoryView } from './Campaigns/CampaignList';
import { ViewContactMessageThreadPage } from './Messages/ViewMessageThread';
import { ViewRecentMessageThreadsPage } from './Messages/ViewMessageHistory';
import { EditCampaignPage_Interactive } from './Campaigns/EditCampaign_Interactive';
import { AccountEditorPage } from './Account/AccountEditor';
import { PaymentEditorPage } from './Account/PaymentEditor';
import { LoginPage, VerifyPhonePage } from './Account/Login';
import { PlanEditorPage } from './Account/PlanEditor';
import { PhoneNumberEditorPage as PhoneNumbersEditorPage } from './Account/PhoneNumberEditor';
import { ViewAccountsPage } from './Admin/ViewAccounts';
import { EditCampaignPage_Sweepstakes } from './Campaigns/EditCampaign_Sweepstakes';
import { EditCampaignPage_Coupon } from './Campaigns/EditCampaign_Coupon';
import { ViewAdminsPage } from './Admin/ViewAdmins';
import { ViewCampaignMessageThreadPage } from './Campaigns/components/CampaignMessages';

export const createViewData = (create: createViewData_create) => {
    const views = {
        RootPage: create(DashboardView, { title: 'Dashboard', isSecure: true }, createSimpleRoute('')),
        ViewContactListsPage: create(ViewContactListsPage, { title: 'Manage Lists', isSecure: true }, createSimpleRoute('manage-lists')),
        ViewContactListPage: create(ViewContactListPage, { title: 'View List', isSecure: true }, createRouteArgs('list',
            (args) => `${args!.contactList.id}`,
            (route) => ({ contactListId: route })
        )),
        CreateContactListPage: create(CreateContactListPage, { title: 'Create List', isSecure: true }, createSimpleRoute('create-list')),
        ViewContactListContactsPage: create(ViewContactListContactsPage, { title: 'View Contacts', isSecure: true }, createRouteArgs('contacts',
            (args) => `${args!.contactList.id}`,
            (route) => ({ contactListId: route })
        )),
        ViewContactPage: create(ViewContactPage, { title: 'View Contact', isSecure: true }, createRouteArgs('contact',
            (args) => `${args!.contact.id}`,
            (route) => ({ contactId: route })
        )),
        ViewAllContactsPage: create(ViewAllContactsPage, { title: 'View All Contacts', isSecure: true }, createSimpleRoute('contacts-all')),
        QuickRemoveContactPage: create(QuickRemoveContactPage, { title: 'Quick Remove Contact', isSecure: true }, createSimpleRoute('quick-remove')),

        EditCampaignPage_Standard: create(EditCampaignPage_Standard, { title: 'Broadcast Campaign', isSecure: true }, createRouteArgs('broadcast',
            (args) => `${args?.campaign.id ?? ''}`,
            (route) => ({ campaignId: route })
        )),
        EditCampaignPage_Interactive: create(EditCampaignPage_Interactive, { title: 'Interactive Campaign', isSecure: true }, createRouteArgs('interactive',
            (args) => `${args?.campaign.id ?? ''}`,
            (route) => ({ campaignId: route })
        )),
        EditCampaignPage_Coupon: create(EditCampaignPage_Coupon, { title: 'Coupon Campaign', isSecure: true }, createRouteArgs('coupon',
            (args) => `${args?.campaign.id ?? ''}`,
            (route) => ({ campaignId: route })
        )),
        EditCampaignPage_Sweepstakes: create(EditCampaignPage_Sweepstakes, { title: 'Sweepstakes Campaign', isSecure: true }, createRouteArgs('sweepstakes',
            (args) => `${args?.campaign.id ?? ''}`,
            (route) => ({ campaignId: route })
        )),
        ActiveCampaignsPage: create(ActiveCampaignsView, { title: 'Active Campaigns', isSecure: true }, createSimpleRoute('active-campaigns')),
        CampaignHistoryPage: create(CampaignHistoryView, { title: 'Campaign History', isSecure: true }, createSimpleRoute('campaign-history')),
        ViewCampaignMessageThreadPage: create(ViewCampaignMessageThreadPage, { title: 'View Campaign Messages', isSecure: true }, createRouteArgs('campaign-messages',
            (args) => `${args!.campaign.id}`,
            (route) => ({ campaignId: route })
        )),

        ViewContactMessageThreadPage: create(ViewContactMessageThreadPage, { title: 'View Contact Messages', isSecure: true }, createRouteArgs('contact-messages',
            (args) => `${args!.contact?.id ?? args!.contactRef?.id ?? ''}`,
            (route) => ({ contactId: route })
        )),
        ViewRecentMessageThreadsPage: create(ViewRecentMessageThreadsPage, { title: 'View Recent Messages', isSecure: true }, createSimpleRoute('recent-messages')),


        EditAccountPage: create(AccountEditorPage, { title: 'Manage Account', isSecure: true }, createSimpleRoute('account')),
        LogoutPage: create(LoginPage, { title: 'Log Out', isSecure: true }, createSimpleRoute('account')),
        EditPlanPage: create(PlanEditorPage, { title: 'Manage Plan', isSecure: true }, createSimpleRoute('manage-plan')),
        EditPaymentPage: create(PaymentEditorPage, { title: 'Manage Payment Details', isSecure: true }, createSimpleRoute('manage-payment')),
        EditPhoneNumbersPage: create(PhoneNumbersEditorPage, { title: 'Manage Phone Numbers', isSecure: true }, createSimpleRoute('phone-numbers')),

        LoginPage: create(LoginPage, { title: 'Login', isSecure: false }, createSimpleRoute('login')),
        InvitePage: create(LoginPage, { title: 'Invite', isSecure: false }, createSimpleRoute('invite')),
        VerifyPhonePage: create(VerifyPhonePage, { title: 'Verify Phone', isSecure: false }, createSimpleRoute('verify-phone')),

        ViewAccountsPage: create(ViewAccountsPage, { title: 'Manage Accounts', isSecure: true }, createRouteArgs('accounts',
            (args) => `${args?.search ?? ''}`,
            (route) => ({ search: route })
        )),
        ViewAdminsPage: create(ViewAdminsPage, { title: 'Manage Administrators', isSecure: true }, createSimpleRoute('admins')),

    };
    return views;
};