import { Timestamp } from "../../../utils/Time"
import { Reference, PhoneNumber } from "../../../data-types/SimpleDataTypes"
import { UserModel, AccountModel, UserSessionModel, UserAccountModel, AccountPhoneNumberModel, ContactModel, ContactListModel, LinkModel, MessageDeliveryModel, MessageTemplateModel as MessageTemplateModel_Typed, CampaignModel, MessageReplyModel, CampaignStateModel, ContactCampaignStateModel, BandwidthEventModel, UserPasswordClientSaltModel, TaskStateModel, MessageGeneratedModel, AccountSystemModel, PlanModel } from "../../../data-types/ModelTypes"
import { ContactListModel_Contacts_2_ContactModel_State_ContactLists, CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns } from "./relationalTypes.generated";
import { Model_FromServer, Model_ToServer } from "../../../data-types/TypeTransformations";
import { SecureToken } from "../../../data-types/SecurityDataTypes";
type MessageTemplateModel = MessageTemplateModel_Typed<any>;

export const relationalEndpoints_server = {
  UserModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<UserModel>[] }> },
  UserPasswordClientSaltModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<UserPasswordClientSaltModel>[] }> },
  UserSessionModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<UserSessionModel>[] }> },
  UserAccountModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<UserAccountModel>[] }> },
  AccountModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<AccountModel>[] }> },
  AccountSystemModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<AccountSystemModel>[] }> },
  PlanModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<PlanModel>[] }> },
  AccountPhoneNumberModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<AccountPhoneNumberModel>[] }> },
  ContactModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<ContactModel>[] }> },
  ContactListModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<ContactListModel>[] }> },
  LinkModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<LinkModel>[] }> },
  MessageTemplateModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageTemplateModel>[] }> },
  MessageGeneratedModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageGeneratedModel>[] }> },
  MessageDeliveryModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageDeliveryModel>[] }> },
  MessageReplyModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageReplyModel>[] }> },
  CampaignModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<CampaignModel>[] }> },
  CampaignStateModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<CampaignStateModel>[] }> },
  ContactCampaignStateModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<ContactCampaignStateModel>[] }> },
  BandwidthEventModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<BandwidthEventModel>[] }> },
  TaskStateModel_Select_All: (args: { paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<TaskStateModel>[] }> },
  UserModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  UserPasswordClientSaltModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  UserSessionModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  UserAccountModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  AccountModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  AccountSystemModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  PlanModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  AccountPhoneNumberModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  ContactModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  ContactListModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  LinkModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  MessageTemplateModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  MessageGeneratedModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  MessageDeliveryModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  MessageReplyModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  CampaignModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  CampaignStateModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  ContactCampaignStateModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  BandwidthEventModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  TaskStateModel_SelectCount_All: (args: {}) => { return null as any as Promise<{ count: number }> },
  UserModel_Select_By_id: (args: { value: Reference<UserModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<UserModel> }> },
  UserModel_Select_By_activeAccount: (args: { value: Reference<AccountModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<UserModel>[] }> },
  UserModel_Select_By_username: (args: { value: string, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<UserModel>[] }> },
  UserModel_Select_By_phone: (args: { value: PhoneNumber, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<UserModel>[] }> },
  UserPasswordClientSaltModel_Select_By_id: (args: { value: Reference<UserPasswordClientSaltModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<UserPasswordClientSaltModel> }> },
  UserPasswordClientSaltModel_Select_By_usernameClaim: (args: { value: string, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<UserPasswordClientSaltModel>[] }> },
  UserSessionModel_Select_By_id: (args: { value: Reference<UserSessionModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<UserSessionModel> }> },
  UserSessionModel_Select_By__user: (args: { value: Reference<UserModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<UserSessionModel>[] }> },
  UserSessionModel_Select_By_activeAccount: (args: { value: Reference<AccountModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<UserSessionModel>[] }> },
  UserSessionModel_Select_By_sessionToken: (args: { value: SecureToken, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<UserSessionModel>[] }> },
  UserAccountModel_Select_By_id: (args: { value: Reference<UserAccountModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<UserAccountModel> }> },
  UserAccountModel_Select_By__user: (args: { value: Reference<UserModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<UserAccountModel>[] }> },
  UserAccountModel_Select_By_account: (args: { value: Reference<AccountModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<UserAccountModel>[] }> },
  AccountModel_Select_By_id: (args: { value: Reference<AccountModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<AccountModel> }> },
  AccountModel_Select_By_name: (args: { value: string, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<AccountModel>[] }> },
  AccountSystemModel_Select_By_id: (args: { value: Reference<AccountSystemModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<AccountSystemModel> }> },
  AccountSystemModel_Select_By_account: (args: { value: Reference<AccountModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<AccountSystemModel>[] }> },
  PlanModel_Select_By_id: (args: { value: Reference<PlanModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<PlanModel> }> },
  AccountPhoneNumberModel_Select_By_id: (args: { value: Reference<AccountPhoneNumberModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<AccountPhoneNumberModel> }> },
  AccountPhoneNumberModel_Select_By_account: (args: { value: Reference<AccountModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<AccountPhoneNumberModel>[] }> },
  AccountPhoneNumberModel_Select_By_name: (args: { value: string, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<AccountPhoneNumberModel>[] }> },
  AccountPhoneNumberModel_Select_By_phoneNumber: (args: { value: PhoneNumber, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<AccountPhoneNumberModel>[] }> },
  ContactModel_Select_By_id: (args: { value: Reference<ContactModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<ContactModel> }> },
  ContactModel_Select_By_phoneNumber: (args: { value: PhoneNumber, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<ContactModel>[] }> },
  ContactModel_Select_By_firstName: (args: { value: string, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<ContactModel>[] }> },
  ContactModel_Select_By_lastName: (args: { value: string, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<ContactModel>[] }> },
  ContactListModel_Select_By_id: (args: { value: Reference<ContactListModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<ContactListModel> }> },
  ContactListModel_Select_By_name: (args: { value: string, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<ContactListModel>[] }> },
  ContactListModel_Select_By_createdTime: (args: { value: Timestamp, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<ContactListModel>[] }> },
  ContactListModel_Select_By_modifiedTime: (args: { value: Timestamp, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<ContactListModel>[] }> },
  LinkModel_Select_By_id: (args: { value: Reference<LinkModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<LinkModel> }> },
  LinkModel_Select_By_messageDelivery: (args: { value: Reference<MessageDeliveryModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<LinkModel>[] }> },
  LinkModel_Select_By_linkToken: (args: { value: string, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<LinkModel>[] }> },
  MessageTemplateModel_Select_By_id: (args: { value: Reference<MessageTemplateModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<MessageTemplateModel> }> },
  MessageTemplateModel_Select_By_campaign: (args: { value: Reference<CampaignModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageTemplateModel>[] }> },
  MessageGeneratedModel_Select_By_id: (args: { value: Reference<MessageGeneratedModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<MessageGeneratedModel> }> },
  MessageGeneratedModel_Select_By_messageTemplate: (args: { value: Reference<MessageTemplateModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageGeneratedModel>[] }> },
  MessageGeneratedModel_Select_By_fromPhoneNumber: (args: { value: Reference<AccountPhoneNumberModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageGeneratedModel>[] }> },
  MessageGeneratedModel_Select_By_deliveredAt: (args: { value: Timestamp, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageGeneratedModel>[] }> },
  MessageDeliveryModel_Select_By_id: (args: { value: Reference<MessageDeliveryModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<MessageDeliveryModel> }> },
  MessageDeliveryModel_Select_By_toContact: (args: { value: Reference<ContactModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageDeliveryModel>[] }> },
  MessageDeliveryModel_Select_By_messageTemplate: (args: { value: Reference<MessageTemplateModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageDeliveryModel>[] }> },
  MessageDeliveryModel_Select_By_createdTime: (args: { value: Timestamp, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageDeliveryModel>[] }> },
  MessageDeliveryModel_Select_By_fromPhoneNumber: (args: { value: PhoneNumber, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageDeliveryModel>[] }> },
  MessageDeliveryModel_Select_By_toPhoneNumber: (args: { value: PhoneNumber, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageDeliveryModel>[] }> },
  MessageDeliveryModel_Select_By_deliverAtTime: (args: { value: Timestamp, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageDeliveryModel>[] }> },
  MessageDeliveryModel_Select_By_deliverImmediately: (args: { value: boolean, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageDeliveryModel>[] }> },
  MessageDeliveryModel_Select_By_deliveryProcessingStartTime: (args: { value: Timestamp, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageDeliveryModel>[] }> },
  MessageDeliveryModel_Select_By_deliveryTime: (args: { value: Timestamp, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageDeliveryModel>[] }> },
  MessageDeliveryModel_Select_By_deliveryResult_deliveredToNetworkTime: (args: { value: Timestamp, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageDeliveryModel>[] }> },
  MessageDeliveryModel_Select_By_deliveryResult_deliveryFailureTime: (args: { value: Timestamp, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageDeliveryModel>[] }> },
  MessageDeliveryModel_Select_By_lastCampaign: (args: { value: Reference<CampaignModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageDeliveryModel>[] }> },
  MessageReplyModel_Select_By_id: (args: { value: Reference<MessageReplyModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<MessageReplyModel> }> },
  MessageReplyModel_Select_By_fromContact: (args: { value: Reference<ContactModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageReplyModel>[] }> },
  MessageReplyModel_Select_By_mostRecentMessageDelivery: (args: { value: Reference<MessageDeliveryModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageReplyModel>[] }> },
  MessageReplyModel_Select_By_createdTime: (args: { value: Timestamp, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageReplyModel>[] }> },
  MessageReplyModel_Select_By_deliveredTime: (args: { value: Timestamp, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageReplyModel>[] }> },
  MessageReplyModel_Select_By_lastCampaign: (args: { value: Reference<CampaignModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<MessageReplyModel>[] }> },
  CampaignModel_Select_By_id: (args: { value: Reference<CampaignModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<CampaignModel> }> },
  CampaignModel_Select_By_fromPhoneNumber: (args: { value: Reference<AccountPhoneNumberModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<CampaignModel>[] }> },
  CampaignModel_Select_By_name: (args: { value: string, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<CampaignModel>[] }> },
  CampaignModel_Select_By_status_createdTime: (args: { value: Timestamp, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<CampaignModel>[] }> },
  CampaignModel_Select_By_status_lastActivityTime: (args: { value: Timestamp, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<CampaignModel>[] }> },
  CampaignModel_Select_By_status_nextActivityTime: (args: { value: Timestamp, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<CampaignModel>[] }> },
  CampaignStateModel_Select_By_id: (args: { value: Reference<CampaignStateModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<CampaignStateModel> }> },
  CampaignStateModel_Select_By_campaign: (args: { value: Reference<CampaignModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<CampaignStateModel>[] }> },
  ContactCampaignStateModel_Select_By_id: (args: { value: Reference<ContactCampaignStateModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<ContactCampaignStateModel> }> },
  ContactCampaignStateModel_Select_By_contact: (args: { value: Reference<ContactModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<ContactCampaignStateModel>[] }> },
  ContactCampaignStateModel_Select_By_campaign: (args: { value: Reference<CampaignModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<ContactCampaignStateModel>[] }> },
  ContactCampaignStateModel_Select_By_openedTime: (args: { value: Timestamp, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<ContactCampaignStateModel>[] }> },
  ContactCampaignStateModel_Select_By_expirationTime: (args: { value: Timestamp, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<ContactCampaignStateModel>[] }> },
  BandwidthEventModel_Select_By_id: (args: { value: Reference<BandwidthEventModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<BandwidthEventModel> }> },
  BandwidthEventModel_Select_By_createdAt: (args: { value: Timestamp, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<BandwidthEventModel>[] }> },
  TaskStateModel_Select_By_id: (args: { value: Reference<TaskStateModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<TaskStateModel> }> },
  TaskStateModel_Select_By_taskKey: (args: { value: string, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<TaskStateModel>[] }> },
  TaskStateModel_Select_By_startTime: (args: { value: Timestamp, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<TaskStateModel>[] }> },
  TaskStateModel_Select_By_endTime: (args: { value: Timestamp, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<TaskStateModel>[] }> },
  ContactListModel_Contacts_2_ContactModel_State_ContactLists_Select_By_id: (args: { value: Reference<ContactListModel_Contacts_2_ContactModel_State_ContactLists>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<ContactListModel_Contacts_2_ContactModel_State_ContactLists> }> },
  ContactListModel_Contacts_2_ContactModel_State_ContactLists_Select_By_state_contactLists_mmfk: (args: { value: Reference<ContactListModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<ContactListModel_Contacts_2_ContactModel_State_ContactLists>[] }> },
  ContactListModel_Contacts_2_ContactModel_State_ContactLists_Select_By_contacts_mmfk: (args: { value: Reference<ContactModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<ContactListModel_Contacts_2_ContactModel_State_ContactLists>[] }> },
  CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_Select_By_id: (args: { value: Reference<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ item: Model_FromServer<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns> }> },
  CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_Select_By_usedByCampaigns_mmfk: (args: { value: Reference<CampaignModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>[] }> },
  CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_Select_By_toContactLists_mmfk: (args: { value: Reference<ContactListModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>[] }> },
  UserModel_SelectCount_By_id: (args: { value: Reference<UserModel>, }) => { return null as any as Promise<{ count: number }> },
  UserModel_SelectCount_By_activeAccount: (args: { value: Reference<AccountModel>, }) => { return null as any as Promise<{ count: number }> },
  UserModel_SelectCount_By_username: (args: { value: string, }) => { return null as any as Promise<{ count: number }> },
  UserModel_SelectCount_By_phone: (args: { value: PhoneNumber, }) => { return null as any as Promise<{ count: number }> },
  UserPasswordClientSaltModel_SelectCount_By_id: (args: { value: Reference<UserPasswordClientSaltModel>, }) => { return null as any as Promise<{ count: number }> },
  UserPasswordClientSaltModel_SelectCount_By_usernameClaim: (args: { value: string, }) => { return null as any as Promise<{ count: number }> },
  UserSessionModel_SelectCount_By_id: (args: { value: Reference<UserSessionModel>, }) => { return null as any as Promise<{ count: number }> },
  UserSessionModel_SelectCount_By__user: (args: { value: Reference<UserModel>, }) => { return null as any as Promise<{ count: number }> },
  UserSessionModel_SelectCount_By_activeAccount: (args: { value: Reference<AccountModel>, }) => { return null as any as Promise<{ count: number }> },
  UserSessionModel_SelectCount_By_sessionToken: (args: { value: SecureToken, }) => { return null as any as Promise<{ count: number }> },
  UserAccountModel_SelectCount_By_id: (args: { value: Reference<UserAccountModel>, }) => { return null as any as Promise<{ count: number }> },
  UserAccountModel_SelectCount_By__user: (args: { value: Reference<UserModel>, }) => { return null as any as Promise<{ count: number }> },
  UserAccountModel_SelectCount_By_account: (args: { value: Reference<AccountModel>, }) => { return null as any as Promise<{ count: number }> },
  AccountModel_SelectCount_By_id: (args: { value: Reference<AccountModel>, }) => { return null as any as Promise<{ count: number }> },
  AccountModel_SelectCount_By_name: (args: { value: string, }) => { return null as any as Promise<{ count: number }> },
  AccountSystemModel_SelectCount_By_id: (args: { value: Reference<AccountSystemModel>, }) => { return null as any as Promise<{ count: number }> },
  AccountSystemModel_SelectCount_By_account: (args: { value: Reference<AccountModel>, }) => { return null as any as Promise<{ count: number }> },
  PlanModel_SelectCount_By_id: (args: { value: Reference<PlanModel>, }) => { return null as any as Promise<{ count: number }> },
  AccountPhoneNumberModel_SelectCount_By_id: (args: { value: Reference<AccountPhoneNumberModel>, }) => { return null as any as Promise<{ count: number }> },
  AccountPhoneNumberModel_SelectCount_By_account: (args: { value: Reference<AccountModel>, }) => { return null as any as Promise<{ count: number }> },
  AccountPhoneNumberModel_SelectCount_By_name: (args: { value: string, }) => { return null as any as Promise<{ count: number }> },
  AccountPhoneNumberModel_SelectCount_By_phoneNumber: (args: { value: PhoneNumber, }) => { return null as any as Promise<{ count: number }> },
  ContactModel_SelectCount_By_id: (args: { value: Reference<ContactModel>, }) => { return null as any as Promise<{ count: number }> },
  ContactModel_SelectCount_By_phoneNumber: (args: { value: PhoneNumber, }) => { return null as any as Promise<{ count: number }> },
  ContactModel_SelectCount_By_firstName: (args: { value: string, }) => { return null as any as Promise<{ count: number }> },
  ContactModel_SelectCount_By_lastName: (args: { value: string, }) => { return null as any as Promise<{ count: number }> },
  ContactListModel_SelectCount_By_id: (args: { value: Reference<ContactListModel>, }) => { return null as any as Promise<{ count: number }> },
  ContactListModel_SelectCount_By_name: (args: { value: string, }) => { return null as any as Promise<{ count: number }> },
  ContactListModel_SelectCount_By_createdTime: (args: { value: Timestamp, }) => { return null as any as Promise<{ count: number }> },
  ContactListModel_SelectCount_By_modifiedTime: (args: { value: Timestamp, }) => { return null as any as Promise<{ count: number }> },
  LinkModel_SelectCount_By_id: (args: { value: Reference<LinkModel>, }) => { return null as any as Promise<{ count: number }> },
  LinkModel_SelectCount_By_messageDelivery: (args: { value: Reference<MessageDeliveryModel>, }) => { return null as any as Promise<{ count: number }> },
  LinkModel_SelectCount_By_linkToken: (args: { value: string, }) => { return null as any as Promise<{ count: number }> },
  MessageTemplateModel_SelectCount_By_id: (args: { value: Reference<MessageTemplateModel>, }) => { return null as any as Promise<{ count: number }> },
  MessageTemplateModel_SelectCount_By_campaign: (args: { value: Reference<CampaignModel>, }) => { return null as any as Promise<{ count: number }> },
  MessageGeneratedModel_SelectCount_By_id: (args: { value: Reference<MessageGeneratedModel>, }) => { return null as any as Promise<{ count: number }> },
  MessageGeneratedModel_SelectCount_By_messageTemplate: (args: { value: Reference<MessageTemplateModel>, }) => { return null as any as Promise<{ count: number }> },
  MessageGeneratedModel_SelectCount_By_fromPhoneNumber: (args: { value: Reference<AccountPhoneNumberModel>, }) => { return null as any as Promise<{ count: number }> },
  MessageGeneratedModel_SelectCount_By_deliveredAt: (args: { value: Timestamp, }) => { return null as any as Promise<{ count: number }> },
  MessageDeliveryModel_SelectCount_By_id: (args: { value: Reference<MessageDeliveryModel>, }) => { return null as any as Promise<{ count: number }> },
  MessageDeliveryModel_SelectCount_By_toContact: (args: { value: Reference<ContactModel>, }) => { return null as any as Promise<{ count: number }> },
  MessageDeliveryModel_SelectCount_By_messageTemplate: (args: { value: Reference<MessageTemplateModel>, }) => { return null as any as Promise<{ count: number }> },
  MessageDeliveryModel_SelectCount_By_createdTime: (args: { value: Timestamp, }) => { return null as any as Promise<{ count: number }> },
  MessageDeliveryModel_SelectCount_By_fromPhoneNumber: (args: { value: PhoneNumber, }) => { return null as any as Promise<{ count: number }> },
  MessageDeliveryModel_SelectCount_By_toPhoneNumber: (args: { value: PhoneNumber, }) => { return null as any as Promise<{ count: number }> },
  MessageDeliveryModel_SelectCount_By_deliverAtTime: (args: { value: Timestamp, }) => { return null as any as Promise<{ count: number }> },
  MessageDeliveryModel_SelectCount_By_deliverImmediately: (args: { value: boolean, }) => { return null as any as Promise<{ count: number }> },
  MessageDeliveryModel_SelectCount_By_deliveryProcessingStartTime: (args: { value: Timestamp, }) => { return null as any as Promise<{ count: number }> },
  MessageDeliveryModel_SelectCount_By_deliveryTime: (args: { value: Timestamp, }) => { return null as any as Promise<{ count: number }> },
  MessageDeliveryModel_SelectCount_By_deliveryResult_deliveredToNetworkTime: (args: { value: Timestamp, }) => { return null as any as Promise<{ count: number }> },
  MessageDeliveryModel_SelectCount_By_deliveryResult_deliveryFailureTime: (args: { value: Timestamp, }) => { return null as any as Promise<{ count: number }> },
  MessageReplyModel_SelectCount_By_id: (args: { value: Reference<MessageReplyModel>, }) => { return null as any as Promise<{ count: number }> },
  MessageReplyModel_SelectCount_By_fromContact: (args: { value: Reference<ContactModel>, }) => { return null as any as Promise<{ count: number }> },
  MessageReplyModel_SelectCount_By_mostRecentMessageDelivery: (args: { value: Reference<MessageDeliveryModel>, }) => { return null as any as Promise<{ count: number }> },
  MessageReplyModel_SelectCount_By_createdTime: (args: { value: Timestamp, }) => { return null as any as Promise<{ count: number }> },
  MessageReplyModel_SelectCount_By_deliveredTime: (args: { value: Timestamp, }) => { return null as any as Promise<{ count: number }> },
  CampaignModel_SelectCount_By_id: (args: { value: Reference<CampaignModel>, }) => { return null as any as Promise<{ count: number }> },
  CampaignModel_SelectCount_By_fromPhoneNumber: (args: { value: Reference<AccountPhoneNumberModel>, }) => { return null as any as Promise<{ count: number }> },
  CampaignModel_SelectCount_By_name: (args: { value: string, }) => { return null as any as Promise<{ count: number }> },
  CampaignModel_SelectCount_By_status_createdTime: (args: { value: Timestamp, }) => { return null as any as Promise<{ count: number }> },
  CampaignModel_SelectCount_By_status_lastActivityTime: (args: { value: Timestamp, }) => { return null as any as Promise<{ count: number }> },
  CampaignModel_SelectCount_By_status_nextActivityTime: (args: { value: Timestamp, }) => { return null as any as Promise<{ count: number }> },
  CampaignStateModel_SelectCount_By_id: (args: { value: Reference<CampaignStateModel>, }) => { return null as any as Promise<{ count: number }> },
  CampaignStateModel_SelectCount_By_campaign: (args: { value: Reference<CampaignModel>, }) => { return null as any as Promise<{ count: number }> },
  ContactCampaignStateModel_SelectCount_By_id: (args: { value: Reference<ContactCampaignStateModel>, }) => { return null as any as Promise<{ count: number }> },
  ContactCampaignStateModel_SelectCount_By_contact: (args: { value: Reference<ContactModel>, }) => { return null as any as Promise<{ count: number }> },
  ContactCampaignStateModel_SelectCount_By_campaign: (args: { value: Reference<CampaignModel>, }) => { return null as any as Promise<{ count: number }> },
  ContactCampaignStateModel_SelectCount_By_openedTime: (args: { value: Timestamp, }) => { return null as any as Promise<{ count: number }> },
  ContactCampaignStateModel_SelectCount_By_expirationTime: (args: { value: Timestamp, }) => { return null as any as Promise<{ count: number }> },
  BandwidthEventModel_SelectCount_By_id: (args: { value: Reference<BandwidthEventModel>, }) => { return null as any as Promise<{ count: number }> },
  BandwidthEventModel_SelectCount_By_createdAt: (args: { value: Timestamp, }) => { return null as any as Promise<{ count: number }> },
  TaskStateModel_SelectCount_By_id: (args: { value: Reference<TaskStateModel>, }) => { return null as any as Promise<{ count: number }> },
  TaskStateModel_SelectCount_By_taskKey: (args: { value: string, }) => { return null as any as Promise<{ count: number }> },
  TaskStateModel_SelectCount_By_startTime: (args: { value: Timestamp, }) => { return null as any as Promise<{ count: number }> },
  TaskStateModel_SelectCount_By_endTime: (args: { value: Timestamp, }) => { return null as any as Promise<{ count: number }> },
  ContactListModel_Contacts_2_ContactModel_State_ContactLists_SelectCount_By_id: (args: { value: Reference<ContactListModel_Contacts_2_ContactModel_State_ContactLists>, }) => { return null as any as Promise<{ count: number }> },
  ContactListModel_Contacts_2_ContactModel_State_ContactLists_SelectCount_By_state_contactLists_mmfk: (args: { value: Reference<ContactListModel>, }) => { return null as any as Promise<{ count: number }> },
  ContactListModel_Contacts_2_ContactModel_State_ContactLists_SelectCount_By_contacts_mmfk: (args: { value: Reference<ContactModel>, }) => { return null as any as Promise<{ count: number }> },
  CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_SelectCount_By_id: (args: { value: Reference<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>, }) => { return null as any as Promise<{ count: number }> },
  CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_SelectCount_By_usedByCampaigns_mmfk: (args: { value: Reference<CampaignModel>, }) => { return null as any as Promise<{ count: number }> },
  CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_SelectCount_By_toContactLists_mmfk: (args: { value: Reference<ContactListModel>, }) => { return null as any as Promise<{ count: number }> },
  ContactModel_Select_By_state_contactLists: (args: { value: Reference<ContactListModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<ContactModel>[] }> },
  ContactListModel_Select_By_contacts: (args: { value: Reference<ContactModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<ContactListModel>[] }> },
  ContactListModel_Select_By_usedByCampaigns: (args: { value: Reference<CampaignModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<ContactListModel>[] }> },
  CampaignModel_Select_By_toContactLists: (args: { value: Reference<ContactListModel>, paging?: { take: number, skip: number } }) => { return null as any as Promise<{ items: Model_FromServer<CampaignModel>[] }> },
  ContactModel_SelectCount_By_state_contactLists: (args: { value: Reference<ContactListModel>, }) => { return null as any as Promise<{ count: number }> },
  ContactListModel_SelectCount_By_contacts: (args: { value: Reference<ContactModel>, }) => { return null as any as Promise<{ count: number }> },
  ContactListModel_SelectCount_By_usedByCampaigns: (args: { value: Reference<CampaignModel>, }) => { return null as any as Promise<{ count: number }> },
  CampaignModel_SelectCount_By_toContactLists: (args: { value: Reference<ContactListModel>, }) => { return null as any as Promise<{ count: number }> },
  UserModel_Insert: (args: { value: Model_ToServer<UserModel>, }) => { return null as any as Promise<{ newId: string }> },
  UserPasswordClientSaltModel_Insert: (args: { value: Model_ToServer<UserPasswordClientSaltModel>, }) => { return null as any as Promise<{ newId: string }> },
  UserSessionModel_Insert: (args: { value: Model_ToServer<UserSessionModel>, }) => { return null as any as Promise<{ newId: string }> },
  UserAccountModel_Insert: (args: { value: Model_ToServer<UserAccountModel>, }) => { return null as any as Promise<{ newId: string }> },
  AccountModel_Insert: (args: { value: Model_ToServer<AccountModel>, }) => { return null as any as Promise<{ newId: string }> },
  AccountSystemModel_Insert: (args: { value: Model_ToServer<AccountSystemModel>, }) => { return null as any as Promise<{ newId: string }> },
  PlanModel_Insert: (args: { value: Model_ToServer<PlanModel>, }) => { return null as any as Promise<{ newId: string }> },
  AccountPhoneNumberModel_Insert: (args: { value: Model_ToServer<AccountPhoneNumberModel>, }) => { return null as any as Promise<{ newId: string }> },
  ContactModel_Insert: (args: { value: Model_ToServer<ContactModel>, }) => { return null as any as Promise<{ newId: string }> },
  ContactListModel_Insert: (args: { value: Model_ToServer<ContactListModel>, }) => { return null as any as Promise<{ newId: string }> },
  LinkModel_Insert: (args: { value: Model_ToServer<LinkModel>, }) => { return null as any as Promise<{ newId: string }> },
  MessageTemplateModel_Insert: (args: { value: Model_ToServer<MessageTemplateModel>, }) => { return null as any as Promise<{ newId: string }> },
  MessageGeneratedModel_Insert: (args: { value: Model_ToServer<MessageGeneratedModel>, }) => { return null as any as Promise<{ newId: string }> },
  MessageDeliveryModel_Insert: (args: { value: Model_ToServer<MessageDeliveryModel>, }) => { return null as any as Promise<{ newId: string }> },
  MessageReplyModel_Insert: (args: { value: Model_ToServer<MessageReplyModel>, }) => { return null as any as Promise<{ newId: string }> },
  CampaignModel_Insert: (args: { value: Model_ToServer<CampaignModel>, }) => { return null as any as Promise<{ newId: string }> },
  CampaignStateModel_Insert: (args: { value: Model_ToServer<CampaignStateModel>, }) => { return null as any as Promise<{ newId: string }> },
  ContactCampaignStateModel_Insert: (args: { value: Model_ToServer<ContactCampaignStateModel>, }) => { return null as any as Promise<{ newId: string }> },
  BandwidthEventModel_Insert: (args: { value: Model_ToServer<BandwidthEventModel>, }) => { return null as any as Promise<{ newId: string }> },
  TaskStateModel_Insert: (args: { value: Model_ToServer<TaskStateModel>, }) => { return null as any as Promise<{ newId: string }> },
  ContactListModel_Contacts_2_ContactModel_State_ContactLists_Insert: (args: { value: Model_ToServer<ContactListModel_Contacts_2_ContactModel_State_ContactLists>, }) => { return null as any as Promise<{ newId: string }> },
  CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_Insert: (args: { value: Model_ToServer<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>, }) => { return null as any as Promise<{ newId: string }> },
  UserModel_Update: (args: { value: Model_ToServer<UserModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  UserPasswordClientSaltModel_Update: (args: { value: Model_ToServer<UserPasswordClientSaltModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  UserSessionModel_Update: (args: { value: Model_ToServer<UserSessionModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  UserAccountModel_Update: (args: { value: Model_ToServer<UserAccountModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  AccountModel_Update: (args: { value: Model_ToServer<AccountModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  AccountSystemModel_Update: (args: { value: Model_ToServer<AccountSystemModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  PlanModel_Update: (args: { value: Model_ToServer<PlanModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  AccountPhoneNumberModel_Update: (args: { value: Model_ToServer<AccountPhoneNumberModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  ContactModel_Update: (args: { value: Model_ToServer<ContactModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  ContactListModel_Update: (args: { value: Model_ToServer<ContactListModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  LinkModel_Update: (args: { value: Model_ToServer<LinkModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  MessageTemplateModel_Update: (args: { value: Model_ToServer<MessageTemplateModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  MessageGeneratedModel_Update: (args: { value: Model_ToServer<MessageGeneratedModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  MessageDeliveryModel_Update: (args: { value: Model_ToServer<MessageDeliveryModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  MessageReplyModel_Update: (args: { value: Model_ToServer<MessageReplyModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  CampaignModel_Update: (args: { value: Model_ToServer<CampaignModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  CampaignStateModel_Update: (args: { value: Model_ToServer<CampaignStateModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  ContactCampaignStateModel_Update: (args: { value: Model_ToServer<ContactCampaignStateModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  BandwidthEventModel_Update: (args: { value: Model_ToServer<BandwidthEventModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  TaskStateModel_Update: (args: { value: Model_ToServer<TaskStateModel>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  ContactListModel_Contacts_2_ContactModel_State_ContactLists_Update: (args: { value: Model_ToServer<ContactListModel_Contacts_2_ContactModel_State_ContactLists>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_Update: (args: { value: Model_ToServer<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>, }) => { return null as any as Promise<{ updatedItemsCount: number }> },
  UserModel_Delete: (args: { value: Reference<UserModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  UserPasswordClientSaltModel_Delete: (args: { value: Reference<UserPasswordClientSaltModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  UserSessionModel_Delete: (args: { value: Reference<UserSessionModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  UserAccountModel_Delete: (args: { value: Reference<UserAccountModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  AccountModel_Delete: (args: { value: Reference<AccountModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  AccountSystemModel_Delete: (args: { value: Reference<AccountSystemModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  PlanModel_Delete: (args: { value: Reference<PlanModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  AccountPhoneNumberModel_Delete: (args: { value: Reference<AccountPhoneNumberModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  ContactModel_Delete: (args: { value: Reference<ContactModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  ContactListModel_Delete: (args: { value: Reference<ContactListModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  LinkModel_Delete: (args: { value: Reference<LinkModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  MessageTemplateModel_Delete: (args: { value: Reference<MessageTemplateModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  MessageGeneratedModel_Delete: (args: { value: Reference<MessageGeneratedModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  MessageDeliveryModel_Delete: (args: { value: Reference<MessageDeliveryModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  MessageReplyModel_Delete: (args: { value: Reference<MessageReplyModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  CampaignModel_Delete: (args: { value: Reference<CampaignModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  CampaignStateModel_Delete: (args: { value: Reference<CampaignStateModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  ContactCampaignStateModel_Delete: (args: { value: Reference<ContactCampaignStateModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  BandwidthEventModel_Delete: (args: { value: Reference<BandwidthEventModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  TaskStateModel_Delete: (args: { value: Reference<TaskStateModel>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  ContactListModel_Contacts_2_ContactModel_State_ContactLists_Delete: (args: { value: Reference<ContactListModel_Contacts_2_ContactModel_State_ContactLists>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
  CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_Delete: (args: { value: Reference<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>, }) => { return null as any as Promise<{ deletedItemsCount: number }> },
}

export const relationalEndpointsDefinition = [
  {
    "methodName": "UserModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<UserModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<UserModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "UserPasswordClientSaltModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<UserPasswordClientSaltModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<UserPasswordClientSaltModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "UserSessionModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<UserSessionModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<UserSessionModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "UserAccountModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<UserAccountModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<UserAccountModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "AccountModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<AccountModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<AccountModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "AccountSystemModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<AccountSystemModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<AccountSystemModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "PlanModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<PlanModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<PlanModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "AccountPhoneNumberModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<AccountPhoneNumberModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<AccountPhoneNumberModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "ContactModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<ContactModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<ContactModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "ContactListModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<ContactListModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<ContactListModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "LinkModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<LinkModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<LinkModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageTemplateModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<MessageTemplateModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageTemplateModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageGeneratedModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<MessageGeneratedModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageGeneratedModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageDeliveryModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageReplyModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<MessageReplyModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageReplyModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "CampaignModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<CampaignModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<CampaignModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "CampaignStateModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<CampaignStateModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<CampaignStateModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "ContactCampaignStateModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<ContactCampaignStateModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<ContactCampaignStateModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "BandwidthEventModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<BandwidthEventModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<BandwidthEventModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "TaskStateModel_Select_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{ items: Model_FromServer<TaskStateModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<TaskStateModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "UserModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "UserPasswordClientSaltModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "UserSessionModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "UserAccountModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "AccountModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "AccountSystemModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "PlanModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "AccountPhoneNumberModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactListModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "LinkModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageTemplateModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageGeneratedModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageDeliveryModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageReplyModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "CampaignModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "CampaignStateModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactCampaignStateModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "BandwidthEventModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "TaskStateModel_SelectCount_All",
    "methodValue": "",
    "methodValue_toServer": "",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "UserModel_Select_By_id",
    "methodValue": "Reference<UserModel>",
    "methodValue_toServer": "Reference<UserModel>",
    "parseResultReturnType": "{ item: Model_FromServer<UserModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<UserModel> }",
    "hasPaging": true
  },
  {
    "methodName": "UserModel_Select_By_activeAccount",
    "methodValue": "Reference<AccountModel>",
    "methodValue_toServer": "Reference<AccountModel>",
    "parseResultReturnType": "{ items: Model_FromServer<UserModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<UserModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "UserModel_Select_By_username",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{ items: Model_FromServer<UserModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<UserModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "UserModel_Select_By_phone",
    "methodValue": "PhoneNumber",
    "methodValue_toServer": "PhoneNumber",
    "parseResultReturnType": "{ items: Model_FromServer<UserModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<UserModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "UserPasswordClientSaltModel_Select_By_id",
    "methodValue": "Reference<UserPasswordClientSaltModel>",
    "methodValue_toServer": "Reference<UserPasswordClientSaltModel>",
    "parseResultReturnType": "{ item: Model_FromServer<UserPasswordClientSaltModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<UserPasswordClientSaltModel> }",
    "hasPaging": true
  },
  {
    "methodName": "UserPasswordClientSaltModel_Select_By_usernameClaim",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{ items: Model_FromServer<UserPasswordClientSaltModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<UserPasswordClientSaltModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "UserSessionModel_Select_By_id",
    "methodValue": "Reference<UserSessionModel>",
    "methodValue_toServer": "Reference<UserSessionModel>",
    "parseResultReturnType": "{ item: Model_FromServer<UserSessionModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<UserSessionModel> }",
    "hasPaging": true
  },
  {
    "methodName": "UserSessionModel_Select_By__user",
    "methodValue": "Reference<UserModel>",
    "methodValue_toServer": "Reference<UserModel>",
    "parseResultReturnType": "{ items: Model_FromServer<UserSessionModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<UserSessionModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "UserSessionModel_Select_By_activeAccount",
    "methodValue": "Reference<AccountModel>",
    "methodValue_toServer": "Reference<AccountModel>",
    "parseResultReturnType": "{ items: Model_FromServer<UserSessionModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<UserSessionModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "UserSessionModel_Select_By_sessionToken",
    "methodValue": "SecureToken",
    "methodValue_toServer": "SecureToken",
    "parseResultReturnType": "{ items: Model_FromServer<UserSessionModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<UserSessionModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "UserAccountModel_Select_By_id",
    "methodValue": "Reference<UserAccountModel>",
    "methodValue_toServer": "Reference<UserAccountModel>",
    "parseResultReturnType": "{ item: Model_FromServer<UserAccountModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<UserAccountModel> }",
    "hasPaging": true
  },
  {
    "methodName": "UserAccountModel_Select_By__user",
    "methodValue": "Reference<UserModel>",
    "methodValue_toServer": "Reference<UserModel>",
    "parseResultReturnType": "{ items: Model_FromServer<UserAccountModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<UserAccountModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "UserAccountModel_Select_By_account",
    "methodValue": "Reference<AccountModel>",
    "methodValue_toServer": "Reference<AccountModel>",
    "parseResultReturnType": "{ items: Model_FromServer<UserAccountModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<UserAccountModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "AccountModel_Select_By_id",
    "methodValue": "Reference<AccountModel>",
    "methodValue_toServer": "Reference<AccountModel>",
    "parseResultReturnType": "{ item: Model_FromServer<AccountModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<AccountModel> }",
    "hasPaging": true
  },
  {
    "methodName": "AccountModel_Select_By_name",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{ items: Model_FromServer<AccountModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<AccountModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "AccountSystemModel_Select_By_id",
    "methodValue": "Reference<AccountSystemModel>",
    "methodValue_toServer": "Reference<AccountSystemModel>",
    "parseResultReturnType": "{ item: Model_FromServer<AccountSystemModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<AccountSystemModel> }",
    "hasPaging": true
  },
  {
    "methodName": "AccountSystemModel_Select_By_account",
    "methodValue": "Reference<AccountModel>",
    "methodValue_toServer": "Reference<AccountModel>",
    "parseResultReturnType": "{ items: Model_FromServer<AccountSystemModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<AccountSystemModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "PlanModel_Select_By_id",
    "methodValue": "Reference<PlanModel>",
    "methodValue_toServer": "Reference<PlanModel>",
    "parseResultReturnType": "{ item: Model_FromServer<PlanModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<PlanModel> }",
    "hasPaging": true
  },
  {
    "methodName": "AccountPhoneNumberModel_Select_By_id",
    "methodValue": "Reference<AccountPhoneNumberModel>",
    "methodValue_toServer": "Reference<AccountPhoneNumberModel>",
    "parseResultReturnType": "{ item: Model_FromServer<AccountPhoneNumberModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<AccountPhoneNumberModel> }",
    "hasPaging": true
  },
  {
    "methodName": "AccountPhoneNumberModel_Select_By_account",
    "methodValue": "Reference<AccountModel>",
    "methodValue_toServer": "Reference<AccountModel>",
    "parseResultReturnType": "{ items: Model_FromServer<AccountPhoneNumberModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<AccountPhoneNumberModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "AccountPhoneNumberModel_Select_By_name",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{ items: Model_FromServer<AccountPhoneNumberModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<AccountPhoneNumberModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "AccountPhoneNumberModel_Select_By_phoneNumber",
    "methodValue": "PhoneNumber",
    "methodValue_toServer": "PhoneNumber",
    "parseResultReturnType": "{ items: Model_FromServer<AccountPhoneNumberModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<AccountPhoneNumberModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "ContactModel_Select_By_id",
    "methodValue": "Reference<ContactModel>",
    "methodValue_toServer": "Reference<ContactModel>",
    "parseResultReturnType": "{ item: Model_FromServer<ContactModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<ContactModel> }",
    "hasPaging": true
  },
  {
    "methodName": "ContactModel_Select_By_phoneNumber",
    "methodValue": "PhoneNumber",
    "methodValue_toServer": "PhoneNumber",
    "parseResultReturnType": "{ items: Model_FromServer<ContactModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<ContactModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "ContactModel_Select_By_firstName",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{ items: Model_FromServer<ContactModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<ContactModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "ContactModel_Select_By_lastName",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{ items: Model_FromServer<ContactModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<ContactModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "ContactListModel_Select_By_id",
    "methodValue": "Reference<ContactListModel>",
    "methodValue_toServer": "Reference<ContactListModel>",
    "parseResultReturnType": "{ item: Model_FromServer<ContactListModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<ContactListModel> }",
    "hasPaging": true
  },
  {
    "methodName": "ContactListModel_Select_By_name",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{ items: Model_FromServer<ContactListModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<ContactListModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "ContactListModel_Select_By_createdTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{ items: Model_FromServer<ContactListModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<ContactListModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "ContactListModel_Select_By_modifiedTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{ items: Model_FromServer<ContactListModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<ContactListModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "LinkModel_Select_By_id",
    "methodValue": "Reference<LinkModel>",
    "methodValue_toServer": "Reference<LinkModel>",
    "parseResultReturnType": "{ item: Model_FromServer<LinkModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<LinkModel> }",
    "hasPaging": true
  },
  {
    "methodName": "LinkModel_Select_By_messageDelivery",
    "methodValue": "Reference<MessageDeliveryModel>",
    "methodValue_toServer": "Reference<MessageDeliveryModel>",
    "parseResultReturnType": "{ items: Model_FromServer<LinkModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<LinkModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "LinkModel_Select_By_linkToken",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{ items: Model_FromServer<LinkModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<LinkModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageTemplateModel_Select_By_id",
    "methodValue": "Reference<MessageTemplateModel>",
    "methodValue_toServer": "Reference<MessageTemplateModel>",
    "parseResultReturnType": "{ item: Model_FromServer<MessageTemplateModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<MessageTemplateModel> }",
    "hasPaging": true
  },
  {
    "methodName": "MessageTemplateModel_Select_By_campaign",
    "methodValue": "Reference<CampaignModel>",
    "methodValue_toServer": "Reference<CampaignModel>",
    "parseResultReturnType": "{ items: Model_FromServer<MessageTemplateModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageTemplateModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageGeneratedModel_Select_By_id",
    "methodValue": "Reference<MessageGeneratedModel>",
    "methodValue_toServer": "Reference<MessageGeneratedModel>",
    "parseResultReturnType": "{ item: Model_FromServer<MessageGeneratedModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<MessageGeneratedModel> }",
    "hasPaging": true
  },
  {
    "methodName": "MessageGeneratedModel_Select_By_messageTemplate",
    "methodValue": "Reference<MessageTemplateModel>",
    "methodValue_toServer": "Reference<MessageTemplateModel>",
    "parseResultReturnType": "{ items: Model_FromServer<MessageGeneratedModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageGeneratedModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageGeneratedModel_Select_By_fromPhoneNumber",
    "methodValue": "Reference<AccountPhoneNumberModel>",
    "methodValue_toServer": "Reference<AccountPhoneNumberModel>",
    "parseResultReturnType": "{ items: Model_FromServer<MessageGeneratedModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageGeneratedModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageGeneratedModel_Select_By_deliveredAt",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{ items: Model_FromServer<MessageGeneratedModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageGeneratedModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageDeliveryModel_Select_By_id",
    "methodValue": "Reference<MessageDeliveryModel>",
    "methodValue_toServer": "Reference<MessageDeliveryModel>",
    "parseResultReturnType": "{ item: Model_FromServer<MessageDeliveryModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<MessageDeliveryModel> }",
    "hasPaging": true
  },
  {
    "methodName": "MessageDeliveryModel_Select_By_toContact",
    "methodValue": "Reference<ContactModel>",
    "methodValue_toServer": "Reference<ContactModel>",
    "parseResultReturnType": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageDeliveryModel_Select_By_messageTemplate",
    "methodValue": "Reference<MessageTemplateModel>",
    "methodValue_toServer": "Reference<MessageTemplateModel>",
    "parseResultReturnType": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageDeliveryModel_Select_By_createdTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageDeliveryModel_Select_By_fromPhoneNumber",
    "methodValue": "PhoneNumber",
    "methodValue_toServer": "PhoneNumber",
    "parseResultReturnType": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageDeliveryModel_Select_By_toPhoneNumber",
    "methodValue": "PhoneNumber",
    "methodValue_toServer": "PhoneNumber",
    "parseResultReturnType": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageDeliveryModel_Select_By_deliverAtTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageDeliveryModel_Select_By_deliverImmediately",
    "methodValue": "boolean",
    "methodValue_toServer": "boolean",
    "parseResultReturnType": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageDeliveryModel_Select_By_deliveryProcessingStartTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageDeliveryModel_Select_By_deliveryTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageDeliveryModel_Select_By_deliveryResult_deliveredToNetworkTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageDeliveryModel_Select_By_deliveryResult_deliveryFailureTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageDeliveryModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageReplyModel_Select_By_id",
    "methodValue": "Reference<MessageReplyModel>",
    "methodValue_toServer": "Reference<MessageReplyModel>",
    "parseResultReturnType": "{ item: Model_FromServer<MessageReplyModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<MessageReplyModel> }",
    "hasPaging": true
  },
  {
    "methodName": "MessageReplyModel_Select_By_fromContact",
    "methodValue": "Reference<ContactModel>",
    "methodValue_toServer": "Reference<ContactModel>",
    "parseResultReturnType": "{ items: Model_FromServer<MessageReplyModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageReplyModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageReplyModel_Select_By_mostRecentMessageDelivery",
    "methodValue": "Reference<MessageDeliveryModel>",
    "methodValue_toServer": "Reference<MessageDeliveryModel>",
    "parseResultReturnType": "{ items: Model_FromServer<MessageReplyModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageReplyModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageReplyModel_Select_By_createdTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{ items: Model_FromServer<MessageReplyModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageReplyModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "MessageReplyModel_Select_By_deliveredTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{ items: Model_FromServer<MessageReplyModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<MessageReplyModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "CampaignModel_Select_By_id",
    "methodValue": "Reference<CampaignModel>",
    "methodValue_toServer": "Reference<CampaignModel>",
    "parseResultReturnType": "{ item: Model_FromServer<CampaignModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<CampaignModel> }",
    "hasPaging": true
  },
  {
    "methodName": "CampaignModel_Select_By_fromPhoneNumber",
    "methodValue": "Reference<AccountPhoneNumberModel>",
    "methodValue_toServer": "Reference<AccountPhoneNumberModel>",
    "parseResultReturnType": "{ items: Model_FromServer<CampaignModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<CampaignModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "CampaignModel_Select_By_name",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{ items: Model_FromServer<CampaignModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<CampaignModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "CampaignModel_Select_By_status_createdTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{ items: Model_FromServer<CampaignModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<CampaignModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "CampaignModel_Select_By_status_lastActivityTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{ items: Model_FromServer<CampaignModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<CampaignModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "CampaignModel_Select_By_status_nextActivityTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{ items: Model_FromServer<CampaignModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<CampaignModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "CampaignStateModel_Select_By_id",
    "methodValue": "Reference<CampaignStateModel>",
    "methodValue_toServer": "Reference<CampaignStateModel>",
    "parseResultReturnType": "{ item: Model_FromServer<CampaignStateModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<CampaignStateModel> }",
    "hasPaging": true
  },
  {
    "methodName": "CampaignStateModel_Select_By_campaign",
    "methodValue": "Reference<CampaignModel>",
    "methodValue_toServer": "Reference<CampaignModel>",
    "parseResultReturnType": "{ items: Model_FromServer<CampaignStateModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<CampaignStateModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "ContactCampaignStateModel_Select_By_id",
    "methodValue": "Reference<ContactCampaignStateModel>",
    "methodValue_toServer": "Reference<ContactCampaignStateModel>",
    "parseResultReturnType": "{ item: Model_FromServer<ContactCampaignStateModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<ContactCampaignStateModel> }",
    "hasPaging": true
  },
  {
    "methodName": "ContactCampaignStateModel_Select_By_contact",
    "methodValue": "Reference<ContactModel>",
    "methodValue_toServer": "Reference<ContactModel>",
    "parseResultReturnType": "{ items: Model_FromServer<ContactCampaignStateModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<ContactCampaignStateModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "ContactCampaignStateModel_Select_By_campaign",
    "methodValue": "Reference<CampaignModel>",
    "methodValue_toServer": "Reference<CampaignModel>",
    "parseResultReturnType": "{ items: Model_FromServer<ContactCampaignStateModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<ContactCampaignStateModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "ContactCampaignStateModel_Select_By_openedTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{ items: Model_FromServer<ContactCampaignStateModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<ContactCampaignStateModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "ContactCampaignStateModel_Select_By_expirationTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{ items: Model_FromServer<ContactCampaignStateModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<ContactCampaignStateModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "BandwidthEventModel_Select_By_id",
    "methodValue": "Reference<BandwidthEventModel>",
    "methodValue_toServer": "Reference<BandwidthEventModel>",
    "parseResultReturnType": "{ item: Model_FromServer<BandwidthEventModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<BandwidthEventModel> }",
    "hasPaging": true
  },
  {
    "methodName": "BandwidthEventModel_Select_By_createdAt",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{ items: Model_FromServer<BandwidthEventModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<BandwidthEventModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "TaskStateModel_Select_By_id",
    "methodValue": "Reference<TaskStateModel>",
    "methodValue_toServer": "Reference<TaskStateModel>",
    "parseResultReturnType": "{ item: Model_FromServer<TaskStateModel> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<TaskStateModel> }",
    "hasPaging": true
  },
  {
    "methodName": "TaskStateModel_Select_By_taskKey",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{ items: Model_FromServer<TaskStateModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<TaskStateModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "TaskStateModel_Select_By_startTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{ items: Model_FromServer<TaskStateModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<TaskStateModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "TaskStateModel_Select_By_endTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{ items: Model_FromServer<TaskStateModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<TaskStateModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "ContactListModel_Contacts_2_ContactModel_State_ContactLists_Select_By_id",
    "methodValue": "Reference<ContactListModel_Contacts_2_ContactModel_State_ContactLists>",
    "methodValue_toServer": "Reference<ContactListModel_Contacts_2_ContactModel_State_ContactLists>",
    "parseResultReturnType": "{ item: Model_FromServer<ContactListModel_Contacts_2_ContactModel_State_ContactLists> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<ContactListModel_Contacts_2_ContactModel_State_ContactLists> }",
    "hasPaging": true
  },
  {
    "methodName": "ContactListModel_Contacts_2_ContactModel_State_ContactLists_Select_By_state_contactLists_mmfk",
    "methodValue": "Reference<ContactListModel>",
    "methodValue_toServer": "Reference<ContactListModel>",
    "parseResultReturnType": "{ items: Model_FromServer<ContactListModel_Contacts_2_ContactModel_State_ContactLists>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<ContactListModel_Contacts_2_ContactModel_State_ContactLists>[] }",
    "hasPaging": true
  },
  {
    "methodName": "ContactListModel_Contacts_2_ContactModel_State_ContactLists_Select_By_contacts_mmfk",
    "methodValue": "Reference<ContactModel>",
    "methodValue_toServer": "Reference<ContactModel>",
    "parseResultReturnType": "{ items: Model_FromServer<ContactListModel_Contacts_2_ContactModel_State_ContactLists>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<ContactListModel_Contacts_2_ContactModel_State_ContactLists>[] }",
    "hasPaging": true
  },
  {
    "methodName": "CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_Select_By_id",
    "methodValue": "Reference<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>",
    "methodValue_toServer": "Reference<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>",
    "parseResultReturnType": "{ item: Model_FromServer<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns> }",
    "parseResultReturnType_FromServer": "{ item:  Model_FromServer<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns> }",
    "hasPaging": true
  },
  {
    "methodName": "CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_Select_By_usedByCampaigns_mmfk",
    "methodValue": "Reference<CampaignModel>",
    "methodValue_toServer": "Reference<CampaignModel>",
    "parseResultReturnType": "{ items: Model_FromServer<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>[] }",
    "hasPaging": true
  },
  {
    "methodName": "CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_Select_By_toContactLists_mmfk",
    "methodValue": "Reference<ContactListModel>",
    "methodValue_toServer": "Reference<ContactListModel>",
    "parseResultReturnType": "{ items: Model_FromServer<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>[] }",
    "hasPaging": true
  },
  {
    "methodName": "UserModel_SelectCount_By_id",
    "methodValue": "Reference<UserModel>",
    "methodValue_toServer": "Reference<UserModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "UserModel_SelectCount_By_activeAccount",
    "methodValue": "Reference<AccountModel>",
    "methodValue_toServer": "Reference<AccountModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "UserModel_SelectCount_By_username",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "UserModel_SelectCount_By_phone",
    "methodValue": "PhoneNumber",
    "methodValue_toServer": "PhoneNumber",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "UserPasswordClientSaltModel_SelectCount_By_id",
    "methodValue": "Reference<UserPasswordClientSaltModel>",
    "methodValue_toServer": "Reference<UserPasswordClientSaltModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "UserPasswordClientSaltModel_SelectCount_By_usernameClaim",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "UserSessionModel_SelectCount_By_id",
    "methodValue": "Reference<UserSessionModel>",
    "methodValue_toServer": "Reference<UserSessionModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "UserSessionModel_SelectCount_By__user",
    "methodValue": "Reference<UserModel>",
    "methodValue_toServer": "Reference<UserModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "UserSessionModel_SelectCount_By_activeAccount",
    "methodValue": "Reference<AccountModel>",
    "methodValue_toServer": "Reference<AccountModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "UserSessionModel_SelectCount_By_sessionToken",
    "methodValue": "SecureToken",
    "methodValue_toServer": "SecureToken",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "UserAccountModel_SelectCount_By_id",
    "methodValue": "Reference<UserAccountModel>",
    "methodValue_toServer": "Reference<UserAccountModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "UserAccountModel_SelectCount_By__user",
    "methodValue": "Reference<UserModel>",
    "methodValue_toServer": "Reference<UserModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "UserAccountModel_SelectCount_By_account",
    "methodValue": "Reference<AccountModel>",
    "methodValue_toServer": "Reference<AccountModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "AccountModel_SelectCount_By_id",
    "methodValue": "Reference<AccountModel>",
    "methodValue_toServer": "Reference<AccountModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "AccountModel_SelectCount_By_name",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "AccountSystemModel_SelectCount_By_id",
    "methodValue": "Reference<AccountSystemModel>",
    "methodValue_toServer": "Reference<AccountSystemModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "AccountSystemModel_SelectCount_By_account",
    "methodValue": "Reference<AccountModel>",
    "methodValue_toServer": "Reference<AccountModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "PlanModel_SelectCount_By_id",
    "methodValue": "Reference<PlanModel>",
    "methodValue_toServer": "Reference<PlanModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "AccountPhoneNumberModel_SelectCount_By_id",
    "methodValue": "Reference<AccountPhoneNumberModel>",
    "methodValue_toServer": "Reference<AccountPhoneNumberModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "AccountPhoneNumberModel_SelectCount_By_account",
    "methodValue": "Reference<AccountModel>",
    "methodValue_toServer": "Reference<AccountModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "AccountPhoneNumberModel_SelectCount_By_name",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "AccountPhoneNumberModel_SelectCount_By_phoneNumber",
    "methodValue": "PhoneNumber",
    "methodValue_toServer": "PhoneNumber",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactModel_SelectCount_By_id",
    "methodValue": "Reference<ContactModel>",
    "methodValue_toServer": "Reference<ContactModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactModel_SelectCount_By_phoneNumber",
    "methodValue": "PhoneNumber",
    "methodValue_toServer": "PhoneNumber",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactModel_SelectCount_By_firstName",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactModel_SelectCount_By_lastName",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactListModel_SelectCount_By_id",
    "methodValue": "Reference<ContactListModel>",
    "methodValue_toServer": "Reference<ContactListModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactListModel_SelectCount_By_name",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactListModel_SelectCount_By_createdTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactListModel_SelectCount_By_modifiedTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "LinkModel_SelectCount_By_id",
    "methodValue": "Reference<LinkModel>",
    "methodValue_toServer": "Reference<LinkModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "LinkModel_SelectCount_By_messageDelivery",
    "methodValue": "Reference<MessageDeliveryModel>",
    "methodValue_toServer": "Reference<MessageDeliveryModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "LinkModel_SelectCount_By_linkToken",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageTemplateModel_SelectCount_By_id",
    "methodValue": "Reference<MessageTemplateModel>",
    "methodValue_toServer": "Reference<MessageTemplateModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageTemplateModel_SelectCount_By_campaign",
    "methodValue": "Reference<CampaignModel>",
    "methodValue_toServer": "Reference<CampaignModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageGeneratedModel_SelectCount_By_id",
    "methodValue": "Reference<MessageGeneratedModel>",
    "methodValue_toServer": "Reference<MessageGeneratedModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageGeneratedModel_SelectCount_By_messageTemplate",
    "methodValue": "Reference<MessageTemplateModel>",
    "methodValue_toServer": "Reference<MessageTemplateModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageGeneratedModel_SelectCount_By_fromPhoneNumber",
    "methodValue": "Reference<AccountPhoneNumberModel>",
    "methodValue_toServer": "Reference<AccountPhoneNumberModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageGeneratedModel_SelectCount_By_deliveredAt",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageDeliveryModel_SelectCount_By_id",
    "methodValue": "Reference<MessageDeliveryModel>",
    "methodValue_toServer": "Reference<MessageDeliveryModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageDeliveryModel_SelectCount_By_toContact",
    "methodValue": "Reference<ContactModel>",
    "methodValue_toServer": "Reference<ContactModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageDeliveryModel_SelectCount_By_messageTemplate",
    "methodValue": "Reference<MessageTemplateModel>",
    "methodValue_toServer": "Reference<MessageTemplateModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageDeliveryModel_SelectCount_By_createdTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageDeliveryModel_SelectCount_By_fromPhoneNumber",
    "methodValue": "PhoneNumber",
    "methodValue_toServer": "PhoneNumber",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageDeliveryModel_SelectCount_By_toPhoneNumber",
    "methodValue": "PhoneNumber",
    "methodValue_toServer": "PhoneNumber",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageDeliveryModel_SelectCount_By_deliverAtTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageDeliveryModel_SelectCount_By_deliverImmediately",
    "methodValue": "boolean",
    "methodValue_toServer": "boolean",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageDeliveryModel_SelectCount_By_deliveryProcessingStartTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageDeliveryModel_SelectCount_By_deliveryTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageDeliveryModel_SelectCount_By_deliveryResult_deliveredToNetworkTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageDeliveryModel_SelectCount_By_deliveryResult_deliveryFailureTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageReplyModel_SelectCount_By_id",
    "methodValue": "Reference<MessageReplyModel>",
    "methodValue_toServer": "Reference<MessageReplyModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageReplyModel_SelectCount_By_fromContact",
    "methodValue": "Reference<ContactModel>",
    "methodValue_toServer": "Reference<ContactModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageReplyModel_SelectCount_By_mostRecentMessageDelivery",
    "methodValue": "Reference<MessageDeliveryModel>",
    "methodValue_toServer": "Reference<MessageDeliveryModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageReplyModel_SelectCount_By_createdTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "MessageReplyModel_SelectCount_By_deliveredTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "CampaignModel_SelectCount_By_id",
    "methodValue": "Reference<CampaignModel>",
    "methodValue_toServer": "Reference<CampaignModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "CampaignModel_SelectCount_By_fromPhoneNumber",
    "methodValue": "Reference<AccountPhoneNumberModel>",
    "methodValue_toServer": "Reference<AccountPhoneNumberModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "CampaignModel_SelectCount_By_name",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "CampaignModel_SelectCount_By_status_createdTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "CampaignModel_SelectCount_By_status_lastActivityTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "CampaignModel_SelectCount_By_status_nextActivityTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "CampaignStateModel_SelectCount_By_id",
    "methodValue": "Reference<CampaignStateModel>",
    "methodValue_toServer": "Reference<CampaignStateModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "CampaignStateModel_SelectCount_By_campaign",
    "methodValue": "Reference<CampaignModel>",
    "methodValue_toServer": "Reference<CampaignModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactCampaignStateModel_SelectCount_By_id",
    "methodValue": "Reference<ContactCampaignStateModel>",
    "methodValue_toServer": "Reference<ContactCampaignStateModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactCampaignStateModel_SelectCount_By_contact",
    "methodValue": "Reference<ContactModel>",
    "methodValue_toServer": "Reference<ContactModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactCampaignStateModel_SelectCount_By_campaign",
    "methodValue": "Reference<CampaignModel>",
    "methodValue_toServer": "Reference<CampaignModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactCampaignStateModel_SelectCount_By_openedTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactCampaignStateModel_SelectCount_By_expirationTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "BandwidthEventModel_SelectCount_By_id",
    "methodValue": "Reference<BandwidthEventModel>",
    "methodValue_toServer": "Reference<BandwidthEventModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "BandwidthEventModel_SelectCount_By_createdAt",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "TaskStateModel_SelectCount_By_id",
    "methodValue": "Reference<TaskStateModel>",
    "methodValue_toServer": "Reference<TaskStateModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "TaskStateModel_SelectCount_By_taskKey",
    "methodValue": "string",
    "methodValue_toServer": "string",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "TaskStateModel_SelectCount_By_startTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "TaskStateModel_SelectCount_By_endTime",
    "methodValue": "Timestamp",
    "methodValue_toServer": "Timestamp",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactListModel_Contacts_2_ContactModel_State_ContactLists_SelectCount_By_id",
    "methodValue": "Reference<ContactListModel_Contacts_2_ContactModel_State_ContactLists>",
    "methodValue_toServer": "Reference<ContactListModel_Contacts_2_ContactModel_State_ContactLists>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactListModel_Contacts_2_ContactModel_State_ContactLists_SelectCount_By_state_contactLists_mmfk",
    "methodValue": "Reference<ContactListModel>",
    "methodValue_toServer": "Reference<ContactListModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactListModel_Contacts_2_ContactModel_State_ContactLists_SelectCount_By_contacts_mmfk",
    "methodValue": "Reference<ContactModel>",
    "methodValue_toServer": "Reference<ContactModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_SelectCount_By_id",
    "methodValue": "Reference<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>",
    "methodValue_toServer": "Reference<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_SelectCount_By_usedByCampaigns_mmfk",
    "methodValue": "Reference<CampaignModel>",
    "methodValue_toServer": "Reference<CampaignModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_SelectCount_By_toContactLists_mmfk",
    "methodValue": "Reference<ContactListModel>",
    "methodValue_toServer": "Reference<ContactListModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactModel_Select_By_state_contactLists",
    "methodValue": "Reference<ContactListModel>",
    "methodValue_toServer": "Reference<ContactListModel>",
    "parseResultReturnType": "{ items: Model_FromServer<ContactModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<ContactModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "ContactListModel_Select_By_contacts",
    "methodValue": "Reference<ContactModel>",
    "methodValue_toServer": "Reference<ContactModel>",
    "parseResultReturnType": "{ items: Model_FromServer<ContactListModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<ContactListModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "ContactListModel_Select_By_usedByCampaigns",
    "methodValue": "Reference<CampaignModel>",
    "methodValue_toServer": "Reference<CampaignModel>",
    "parseResultReturnType": "{ items: Model_FromServer<ContactListModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<ContactListModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "CampaignModel_Select_By_toContactLists",
    "methodValue": "Reference<ContactListModel>",
    "methodValue_toServer": "Reference<ContactListModel>",
    "parseResultReturnType": "{ items: Model_FromServer<CampaignModel>[] }",
    "parseResultReturnType_FromServer": "{ items: Model_FromServer<CampaignModel>[] }",
    "hasPaging": true
  },
  {
    "methodName": "ContactModel_SelectCount_By_state_contactLists",
    "methodValue": "Reference<ContactListModel>",
    "methodValue_toServer": "Reference<ContactListModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactListModel_SelectCount_By_contacts",
    "methodValue": "Reference<ContactModel>",
    "methodValue_toServer": "Reference<ContactModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "ContactListModel_SelectCount_By_usedByCampaigns",
    "methodValue": "Reference<CampaignModel>",
    "methodValue_toServer": "Reference<CampaignModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "CampaignModel_SelectCount_By_toContactLists",
    "methodValue": "Reference<ContactListModel>",
    "methodValue_toServer": "Reference<ContactListModel>",
    "parseResultReturnType": "{count: number}",
    "parseResultReturnType_FromServer": "{count: number}",
    "hasPaging": false
  },
  {
    "methodName": "UserModel_Insert",
    "methodValue": "Model_ToServer<UserModel>",
    "methodValue_toServer": "Model_ToServer<UserModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "UserPasswordClientSaltModel_Insert",
    "methodValue": "Model_ToServer<UserPasswordClientSaltModel>",
    "methodValue_toServer": "Model_ToServer<UserPasswordClientSaltModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "UserSessionModel_Insert",
    "methodValue": "Model_ToServer<UserSessionModel>",
    "methodValue_toServer": "Model_ToServer<UserSessionModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "UserAccountModel_Insert",
    "methodValue": "Model_ToServer<UserAccountModel>",
    "methodValue_toServer": "Model_ToServer<UserAccountModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "AccountModel_Insert",
    "methodValue": "Model_ToServer<AccountModel>",
    "methodValue_toServer": "Model_ToServer<AccountModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "AccountSystemModel_Insert",
    "methodValue": "Model_ToServer<AccountSystemModel>",
    "methodValue_toServer": "Model_ToServer<AccountSystemModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "PlanModel_Insert",
    "methodValue": "Model_ToServer<PlanModel>",
    "methodValue_toServer": "Model_ToServer<PlanModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "AccountPhoneNumberModel_Insert",
    "methodValue": "Model_ToServer<AccountPhoneNumberModel>",
    "methodValue_toServer": "Model_ToServer<AccountPhoneNumberModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "ContactModel_Insert",
    "methodValue": "Model_ToServer<ContactModel>",
    "methodValue_toServer": "Model_ToServer<ContactModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "ContactListModel_Insert",
    "methodValue": "Model_ToServer<ContactListModel>",
    "methodValue_toServer": "Model_ToServer<ContactListModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "LinkModel_Insert",
    "methodValue": "Model_ToServer<LinkModel>",
    "methodValue_toServer": "Model_ToServer<LinkModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "MessageTemplateModel_Insert",
    "methodValue": "Model_ToServer<MessageTemplateModel>",
    "methodValue_toServer": "Model_ToServer<MessageTemplateModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "MessageGeneratedModel_Insert",
    "methodValue": "Model_ToServer<MessageGeneratedModel>",
    "methodValue_toServer": "Model_ToServer<MessageGeneratedModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "MessageDeliveryModel_Insert",
    "methodValue": "Model_ToServer<MessageDeliveryModel>",
    "methodValue_toServer": "Model_ToServer<MessageDeliveryModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "MessageReplyModel_Insert",
    "methodValue": "Model_ToServer<MessageReplyModel>",
    "methodValue_toServer": "Model_ToServer<MessageReplyModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "CampaignModel_Insert",
    "methodValue": "Model_ToServer<CampaignModel>",
    "methodValue_toServer": "Model_ToServer<CampaignModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "CampaignStateModel_Insert",
    "methodValue": "Model_ToServer<CampaignStateModel>",
    "methodValue_toServer": "Model_ToServer<CampaignStateModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "ContactCampaignStateModel_Insert",
    "methodValue": "Model_ToServer<ContactCampaignStateModel>",
    "methodValue_toServer": "Model_ToServer<ContactCampaignStateModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "BandwidthEventModel_Insert",
    "methodValue": "Model_ToServer<BandwidthEventModel>",
    "methodValue_toServer": "Model_ToServer<BandwidthEventModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "TaskStateModel_Insert",
    "methodValue": "Model_ToServer<TaskStateModel>",
    "methodValue_toServer": "Model_ToServer<TaskStateModel>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "ContactListModel_Contacts_2_ContactModel_State_ContactLists_Insert",
    "methodValue": "Model_ToServer<ContactListModel_Contacts_2_ContactModel_State_ContactLists>",
    "methodValue_toServer": "Model_ToServer<ContactListModel_Contacts_2_ContactModel_State_ContactLists>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_Insert",
    "methodValue": "Model_ToServer<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>",
    "methodValue_toServer": "Model_ToServer<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>",
    "parseResultReturnType": "{ newId: string }",
    "parseResultReturnType_FromServer": "{ newId: string }",
    "hasPaging": false
  },
  {
    "methodName": "UserModel_Update",
    "methodValue": "Model_ToServer<UserModel>",
    "methodValue_toServer": "Model_ToServer<UserModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "UserPasswordClientSaltModel_Update",
    "methodValue": "Model_ToServer<UserPasswordClientSaltModel>",
    "methodValue_toServer": "Model_ToServer<UserPasswordClientSaltModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "UserSessionModel_Update",
    "methodValue": "Model_ToServer<UserSessionModel>",
    "methodValue_toServer": "Model_ToServer<UserSessionModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "UserAccountModel_Update",
    "methodValue": "Model_ToServer<UserAccountModel>",
    "methodValue_toServer": "Model_ToServer<UserAccountModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "AccountModel_Update",
    "methodValue": "Model_ToServer<AccountModel>",
    "methodValue_toServer": "Model_ToServer<AccountModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "AccountSystemModel_Update",
    "methodValue": "Model_ToServer<AccountSystemModel>",
    "methodValue_toServer": "Model_ToServer<AccountSystemModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "PlanModel_Update",
    "methodValue": "Model_ToServer<PlanModel>",
    "methodValue_toServer": "Model_ToServer<PlanModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "AccountPhoneNumberModel_Update",
    "methodValue": "Model_ToServer<AccountPhoneNumberModel>",
    "methodValue_toServer": "Model_ToServer<AccountPhoneNumberModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "ContactModel_Update",
    "methodValue": "Model_ToServer<ContactModel>",
    "methodValue_toServer": "Model_ToServer<ContactModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "ContactListModel_Update",
    "methodValue": "Model_ToServer<ContactListModel>",
    "methodValue_toServer": "Model_ToServer<ContactListModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "LinkModel_Update",
    "methodValue": "Model_ToServer<LinkModel>",
    "methodValue_toServer": "Model_ToServer<LinkModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "MessageTemplateModel_Update",
    "methodValue": "Model_ToServer<MessageTemplateModel>",
    "methodValue_toServer": "Model_ToServer<MessageTemplateModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "MessageGeneratedModel_Update",
    "methodValue": "Model_ToServer<MessageGeneratedModel>",
    "methodValue_toServer": "Model_ToServer<MessageGeneratedModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "MessageDeliveryModel_Update",
    "methodValue": "Model_ToServer<MessageDeliveryModel>",
    "methodValue_toServer": "Model_ToServer<MessageDeliveryModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "MessageReplyModel_Update",
    "methodValue": "Model_ToServer<MessageReplyModel>",
    "methodValue_toServer": "Model_ToServer<MessageReplyModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "CampaignModel_Update",
    "methodValue": "Model_ToServer<CampaignModel>",
    "methodValue_toServer": "Model_ToServer<CampaignModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "CampaignStateModel_Update",
    "methodValue": "Model_ToServer<CampaignStateModel>",
    "methodValue_toServer": "Model_ToServer<CampaignStateModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "ContactCampaignStateModel_Update",
    "methodValue": "Model_ToServer<ContactCampaignStateModel>",
    "methodValue_toServer": "Model_ToServer<ContactCampaignStateModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "BandwidthEventModel_Update",
    "methodValue": "Model_ToServer<BandwidthEventModel>",
    "methodValue_toServer": "Model_ToServer<BandwidthEventModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "TaskStateModel_Update",
    "methodValue": "Model_ToServer<TaskStateModel>",
    "methodValue_toServer": "Model_ToServer<TaskStateModel>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "ContactListModel_Contacts_2_ContactModel_State_ContactLists_Update",
    "methodValue": "Model_ToServer<ContactListModel_Contacts_2_ContactModel_State_ContactLists>",
    "methodValue_toServer": "Model_ToServer<ContactListModel_Contacts_2_ContactModel_State_ContactLists>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_Update",
    "methodValue": "Model_ToServer<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>",
    "methodValue_toServer": "Model_ToServer<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>",
    "parseResultReturnType": "{ updatedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ updatedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "UserModel_Delete",
    "methodValue": "Reference<UserModel>",
    "methodValue_toServer": "Reference<UserModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "UserPasswordClientSaltModel_Delete",
    "methodValue": "Reference<UserPasswordClientSaltModel>",
    "methodValue_toServer": "Reference<UserPasswordClientSaltModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "UserSessionModel_Delete",
    "methodValue": "Reference<UserSessionModel>",
    "methodValue_toServer": "Reference<UserSessionModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "UserAccountModel_Delete",
    "methodValue": "Reference<UserAccountModel>",
    "methodValue_toServer": "Reference<UserAccountModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "AccountModel_Delete",
    "methodValue": "Reference<AccountModel>",
    "methodValue_toServer": "Reference<AccountModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "AccountSystemModel_Delete",
    "methodValue": "Reference<AccountSystemModel>",
    "methodValue_toServer": "Reference<AccountSystemModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "PlanModel_Delete",
    "methodValue": "Reference<PlanModel>",
    "methodValue_toServer": "Reference<PlanModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "AccountPhoneNumberModel_Delete",
    "methodValue": "Reference<AccountPhoneNumberModel>",
    "methodValue_toServer": "Reference<AccountPhoneNumberModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "ContactModel_Delete",
    "methodValue": "Reference<ContactModel>",
    "methodValue_toServer": "Reference<ContactModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "ContactListModel_Delete",
    "methodValue": "Reference<ContactListModel>",
    "methodValue_toServer": "Reference<ContactListModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "LinkModel_Delete",
    "methodValue": "Reference<LinkModel>",
    "methodValue_toServer": "Reference<LinkModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "MessageTemplateModel_Delete",
    "methodValue": "Reference<MessageTemplateModel>",
    "methodValue_toServer": "Reference<MessageTemplateModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "MessageGeneratedModel_Delete",
    "methodValue": "Reference<MessageGeneratedModel>",
    "methodValue_toServer": "Reference<MessageGeneratedModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "MessageDeliveryModel_Delete",
    "methodValue": "Reference<MessageDeliveryModel>",
    "methodValue_toServer": "Reference<MessageDeliveryModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "MessageReplyModel_Delete",
    "methodValue": "Reference<MessageReplyModel>",
    "methodValue_toServer": "Reference<MessageReplyModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "CampaignModel_Delete",
    "methodValue": "Reference<CampaignModel>",
    "methodValue_toServer": "Reference<CampaignModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "CampaignStateModel_Delete",
    "methodValue": "Reference<CampaignStateModel>",
    "methodValue_toServer": "Reference<CampaignStateModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "ContactCampaignStateModel_Delete",
    "methodValue": "Reference<ContactCampaignStateModel>",
    "methodValue_toServer": "Reference<ContactCampaignStateModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "BandwidthEventModel_Delete",
    "methodValue": "Reference<BandwidthEventModel>",
    "methodValue_toServer": "Reference<BandwidthEventModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "TaskStateModel_Delete",
    "methodValue": "Reference<TaskStateModel>",
    "methodValue_toServer": "Reference<TaskStateModel>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "ContactListModel_Contacts_2_ContactModel_State_ContactLists_Delete",
    "methodValue": "Reference<ContactListModel_Contacts_2_ContactModel_State_ContactLists>",
    "methodValue_toServer": "Reference<ContactListModel_Contacts_2_ContactModel_State_ContactLists>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  },
  {
    "methodName": "CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_Delete",
    "methodValue": "Reference<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>",
    "methodValue_toServer": "Reference<CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns>",
    "parseResultReturnType": "{ deletedItemsCount: number }",
    "parseResultReturnType_FromServer": "{ deletedItemsCount: number }",
    "hasPaging": false
  }
];
