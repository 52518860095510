import { AppConfig_Client } from "../_config/client/appConfig_client";
import { AdminApiAccess } from "./types/AdminApiAccess";
import { UserCredentialsProvider } from "../data-types/SecurityDataTypes";
import { createAdminApiWebClient } from "../api/admin/client/AdminApiWebClient";
import { Reference } from "../data-types/SimpleDataTypes";
import { RoleKind, UserModel } from "../data-types/ModelTypes";

export const createAdminApiAccess = (appConfig: AppConfig_Client, userCredentialsProvider: UserCredentialsProvider, onAuthStateChange: () => Promise<void>) => {

    const adminApi = createAdminApiWebClient(appConfig, userCredentialsProvider);

    const apiAccess: AdminApiAccess = {
        getAdmins: async () => (await adminApi.getAdmins({})).users,
        findUsers: async (search: string) => (await adminApi.findUsers({ search })).users,
        setAdminRole: async (user: Reference<UserModel>, role: RoleKind) => (await adminApi.setAdminRole({ user, role })),
        getAccountStates: async () => {
            const accounts = await adminApi.getAccounts({});
            return accounts.map(x => ({
                id: x.accountData.id,
                accountInfo: x.accountInfo,
                accountState: x.accountState,
                accountData: x.accountData,
                userEmails: x.accountUsers.map(x => x.email),
                accountAdminSettings: x.accountAdminSettings ?? {},
                // accountPhoneNumbers: x.accountPhoneNumbers,
            }));
        },
        setAccountAdminSettings: async (account, settings) => {
            await adminApi.setAccountAdminSettings({ account, settings });
        },
        setAccountSubscription: async (account, subscription) => {
            await adminApi.setAccountSubscription({ account, subscription });
        },
        addUserToAccount: async (account, user) => {
            await adminApi.addUserToAccount({ account, user });
        },
        removeUserFromAccount: async (account, user) => {
            await adminApi.removeUserFromAccount({ account, user });
        },
        createNewAccount: async () => {
            const result = await adminApi.createNewAccount({});
            return apiAccess.impersonateAccount(result.newAccount);
        },
        impersonateAccount: async (account) => {
            const result = await adminApi.impersonateAccount({ account });
            userCredentialsProvider.setUserCredentials(result.newUserCredentials ?? null);
            await onAuthStateChange();
        },
        stopImpersonation: async () => {
            const result = await adminApi.stopImpersonation({});
            userCredentialsProvider.setUserCredentials(result.newUserCredentials ?? null);
            await onAuthStateChange();
        },
    };

    return apiAccess;
};