import React, { useEffect, useState } from 'react';
import { View, Text, ActivityIndicator } from 'react-native';
import { Store } from '../../store/Store';
import { useLoadData, useAutoLoadingError } from '../../../utils/Hooks';
import { List } from '../../components/List';
import { AccountModel, AccountAdminSettings, UserModel } from '../../../data-types/ModelTypes';
import { Timestamp } from '../../../utils/Time';
import { ButtonTextView, TextView, TextField, TextInputView } from '../../components/TextView';
import { MainLayout } from '../../components/Layout';
import { ErrorBox } from '../../components/ErrorBox';
import { NavLinks } from '../Common/NavLinks';
import { getReference, getReferenceOrUndefined, Price, PhoneNumber, EmailAddress, getReferenceFromId, Reference } from '../../../data-types/SimpleDataTypes';
import { CampaignLink, useReferenceList_LoadAll } from '../Common/ReferenceLinks';
import { ViewKeywords } from '../Keywords/ViewKeywords';
import { AccountAdminData } from '../../../client-api-access/types/AdminApiAccess';
import { formatPhoneNumber_UsaCanada, toStandardPhoneNumber_optional, toStandardPhoneNumber } from '../../../utils/PhoneNumbers';
import { Validation_PhoneNumber_UsaCanada } from '../../../utils/Validation';
import { AppError } from '../../../utils/Errors';
import { UserAdminInfo } from '../../../api/admin/client/AdminApiWebClientTypes';

export const ViewAdminsPage = (props: { store: Store }) => {
    const { store } = props;
    const { api, admin, theme } = store;
    const { loading, error, doWork } = useAutoLoadingError();
    const [adminUsers, setAdminUsers] = useState(null as null | UserAdminInfo[]);
    const [userSearch, setUserSearch] = useState('');
    const [usersFound, setUsersFound] = useState(null as null | UserAdminInfo[]);

    useEffect(() => {
        doWork(async (stopIfObsolete) => {
            getAdminUsers(stopIfObsolete);
        });
    }, []);

    const getAdminUsers = async (stopIfObsolete: () => void) => {
        const userResult = await admin.getAdmins();
        stopIfObsolete();
        setAdminUsers(userResult.sort((a, b) => a.username.localeCompare(b.username)) ?? null);
    };

    const findUser = () => {
        doWork(async (stopIfObsolete) => {
            const userResult = await admin.findUsers(userSearch);
            stopIfObsolete();

            setUsersFound(userResult.sort((a, b) => a.username.localeCompare(b.username)) ?? null);
        });
    };
    const addAdmin = (user: UserAdminInfo) => {
        doWork(async (stopIfObsolete) => {
            await admin.setAdminRole(user.user, 'admin');
            stopIfObsolete();
            setUsersFound(s => (s ?? []).filter(x => x.user.id !== user.user.id));
            await getAdminUsers(stopIfObsolete);
        });
    };
    const removeAdmin = (user: UserAdminInfo) => {
        doWork(async (stopIfObsolete) => {
            await admin.setAdminRole(user.user, 'none');
            stopIfObsolete();
            setUsersFound(s => (s ?? []).filter(x => x.user.id !== user.user.id));
            await getAdminUsers(stopIfObsolete);
        });
    };

    return (
        <MainLayout store={store}>
            {loading && <ActivityIndicator size="large" />}
            {error && <ErrorBox error={error} />}
            <View style={theme.card.view_white}>
                <TextView style={theme.card.cardSectionTitle} text={'Administrators'} />
                {adminUsers?.map(x => (
                    <View key={x.user.id} style={{ paddingTop: 4, borderTopWidth: 1, borderTopColor: theme.card.view_white.borderBottomColor }}>
                        <View style={theme.card.rowWrap}>
                            <TextView style={theme.card.cardSummary} text={x.username} />
                            <TextView style={theme.card.cardSummary} text={x.phone ? formatPhoneNumber_UsaCanada(x.phone) : ''} />
                            <View style={{ flex: 1 }} />
                            <TextView style={theme.card.cardSummary} text={x.role} />
                        </View>
                        <View style={theme.card.buttonRow}>
                            <View style={{ flex: 1 }} />
                            {x.role !== 'super-admin' && (
                                <ButtonTextView style={theme.card.buttonTextView_danger} text={'Remove as Admin'} onPress={() => removeAdmin(x)} />
                            )}
                        </View>
                    </View>
                ))}
            </View>
            <View style={theme.card.view_white}>
                <TextView style={theme.card.cardSectionTitle} text={'Add Administrator'} />
                <View style={theme.card.rowWrap}>
                    <TextField
                        style={theme.card.textField}
                        field={{
                            __type: 'ui',
                            isRequired: false,
                            label: 'Username, Email, or Phone',
                            hint: `Enter user's email to add that User as an admin`,
                        }}
                        value={userSearch}
                        onChangeValue={x => setUserSearch(x?.value ?? '')}
                    />
                </View>
                <View style={theme.card.buttonRow}>
                    <ButtonTextView style={theme.card.buttonTextView_minor} text={'Find User'} isDisabled={!userSearch} onPress={() => findUser()} />
                    <View style={{ flex: 1 }} />
                </View>
                {usersFound?.map(x => (
                    <View key={x.user.id} style={{ paddingTop: 4, borderTopWidth: 1, borderTopColor: theme.card.view_white.borderBottomColor }}>
                        <View style={theme.card.rowWrap}>
                            <TextView style={theme.card.cardSummary} text={x.username} />
                            <TextView style={theme.card.cardSummary} text={x.phone ? formatPhoneNumber_UsaCanada(x.phone) : ''} />
                            <View style={{ flex: 1 }} />
                            <TextView style={theme.card.cardSummary} text={x.role} />
                        </View>
                        <View style={theme.card.buttonRow}>
                            <View style={{ flex: 1 }} />
                            <ButtonTextView style={theme.card.buttonTextView_major} text={'Add Admin'} onPress={() => addAdmin(x)} />
                        </View>
                    </View>
                ))}

            </View>
        </MainLayout>
    );
};

const UserList = () => {

};
