import React, { useState } from 'react';
import { View, Text, ActivityIndicator, Platform, Alert, TextStyle, ViewStyle } from 'react-native';
import { TextViewStyle, theme } from '../style/Theme';
import { TextView, ButtonTextView } from './TextView';

export type AlertModalStyle = {
    container: ViewStyle,
    background: ViewStyle,
    view: ViewStyle,
    title: TextViewStyle,
    message: TextViewStyle,
    buttonContainer: ViewStyle,
    button: TextViewStyle,
};
export function useAlertModal(options: { title: string, message: string, buttons: { text: string, onPress: () => void, webStyle?: TextViewStyle }[] }) {
    const isWeb = Platform.OS == 'web';
    const [isVisible, setIsVisible] = useState(false);
    const { title, message, buttons } = options;
    return {
        showAlert: () => {
            if (isWeb) {
                setIsVisible(true);
            } else {
                Alert.prompt(title, message, buttons);
            }
        },
        AlertHost: (props: { webStyle?: AlertModalStyle, useFixed?: boolean }) => {
            const { webStyle = theme.alertModalWeb } = props;
            return (<>
                {isWeb && isVisible && (
                    <View style={{ ...webStyle.container, position: props.useFixed ? 'fixed' : 'absolute' }}>
                        <View style={webStyle.background}>
                        </View>
                        <View style={webStyle.view}>
                            <TextView style={webStyle.title} text={title} />
                            <TextView style={webStyle.message} text={message} />
                            <View style={webStyle.buttonContainer}>
                                <View style={{ flex: 1 }} />
                                {buttons.map(x => (
                                    <ButtonTextView key={x.text} style={x.webStyle || webStyle.button} text={x.text} onPress={() => {
                                        setIsVisible(false);
                                        x.onPress();
                                    }} />
                                ))}
                            </View>
                        </View>
                    </View>
                )}
            </>
            );
        },
    };
}