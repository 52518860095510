import React, { useState, useRef, useEffect } from 'react';
import { Text, View, TouchableOpacity, TextInput, Picker, ActivityIndicator, Platform } from 'react-native';
import { TextViewStyle, TextFieldStyle, OptionFieldStyle, OptionControlStyle, theme } from '../style/Theme';
import { delay } from '../../utils/Async';
import { Icon, IconKind } from './Icon';
import { useAutoLoadingError } from '../../utils/Hooks';
import { ValidValue } from '../../utils/Builder';
import { UIFieldDefinition } from '../../utils/BuilderFieldDefinitions';
import { ErrorBox } from './ErrorBox';
import { parseIntOrZero } from '../../utils/Strings';
import { Validation_MaxLengthRequired, Validation_TextRequired } from '../../utils/Validation';

export const TextView = (props: { text: string, style: TextViewStyle }) => (
    <View style={props.style.view}>
        <Text style={props.style.text}>{props.text}</Text>
        {props.style.underline && <View style={props.style.underline} />}
    </View>
);
export const TextViewWithHint = (props: { text: string, style: TextViewStyle, hint: string }) => (
    <View style={props.style.view}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={props.style.text}>{props.text}</Text>
            <HintPopup hint={props.hint} />
        </View>
        {props.style.underline && <View style={props.style.underline} />}
    </View>
);

export const ButtonTextView = (props: { text: string, icon?: IconKind, style: TextViewStyle, onPress: () => void, flex?: boolean, isDisabled?: boolean, loading?: boolean, error?: any }) => {
    return (
        <TouchableOpacity onPress={props.onPress} style={[props.style.view, props.isDisabled ? { opacity: 0.5 } : {}]} disabled={props.isDisabled || props.loading}>
            <View style={{ flexDirection: 'row' }}>
                {props.loading && <ActivityIndicator color={props.style.text.color} />}
                <Text style={props.style.text}>{props.icon && (<Icon kind={props.icon} style={props.style.icon ?? props.style.text} />)} {props.text}</Text>
            </View>
            {props.error && <ErrorBox error={props.error} />}
        </TouchableOpacity>
    );
};


export const HintPopup = (props: { hint?: string }) => {
    const textRef = useRef(null as null | Text);
    useEffect(() => {
        if (Platform.OS !== 'web') { return; }
        textRef.current?.setNativeProps({ title: props.hint });
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const toggleTooltip = () => { setIsOpen(s => !s) };

    if (!props.hint) { return null; }

    return (
        <>
            <TouchableOpacity style={{}} onPress={toggleTooltip}>
                <View style={{ marginLeft: 4, marginRight: 4, }}>
                    <Text ref={textRef} style={{
                        height: 16, width: 16, borderRadius: 8, lineHeight: 16, fontSize: 16, textAlign: 'center',
                        color: '#FFFFFF', backgroundColor: '#1856b2', borderColor: '#FFFFFF',
                    }}>i</Text>
                </View>
            </TouchableOpacity>
            {isOpen && (
                <TouchableOpacity style={{ flexShrink: 1 }} onPress={toggleTooltip}>
                    <View style={{ backgroundColor: theme.colors.purpleLite, padding: 4, borderRadius: 4, }}>
                        <Text style={{ color: theme.colors.textWhite, fontSize: theme.sizes.fontSize_tooltip }}>{props.hint}</Text>
                    </View>
                </TouchableOpacity>
            )}
        </>
    );
};

export const TextField = (props: {
    style: TextFieldStyle,
    field: UIFieldDefinition<string>,
    value?: string,
    formatValue?: (value: string) => string,
    onChangeValue: (value: ValidValue<string> | null) => void,
    debounceTimeMs?: number,
    disableAutoTrim?: boolean,
    ignoreLabelAndHint?: boolean,
}) => {

    const { debounceTimeMs = 250, disableAutoTrim = false } = props;

    const { label, hint, placeholder, defaultValue, isRequired, isDisabled, numberMask } = props.field;
    const validation = props.field.validation ?? (props.field.isRequired ? Validation_TextRequired() : undefined);

    const valueRef = useRef('');
    const [value, setValue] = useState(defaultValue?.() ?? '');

    useEffect(() => {
        if (props.value === valueRef.current) {
            return;
        }

        valueRef.current = props.value ?? '';
        // setValue(props.value ?? '');
        onChangeText(props.value ?? '', true)
    }, []);

    const [status, setStatus] = useState({
        isValid: true,
        message: validation?.getInitialMessage?.() ?? '',
    });

    const onChangeText = async (text: string, shouldSkipCallback = false) => {
        // Trim start immediately (space cannot be typed at start)
        if (!disableAutoTrim) {
            text = text.trimStart();
        }

        const lastText = valueRef.current;
        valueRef.current = text;
        // setValue(text);

        // // Trim start only (but only as used, so it can still be typed)
        // if (!disableAutoTrim) {
        //     text = text.trimStart();
        // }

        // // Only trim end as used (so it can still be typed)
        // if (!disableAutoTrim) {
        //     text = text.trim();
        // }

        const { isValid, message, reject, corrected } = validation?.validate(text) ?? { isValid: true, message: '' };
        if (reject) {
            valueRef.current = lastText;
            setValue(lastText ?? '');
            setStatus({ isValid, message });
            return;
        }
        if (corrected != null) {
            text = corrected;
            valueRef.current = text;
        }

        setValue(text);
        setStatus({ isValid, message });

        if (shouldSkipCallback) { return; }

        await delay(debounceTimeMs);
        if (valueRef.current !== text) { return; }

        props.onChangeValue(text && { value: text, isValid, error: !isValid ? message : undefined } || null);
    };

    return (
        <View style={props.style.view}>
            {!props.ignoreLabelAndHint && label && <Text style={props.style.label}>{label}{isRequired ? '' : ''}<HintPopup hint={hint} /></Text>}
            {props.field.isDisabled ? (
                <View style={props.style.text_disabled.view}>
                    <Text style={props.style.text_disabled.text}>{value}</Text>
                </View>
            ) : (
                    <View style={props.style.text.view}>
                        <TextInput style={props.style.text.text} multiline={props.style.multiline} numberOfLines={props.style.numberOfLines}
                            secureTextEntry={props.style.isPassword ?? false}
                            value={value}
                            placeholder={placeholder}
                            onChangeText={onChangeText}
                            {...{ autoComplete: Platform.OS === 'web' ? 'noop' as 'off' : 'off' }}
                        />
                    </View>
                )}
            {validation && (
                <View style={props.style.statusView}>
                    {!status.isValid && <Icon kind={IconKind.error} style={props.style.errorIcon} />}
                    <Text style={status.isValid ? props.style.info : props.style.error}>{status.message}</Text>
                </View>
            )}
        </View>
    );
};
export const TextInputView = (props: { style: TextViewStyle, placeholder?: string, value?: string, onChangeText: (text: string) => void, debounceTimeMs?: number }) =>
(<TextField
    style={{ text: props.style } as TextFieldStyle}
    field={{ __type: 'ui', isRequired: false, ...props }}
    value={props.value}
    onChangeValue={value => props.onChangeText(value?.value ?? '')} />);

export const NumberTextField = (props: {
    style: TextFieldStyle,
    field: UIFieldDefinition<number>,
    value?: number,
    onChangeValue: (value: ValidValue<number> | null) => void,
    debounceTimeMs?: number,
    disableAutoTrim?: boolean,
    ignoreLabelAndHint?: boolean,
}) => (<TextField
    {...props}
    field={props.field as any}
    // field={...props.field, {defaultValue:()=> (props.field.defaultValue?.() + ''), validation:}}
    value={props.value != null ? props.value + '' : undefined}
    onChangeValue={value => props.onChangeValue(value && { ...value, value: parseIntOrZero(value?.value) } || null)} />);

export const OptionControl = (props: {
    style: OptionControlStyle,
    selectedValue: string | null, onValueChange: (value: string) => void,
    options: OptionValue[],
    noOptionsMessage?: string,
}) => {

    if (!props.options.length) {
        return (
            <TextView style={props.style.noOptionsMessage} text={props.noOptionsMessage ?? 'Missing'} />
        );
    }

    if (props.style.controlKind === 'picker') {
        if (Platform.OS === 'ios') {

            const s = props.style.pickerItem;

            return (
                <Picker
                    style={[props.style.picker, { flex: 1, marginLeft: 16, marginRight: 16 }]}
                    itemStyle={props.style.pickerItem}
                    selectedValue={props.selectedValue || ''}
                    onValueChange={(v) => props.onValueChange(v || null)}>
                    <Picker.Item label={''} value={''} />
                    {props.options.map(x => (
                        <Picker.Item key={x.value} label={x.label} value={x.value} />
                    ))}
                </Picker>
            );
        }

        return (
            <Picker
                style={[props.style.picker, { flex: 1 }]}
                itemStyle={props.style.pickerItem}
                selectedValue={props.selectedValue || ''}
                onValueChange={(v) => props.onValueChange(v || null)}>
                <Picker.Item label={''} value={''} />
                {props.options.map(x => (
                    <Picker.Item key={x.value} label={x.label} value={x.value} />
                ))}
            </Picker>
        );
    } else {
        const tabStyle = props.style;
        return (
            <View style={tabStyle.container}>
                {props.options.map(x => (
                    <ButtonTextView key={x.value} style={x.value === props.selectedValue ? tabStyle.activeItem : tabStyle.inactiveItem} text={x.label} onPress={() => props.onValueChange(x.value)} />
                ))}
            </View>
        );
    }
};

export type OptionValue = { value: string, label: string };
export const OptionField = (props: {
    style: OptionFieldStyle, label?: string, hint?: string,
    options: () => Promise<OptionValue[]>,
    value: string | null, onChangeValue: (value: ValidValue<string> | null) => void,
    noOptionsMessage?: string,
    disable?: boolean,
}) => {
    const [value, setValue] = useState(props.value ?? null);
    const [options, setOptions] = useState([] as OptionValue[]);
    const { loading, error, doWork } = useAutoLoadingError();
    useEffect(() => {
        doWork(async (stopIfObsolete) => {
            const o = await props.options();
            stopIfObsolete();
            setOptions(o);
        });
    }, []);

    const onChangeSelection = async (value: string | null) => {
        setValue(value);
        props.onChangeValue(value && { value: value, isValid: true, error: undefined } || null);
    };

    return (
        <View style={props.style.view}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {props.label && <Text style={props.style.label}>{props.label}</Text>}
                {props.hint && <HintPopup hint={props.hint} />}
            </View>
            {error && <ErrorBox error={error} />}
            <View style={{ flexDirection: 'row' }}>
                {loading && <ActivityIndicator size="small" />}
                <OptionControl style={props.style.control}
                    selectedValue={value}
                    onValueChange={onChangeSelection}
                    options={options}
                    noOptionsMessage={props.noOptionsMessage}
                />
            </View>
            {/* <Text>{props.value}</Text> */}
        </View>
    );
};