import React from 'react';
import { View, Text } from "react-native";
import { HintPopup, TextView } from '../../../components/TextView';
import { theme } from '../../../style/Theme';

export const CampaignPhoneNumber = (props: { fromPhoneNumberOptions: { value: string, label: string }[] }) => {
    return (
        <>
            {/* <OptionField style={theme.card.optionField}
                            label="Your Gator Text Phone Number"
                            options={() => Promise.resolve(fromPhoneNumberOptions)}
                            value={campaign.fromPhoneNumber?.id || null}
                            onChangeValue={x => updateCampaign(() => {
                                campaign.contactList = { id: x?.value };
                                return { isValid: x?.isValid ?? false };
                            })}
                            noOptionsMessage={'No Phone Numbers - Purchase a Phone Number First'}
                        /> */}
            <View style={theme.card.textField.view}>
                <Text style={theme.card.textField.label}>Your GatorText Phone Number<HintPopup hint={'This is the phone number that the recipient will see when they receive the message.'} /></Text>
                <TextView style={theme.card.textField.text_disabled} text={props.fromPhoneNumberOptions[0]?.label ?? ''} />
            </View>
        </>
    );
}