import { Validation } from './Validation';
import { Builder, BuilderResult, isValidValue } from './Builder';
import { getKeysTyped, getKeysTypedExcept } from './TypeFunctions';
import { distinct } from './Arrays';
import { MessageTemplateVariable } from './MessageTemplates';
import { Reference, ReferenceList } from '../data-types/SimpleDataTypes';
import { TimeOnly } from './Time';


export type UIFieldDefinition<T, TSuper = T> = {
    __type: 'ui';
    isRequired: boolean,
    isDisabled?: boolean,
    label?: string,
    placeholder?: string,
    numberMask?: string,
    hint?: string,
    defaultValue?: () => T,
    getOptionValues?: () => Promise<{ value: TSuper, label: string }[]>,
    validation?: Validation<T>,
    variables?: MessageTemplateVariable[];
    // Special
    isMinDateToday?: boolean;
    minTime?: TimeOnly;
    maxTime?: TimeOnly;
};
export type CalculatedFieldDefinition<T> = {
    __type: 'calculated';
    calculate(fieldPath: string[]): T;
};
export type AutomaticFieldDefinition = { __type: 'automatic'; kind: string; };
export type ConstantFieldDefinition<T> = { __type: 'constant'; value: T; };
export type ReferenceFieldDefinition<T> = { id: UIFieldDefinition<string> | ConstantFieldDefinition<string> | AutomaticFieldDefinition; };

export type FieldDefinition<T, TSuper = T> = UIFieldDefinition<T, TSuper> | CalculatedFieldDefinition<T> | ConstantFieldDefinition<T> | AutomaticFieldDefinition;

type FieldMatch<T> = T | undefined;
export type ObjectDefinition = {
    __objectDefinition?: {
        isRequired?: boolean;
    }
};
export type FieldDefinitions<T> = {
    [P in keyof Required<T>]: (
        T[P] extends Function ? never
        : T[P] extends Reference<infer U> ? (FieldDefinition<U, U> | ReferenceFieldDefinition<U>)
        : T[P] extends ReferenceList<infer U> ? (FieldDefinition<U, U> | ReferenceFieldDefinition<U>)[]
        : T[P] extends FieldMatch<string> ? FieldDefinition<T[P], string>
        : T[P] extends FieldMatch<number> ? FieldDefinition<T[P], number>
        : T[P] extends FieldMatch<boolean> ? FieldDefinition<T[P], boolean>
        : T[P] extends FieldMatch<Date> ? FieldDefinition<T[P], Date>
        : FieldDefinitions<T[P]>
    );
} & ObjectDefinition;

export type UIFieldDefinitions<T> = {
    [P in keyof T]: (
        T[P] extends Function ? never
        : T[P] extends Reference<infer U> ? (UIFieldDefinition<U, U> | ReferenceFieldDefinition<U>)
        : T[P] extends ReferenceList<infer U> ? (UIFieldDefinition<U, U> | ReferenceFieldDefinition<U>)[]
        : T[P] extends FieldMatch<string> ? UIFieldDefinition<T[P], string>
        : T[P] extends FieldMatch<number> ? UIFieldDefinition<T[P], number>
        : T[P] extends FieldMatch<boolean> ? UIFieldDefinition<T[P], boolean>
        : T[P] extends FieldMatch<Date> ? UIFieldDefinition<T[P], Date>
        : UIFieldDefinitions<T[P]>
    );
} & ObjectDefinition;

const state = {
    nextId: 10000,
};

export function buildBuilder<T>(builder: Builder<T>, fieldDefinitions: FieldDefinitions<T>, path: string[] = []): BuilderResult<T> {
    const obj = {} as T;
    const errors = [] as { path: string, error?: string }[];

    fieldDefinitions = fieldDefinitions ?? {};

    // defaultValues should contain all required fields (in case the builder is missing a field, it's key wouldn't exist)
    // builderKeys will contain all fields that were created (with optional fields that would not exist in the default as a required field)
    const defaultKeys = getKeysTypedExcept(fieldDefinitions, { __objectDefinition: {} } as ObjectDefinition);
    const builderKeys = getKeysTyped(builder);
    const keys = distinct([...defaultKeys, ...builderKeys], x => x as string);

    for (const k of keys) {
        const field = builder[k];
        const def: FieldDefinition<unknown> | null = (fieldDefinitions[k] as FieldDefinition<unknown>);

        if (field == null && def != null) {
            // console.log('field null', { rField, REQUIRED, AUTOMATIC, isRequired: rField === REQUIRED, isAutomatic: rField === AUTOMATIC });

            if (def?.__type === 'ui') {
                const uiDef = def as UIFieldDefinition<unknown>;
                if (uiDef.isRequired) {
                    // Required
                    errors.push({ path: k as string, error: `Missing ${k}` });
                } else {
                    // Optional - ignore missing
                }
            } else if (def.__type === 'automatic') {
                const autoDef = def as AutomaticFieldDefinition;
                // TODO: Do something with this later
                if (autoDef.kind === 'id') {
                    obj[k] = 'builderId_' + state.nextId++ as any;
                }

            } else if (def.__type === 'calculated') {
                const calcDef = def as CalculatedFieldDefinition<unknown>;
                obj[k] = calcDef.calculate(path) as any;

            } else if (def.__type === 'constant') {
                const constDef = def as ConstantFieldDefinition<unknown>;
                obj[k] = constDef.value as any;

            } else if (typeof def === 'object') {
                const objectDef = def as ObjectDefinition;
                const isObjectRequired = objectDef.__objectDefinition?.isRequired ?? true;
                if (isObjectRequired) {
                    // Go Deeper (when value is null to get calculated values, etc.)
                    const subObj = buildBuilder<any>({}, def as any, [...path, k as string]);
                    errors.push(...subObj.errors.map(x => ({ path: `${k}.${x.path}`, error: x.error })));
                    obj[k] = subObj.value;
                }
            } else {
                // Do Nothing
            }

        } else if (isValidValue(field)) {
            // Scalar Values
            const validValue = field;

            if (!validValue.isValid) {
                errors.push({ path: k as string, error: validValue.error });
            }

            obj[k] = validValue.value as any;

        } else {
            // Go Deeper
            const subObj = buildBuilder<any>(field as any, def as any, [...path, k as string]);
            errors.push(...subObj.errors.map(x => ({ path: `${k}.${x.path}`, error: x.error })));
            obj[k] = subObj.value;
        }
    }

    return {
        value: obj,
        errors,
        isValid: !errors.length,
    };
}
