import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, Dimensions, Platform, Linking } from 'react-native';
import { Store } from '../store/Store';
import { ButtonTextView, TextView } from './TextView';
import { MainLayout } from './Layout';
import { IconKind } from './Icon';
import { getKeysTyped } from '../../utils/TypeFunctions';
import { HeaderStyle, colors } from '../style/Theme';
import { LogoImage } from './Assets';
import { RoleKind } from '../../data-types/ModelTypes';

type HeaderItemNormal = {
    isAdminOnly?: boolean,
    isSuperAdminOnly?: boolean,
    isHidden?: false,
    isActive?: boolean,
    title: string,
    icon?: IconKind,
    link?: string,
    viewNav?: {
        isActive: boolean,
        open: (args: any) => void,
    },
    children?: HeaderItem[]
};
type HeaderItemHidden = {
    isAdminOnly?: boolean,
    isSuperAdminOnly?: boolean,
    isHidden: true,
    isActive?: boolean,
    title: string,
    viewNav?: {
        isActive: boolean,
    },
    children?: HeaderItem[]
};
export type HeaderItem = HeaderItemNormal | HeaderItemHidden;

export type HeaderData = {
    siteBar: HeaderItem,
    accountBar: HeaderItem,
    main: HeaderItem,
};

const LayoutHeaderBar = (props: { style: HeaderStyle, navDataItems: HeaderItem[], userRole: RoleKind, }) => {
    const items = props.navDataItems
        .filter(x => !x.isHidden)
        .filter(x => !x.isAdminOnly || RoleKind.isAdmin(props.userRole))
        .filter(x => !x.isSuperAdminOnly || RoleKind.isSuperAdmin(props.userRole))
        ;

    const openHeader = (headerItem: HeaderItemNormal) => {
        if (headerItem.viewNav) {
            headerItem.viewNav.open(undefined);
        }
        if (headerItem.link) {
            Linking.openURL(headerItem.link)
        }
    };

    return (
        <>
            {!!items.length && (
                <View style={props.style.container}>
                    <View style={props.style.containerInner}>
                        <View style={props.style.itemContainer}>
                            {items.map((headerItemRaw, i) => {
                                const headerItem = headerItemRaw as HeaderItemNormal;

                                const isDisabled = !headerItem.viewNav && !headerItem.link;
                                const isActive = headerItem.isActive ?? false;
                                const s = isDisabled ? props.style.item_disabled : isActive ? props.style.item_active : props.style.item;
                                return (
                                    <ButtonTextView key={i} style={s} isDisabled={isDisabled}
                                        text={headerItem.title} icon={headerItem.icon} onPress={() => openHeader(headerItem)} />
                                );
                            })}
                        </View>
                    </View>
                </View>
            )}
        </>
    );
};

const SiteHeader = (props: { store: Store, headerData: HeaderData, userRole: RoleKind, username: string, isCollapsed: boolean }) => {
    const { store, headerData, userRole } = props;
    const { nav, theme } = store;
    const style = theme.headers.site;
    const navDataItems = headerData.siteBar?.children ?? [];
    const accountItem = headerData.accountBar?.children?.[0];

    const navigateHome = () => {
        store.navigateWebSiteHome();
    };

    if (props.isCollapsed) {
        return (
            <View style={style.container}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={{ flexShrink: 1 }}>
                        <TouchableOpacity onPress={navigateHome}>
                            <LogoImage size='small' />
                        </TouchableOpacity>
                    </View>
                    <SiteHeaderAccount {...props} userRole={userRole} username={props.username} accountItem={accountItem} />
                </View>
            </View>
        );
    }

    return (
        <View style={style.container}>
            <View style={style.containerInner}>
                {/* Logo */}
                <TouchableOpacity onPress={navigateHome}>
                    <LogoImage />
                </TouchableOpacity>

                <Text style={theme.info.versionText}>v. {store.version}</Text>
                {/* Items */}
                <View style={style.itemContainer}>

                    {navDataItems.map((headerItemRaw, i) => {
                        if ((headerItemRaw as HeaderItemHidden).isHidden) { return null; }
                        if (!RoleKind.isAdmin(userRole) && headerItemRaw.isAdminOnly) { return null; }
                        if (!RoleKind.isSuperAdmin(userRole) && headerItemRaw.isSuperAdminOnly) { return null; }

                        const headerItem = headerItemRaw as HeaderItemNormal;

                        const isDisabled = !headerItem.viewNav;
                        const isActive = headerItem.isActive ?? false;
                        const s = isDisabled ? style.item_disabled : isActive ? style.item_active : style.item;
                        return (
                            <ButtonTextView key={i} style={s} isDisabled={isDisabled}
                                text={headerItem.title} icon={headerItem.icon} onPress={() => headerItem.viewNav?.open(undefined)} />
                        );
                    })}
                </View>
                {/* Account */}
                <SiteHeaderAccount {...props} userRole={userRole} accountItem={accountItem} />
            </View>
        </View>
    );
};

const SiteHeaderAccount = ({
    store,
    accountItem,
    userRole,
    username,
}: {
    store: Store,
    accountItem: HeaderItemNormal | HeaderItemHidden | undefined,
    userRole: RoleKind,
    username: string,
}) => {
    const style = store.theme.headers.site;

    const logout = async () => {
        await store.auth.logout();
        store.nav._refresh();
    };

    return (
        <>
            {accountItem && [accountItem].map((headerItemRaw, i) => {
                if ((headerItemRaw as HeaderItemHidden).isHidden) { return null; }
                if (!RoleKind.isAdmin(userRole) && headerItemRaw.isAdminOnly) { return null; }
                if (!RoleKind.isSuperAdmin(userRole) && headerItemRaw.isSuperAdminOnly) { return null; }

                const headerItem = headerItemRaw as HeaderItemNormal;

                const isDisabled = !headerItem.viewNav;
                const isActive = headerItem.isActive ?? false;
                const s = isDisabled ? style.item_disabled : isActive ? style.item_active : style.item;
                return (
                    <React.Fragment key={i}>
                        <View style={s.view}>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <ButtonTextView style={{ view: {}, text: s.text }} isDisabled={isDisabled}
                                    text={username || headerItem.title} icon={headerItem.icon} onPress={() => headerItem.viewNav?.open(undefined)} />
                                {!!username && (
                                    <ButtonTextView style={{ view: { marginLeft: 8 }, text: s.text }}
                                        text={''} icon={IconKind.logout} onPress={logout} />
                                )}
                            </View>
                        </View>
                    </React.Fragment>
                );
            })}
        </>
    );
};

const globalState = { collapsed: true };
export const LayoutHeader = (props: {
    store: Store, headerData: HeaderData,
    options?: { isFullWidth?: boolean },
}) => {

    const { store, headerData } = props;
    const { nav, theme } = store;

    const updateIsActive = (item: HeaderItem) => {
        item.children?.forEach(updateIsActive);
        item.isActive = item.viewNav?.isActive || (item.children?.filter(x => x.isActive).length ?? 0) > 0;
    };
    updateIsActive(headerData.main);
    updateIsActive(headerData.siteBar);
    updateIsActive(headerData.accountBar);

    const headerItem0 = headerData.siteBar.isActive ? headerData.siteBar
        //: headerData.accountBar.isActive ? headerData.accountBar
        : headerData.main;
    const headerItem1 = headerItem0?.children?.filter(x => x.isActive)[0] ?? null;
    const headerItem2 = headerItem1?.children?.filter(x => x.isActive)[0] ?? null;

    // console.log('LayoutHeader', { headerItem0, headerItem1, headerItem2, headerData });

    const [collapsed, setCollapsed] = useState(globalState.collapsed);
    useEffect(() => {
        const resetCollapsed = () => {
            const windowWidth = Dimensions.get('window').width;
            const shouldBeCollapsed = windowWidth < 600;
            globalState.collapsed = shouldBeCollapsed;
            setCollapsed(shouldBeCollapsed);
        };
        resetCollapsed();

        Dimensions.addEventListener('change', resetCollapsed);
        return () => Dimensions.removeEventListener('change', resetCollapsed);
    }, []);

    const s = props.options?.isFullWidth ? theme.headers_fullWidth : theme.headers;
    const userRole = store.api.getAccountState().userRole ?? 'none';
    const username = store.api.getAccountState().accountBusinessName ?? '';

    return (
        <View>
            <SiteHeader {...props} userRole={userRole} username={username} isCollapsed={collapsed} />
            <LayoutHeaderBar style={s.header0} navDataItems={headerData.main.children ?? []} userRole={userRole} />

            <LayoutHeaderBar style={s.header1} navDataItems={headerItem1?.children ?? []} userRole={userRole} />
            <LayoutHeaderBar style={s.header2} navDataItems={headerItem2?.children ?? []} userRole={userRole} />
        </View>
    );
};

export const LayoutFooter = (props: {}) => {
    return (
        <>
            <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: colors.purple, padding: 16 }}>
                <LogoImage />
                <Text style={{ textAlign: 'center', color: colors.textWhite }}>Copyright {new Date().getFullYear()} Gator Text. All rights reserved.</Text>
            </View>
        </>
    );
};