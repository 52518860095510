import React, { useState } from 'react';
import { View, Text } from 'react-native';
import { Store } from '../store/Store';
import { ButtonTextView, HintPopup } from './TextView';
import { IconKind } from './Icon';

export function ListEditor(props: { store: Store, label?: string, hint?: string, items: any[], renderItem: (props: { i: number }) => JSX.Element }) {
    const { store } = props;
    const { theme } = store;

    const [renderId, setRenderId] = useState(0);
    const addItem = () => {
        const d = props.items;
        d.push({} as any);
        setRenderId(s => s + 1);
    };
    const removeItem = (i: number) => {
        const d = props.items;
        d.splice(i, 1);
        setRenderId(s => s + 1);
    };

    if (!props.items.length) {
        props.items.push({} as any);
    }

    const RenderItem = props.renderItem;
    return (
        <>
            {props.label && <Text style={theme.card.listEditor.label}>{props.label}<HintPopup hint={props.hint} /></Text>}
            {props.items.map((item, i) => (
                <View style={theme.card.row} key={i + ':' + props.items.length} >
                    <RenderItem i={i} />
                    <View style={theme.card.rowActionButton.view}>
                        <ButtonTextView isDisabled={i !== props.items.length - 1} text='' icon={IconKind.add} style={theme.card.rowActionButton.buttonTextView} onPress={addItem} />
                    </View>
                    <View style={theme.card.rowActionButton.view}>
                        <ButtonTextView isDisabled={props.items.length <= 1} text='' icon={IconKind.remove} style={theme.card.rowActionButton.buttonTextView} onPress={() => removeItem(i)} />
                    </View>
                </View>
            ))}
        </>
    );
};