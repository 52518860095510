import React, { useEffect, useRef, useState } from 'react';
import { Text, View, ViewStyle, Image, ActivityIndicator } from 'react-native';
import { MessageTemplateModel } from '../../../../data-types/ModelTypes';
import { TextFieldStyle, TabBarStyle, theme } from '../../../style/Theme';
import { TextField, OptionControl, HintPopup, ButtonTextView, TextView } from '../../../components/TextView';
import { Builder, ValidValue } from '../../../../utils/Builder';
import { MessageTemplateFieldDefinition } from '../../../../logic/campaigns/campaignFieldDefinitions';
import { MediaUploader } from '../../../components/MediaUploader';
import { Store } from '../../../store/Store';
import { Gsm } from '../../../../utils/Gsm';
import { getMessageMode } from '../../../../data-types/messageMode';
import { ErrorBox } from '../../../components/ErrorBox';

export type MessageTemplateFieldStyle = {
    container: ViewStyle,
    editor: TextFieldStyle,
    tabBar: TabBarStyle,
};

export const MessageTemplateFieldEditor = (props: {
    field: MessageTemplateFieldDefinition,
    style: MessageTemplateFieldStyle,
    value?: Builder<MessageTemplateModel<any>>,
    onChangeValue: (value: ValidValue<Builder<MessageTemplateModel<any>>>) => void,
    store: Store,
}) => {

    const [renderId, setRenderId] = useState(0);
    const [messageType, setMessageType] = useState(props.value?.imageUrl?.value ? 'mms' : 'sms' as 'sms' | 'mms');
    const setMode = (mode: 'sms' | 'mms') => {
        field.setMode(mode);

        if (mode === 'sms') {
            message.imageUrl = undefined;
            changeMessage();
        }

        setMessageType(mode);
    };

    const canChangeMessageKind = !props.field.imageUrl.isDisabled || !props.field.linkUrl.isDisabled;
    const { label, hint, isRequired, variables } = props.field.text;

    const message = props.value ?? {};
    const field = props.field;
    const changeMessage = () => {

        const isValid = message.text?.isValid ?? false;
        const error = message.text?.error ?? (!isValid ? 'Message Template Error' : undefined);

        props.onChangeValue({
            value: message,
            isValid,
            error,
        });
    };

    const setMessage = (value: ValidValue<string>) => {
        message.text = value;
        changeMessage();
    };

    const [imageError, setImageError] = useState(null as null | string);

    const onImageError = (errorMessage: string) => {
        message.imageUrl = { value: undefined, isValid: true };
        changeMessage();
        setImageError(errorMessage);
    };
    const setImageUrl = (value: ValidValue<string | undefined>) => {
        message.imageUrl = value;
        changeMessage();
        setRenderId(s => s + 1);
        setImageError(null);
    };
    const setLinkUrl = (value: ValidValue<string>) => {
        message.linkUrl = value;
        changeMessage();
    };

    return (
        <View style={props.style.container}>
            <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                    {label && <Text style={props.style.editor.label}>{label}{isRequired ? ' *' : ''}<HintPopup hint={hint} /></Text>}
                    {variables && variables.length && <Text style={props.style.editor.hint}>Variables:</Text>}
                    {variables && variables.length && variables.map(x => (
                        <Text key={x.name} style={props.style.editor.hint}>{'    {' + x.name + '}'}: {x.description}</Text>
                    ))}
                </View>
                {canChangeMessageKind && <OptionControl style={props.style.tabBar}
                    selectedValue={messageType} onValueChange={(v) => setMode(v as any)}
                    options={[{ value: 'sms', label: 'SMS' }, { value: 'mms', label: 'MMS' }]} />
                }
            </View>
            <TextField key={messageType} style={props.style.editor}
                field={field.text} ignoreLabelAndHint={true}
                value={message.text?.value}
                onChangeValue={setMessage} />

            {messageType === 'mms' && (
                <>
                    {/* Image */}
                    {!field.imageUrl.isDisabled && (
                        <>
                            {message.imageUrl?.value && (<Image source={{ uri: message.imageUrl.value }} style={{ width: 200, height: 200 }} resizeMode='contain' />)}
                            <MediaUploader uploadApi={props.store.uploadApi} onUploaded={(readUrl) => setImageUrl({ value: readUrl, isValid: true })} onError={(message) => onImageError(message)} maxFileSize={1000 * 1024} onClear={() => setImageUrl({ isValid: true, value: undefined })} />
                            {imageError && <Text>{imageError}</Text>}
                        </>
                    )}
                </>
            )}
        </View>
    );
};

type MessageTemplateEdit = {
    id?: string;
    text: string;
    additionalTexts: string[];
    imageUrl?: string;
    linkUrl?: string;
};

export const MessageTemplateFieldEditorV2 = ({
    field,
    style,
    value,
    onChangeValue,
    store,
}: {
    field: MessageTemplateFieldDefinition,
    style: MessageTemplateFieldStyle,
    value?: MessageTemplateEdit,
    onChangeValue: (value: { message: MessageTemplateEdit, isValid: boolean }) => void,
    store: Store,
}) => {
    const message: MessageTemplateEdit = value ?? { text: '', additionalTexts: [] };
    const canChangeMessageKind = !field.imageUrl.isDisabled || !field.linkUrl.isDisabled;
    const { label, hint, isRequired, variables } = field.text;

    const [renderId, setRenderId] = useState(0);
    const [messageType, setMessageType] = useState(getMessageMode(value));
    field.setMode(messageType);

    const setMode = (mode: 'sms' | 'mms') => {

        if (mode === 'sms') {
            message.imageUrl = undefined;
            changeMessage();
        } else {
            message.additionalTexts = [];
            changeMessage();
        }

        setMessageType(mode);
    };

    const changeMessage = () => {

        lastValue.current = message;
        const validation = Gsm.validation(true, () => messageType);
        onChangeValue({ message, isValid: [message.text, ...message.additionalTexts ?? []].every(x => validation.validate(x).isValid) });
    };

    const setMessage = (value: ValidValue<string>) => {
        message.text = value?.value ?? '';

        // if (messageType === 'sms'
        //     && (message.additionalTexts?.length ?? 0) < 1
        //     && message.text.length > 160
        // ) {
        //     message.additionalTexts = [''];
        //     // message.additionalTexts = [message.text.substr(160)];
        //     //message.text = message.text.substr(0, 160);
        // }

        changeMessage();
    };

    const setAdditionalMessage = (i: number, value?: ValidValue<string>) => {
        message.additionalTexts = message.additionalTexts ?? [];
        message.additionalTexts[i] = value?.value ?? '';

        const validation = Gsm.validation(true, () => messageType);
        onChangeValue({ message, isValid: [message.text, ...message.additionalTexts ?? []].every(x => validation.validate(x).isValid) });
    };

    const addAdditionalMessage = () => {
        message.additionalTexts = message.additionalTexts ?? [];
        message.additionalTexts.push('');

        const validation = Gsm.validation(true, () => messageType);
        onChangeValue({ message, isValid: [message.text, ...message.additionalTexts ?? []].every(x => validation.validate(x).isValid) });
    };

    const removeAdditionalMessage = (i: number) => {
        message.additionalTexts = message.additionalTexts ?? [];
        message.additionalTexts.splice(i, 1);

        const validation = Gsm.validation(true, () => messageType);
        onChangeValue({ message, isValid: [message.text, ...message.additionalTexts ?? []].every(x => validation.validate(x).isValid) });
    };

    const [imageLoading, setImageLoading] = useState(false);
    const [imageError, setImageError] = useState(null as null | string);

    const onImageError = (errorMessage: string) => {
        message.imageUrl = undefined;
        changeMessage();
        setImageError(errorMessage);
        setImageLoading(false);
    };
    const setImageUrl = (value: ValidValue<string | undefined>) => {
        message.imageUrl = value.value;
        changeMessage();
        setImageError(null);
        setImageLoading(false);
        setRenderId(s => s + 1);
    };
    const setLinkUrl = (value: ValidValue<string>) => {
        message.linkUrl = value.value;
        changeMessage();
    };

    const lastValue = useRef(value);
    useEffect(() => {
        if (lastValue.current?.text === value?.text) { return; }
        console.log('MessageTemplateFieldEditorV2 value changed externally', { lastValue, value });

        lastValue.current = value;

        setRenderId(s => s + 1);
    }, [value]);

    // if (messageType === 'sms' && (message.additionalTexts?.length ?? 0) < 1) {
    //     message.additionalTexts = [''];
    // }

    return (
        <View style={style.container}>
            <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                    {!!label && <Text style={style.editor.label}>{label}{isRequired ? '' : ''}<HintPopup hint={hint} /></Text>}
                    {variables && variables.length > 0 && <Text style={style.editor.hint}>Variables:</Text>}
                    {variables && variables.length > 0 && variables.map(x => (
                        <Text key={x.name} style={style.editor.hint}>{'    {' + x.name + '}'}: {x.description}</Text>
                    ))}
                </View>
                {canChangeMessageKind && <OptionControl style={style.tabBar}
                    selectedValue={messageType} onValueChange={(v) => setMode(v as any)}
                    options={[{ value: 'sms', label: 'SMS' }, { value: 'mms', label: 'MMS' }]} />
                }
            </View>
            <TextField
                key={messageType + renderId}
                style={style.editor}
                field={field.text} ignoreLabelAndHint={true}
                value={message.text}
                onChangeValue={setMessage} />

            {messageType === 'sms' && canChangeMessageKind && (
                <>
                    {(message.additionalTexts?.length ?? 0) > 0 && (
                        <Text style={style.editor.label}>{''}<HintPopup hint={'If you are sending a text message that is longer than the allowed characters, we recommend that you set it up so that the first message begins with “(1 of 2)” and then the second text message begins with “(2 of 2)”. If you are sending a text message that is longer than 3 messages, we recommend that you use the MMS feature instead since it allows for nearly unlimited text characters.'} /></Text>
                    )}
                    {message.additionalTexts?.map((x, i) => (
                        <React.Fragment key={messageType + ':' + i}>
                            <TextField style={style.editor}
                                field={field.text} ignoreLabelAndHint={true}
                                value={x}
                                onChangeValue={(v) => setAdditionalMessage(i, v ?? undefined)} />
                            <View style={{ ...theme.card.row, justifyContent: 'flex-end' }}>
                                <ButtonTextView style={theme.card.buttonTextView_danger} text='Remove Additional SMS Message' onPress={() => removeAdditionalMessage(i)} />
                            </View>
                        </React.Fragment>
                    ))}
                    {(message.additionalTexts?.length ?? 0) < 2 && (
                        <View style={theme.card.row}>
                            <ButtonTextView style={theme.card.buttonTextView_minor} text='Add an additional SMS message.' onPress={addAdditionalMessage} />
                        </View>
                    )}
                </>
            )}

            {messageType === 'mms' && (
                <>
                    {!field.imageUrl.isDisabled && (
                        <>
                            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                {!!message.imageUrl && (<Image source={{ uri: message.imageUrl }} style={{ width: 200, height: 200 }} resizeMode='contain' />)}
                            </View>
                            <MediaUploader uploadApi={store.uploadApi}
                                onUploadingStart={() => { setImageLoading(true); setImageError(null); }}
                                onUploaded={(readUrl) => setImageUrl({ value: readUrl, isValid: true })}
                                onError={(message) => onImageError(message)}
                                maxFileSize={500 * 1024}
                                onClear={() => setImageUrl({ isValid: true, value: undefined })}
                            />
                            <Text style={{ padding: 8, fontWeight: 'bold' }}>JPG, PNG, or GIF is recommended. Max file size is 500kb.</Text>
                            {!!imageError && <ErrorBox error={{ message: imageError }} />}
                            {!!imageLoading && <ActivityIndicator size={'large'} />}
                        </>
                    )}
                </>
            )}
        </View>
    );
};
