import React, { useState, useEffect, useRef } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { CampaignModel_Common, CampaignStats, ContactListModel, MessageTemplateModel } from '../../../../data-types/ModelTypes';
import { getReference, Reference, ReferenceList } from '../../../../data-types/SimpleDataTypes';
import { useAutoLoadingError } from '../../../../utils/Hooks';
import { ErrorBox } from '../../../components/ErrorBox';
import { ButtonTextView, TextView } from '../../../components/TextView';
import { Store } from '../../../store/Store';
import { ContactListLinks } from '../../Common/ReferenceLinks';


export const CampaignDetails_Stats = <TCampaign extends CampaignModel_Common>(props: {
    store: Store, campaign: TCampaign,
    getMainMessage: (campaign: TCampaign) => Reference<MessageTemplateModel<any>>,
    getContactLists?: (campaign: TCampaign) => undefined | ReferenceList<ContactListModel>
}) => {
    const { store, campaign } = props;
    const { loading, error, doWork } = useAutoLoadingError();

    const [campaignStats, setCampaignStats] = useState(null as null | CampaignStats);

    useEffect(() => {
        doWork(async (stopIfObsolete) => {
            const campaignStats = await store.api.getCampaignStats(campaign, props.getMainMessage(campaign), props.getContactLists?.(campaign));
            stopIfObsolete();

            setCampaignStats(campaignStats ?? null);
        });
    }, []);

    const scheduled = !campaignStats ? 0 : campaignStats.messagesDeliveriesCreated;
    const delivered = !campaignStats ? 0 : campaignStats.messagesDeliveriesDelivered;
    const pending = !campaignStats ? 0 : (campaignStats.messagesDeliveriesDelivered - (campaignStats.messagesDeliveriesSuccessful + campaignStats.messagesDeliveriesFailed));
    const successful = !campaignStats ? 0 : campaignStats.messagesDeliveriesSuccessful;
    const failed = !campaignStats ? 0 : campaignStats.messagesDeliveriesFailed;
    const pendingRatio = !delivered ? 0 : (pending / delivered);
    const successfulRatio = !delivered ? 0 : (successful / delivered);
    const failedRatio = !delivered ? 0 : (failed / delivered);

    return (
        <View style={{ margin: 8 }}>
            {loading && <ActivityIndicator />}
            {error && <ErrorBox error={error} />}

            {campaignStats && (
                <>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                        <View style={{ flex: 1 }}>
                            <TextView style={store.theme.card.cardSectionTitle_small} text={'Lists'} />
                            <StatItem store={store} title={`Total Contacts`} value={`${campaignStats.contactsToDeliverTotal}`} />
                            <ContactListLinks store={store} style={store.theme.grid.dataLink} listRef={props.getContactLists?.(campaign)} />
                        </View>
                        <View style={{ flex: 1 }}>
                            <TextView style={store.theme.card.cardSectionTitle_small} text={'Deliveries'} />
                            <StatItem store={store} title={`Scheduled`} value={`${scheduled}`} />
                            <StatItem store={store} title={`Attempted`} value={`${delivered}`} />
                            <StatItem store={store} title={`Pending`} value={`${pending}`} ratio={pendingRatio} />
                            <StatItem store={store} title={`Successful`} value={`${successful}`} ratio={successfulRatio} />
                            <StatItem store={store} style={{ color: failed > 0 ? store.theme.colors.warnRed : undefined }} title={`Failed`}
                                value={`${failed}`} ratio={failedRatio} />
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                        <ButtonTextView onPress={() => store.nav.ViewCampaignMessageThreadPage.open({ campaign: getReference(campaign) })}
                            style={store.theme.card.buttonTextView_minor}
                            text={`View Campaign Replies`} />
                    </View>
                </>
            )}
        </View>
    );
}

const StatItem = ({ store, title, value, ratio, style }: { store: Store, title: string, value: string, ratio?: number, style?: { color?: string } }) => {
    const s = {
        title: {
            view: {
                minWidth: 140,
            },
            text: {
                ...store.theme.card.cardTextInline.text,
                color: style?.color ?? store.theme.colors.textBlack,
            },
        }, value: {
            view: {
                minWidth: 60,
            },
            text: {
                ...store.theme.card.cardTextInline.text,
                color: style?.color ?? store.theme.colors.textBlack,
            },
        }
    };
    return (
        <View style={{ flexDirection: 'row' }}>
            <TextView style={s.title} text={title} />
            <TextView style={s.value} text={value} />
            {ratio !== undefined && (
                <TextView style={s.value} text={`${((ratio || 0) * 100).toFixed(0)}%`} />
            )}
        </View>
    );
};