import { distinct } from "./Arrays";

export const formatKeywordString = (keywords: string | undefined | null): string => {
    return splitKeywords(keywords).join(', ');
};

export const splitKeywords = (keywords: string | undefined | null): string[] => {
    const k = (keywords ?? '').split(/[^A-Za-z0-9]/g).map(x => x.toLocaleLowerCase().trim()).filter(x => x);
    k.sort((a, b) => a.localeCompare(b));
    return distinct(k, x => x);
};

export const joinKeywords = (keywords: string[]): string => {
    return keywords.join(',');
};
