import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity } from "react-native";
import { Store } from '../../../store/Store';
import { Icon, IconKind } from '../../../components/Icon';
import { HintPopup } from '../../../components/TextView';

export const CheckBoxControl = (props: { store: Store, label: string, hint?: string, value: boolean, onChange: (value: boolean) => void, useRadioIcon?: boolean }) => {
    const theme = props.store.theme;

    return (
        <TouchableOpacity onPress={() => props.onChange(!props.value)}>
            <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 4 }}>
                <Icon kind={!props.useRadioIcon ? (props.value ? IconKind.checkboxChecked : IconKind.checkboxUnchecked) : (props.value ? IconKind.radioChecked : IconKind.radioUnchecked)} />
                <Text style={theme.card.textField.label}>{props.label}<HintPopup hint={props.hint} /></Text>

            </View>
        </TouchableOpacity>
    );
};