
// From: https://stackoverflow.com/a/30851002/567524
export function escapeRegExp(text: string) {
    return text.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&');
}

export function capitalize(text: string) {
    if (!text) { return ''; }
    return text[0].toUpperCase() + text.substr(1, text.length);
}

export function parseIntOrZero(text?: string | null) {
    if (!text) { return 0; }
    text = text.trim();
    const n = parseInt(text);
    if (!n || isNaN(n) || !isFinite(n)) { return 0; }
    const nText = n + '';
    if (text !== nText) { return 0; }
    return n;
}