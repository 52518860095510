import React from 'react';
import { Store } from '../store/Store';
import { View, Platform } from 'react-native';
import { getKeysTyped } from '../../utils/TypeFunctions';

type Routing<TArgs, TArgsRoute> = {
    getRoute: (args: TArgs) => string,
    parseRoute: (route: string) => { canParse: boolean, argsRoute?: TArgsRoute, },
};

type OpenOptions = { replaceInHistory?: boolean };

function setWebRoute<TArgs, TArgsRoute>(routing: Routing<TArgs, TArgsRoute>, args: TArgs, options?: OpenOptions) {
    if (Platform.OS !== 'web') { return; }

    const path = routing.getRoute(args);
    if (options?.replaceInHistory) {
        window.history.replaceState({}, path, path);
    } else {
        window.history.pushState({}, path, path);
    }
}

export function subscribeWebRoute(views: { openIfCanParse(route: string): boolean }[], openDefaultView: () => void) {
    if (Platform.OS !== 'web') {
        openDefaultView();
        return;
    }

    const loadRoute = () => {
        const route = window.location.pathname;
        console.log('loadRoute', { route });

        for (const v of views) {
            if (v.openIfCanParse(route)) { return; }
        }

        console.log('loadRoute NO ROUTE FOUND', { route });
        openDefaultView();
    };

    window.onpopstate = loadRoute;

    // Handle Initial load
    loadRoute();
}

function createViewInstance<TArgs, TArgsRoute>(
    getStore: () => Store,
    setActiveView: (ActiveView: () => JSX.Element, setIsActive: (isActive: boolean) => void, getIsSecure: () => boolean,) => void,
    ViewComponent: ((props: { store: Store, args: TArgs, argsRoute: TArgsRoute }) => JSX.Element),
    title: string,
    isSecure: boolean,
    routing: Routing<TArgs, TArgsRoute>,
): {
    isActive: boolean,
    isSecure: boolean,
    title: string,
    open: (args: TArgs, options?: OpenOptions) => void,
    _openIfCanParse: (route: string) => boolean,
} {

    let argsState = null as null | TArgs;
    let argsRouteState = null as null | TArgsRoute;
    const RenderViewComponent = () => {
        console.log('createViewInstance.RenderViewComponent', { argsState, argsRouteState });
        return (
            <ViewComponent store={getStore()} args={argsState!} argsRoute={argsRouteState!} />
        );
    };
    const value = {
        isActive: false,
        isSecure,
        title,
        open: (args: TArgs, options?: OpenOptions) => {
            console.log('createViewInstance.view.open', { args, argsState, argsRouteState });
            argsState = args;
            argsRouteState = null;
            setActiveView(() => RenderViewComponent(), x => value.isActive = x, () => value.isSecure);
            setWebRoute(routing, args, options);
        },
        _openIfCanParse: (route: string) => {
            const { canParse, argsRoute } = routing.parseRoute(route);
            if (!canParse) { return false; }

            argsState = null;
            argsRouteState = argsRoute!;
            setActiveView(() => RenderViewComponent(), x => value.isActive = x, () => value.isSecure);

            return true;
            // setWebRoute(routing, args);
        },
        //ViewComponent: RenderViewComponent,
    };

    return value;
}

export function createSimpleRoute(path: string): () => Routing<void, void> {
    return () => ({
        getRoute: () => `/${path}`,
        parseRoute: (route: string) => ({ canParse: (route + '/').startsWith(`/${path}/`), }),
    });
}

export function createRouteArgs<TArgs, TArgsRoute>(pathBase: string, getArgsPath: (args: TArgs) => string, parseArgsPath: (argValue: string) => TArgsRoute): (args: TArgs) => Routing<TArgs, TArgsRoute> {
    return () => ({
        getRoute: (args) => `/${pathBase}/${getArgsPath(args)}`,
        parseRoute: (route: string) => ({ canParse: route.startsWith(`/${pathBase}/`), argsRoute: parseArgsPath(route.replace(`/${pathBase}/`, '')) }),
    });
}

export type createViewData_create = (<TArgs = void, TArgsRoute = void>(
    Component: ((props: { store: Store, args: TArgs, argsRoute: TArgsRoute }) => JSX.Element),
    options: {
        title: string,
        isSecure: boolean,
    },
    routing: (args: TArgs, argsRoute: TArgsRoute) => Routing<TArgs, TArgsRoute>,
) => {
    isActive: boolean,
    isSecure: boolean,
    title: string,
    open: (args: TArgs, options?: OpenOptions) => void,
    _openIfCanParse: (route: string) => boolean,
});

export type createViewData_result = ReturnType<createViewData_create>;

export const createAppViewsInner = <T extends { [name: string]: createViewData_result }>(getStore: () => Store, createViewData: (create: createViewData_create) => T) => {

    let _last_setIsActive: ((isActive: boolean) => void) | null = null;
    let ActiveViewComponent = () => {
        console.warn('Rendering Empty ActiveViewComponent Placeholder');
        return (<View></View>);
    }
    let isActiveViewSecure = false;
    const setActiveView = (v: () => JSX.Element, setIsActive: (isActive: boolean) => void, getIsSecure: () => boolean) => {

        ActiveViewComponent = v;
        isActiveViewSecure = getIsSecure();
        console.log('setActiveView', { isActiveViewSecure, v });

        _last_setIsActive?.(false);
        setIsActive(true);
        _last_setIsActive = setIsActive;

        result._handleNavigate();
    };
    const getActiveView = (): (() => JSX.Element) => {
        return ActiveViewComponent;
    };
    const getIsActiveViewSecure = (): boolean => {
        return isActiveViewSecure;
    };

    function create<TArgs = void, TArgsRoute = void>(
        Component: ((props: { store: Store, args: TArgs, argsRoute: TArgsRoute }) => JSX.Element),
        options: {
            title: string,
            isSecure: boolean,
        },
        routing: (args: TArgs, argsRoute: TArgsRoute) => Routing<TArgs, TArgsRoute>,
    ): { isActive: boolean, isSecure: boolean, title: string, open: (args: TArgs) => void, _openIfCanParse: (route: string) => boolean, } {
        return createViewInstance<TArgs, TArgsRoute>(getStore, setActiveView, Component, options.title, options.isSecure, routing(null as any, null as any));
    }

    const views = createViewData(create);

    const _subscribeWebRoute = () => {
        const viewsOpenIfCanParse = getKeysTyped(views).map(k => ({ openIfCanParse: ((route: string) => views[k]._openIfCanParse(route)) }));
        subscribeWebRoute(viewsOpenIfCanParse, () => views.RootPage.open({}));
    };

    const result = {
        _getActiveViewComponent: getActiveView,
        _getIsActiveViewSecure: getIsActiveViewSecure,
        _handleNavigate: () => { },
        _subscribeWebRoute,
        _refresh: () => { result._handleNavigate() },
        ...views,
    };

    // This will be handled by subscribeWebRoute
    // views.RootView.open();
    return result;
};
