import React, { ReactNode, useEffect, useState } from 'react';
import { View, Text, ActivityIndicator, Modal, Platform, ScrollView, SafeAreaView, KeyboardAvoidingView, Keyboard } from 'react-native';
import { Store } from '../store/Store';
import { ActivityModal } from './ActivityModal';
import { TextView } from './TextView';
import { ErrorBox } from './ErrorBox';
import { LayoutFooter, LayoutHeader } from './LayoutHeader';
import { createHeaderData } from '../views/_HeaderData';
import { AdminInfo } from './AdminInfo';

const KeyboardExpander = (props: {}) => {

    const [expanded, setExpanded] = useState(false);
    useEffect(() => {
        const expand = () => { setExpanded(true); };
        const collapse = () => { setExpanded(false); };
        Keyboard.addListener('keyboardDidShow', expand);
        Keyboard.addListener('keyboardDidHide', collapse);
        return () => {
            Keyboard.removeListener('keyboardDidShow', expand);
            Keyboard.removeListener('keyboardDidHide', collapse);
        };
    });

    return (
        <View style={expanded ? { height: 400 } : {}}>
        </View>
    );
};

export const MainLayout = (props: {
    children: ReactNode, store: Store, loading?: boolean, error?: any,
    options?: { isLogoVisible?: boolean, isFullWidth?: boolean, isFullHeight?: boolean }
}) => {
    const headerData = createHeaderData(props.store);
    const rootStyle = props.options?.isFullWidth ? props.store.theme.root.fullWidth : props.store.theme.root.normal;
    const contentStyle = props.options?.isFullWidth ? props.store.theme.content.fullWidth : props.store.theme.content.normal;

    if (props.options?.isFullHeight) {
        if (Platform.OS === 'web') {
            document.body.style.height = '100%';
        }
    }

    if (Platform.OS !== 'web') {
        return (
            <SafeAreaView>
                <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                    <View style={rootStyle.view}>
                        {/* <AccountInfo store={props.store} /> */}
                        <AdminInfo store={props.store} />
                        <LayoutHeader store={props.store} headerData={headerData} options={props.options} />
                        {!!props.error && (
                            <ErrorBox error={props.error} />
                        )}
                        <View style={contentStyle.viewOuter}>
                            <View style={contentStyle.view}>
                                {props.children}
                            </View>
                        </View>
                        <LayoutFooter />
                        {!!props.loading && (
                            <ActivityModal />
                        )}
                    </View>
                    <KeyboardExpander />
                </ScrollView>
            </SafeAreaView>
        );
    }

    return (
        <View style={rootStyle.view}>
            {/* <AccountInfo store={props.store} /> */}
            <AdminInfo store={props.store} />
            <LayoutHeader store={props.store} headerData={headerData} options={props.options} />
            {!!props.error && (
                <ErrorBox error={props.error} />
            )}
            <View style={contentStyle.viewOuter}>
                <View style={contentStyle.view}>
                    {props.children}
                </View>
            </View>
            <LayoutFooter />
            {!!props.loading && (
                <ActivityModal />
            )}
        </View>
    );
};