import { PaymentProviderName, PaymentMethodStorageKey, PaymentClientApi } from "../lib/payments/common/types";
import { PaymentViewServerAccess_WithDebug } from "../lib/payments/client/payment-react";
import { AppConfig_Client } from "../_config/client/appConfig_client";
import { createPaymentApiClient } from "../api/payment/client/PaymentApiClient";
import { UserCredentialsProvider } from "../data-types/SecurityDataTypes";

export const createPaymentServerAccess = (appConfig: AppConfig_Client, userCredentialsProvider: UserCredentialsProvider) => {
    const client = createPaymentApiClient(appConfig, userCredentialsProvider);

    const providerName = `stripe` as PaymentProviderName;
    const access: PaymentViewServerAccess_WithDebug = {
        onSetupPayment: async () => {
            console.log(`onSetupPayment START`);
            const result = await client.setupSavedPaymentMethod({ providerName });
            console.log(`onSetupPayment END`, { result });
            return result;
        },
        onPaymentMethodReady: async (token) => {
            console.log(`onPaymentMethodReady START`);
            const result = await client.saveSavedPaymentMethod({ providerName, paymentMethodClientToken: token });
            console.log(`onPaymentMethodReady END`, { result });
            return result;
        },
        getPaymentMethods: async () => {
            console.log(`getPaymentMethods START`);
            const result = await client.getSavedPaymentMethods();
            console.log(`getPaymentMethods END`, { result });
            return result;
        },
        deletePaymentMethod: async (key: PaymentMethodStorageKey) => {
            console.log(`deletePaymentMethod`);
            await client.deleteSavedPaymentMethod({ key });
        },
        getPayments: async () => {
            console.log(`getPayments START`);
            const result = await client.getPayments();
            console.log(`getPayments END`, { result });
            return result;
        },

        // Debug
        onMakePurchase: async (amount: number) => {
            console.log(`onMakePurchase`);
            await client.debug_triggerPayment({ amount: { currency: `usd`, usdCents: Math.floor(amount * 100) } });
        },
    };
    return access;
};