import React, { useState, useEffect } from 'react';
import { View, Text } from 'react-native';
import { Store } from '../../../store/Store';
import { MessageTemplateField } from '../../../../logic/campaigns/campaignFieldDefinitions';
import { CheckBoxControl } from './CheckBoxControl';
import { MessageTemplateFieldEditorV2 } from './MessageTemplateEditor';
import { ButtonTextView, HintPopup, TextView, TextViewWithHint } from '../../../components/TextView';


const optInMessageTemplate = MessageTemplateField({
    templateId: 'optInMessage',
    label: 'Double Opt-In Message',
    hint: '”Yes” is the second keyword that the customer will need to send to the number to opt-in to your database. Your message should indicate that the customer must reply with “Yes” to opt-in to the database. You can use the message already contained within the box or you can add your own.',
    placeholder: 'Enter the Opt-In Message',
    imageMode: 'disabled', linkMode: 'disabled',
});
const confirmationMessageTemplate = MessageTemplateField({
    templateId: 'optInConfirmationMessage',
    label: 'Double Opt-In Confirmation Message',
    hint: 'What message should be sent after the user has confirmed?',
    placeholder: 'Enter the Confirmation Message',
    imageMode: 'disabled', linkMode: 'disabled',
});

export const OptInMessageControl = (props: { store: Store, value: null | { message: string, confirmationMessage: string }, onChangeMessage: (value: null | { message: string, confirmationMessage: string }) => void, isOptional: boolean }) => {
    const theme = props.store.theme;

    const legalOptInMessage = 'Msg&Data rates may apply. Reply STOP to stop, HELP 4 help.';

    const [isOptInEnabled, setIsOptInEnabled] = useState(!props.isOptional);
    const changeOptIn = (value: boolean) => {
        setIsOptInEnabled(value);

        if (value) {
            if (!props.value?.message) {
                props.onChangeMessage({
                    message: 'Please reply "yes" if you wish to receive future messages.',
                    confirmationMessage: 'You are now part of our VIP Club and will receive up to x messages per week. ' + legalOptInMessage
                });
            }
        } else {
            props.onChangeMessage(null);
        }
    };

    useEffect(() => {
        changeOptIn(!!props.value || !props.isOptional);
    }, [props.isOptional, props.value]);

    const addLegalOptIn = () => {
        if (props.value?.confirmationMessage.includes(legalOptInMessage)) { return; }

        props.onChangeMessage({
            message: (props.value?.message.trim() ?? ''),
            confirmationMessage: ((props.value?.confirmationMessage ?? '') + ' ' + legalOptInMessage).trim(),
        });
    };

    // console.log('OptInMessageControl', { isOptional: props.isOptional, });
    return (
        <>
            {props.isOptional && (
                <>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <CheckBoxControl store={props.store} label='Double Opt-In (Optional)' value={isOptInEnabled} onChange={changeOptIn} />
                        <Text style={theme.card.cardSectionTitle.text}><HintPopup hint={'Check here if you wish to require the customer to reply to two messages to opt-in to your database.'} /></Text>
                    </View>
                </>
            )}
            {isOptInEnabled && (
                <>
                    <View style={{ marginTop: 16, marginBottom: 16 }}>
                        <View style={{ padding: 8 }}>
                            <TextView style={theme.card.cardSummary} text='In some cases, you may wish to provide a double opt-in to your customer. This will require the customer to reply to two messages to become part of your opt-in database to receive future broadcast messages.' />
                        </View>

                        <MessageTemplateFieldEditorV2
                            style={props.store.theme.card.messageTemplateField}
                            field={optInMessageTemplate}
                            value={{ text: props.value?.message ?? '', additionalTexts: [] }}
                            onChangeValue={x => props.onChangeMessage({ message: x.message.text, confirmationMessage: props.value?.confirmationMessage ?? '' })}
                            store={props.store}
                        />
                        <MessageTemplateFieldEditorV2
                            style={props.store.theme.card.messageTemplateField}
                            field={confirmationMessageTemplate}
                            value={{ text: props.value?.confirmationMessage ?? '', additionalTexts: [] }}
                            onChangeValue={x => props.onChangeMessage({ confirmationMessage: x.message.text, message: props.value?.message ?? '' })}
                            store={props.store}
                        />
                        <View style={{ padding: 8, flexDirection: 'row', alignItems: 'center' }}>
                            <ButtonTextView style={theme.card.buttonTextView_minor} text='Add opt-in legal language.' onPress={addLegalOptIn} isDisabled={props.value?.confirmationMessage.includes(legalOptInMessage)} />
                            <HintPopup hint='You should use this language on the first opt-in from your customer. The following language will be added to the end of your reply message and will take 58 characters: “Msg&Data rates may apply. Reply STOP to stop, HELP 4 help.” NOTE -- This is not legal advice; please contact your own attorney for specific legal requirements.' />
                        </View>
                    </View>
                </>
            )}
        </>
    );
};