import React, { useState } from 'react';
import { View, Text, ActivityIndicator } from 'react-native';
import { Store } from '../../store/Store';
import { useLoadData, useAutoLoadingError } from '../../../utils/Hooks';
import { List } from '../../components/List';
import { AccountModel, AccountAdminSettings } from '../../../data-types/ModelTypes';
import { DateOnly, Timestamp } from '../../../utils/Time';
import { ButtonTextView, TextView, TextField } from '../../components/TextView';
import { MainLayout } from '../../components/Layout';
import { ErrorBox } from '../../components/ErrorBox';
import { NavLinks } from '../Common/NavLinks';
import { getReference, getReferenceOrUndefined, Price, PhoneNumber, EmailAddress, getReferenceFromId, Reference } from '../../../data-types/SimpleDataTypes';
import { CampaignLink, useReferenceList_LoadAll } from '../Common/ReferenceLinks';
import { ViewKeywords } from '../Keywords/ViewKeywords';
import { AccountAdminData } from '../../../client-api-access/types/AdminApiAccess';
import { formatPhoneNumber_UsaCanada, toStandardPhoneNumber_optional, toStandardPhoneNumber } from '../../../utils/PhoneNumbers';
import { Validation_PhoneNumber_UsaCanada } from '../../../utils/Validation';
import { AppError } from '../../../utils/Errors';
import { DateFieldEditor } from '../Campaigns/components/DateRangeFieldsEditor';
import { DatePicker } from '../../components/DateTimePicker';


const AccountItem = (props: { store: Store, item: AccountAdminData, onItemChanged: (item: AccountAdminData) => void }) => {
    const { store, item: itemInit } = props;
    const { nav, theme } = store;

    const [item, setItem] = useState(itemInit);
    // const [isEditing, setIsEditing] = useState(false);
    // const onItemChanged = (item: AccountAdminData) => {
    //     setItem(item);
    //     setIsEditing(false);
    //     props.onItemChanged?.(item);
    // };


    const account = item.accountData;
    const info = item.accountInfo ?? {};



    const [accountSettings, setAccountSettings] = useState(
        [
            { key: 'canSendAtNight', name: 'Can Send at Any Hour (Night)', value: item.accountAdminSettings.canSendAtNight ?? false },
            { key: 'canSendToImportedContacts', name: 'Can Send to Imported Contacts', value: item.accountAdminSettings.canSendToImportedContacts ?? false },
        ] as {
            key: keyof AccountAdminSettings,
            name: string,
            value: boolean
        }[]
    );

    const { loading, error, doWork } = useAutoLoadingError();

    const changeAccountSetting = (key: keyof AccountAdminSettings, value: boolean) => {
        doWork(async (stopIfObsolete) => {
            item.accountAdminSettings[key] = value;
            await store.admin.setAccountAdminSettings(account.account, item.accountAdminSettings);
            stopIfObsolete();

            setAccountSettings(s => { s.find(x => x.key === key)!.value = value; return [...s]; });
        });
    };

    const isDisabled = account.accountAdminSettings?.disabled ?? false;
    const noSubscription = !account.subscriptionDetails;
    const isPaymentDue = account.subscriptionDetails && Timestamp.addDays(account.subscriptionDetails.cycleEndTime, -1) < Timestamp.now();
    const hasPaymentFailed = (account.paymentDetails?.paymentSystemState?.paymentFailureCount ?? 0) > 0;
    const isFreeOrManualAccount = account.planDetails && account.planDetails.priceMonthly === 0;
    const isPaymentSetupRequired = !isFreeOrManualAccount && !account.paymentDetails?.paymentSystemState?.paymentsReady;

    const status = 
        isDisabled ? 'disabled'
            : noSubscription ? 'no-subscription'
                : hasPaymentFailed ? 'payment-failed'
                    : isPaymentSetupRequired ? 'payment-not-ready'
                        : isPaymentDue ? 'payment-due'
                            : 'active';
                        
    const statusMessage = 
        status === 'disabled' ? 'Account Disabled' 
            : status === 'no-subscription' ? 'Subscription not active' 
                : status === 'payment-failed' ? 'Last Payment Failed' 
                    : status === 'payment-not-ready' ? 'Payment Not Ready' 
                        : status === 'payment-due' ? 'Payment Due' 
                            : 'Active';
    return (
        <View style={status === 'active' ? theme.card.view : theme.card.view_disabled }>

            {loading && (<ActivityIndicator size='large' />)}
            {error && (<ErrorBox error={error} />)}

            {status !== 'active' && (
                <View style={theme.grid.row}>
                    <Text style={theme.grid.warningText}>{statusMessage}</Text>
                </View>
            )}
            <View style={theme.grid.row}>

                <View style={theme.grid.cell}>
                    <Text style={theme.grid.titleText}>{account.accountDetails?.businessName ?? info.accountName}</Text>

                    <View style={theme.card.fieldView}>
                        <Text style={theme.card.fieldLabelText}>Created:</Text>
                        <Text style={theme.card.fieldValueText}>{Timestamp.formatTimestamp_asDateOnly(info.createdAt)}</Text>
                    </View>

                    <View style={theme.card.fieldView}>
                        <Text style={theme.card.fieldLabelText}>Purchased Phone Numbers:</Text>
                        {!item.accountState.stats.accountPhoneNumbers && (<Text style={theme.card.fieldValueText}>Not Setup</Text>)}
                        <View style={theme.grid.cell}>
                            {item.accountState.stats.accountPhoneNumbers && item.accountState.stats.accountPhoneNumbers.map((x, i) => (
                                <View key={x + i} style={theme.grid.row_simple}>
                                    <Text style={theme.card.fieldValueText}>{formatPhoneNumber_UsaCanada(x)}</Text>
                                </View>
                            ))}
                        </View>
                    </View>
                </View>

                <View style={theme.grid.cell}>
                    <View style={theme.card.fieldView}>
                        <Text style={theme.card.fieldLabelText}>Business Email:</Text>
                        <Text style={theme.card.header2Text}>{account.accountDetails?.businessEmail}</Text>
                    </View>
                    <View style={theme.card.fieldView}>
                        <Text style={theme.card.fieldLabelText}>Users:</Text>
                        <View style={theme.grid.cell}>
                            {item.userEmails.map(x => (
                                <View key={x} style={theme.grid.row_simple}>
                                    <Text style={theme.card.header2Text}>{x}</Text>
                                </View>
                            ))}
                        </View>
                    </View>
                </View>

            </View>

            <View style={theme.grid.row}>

                <View style={theme.grid.cell}>
                    <View style={theme.card.fieldView}>
                        <Text style={theme.card.fieldLabelText}>Plan:</Text>
                        {!account.planDetails && (<Text style={theme.card.fieldValueText}>Not Setup</Text>)}
                        {account.planDetails && (<Text style={theme.card.fieldValueText}> {account.planDetails.name}</Text>)}
                        {account.planDetails && (<Text style={theme.card.fieldValueText}> : {Price.formatPrice(account.planDetails.priceMonthly)}</Text>)}
                        {account.planDetails && (<Text style={theme.card.fieldValueText}> - {account.planDetails.messagesMonthly} Credits</Text>)}
                    </View>
                    <View style={theme.card.fieldView}>
                        <Text style={theme.card.fieldLabelText}>Cycle:</Text>
                        {!account.subscriptionDetails && (<Text style={theme.card.fieldValueText}>Not Setup</Text>)}
                        {account.subscriptionDetails && (<Text style={theme.card.fieldValueText}> {Timestamp.formatTimestamp_asDateOnly(account.subscriptionDetails.cycleStartTime)} - {Timestamp.formatTimestamp_asDateOnly(account.subscriptionDetails.cycleEndTime)}</Text>)}
                    </View>
                    <View style={theme.card.fieldView}>
                        <Text style={theme.card.fieldLabelText}>Credits:</Text>
                        {account.subscriptionDetails && (<Text style={theme.card.fieldValueText}>{account.subscriptionDetails.cycleRemainingCredits} Remaining / {account.subscriptionDetails.cycleSubscriptionCredits} + {account.subscriptionDetails.cycleExtraCredits} Extra</Text>)}
                    </View>

                </View>

                <View style={theme.grid.cell}>

                    <View style={theme.card.fieldView}>
                        <Text style={theme.card.fieldLabelText}>Address:</Text>
                        <View style={theme.grid.cell}>
                            <View style={theme.grid.row_simple}>
                                {!account.accountDetails && (<Text style={theme.card.fieldValueText}>Not Setup</Text>)}
                                {account.accountDetails && (<Text style={theme.card.fieldValueText}>{` ${account.accountDetails.address.street}`}</Text>)}
                            </View>
                            <View style={theme.grid.row_simple}>
                                {account.accountDetails && (<Text style={theme.card.fieldValueText}>{` ${account.accountDetails.address.city}`}</Text>)}
                                {account.accountDetails && (<Text style={theme.card.fieldValueText}>{` ${account.accountDetails.address.state}`}</Text>)}
                                {account.accountDetails && (<Text style={theme.card.fieldValueText}>{` ${account.accountDetails.address.zip}`}</Text>)}
                            </View>
                            <View style={theme.grid.row_simple}>
                                {account.accountDetails && (<Text style={theme.card.fieldValueText}>{` ${account.accountDetails.address.country}`}</Text>)}
                            </View>
                        </View>
                    </View>

                </View>

            </View>

            <View style={theme.grid.row}>
                <View style={theme.grid.cell}>
                    <View style={theme.card.fieldView}>
                        <Text style={theme.card.fieldLabelText}>Payment Provider:</Text>
                        {!account.paymentDetails?.paymentSystemState?.paymentsReady && (<Text style={theme.card.fieldValueText}>Not Setup</Text>)}
                        {account.paymentDetails?.paymentSystemState?.paymentsReady && (<>
                            <Text style={theme.card.fieldValueText}>Ready</Text>
                            {/* <Text style={theme.card.fieldValueText}>Last Payment: {Timestamp.formatTimestamp_asDateOnly(account.paymentDetails.paymentSystemState.lastPaymentTime)}</Text> */}
                        </>)}
                        {/* {account.paymentDetails && (<Text style={theme.card.fieldValueText}> {account.paymentDetails.provider}</Text>)}
                        {account.paymentDetails && (<Text style={theme.card.fieldValueText}> {account.paymentDetails.providerPaymentId}</Text>)} */}
                    </View>
                    <View style={theme.card.fieldView}>
                        <Text style={theme.card.fieldLabelText}>Last Payment:</Text>
                        {account.subscriptionDetails && (<Text style={theme.card.fieldValueText}> {Price.formatPrice(account.subscriptionDetails.cyclePaymentAmount ?? 0)}</Text>)}
                    </View>
                    <View style={theme.card.fieldView}>
                        <Text style={theme.card.fieldLabelText}>Next Payment Due:</Text>
                        {account.subscriptionDetails && (<Text style={theme.card.fieldValueText}> {Price.formatPrice(account.planDetails?.priceMonthly ?? 0 as Price)} {account.subscriptionDetails && Timestamp.formatTimestamp_asDateOnly(account.subscriptionDetails.cycleEndTime)}</Text>)}
                    </View>
                </View>
            </View>

            <View style={theme.grid.row}>
                <View style={theme.grid.cell}>
                    <Text style={theme.grid.titleText}>Account Settings</Text>

                    {accountSettings.map(x => (
                        <View key={x.key} style={theme.grid.row_centered}>
                            <ButtonTextView style={theme.card.buttonTextView_minor_inline}
                                onPress={() => changeAccountSetting(x.key, !x.value)}
                                text={!x.value ? 'Enable' : 'Disable'} />
                            <Text style={theme.card.fieldLabelText}>{x.name}</Text>
                            <Text style={theme.card.fieldLabelText}>{x.value ? '(Enabled)' : '(Disabled)'}</Text>
                        </View>
                    ))}
                </View>
            </View>
            <AdjustSubscriptionView {...props} />
            <AddUserToAccountView {...props} />
            <ImpersonateAccountArea {...props} />
            <DisableAccountArea {...props} />

            {/* <View style={theme.grid.row_simple}>
                <View style={theme.grid.cell}>
                    <View style={theme.card.fieldView}>
                        <Text style={theme.card.fieldLabelText}>Created:</Text>
                        <Text style={theme.card.fieldValueText}>{Timestamp.formatTimestamp(item.createdTime)}</Text>
                    </View>
                    <View style={theme.card.fieldView}>
                        <Text style={theme.card.fieldLabelText}>Edited:</Text>
                        <Text style={theme.card.fieldValueText}>{Timestamp.formatTimestamp(item.modifiedTime)}</Text>
                    </View>
                    <View style={theme.card.fieldView}>
                        <Text style={theme.card.fieldLabelText}>Contacts:</Text>
                        <Text style={theme.card.fieldValueText}>{contactsCount.data ?? ''}</Text>
                    </View>
                </View>
                <View style={theme.grid.cell}>
                    <View style={theme.card.fieldView}>
                        <Text style={theme.card.fieldLabelText}>Campaigns:</Text>
                        <View>
                            {campaigns?.map(x => (
                                <View key={x.id} style={theme.card.row_simple}>
                                    <CampaignLink store={store} style={theme.card.navTextView} itemRef={getReference(x)} />
                                </View>
                            ))}
                        </View>
                    </View>
                </View>
            </View>

            <View style={theme.card.buttonRow}>
                <ButtonTextView style={theme.card.buttonTextView_minor}
                    onPress={() => nav.ViewAccountContactsPage.open({ account: item })}
                    text="View Contacts" />
                <ButtonTextView style={theme.card.buttonTextView_major}
                    onPress={() => setIsEditing(!isEditing)}
                    text="Edit" />
            </View> */}
            {/* {isEditing && (
                <EditAccount {...props} onCancelEdit={() => setIsEditing(false)} onItemChanged={onItemChanged} />
            )} */}
        </View>
    );
};

const CreateNewAccountArea = (props: { store: Store, }) => {
    const { store, } = props;
    const { nav, theme } = store;

    const { loading, error, doWork } = useAutoLoadingError();

    const createNewAccount = () => doWork(async (stopIfObsolete) => {
        await store.admin.createNewAccount();

        setTimeout(() => {
            store.nav.EditPlanPage.open();
        });
    });

    return (
        <>
            <View style={theme.grid.row}>
                <ButtonTextView style={theme.card.buttonTextView_minor_inline}
                    onPress={createNewAccount}
                    text={'Create New Account'} />
                <View style={theme.grid.cell}></View>
            </View>
            {loading && (<ActivityIndicator size='large' />)}
            {error && (<ErrorBox error={error} />)}
        </>
    );
};

const ImpersonateAccountArea = (props: { store: Store, item: AccountAdminData }) => {
    const { store, item } = props;
    const { nav, theme } = store;

    const { loading, error, doWork } = useAutoLoadingError();

    const impersonateAccount = () => doWork(async () => {
        await store.admin.impersonateAccount(item.accountData.account);
    });

    return (
        <>
            <View style={theme.grid.row}>
                <ButtonTextView style={theme.card.buttonTextView_minor_inline}
                    onPress={impersonateAccount}
                    text={'Login to Account'} />
                <View style={theme.grid.cell}></View>
            </View>
            {loading && (<ActivityIndicator size='large' />)}
            {error && (<ErrorBox error={error} />)}
        </>
    );
};


const DisableAccountArea = (props: { store: Store, item: AccountAdminData, onItemChanged: (item: AccountAdminData) => void }) => {
    const { store, item, onItemChanged } = props;
    const { nav, theme } = store;

    const { loading, error, doWork } = useAutoLoadingError();

    const changeAccountEnabled = (enabled: boolean)=>{
        doWork(async (stopIfObsolete) => {
            item.accountAdminSettings.disabled = !enabled;
            await store.admin.setAccountAdminSettings(item.accountData.account, item.accountAdminSettings);
            stopIfObsolete();
            onItemChanged(item);
        });
    };

    const isDisabled = item.accountAdminSettings.disabled;

    return (
        <>
            <View style={theme.grid.row}>
                <View style={theme.grid.cell}></View>
                {isDisabled && (
                    <ButtonTextView style={theme.card.buttonTextView_major}
                        onPress={() => changeAccountEnabled(true)}
                        text={'Enable Account'} />
                )}
                {!isDisabled && (
                   <ButtonTextView style={theme.card.buttonTextView_danger}
                        onPress={() => changeAccountEnabled(false)}
                        text={'Disable Account'} />
                )}
            </View>
            {loading && (<ActivityIndicator size='large' />)}
            {error && (<ErrorBox error={error} />)}
        </>
    );
};



export const AddUserToAccountView = (props: { store: Store, item: AccountAdminData, onItemChanged: (item: AccountAdminData) => void }) => {
    const { store, item } = props;
    const { theme } = store;
    const [isExpanded, setIsExpanded] = useState(false);
    const [email, setEmail] = useState(null as null | EmailAddress);
    const [phone, setPhone] = useState(null as null | PhoneNumber);
    const [resultMessage, setResultMessage] = useState(null as null | { invitationLink: string });

    const { loading, error, doWork } = useAutoLoadingError();

    const addUser = () => {
        if (!email || !phone) {
            throw new AppError('Invalid Email or Phone');
        }

        doWork(async (stopIfObsolete) => {
            await store.admin.addUserToAccount(item.accountData.account, { email, phone });
            stopIfObsolete();
            // setIsExpanded(false);
            setEmail(null);
            setPhone(null);
            setResultMessage({ invitationLink: `${store.config.webAppDomain}/Invite?username=${email}` });
        });
    };

    const removeUser = (email: EmailAddress) => {
        doWork(async (stopIfObsolete) => {
            await store.admin.removeUserFromAccount(item.accountData.account, { email });
            stopIfObsolete();
            props.onItemChanged(item);
        });

    };

    return (
        <>
            {!isExpanded && (
                <>
                    <View style={theme.grid.row}>
                        <ButtonTextView style={theme.card.buttonTextView_minor_inline}
                            onPress={() => setIsExpanded(s => !s)}
                            text={'Edit Account Users'} />
                        <View style={theme.grid.cell}></View>
                    </View>
                </>
            )}{isExpanded && !resultMessage && (
                <>
                    <View style={theme.card.view_white}>
                      
                        <TextView style={theme.card.cardSectionTitle} text='Edit Users' />
                        {item.userEmails.length <= 0 && (
                            <View style={{...theme.card.row, alignItems:'center', padding: 16 }}>
                                <TextView style={theme.card.cardTextInline} text={'No Users'} />
                            </View>
                        )}
                        {item.userEmails.map(x=>(
                            <View key={x} style={{...theme.card.row, alignItems:'center', padding: 16 }}>
                                    <TextView style={theme.card.cardTextInline} text={x} />
                                    <ButtonTextView style={theme.card.buttonTextView_danger}
                                        onPress={() => removeUser(x as EmailAddress)}
                                        text={'Remove User'} />
                            </View>
                        ))}

                        <TextView style={theme.card.cardSectionTitle} text='Add User to Account' />

                        <TextField style={theme.card.textField}
                            field={{
                                __type: 'ui',
                                isRequired: true, label: 'Email', placeholder: 'Email'
                            }}
                            value={email ?? ''}
                            onChangeValue={x => setEmail((x?.value ?? '') as EmailAddress)} />

                        <TextField style={theme.card.textField}
                            field={{
                                __type: 'ui',
                                isRequired: true, label: 'Phone Number', placeholder: 'Phone Number', validation: Validation_PhoneNumber_UsaCanada()
                            }}
                            value={formatPhoneNumber_UsaCanada(phone ?? '')}
                            onChangeValue={x => setPhone(toStandardPhoneNumber(x?.value ?? ''))} />

                        <View style={theme.card.buttonRow}>
                            <ButtonTextView style={theme.card.buttonTextView_danger}
                                onPress={() => setIsExpanded(false)}
                                text={'Cancel'} />
                            <View style={theme.grid.cell}></View>
                            <ButtonTextView style={theme.card.buttonTextView_major}
                                isDisabled={!phone || !email}
                                onPress={addUser}
                                text={'Add User'} />
                        </View>


                        {loading && (<ActivityIndicator size='large' />)}
                        {error && (<ErrorBox error={error} />)}
                    </View>
                </>
            )}
            {isExpanded && resultMessage && (
                <>
                    <View style={theme.card.view_white}>
                        <TextView style={theme.card.cardSectionTitle} text='Add User to Account - Invitation Link' />
                        <TextView style={theme.card.cardSummary} text='Send this invitation link to the user so they can create their password and login:' />
                        <TextView style={theme.card.cardSummary} text={resultMessage.invitationLink} />
                    </View>
                </>
            )}
        </>
    );
};



export const AdjustSubscriptionView = (props: { store: Store, item: AccountAdminData, onItemChanged: (item: AccountAdminData) => void }) => {
    const { store, item } = props;
    const { theme } = store;
    const [isExpanded, setIsExpanded] = useState(false);
    const [planCredits, setPlanCredits] = useState(item.accountData.planDetails?.messagesMonthly ?? 0);
    const [planPrice, setPlanPrice] = useState(item.accountData.planDetails?.priceMonthly ?? 0 as Price);
    const [extraCredits, setExtraCredits] = useState(item.accountData.subscriptionDetails?.cycleExtraCredits ?? 0);
    const [endDate, setEndDate] = useState(Timestamp.toDateOnly(item.accountData.subscriptionDetails?.cycleEndTime));

    const { loading, error, doWork } = useAutoLoadingError();

    const save = () => {

        console.log('AdjustSubscriptionView save', {
            planCredits,
            planCredits_before: item.accountData.planDetails?.messagesMonthly,
            planPrice,
            planPrice_before: item.accountData.planDetails?.priceMonthly,
            extraCredits,
            extraCredits_before: item.accountData.subscriptionDetails?.cycleExtraCredits,
            endDate,
            endDate_before: item.accountData.subscriptionDetails?.cycleEndTime,
        });

        doWork(async (stopIfObsolete) => {
            await store.admin.setAccountSubscription(item.accountData.account, {
                planCreditsMonthly: planCredits ?? undefined,
                planPriceMonthly: planPrice ?? undefined,
                cycleExtraCredits: extraCredits ?? undefined,
                cycleEndDate: endDate ?? undefined,
            });

            stopIfObsolete();
            setIsExpanded(false);
            props.onItemChanged(item);
        });
    };

    return (
        <>
            {!isExpanded ? (
                <>
                    <View style={theme.grid.row}>
                        <ButtonTextView style={theme.card.buttonTextView_minor_inline}
                            onPress={() => setIsExpanded(s => !s)}
                            text={'Edit Account Subscription'} />
                        <View style={theme.grid.cell}></View>
                    </View>
                </>
            ) : (
                <>
                    <View style={theme.card.view_white}>
                        <TextView style={theme.card.cardSectionTitle} text='Edit Account Subscription' />

                        <TextField style={theme.card.textField}
                            field={{
                                __type: 'ui',
                                isRequired: true, label: 'Plan Credits', placeholder: '0'
                            }}
                            value={planCredits + ''}
                            onChangeValue={x => setPlanCredits(parseInt(x?.value ?? ''))}
                        />
                        <TextField style={theme.card.textField}
                            field={{
                                __type: 'ui',
                                isRequired: true, label: 'Plan Price', placeholder: '0'
                            }}
                            value={Price.formatPrice(planPrice)}
                            onChangeValue={x => setPlanPrice(Price.parsePrice(x?.value))}
                        />
                        <TextField style={theme.card.textField}
                            field={{
                                __type: 'ui',
                                isRequired: true, label: 'Extra Credits', placeholder: '0'
                            }}
                            value={extraCredits + ''}
                            onChangeValue={x => setExtraCredits(parseInt(x?.value ?? ''))}
                        />
                        <DatePicker style={theme.card.textField}
                            field={{
                                __type: 'ui',
                                isRequired: true, label: 'Next Payment Date', placeholder: '0'
                            }}
                            value={endDate}
                            onChangeValue={x => setEndDate(x?.value ?? DateOnly.today())}
                        />

                        <View style={theme.card.buttonRow}>
                            <ButtonTextView style={theme.card.buttonTextView_danger}
                                onPress={() => setIsExpanded(false)}
                                text={'Cancel'} />
                            <View style={theme.grid.cell}></View>
                            <ButtonTextView style={theme.card.buttonTextView_major}
                                onPress={save}
                                text={'Save'} />
                        </View>

                        {loading && (<ActivityIndicator size='large' />)}
                        {error && (<ErrorBox error={error} />)}
                    </View>
                </>
            )}
        </>
    );
};

// export const CreateAccountPage = (props: { store: Store }) => {
//     const { store } = props;
//     const { api, nav, theme } = store;
//     const { loading, error, doWork } = useAutoLoadingError();

//     return (
//         <MainLayout store={store}>
//             <View style={theme.page.titleView}>
//                 <Text style={theme.page.titleText}>Create List</Text>
//                 <View style={theme.page.titleUnderlineView}>
//                 </View>
//             </View>
//             {loading && <ActivityIndicator size="large" />}
//             {error && <ErrorBox error={error} />}
//             {/* <EditAccount store={store} onItemChanged={(x) => nav.ViewAccountPage.open({ account: x }, { replaceInHistory: true })} /> */}
//         </MainLayout>
//     );
// };

// export const ViewAccountPage = (props: { store: Store, args?: { account: AccountModel }, argsRoute?: { accountId: string } }) => {
//     const { store } = props;
//     const { api, nav, theme } = store;
//     const { loading, error, doWork } = useAutoLoadingError();
//     const accountData = useLoadData(doWork, async () => {
//         const account = props.args?.account ?? await api.getAccount(props.argsRoute?.accountId!);
//         return {
//             account,
//         };
//     }, () => [props.args]);

//     const account = accountData.data?.account;

//     return (
//         <MainLayout store={store}>
//             <View style={theme.nav.row_simple}>
//                 <NavLinks.ViewAccounts store={store} style={store.theme.nav.breadcrumbButton} />
//                 <NavLinks.ViewAccount store={store} style={store.theme.nav.breadcrumbButton} itemRef={getReferenceOrUndefined(account)} />
//             </View>
//             <View style={theme.page.titleView}>
//                 <Text style={theme.page.titleText}>View List</Text>
//                 <View style={theme.page.titleUnderlineView}>
//                 </View>
//             </View>
//             {loading && <ActivityIndicator size="large" />}
//             {error && <ErrorBox error={error} />}
//             {account && (<AccountItem store={store} item={account} />)}
//         </MainLayout>
//     );
// };


export const ViewAccountsPage = (props: { store: Store, args?: { search: string }, argsRoute?: { search: string } }) => {
    const { store } = props;
    const { api, admin, theme } = store;
    const { loading, error, doWork } = useAutoLoadingError();
    const [renderId, setRenderId] = useState(0);
    const accountsData = useLoadData(doWork, () => admin.getAccountStates(), () => [renderId]);

    const defaultSearch = decodeURIComponent(props.argsRoute?.search || '') || props.args?.search;

    const [filter, setFilter] = useState(defaultSearch ? 'all-by-recent' : 'all-by-recent' as 'active' | 'all-by-recent' | 'active-by-cycle' | 'all' | 'disabled' | 'payment-due');
    const accountNotDisabled = accountsData.data?.filter(x => !x.accountAdminSettings.disabled) ?? [];
    const accountsFiltered = filter === 'active' ? accountNotDisabled.filter(x => x.accountState.isPaymentsReady || x.accountData.planDetails?.priceMonthly === 0)
        : filter === 'all-by-recent' ? accountNotDisabled
            : filter === 'active-by-cycle' ? accountNotDisabled.filter(x => x.accountState.isPaymentsReady || x.accountData.planDetails?.priceMonthly === 0)
                : filter === 'payment-due' ? accountNotDisabled.filter(x => x.accountData.subscriptionDetails && Timestamp.addDays(x.accountData.subscriptionDetails.cycleEndTime, -1) < Timestamp.now())
                    : filter === 'disabled' ? accountsData.data?.filter(x=>x.accountAdminSettings.disabled)
                        // All including disabled
                            : accountsData.data;

    const extractSortKey = (x: AccountAdminData) =>
        filter === 'all-by-recent' ? [(Number.MAX_SAFE_INTEGER - x.accountInfo.createdAt + '').padStart(16, '0')]
            : filter === 'active-by-cycle' || filter === 'payment-due' ? [(Number.MAX_SAFE_INTEGER - x.accountState.stats.cycleEndTime + '').padStart(16, '0')]
                : [x.accountData.accountDetails?.businessName ?? 'ZZZ', x.accountInfo.accountName];

    return (
        <MainLayout store={store}>
            {/* <View style={theme.nav.row_simple}>
                <NavLinks.ViewAccounts store={store} style={store.theme.nav.breadcrumbButton} />
            </View> */}
            <View style={theme.page.titleView}>
                <Text style={theme.page.titleText}>Manage Accounts</Text>
                <View style={theme.page.titleUnderlineView} />
            </View>

            <View style={theme.card.buttonRow}>
                <CreateNewAccountArea {...props} />
            </View>

            <View style={theme.card.buttonRow_center}>
                <ButtonTextView style={filter === 'active' ? theme.card.buttonTextView_major : theme.card.buttonTextView_minor}
                    onPress={() => setFilter('active')}
                    text={'Active'} />
                <ButtonTextView style={filter === 'all-by-recent' ? theme.card.buttonTextView_major : theme.card.buttonTextView_minor}
                    onPress={() => setFilter('all-by-recent')}
                    text={'Recent'} />
                <ButtonTextView style={filter === 'payment-due' ? theme.card.buttonTextView_major : theme.card.buttonTextView_minor}
                    onPress={() => setFilter('payment-due')}
                    text={'Payment Due'} />
                {/* <ButtonTextView style={filter === 'active-by-cycle' ? theme.card.buttonTextView_major : theme.card.buttonTextView_minor}
                    onPress={() => setFilter('active-by-cycle')}
                    text={'Active - Sort By Next Payment Due'} /> */}
                <ButtonTextView style={filter === 'disabled' ? theme.card.buttonTextView_major : theme.card.buttonTextView_minor}
                    onPress={() => setFilter('disabled')}
                    text={'Disabled'} />
                <ButtonTextView style={filter === 'all' ? theme.card.buttonTextView_major : theme.card.buttonTextView_minor}
                    onPress={() => setFilter('all')}
                    text={'All'} />

            </View>

            <List
                style={theme.list}
                items={accountsFiltered ?? []}
                loading={loading}
                error={error}
                extractKey={x => x.id}
                extractSearchKey={x => [x.id, x.accountData.accountDetails?.businessName ?? '', x.accountData.accountDetails?.businessEmail ?? '', x.accountInfo.accountName, ...x.accountState.stats.accountPhoneNumbers, ...x.userEmails]}
                extractSortKey={extractSortKey}
                renderItem={x => <AccountItem store={store} item={x.item} onItemChanged={() => { setRenderId(s => s + 1) }} />}
                defaultSearch={defaultSearch}
            />
        </MainLayout>
    );
};

