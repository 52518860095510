import React, { useState, useEffect } from 'react';
import { View, ActivityIndicator, Text, TextInput } from 'react-native';
import { Store } from '../../store/Store';
import { useAutoLoadingError } from '../../../utils/Hooks';
import { ContactListModel, ContactListModel_Edit } from '../../../data-types/ModelTypes';
import { ButtonTextView, TextView, TextField, HintPopup } from '../../components/TextView';
import { ErrorBox } from '../../components/ErrorBox';
import { splitKeywords, joinKeywords } from '../../../utils/Keywords';
import { AppError } from '../../../utils/Errors';
import { Reference, getReference } from '../../../data-types/SimpleDataTypes';
import { Loading } from '../../../lib/controls-react/loading';

export const EditContactList = (props: { store: Store, item?: ContactListModel, 
    onCancelEdit?: () => void, 
    onItemChanged?: (item: ContactListModel) => void,
    onDeleted?: () => void,
}) => {

    const { store, item: itemInit } = props;
    const { theme } = store;

    const { loading, error, doWork } = useAutoLoadingError();
    const [item, setItem] = useState(itemInit ? { ...itemInit } : null);
    const [itemEdit, setItemEdit] = useState({ ...itemInit } as ContactListModel_Edit);

    const [hasDuplicate, setHasDuplicate] = useState(false);

    const saveChanges = () => {
        doWork(async (stopIfObsolete) => {
            if (hasDuplicate) {
                throw new AppError('Cannot use a duplicate keyword.');
            }

            // const keywords = splitKeywords(itemEdit.keywords);
            // const allExistingKeywords = await store.api.getKeywords();
            // const initItemKeywords = splitKeywords(item?.keywords);
            // const existingKeywordsExceptItem = allExistingKeywords.filter(x => !initItemKeywords.includes(x));

            // const duplicates = keywords.filter(x => existingKeywordsExceptItem.includes(x));
            // if (duplicates.length) {
            //     setDuplicateKeywords(duplicates.join(', '));
            //     return;
            // }

            const result = !item ? await store.api.createContactList(itemEdit)
                : await store.api.setContactListData(item, itemEdit);
            stopIfObsolete();

            setItem(result);
            props.onItemChanged?.(result);
        });
    };

    const deleteList = () => {
        if(!item){ return; }

        doWork(async (stopIfObsolete) => {
            await store.api.deleteList(item);
            stopIfObsolete();
            props.onDeleted?.();
        });
    };

    const changeItemValue = (value: ContactListModel_Edit) => {
        setItemEdit(x => ({ ...x, ...value }));
    };

    return (
        <View style={theme.card.view_white}>
            {!item && (<TextView style={theme.card.cardTitle} text={`Create List`} />)}
            {item && (<TextView style={theme.card.cardTitle} text={`Edit List - ${item.name}`} />)}

            <TextField style={theme.card.textField}
                field={{ __type: 'ui', isRequired: false, label: 'Name', placeholder: 'Name' }}
                value={itemEdit.name}
                onChangeValue={x => changeItemValue({ name: x?.value ?? '' })} />
            <TextField style={theme.card.textField}
                field={{ __type: 'ui', isRequired: false, label: 'Description', placeholder: 'Description' }}
                value={itemEdit.description}
                onChangeValue={x => changeItemValue({ description: x?.value ?? '' })} />

            {item && (
                <EditContactListKeywords store={store}
                    preventDuplicates='list'
                    label='Optin Keywords'
                    hint={`Enter the keywords that will be used to opt-in to the campaign (Upper-case and spaces are ignored)`}
                    initialValue={item?.keywords} value={itemEdit.keywords} onChange={x => { itemEdit.keywords = x.keywords; setHasDuplicate(x.hasDuplicate); }} />
            )}
            <View style={theme.card.buttonRow}>
                {!!item && (<ButtonTextView style={theme.card.buttonTextView_danger} text="Delete" onPress={deleteList} />)}
                <View style={theme.card.buttonSpacer}></View>
                {props.onCancelEdit && (<ButtonTextView style={theme.card.buttonTextView_minor} text="Cancel" onPress={props.onCancelEdit} />)}
                <ButtonTextView style={theme.card.buttonTextView_major} text="Save" onPress={saveChanges} isDisabled={loading || hasDuplicate} />
            </View>

            {loading && <ActivityIndicator size="large" />}
            {error && <ErrorBox error={error} />}
        </View>
    );
};

export const EditContactListKeywords = (props: {
    store: Store,
    initialValue?: string,
    value?: string,
    onChange: (args: { keywords: string, hasDuplicate: boolean }) => void,
    label?: string,
    hint?: string,
    preventDuplicates: 'list' | 'campaign',
}) => {
    const { store, initialValue, value, onChange } = props;
    const { theme } = store;

    const { loading, error, doWork } = useAutoLoadingError();
    const [duplicateKeywords, setDuplicateKeywords] = useState([] as string[]);

    const [otherKeywords, setOtherKeywords] = useState(null as null | string[]);
    useEffect(() => {
        doWork(async (stopIfObsolete) => {
            const existing = props.preventDuplicates === 'list' ? await store.api.getKeywords() : await store.api.getKeywords_activeCampaigns();
            stopIfObsolete();

            existing.sort((a, b) => a.localeCompare(b));
            const initItemKeywords = splitKeywords(initialValue);
            const existingKeywordsExceptItem = existing.filter(x => !initItemKeywords.includes(x));
            setOtherKeywords(existingKeywordsExceptItem);
        });
    }, []);

    const onKeywordsChanged = (value: string) => {
        const keywords = splitKeywords(value);

        const duplicates = keywords.filter(x => (otherKeywords ?? []).includes(x));
        setDuplicateKeywords(duplicates);

        onChange({ keywords: joinKeywords(keywords), hasDuplicate: duplicates.length > 0 });
    };

    return (
        <>
            <KeywordInput {...props} onKeywordsChanged={onKeywordsChanged} loading={loading} isRequired={true} />
            {duplicateKeywords.length > 0 && (
                <View style={theme.card.rowWrap}>
                    <TextView style={theme.card.cardTextInline_error} text='Duplicate Keywords: ' />
                    {duplicateKeywords?.map(x => (
                        <TextView key={x} style={theme.card.cardTextInline_error} text={x + ' '} />
                    ))}
                </View>
            )}

            <View style={theme.card.rowWrap}>
                <TextView style={theme.card.cardTextInline} text='Used Keywords: ' />
                {otherKeywords && otherKeywords?.map(x => (
                    <TextView key={x} style={theme.card.cardTextInline} text={x + ' '} />
                ))}
            </View>

            {loading && <Loading loading={loading} />}
            {error && <ErrorBox error={error} />}
        </>
    );
};

const KeywordInput = ({
    store,
    value,
    onKeywordsChanged,
    label,
    loading,
    hint,
    isRequired,
}: {
    store: Store,
    value?: string,
    onKeywordsChanged: (value: string) => void
    label?: string,
    hint?: string,
    isRequired?: boolean,
    loading: boolean
}) => {
    const { theme } = store;

    const [actualValue, setActualValue] = useState(value ?? '');

    const onChange = (value: string) => {

        const end = value.match(/\W$/g)?.[0] ?? '';
        const cleaned = value
            .replace(/\W/g, ',')
            .split(',')
            .filter(x => x)
            .map(x => x.toLowerCase().replace(/\s/g, ''))
            .join(', ')
            + end
            ;

        setActualValue(cleaned);
        onKeywordsChanged(cleaned);
    };

    const style = theme.card.textField;

    return (
        <View style={style.view}>
            <Text style={style.label}>{label}{isRequired ? '' : ''}<HintPopup hint={hint} /></Text>
            <View style={style.text.view}>
                <TextInput style={style.text.text}
                    value={actualValue}
                    placeholder={'Choose keyword(s)'}
                    onChangeText={onChange} />
            </View>
        </View>

    );
};

export const CreateOrEditContactListFromKeywords = (
    {
        store,
        contactListRef,
        onChangeContactListRef,
        defaultContactListName,
        defaultContactListDescription,
    }: {
        store: Store,
        contactListRef?: Reference<ContactListModel>,
        onChangeContactListRef: (contactListRef: Reference<ContactListModel>) => void,
        defaultContactListName?: string,
        defaultContactListDescription?: string,
    }) => {
    const [initialKeywords, setInitialKeywords] = useState('');
    const [keywords, setKeywords] = useState('');
    const [contactList, setContactList] = useState(null as null | ContactListModel);

    const { loading, error, doWork } = useAutoLoadingError();
    useEffect(() => {
        doWork(async (stopIfObsolete) => {
            if (!contactListRef) { return; }

            const loadedContactList = await store.api.loadContactList(contactListRef).valueOrLoad();
            stopIfObsolete();

            setContactList(loadedContactList);

            const keywords = loadedContactList.keywords;
            setInitialKeywords(keywords);
            setKeywords(keywords);
        });
    }, []);


    const changeKeywords = (keywords: string) => {
        setKeywords(keywords);

        // Update keywords
        doWork(async (stopIfObsolete) => {
            const result = !contactList ? await store.api.createContactList({ name: defaultContactListName, description: defaultContactListDescription, keywords })
                : await store.api.setContactListData(contactList, { keywords });
            stopIfObsolete();

            setContactList(result);
            onChangeContactListRef(getReference(result));
        });
    };

    return (
        <>
            {loading && <Loading loading={loading} />}
            {error && <ErrorBox error={error} />}
            {!loading && !error && (
                <EditContactListKeywords
                    preventDuplicates='list'
                    store={store}
                    hint={`Enter the keywords that will be used to opt-in to the campaign (Upper-case and spaces are ignored)`}
                    value={keywords}
                    initialValue={initialKeywords}
                    onChange={x => changeKeywords(x.keywords)} />
            )}
        </>
    );
};