import { Platform } from 'react-native';
import { MockOptions } from '../client-api-access/mock/MockOptions';

export class SystemControls {
    static isDebug = true;
    static isWeb_override = false;
    static mockOptions: MockOptions = {
        maxWaitTime: 50,
        shouldSimulateErrors: false,
    };
    static isWeb() {
        return this.isWeb_override !== null ? this.isWeb_override
            : Platform.OS === 'web';
    }
}