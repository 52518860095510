import React from 'react';
import { Text, TextStyle } from 'react-native';
import { MaterialIcons, MaterialCommunityIcons, FontAwesome } from '@expo/vector-icons';

export enum IconKind {
    menu,
    back,
    error,
    expanded,
    collapsed,
    copy,
    retry,
    dashboard,
    add,
    remove,
    eye,
    eyeSlash,
    logout,

    checkboxChecked,
    checkboxUnchecked,
    radioChecked,
    radioUnchecked,


    // Main Nav
    account,
    message,
    subscription,
    campaigns,
    contacts,
    keywords,
    resources,
    admin,
    // Solutions,
    // Texting,
    // Pricing,
    // Download,
}

function getIcon(kind: IconKind) {
    switch (kind) {
        case IconKind.menu: return { name: 'menu', component: MaterialIcons };
        case IconKind.back: return { name: 'arrow-back', component: MaterialIcons };
        case IconKind.error: return { name: 'error-outline', component: MaterialIcons };
        case IconKind.expanded: return { name: 'chevron-down', component: MaterialCommunityIcons };
        case IconKind.collapsed: return { name: 'chevron-right', component: MaterialCommunityIcons };
        case IconKind.copy: return { name: 'content-copy', component: MaterialCommunityIcons };
        case IconKind.retry: return { name: 'reload', component: MaterialCommunityIcons };
        case IconKind.dashboard: return { name: 'dashboard', component: FontAwesome };
        case IconKind.add: return { name: 'plus', component: FontAwesome };
        case IconKind.remove: return { name: 'minus', component: FontAwesome };
        case IconKind.eye: return { name: 'eye', component: FontAwesome };
        case IconKind.eyeSlash: return { name: 'eye-slash', component: FontAwesome };
        case IconKind.logout: return { name: 'logout', component: MaterialCommunityIcons };

        case IconKind.checkboxChecked: return { name: 'check-square-o', component: FontAwesome };
        case IconKind.checkboxUnchecked: return { name: 'square-o', component: FontAwesome };
        case IconKind.radioChecked: return { name: 'check-circle-o', component: FontAwesome };
        case IconKind.radioUnchecked: return { name: 'circle-o', component: FontAwesome };

        case IconKind.account: return { name: 'account-circle', component: MaterialIcons };
        case IconKind.message: return { name: 'message', component: MaterialIcons };
        case IconKind.subscription: return { name: 'ticket-account', component: MaterialCommunityIcons };
        case IconKind.campaigns: return { name: 'calendar-multiselect', component: MaterialCommunityIcons };
        case IconKind.contacts: return { name: 'contacts', component: MaterialIcons };
        case IconKind.keywords: return { name: 'key-variant', component: MaterialCommunityIcons };
        case IconKind.resources: return { name: 'collections-bookmark', component: MaterialIcons };
        case IconKind.admin: return { name: 'settings', component: MaterialIcons };

        // case IconKind.Solutions: return { name: '', component: FontAwesome };
        // case IconKind.Texting: return { name: '', component: FontAwesome };
        // case IconKind.Pricing: return { name: '', component: FontAwesome };
        // case IconKind.Download: return { name: '', component: FontAwesome };

        default:
            return { name: 'broken-image', component: MaterialIcons };
    }
}

export const Icon = (props: { kind: IconKind, style?: TextStyle }) => {
    const icon = getIcon(props.kind);
    const IconComponent = icon.component;
    return (
        <IconComponent name={icon.name} size={null} color={null} style={props.style} />
    );
};