import React, { useState, ReactNode, Children, useEffect } from 'react';
import { Text, View } from 'react-native';
import { Store } from '../../../store/Store';
import { SetValueWithCallback } from '../../../../utils/Async';
import { Builder, ValidValue } from '../../../../utils/Builder';
import { DatePicker, TimePicker, DayOfWeekPicker, DayOfMonthPicker } from '../../../components/DateTimePicker';
import { DateRangeEditorState } from '../../../../data-types/ModelTypes';
import { UIFieldDefinition } from '../../../../utils/BuilderFieldDefinitions';
import { DateRangeEditorFieldDefinitions } from '../../../../logic/campaigns/campaignFieldDefinitions';
import { TextFieldStyle } from '../../../style/Theme';
import { TimeZone, DateOnly, TimeOnly } from '../../../../utils/Time';
import { CheckBoxControl } from './CheckBoxControl';


type DatePickerControl<T> = (props: { style: TextFieldStyle, field: UIFieldDefinition<NonNullable<T>>, value: T | null, onChangeValue: (value: ValidValue<NonNullable<T>> | null) => void }) => JSX.Element;
export function DateFieldEditor<T>(props: {
    store: Store,
    field: DateRangeEditorFieldDefinitions,
    fieldKey: keyof DateRangeEditorState,
    value: Builder<DateRangeEditorState>,
    onChangeValue: (value: Builder<DateRangeEditorState>) => void,
    Control: DatePickerControl<T>,
    ToggleLabel?: string,
}) {
    const { fieldKey, field, value, onChangeValue, store, Control } = props;
    const { theme } = store;
    const f = field[fieldKey] as UIFieldDefinition<T> | undefined;
    const v = value[fieldKey] as ValidValue<T> | undefined;

    const [expanded, setExpanded] = useState(!props.ToggleLabel || !!v?.value);

    return (
        <>
            {!!props.ToggleLabel && (
                <View style={theme.row.container}>
                    <CheckBoxControl 
                        store={props.store} 
                        label={props.ToggleLabel}
                        hint={f?.hint}
                        value={expanded}
                        onChange={x=>{
                            setExpanded(x);
                            if(!x){
                                onChangeValue({});
                            }
                        }}
                    />
                </View>
            )}
            {expanded && f && !(f.isDisabled ?? false) && (
                <View style={theme.row.cell}>
                    <Control style={theme.card.textField}
                        field={f as any}
                        value={v?.value || null}
                        onChangeValue={x => {
                            value[fieldKey] = x || undefined as any;
                            onChangeValue({ ...value });
                        }}
                    />
                </View>
            )}
        </>
    );
}

export function HourOfDayEditor<T>(props: {
    store: Store,
    field: DateRangeEditorFieldDefinitions,
    fieldKey: keyof DateRangeEditorState,
    value: Builder<DateRangeEditorState>,
    onChangeValue: (value: Builder<DateRangeEditorState>) => void,
    Control: DatePickerControl<T>,
}) {

    const onChangeValue = (v: Builder<DateRangeEditorState>) => {
        let hourOfDayValue = v.hourOfDay?.value;
        if (hourOfDayValue) {
            if (hourOfDayValue < timeRange.min) {
                hourOfDayValue = timeRange.min;
                setCorrectedHourId(s => s + 1);
            }
            if (hourOfDayValue > timeRange.max) {
                hourOfDayValue = timeRange.max;
                setCorrectedHourId(s => s + 1);
            }
        }

        props.onChangeValue({ hourOfDay: { value: hourOfDayValue, isValid: true } });
    };

    const [correctedHourId, setCorrectedHourId] = useState(0);
    const [timeRange, setTimeRange] = useState({ min: '08:00' as TimeOnly, max: '21:00' as TimeOnly });

    useEffect(() => {
        const canSendAtNight = props.store.api.getAccountState().accountAdminSettings?.canSendAtNight ?? false;
        if (canSendAtNight) {
            setTimeRange({ min: '00:00' as TimeOnly, max: '23:59' as TimeOnly });
        } else {
            setTimeRange({ min: '08:00' as TimeOnly, max: '20:59' as TimeOnly });
            // Init value
            onChangeValue({ hourOfDay: { value: props.value.hourOfDay?.value ?? TimeOnly.nowTimeOnly(), isValid: true } });
        }
    }, []);

    const field = props.field;
    if (field.hourOfDay) {
        field.hourOfDay.minTime = timeRange.min;
        field.hourOfDay.maxTime = timeRange.max;
    }

    // const [isValidHour, setIsValidHour] = useState(true);

    return (
        <DateFieldEditor<T> key={correctedHourId} {...props} onChangeValue={onChangeValue} />
    );
}
