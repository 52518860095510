import { AppApi, AppApiEndpointName, AppApiRequestData } from "./AppApiWebClientTypes";
import { AppConfig_Client } from "../../../_config/client/appConfig_client";
import { webRequest } from "../../../utils/WebRequest";
import { UserCredentials, UserCredentialsProvider } from "../../../data-types/SecurityDataTypes";
import { PlanModel, AccountState, MessageDeliveryModel } from "../../../data-types/ModelTypes";
import { PhoneNumber, Reference } from "../../../data-types/SimpleDataTypes";
import { AuthError } from "../../../utils/Errors";

export const createAppApiWebClient = (appConfig: AppConfig_Client, userCredentialsProvider: UserCredentialsProvider): AppApi => {

    async function apiRequest<T>(endpoint: AppApiEndpointName, data: unknown): Promise<T> {
        const url = `${appConfig.appApiDomain}/${endpoint}`;
        const userCredentials = userCredentialsProvider.getUserCredentials();
        if (!userCredentials) {
            throw new AuthError('Cannot call AppApi without UserCredentials', { userCredentials, endpoint });
        }

        const requestData: AppApiRequestData = {
            userCredentials,
            endpoint,
            data,
        };

        const resultObj = (await webRequest(url, requestData)) as { data: T, newUserCredentials: UserCredentials | null | undefined };

        if (resultObj.newUserCredentials !== undefined) {
            await userCredentialsProvider.setUserCredentials(resultObj.newUserCredentials);
        }

        return resultObj.data;
    };

    const api: AppApi = {
        getAccountState: (data) => apiRequest('getAccountState', data),
        getPlans: (data) => apiRequest('getPlans', data),
        getPlans_extraCredits: (data) => apiRequest('getPlans_extraCredits', data),
        savePlan: (data) => apiRequest('savePlan', data),
        saveAccountDetails: (data) => apiRequest('saveAccountDetails', data),
        verifyPaymentsReady: (data) => apiRequest('verifyPaymentsReady', data),
        verifyPaymentAndProcessSubscription: (data) => apiRequest('verifyPaymentAndProcessSubscription', data),
        searchPhoneNumbers: (data) => apiRequest('searchPhoneNumbers', data),
        purchasePhoneNumber: (data) => apiRequest('purchasePhoneNumber', data),
        updatePhoneNumberStatus: (data) => apiRequest('updatePhoneNumberStatus', data),
        sendDirectMessage: (data) => apiRequest('sendDirectMessage', data),
        purchaseExtraCredits: (data) => apiRequest('purchaseExtraCredits', data),

        bulkGetContactInfo: (data) => apiRequest('bulkGetContactInfo', data),
        bulkUpdateContacts: (data) => apiRequest('bulkUpdateContacts', data),
        getMessageStats: (data) => apiRequest('getMessageStats', data),
    };

    return api;
}
