import React, { useState, useEffect } from 'react';
import { View, Text } from "react-native";
import { CampaignModel_Interactive, CampaignEdit_Interactive } from '../../../data-types/ModelTypes';
import { Store } from '../../store/Store';
import { CampaignCard, } from './EditCampaignComponents';
import { theme } from '../../style/Theme';
import { TextView, TextField } from '../../components/TextView';
import { MessageTemplateFieldEditorV2 } from './components/MessageTemplateEditor';
import { MainLayout } from '../../components/Layout';
import { AppError } from '../../../utils/Errors';
import { useAutoLoadingError } from '../../../utils/Hooks';
import { getReferenceFromId } from '../../../data-types/SimpleDataTypes';
import { Timestamp, DateOnly } from '../../../utils/Time';
import { EditContactListKeywords } from '../ContactLists/EditContactList';
import { CampaignEdit } from '../../../data-types/TypeTransformations';
import { Loading } from '../../../lib/controls-react/loading';
import { ErrorBox } from '../../../lib/controls-react/error-box';
import { MessageTemplateField } from '../../../logic/campaigns/campaignFieldDefinitions';
import { formatPhoneNumber_UsaCanada } from '../../../utils/PhoneNumbers';
import { DateFieldEditor } from './components/DateRangeFieldsEditor';
import { DatePicker } from '../../components/DateTimePicker';
import { Validation_DateAfterOrOnToday } from '../../../utils/Validation';
import { OptInMessageControl } from './components/OptInMessageControl';
import { CampaignPreviewSaveArea } from './components/CampaignPreviewSaveArea';
import { CampaignPhoneNumber } from './components/CampaignPhoneNumber';
import { useAlertModal } from '../../components/AlertModal';
import { Gsm } from '../../../utils/Gsm';


type TCampaign = CampaignModel_Interactive;
type TCampaignEdit = CampaignEdit_Interactive;

export const EditCampaignPage_Interactive = (props: {
    store: Store,
    args?: { campaign: TCampaign }, argsRoute?: { campaignId: string }
}) => {
    return <EditCampaignPage_Interactive_Inner {...props} />
}

const CampaignResultViewer = ({
    store,
    campaignValue,
}: {
    store: Store,
    campaignValue: TCampaignEdit,
}) => {
    // Save Campaign
    const { loading, error, doWork } = useAutoLoadingError();

    const saveCampaign = () => {
        if (!campaignValue.isValid) {
            throw new AppError('Cannot save invalid campaign - this should not be enabled');
        }
        doWork(async (stopIfObsolete) => {
            // Get existing contact list
            const contactList = campaignValue.contactList?.id ? await store.api.loadContactList(getReferenceFromId(campaignValue.contactList.id)).valueOrLoad() : undefined;
            const contactList_edit = campaignValue.contactList;

            // Update or create Contact List
            const result = !contactList ? await store.api.getOrCreateContactList(contactList_edit)
                : await store.api.setContactListData(contactList, contactList_edit);
            stopIfObsolete();

            // Save contact list
            const campaignObj: CampaignEdit<TCampaign> = {
                // New campaigns should use a null id
                id: campaignValue.id ?? null as any,
                kind: 'interactive',

                name: campaignValue.name,
                description: campaignValue.description,

                fromPhoneNumber: campaignValue.fromPhoneNumber!,
                endDate: campaignValue.endDate,
                messages: {
                    message: {
                        id: campaignValue.message.id ?? null as any,
                        text: campaignValue.message.text,
                        additionalTexts: campaignValue.message.additionalTexts,
                        imageUrl: campaignValue.message.imageUrl,
                        linkUrl: campaignValue.message.linkUrl,
                        // This will be set
                        campaign: { id: null } as any,
                        campaignPath: 'messages.message',
                        campaignRole: 'Message',
                    },
                    optInMessage: campaignValue.optInMessage ? {
                        id: campaignValue.optInMessage.id ?? null as any,
                        text: campaignValue.optInMessage.text,
                        additionalTexts: campaignValue.optInMessage.additionalTexts,
                        imageUrl: campaignValue.optInMessage.imageUrl,
                        linkUrl: campaignValue.optInMessage.linkUrl,
                        // This will be set
                        campaign: { id: null } as any,
                        campaignPath: 'messages.optInMessage',
                        campaignRole: 'OptInMessage',
                    } : undefined,
                    optInConfirmationMessage: campaignValue.optInConfirmationMessage ? {
                        id: campaignValue.optInConfirmationMessage.id ?? null as any,
                        text: campaignValue.optInConfirmationMessage.text,
                        additionalTexts: campaignValue.optInConfirmationMessage.additionalTexts,
                        imageUrl: campaignValue.optInConfirmationMessage.imageUrl,
                        linkUrl: campaignValue.optInConfirmationMessage.linkUrl,
                        // This will be set
                        campaign: { id: null } as any,
                        campaignPath: 'messages.optInConfirmationMessage',
                        campaignRole: 'OptInConfirmationMessage',
                    } : undefined,
                },
                toContactLists: [getReferenceFromId(result.id)] as any,
            };
            if (!campaignObj.status?.createdTime) {
                campaignObj.status = {
                    isActive: true,
                    createdTime: Timestamp.now(),
                    lastActivityTime: Timestamp.now(),
                    nextActivityTime: Timestamp.now(),
                };
            }
            await store.api.saveCampaign(campaignObj);

            stopIfObsolete();
            showConfirmation();
        });
    };
    const { AlertHost, showAlert: showConfirmation } = useAlertModal({
        title: `Campaign Saved`,
        message: `Your campaign has been saved.`,
        buttons: [
            { text: 'OK', onPress: () => { store.nav.ActiveCampaignsPage.open(); } },
        ]
    });

    return (
        <>
            <CampaignPreviewSaveArea
                store={store}
                campaign={campaignValue}
                campaignMessages={[
                    { title: 'Message', message: campaignValue.message },
                    { title: 'Double Opt-In Message', message: campaignValue.optInMessage },
                    { title: 'Double Opt-In Confirmation Message', message: campaignValue.optInConfirmationMessage },
                ]}
                saveCampaign={saveCampaign}
                isDisabled={!campaignValue.isValid} loading={loading} error={error} />
            <AlertHost useFixed={true} />
        </>
    );
}

const messageTemplate = MessageTemplateField({
    templateId: 'message',
    label: 'Reply Message',
    hint: 'This is the reply message that the customer will receive after texting the keyword to your account phone number. Avoid using copy and paste to avoid special characters. HINT -- Put the name of your company at the beginning of the message. This reply message is limited to 160 characters. If you need additional characters, a second message may be sent to accommodate the additional characters. Please note that if you do send multiple messages, your account will be charged for each text messages.',
    placeholder: 'Enter the Message',
    imageMode: 'optional', linkMode: 'optional',
    supportsMultipleMessages: true,
});

const EditCampaignPage_Interactive_Inner = ({
    store,
    args,
    argsRoute,
}: {
    store: Store,
    args?: { campaign: TCampaign },
    argsRoute?: { campaignId: string },
}) => {

    const newCampaign = {
        isValid: false,
        status: 'new',
        id: undefined,
        name: '',
        description: '',
        contactList: {
            keywords: '',
        },
        endDate: undefined as undefined | DateOnly,
        message: {
            text: '',
        },
        optInMessage: undefined,
        optInConfirmationMessage: undefined,
    } as TCampaignEdit;
    const [campaign, setCampaign] = useState(null as null | TCampaignEdit);

    const [fromPhoneNumberOptions, setFromPhoneNumberOptions] = useState([] as { value: string, label: string }[]);

    // Load Campaign
    const { loading, error, doWork } = useAutoLoadingError();
    useEffect(() => {

        doWork(async (stopIfObsolete) => {

            // Get Phone
            const phoneNumbers = await store.api.getAccountPhoneNumbers();
            stopIfObsolete();

            setFromPhoneNumberOptions(phoneNumbers.map(x => ({ value: x.id, label: `${formatPhoneNumber_UsaCanada(x.phoneNumber)}` })));
            const fromPhoneNumber = getReferenceFromId(phoneNumbers[0].id);

            if (!args && !argsRoute) {
                setCampaign({ ...newCampaign, fromPhoneNumber });
                return;
            }

            const campaign_loaded = args?.campaign
                || argsRoute?.campaignId && await store.api.loadCampaign(getReferenceFromId(argsRoute.campaignId)).valueOrLoad() as TCampaign
                || null;
            if (!campaign_loaded) {
                setCampaign({ ...newCampaign, fromPhoneNumber });
                return;
            }
            stopIfObsolete();

            const contactLists = await store.api.loadItemsContactLists(campaign_loaded.toContactLists).valueOrLoad();
            const message = await store.api.loadMessageTemplate(campaign_loaded.messages.message).valueOrLoad();
            const optInMessage = campaign_loaded.messages.optInMessage ? await store.api.loadMessageTemplate(campaign_loaded.messages.optInMessage).valueOrLoad() : undefined;
            const optInConfirmationMessage = campaign_loaded.messages.optInConfirmationMessage ? await store.api.loadMessageTemplate(campaign_loaded.messages.optInConfirmationMessage).valueOrLoad() : undefined;
            stopIfObsolete();


            setValidity({});
            setCampaign({
                isValid: true,
                status: campaign_loaded.status?.isActive ? 'active' : 'inactive',
                id: campaign_loaded.id,
                kind: 'interactive',
                name: campaign_loaded.name,
                description: campaign_loaded.description,
                fromPhoneNumber: campaign_loaded.fromPhoneNumber,
                contactList: contactLists[0],
                endDate: campaign_loaded.endDate,
                message: {
                    text: message.text,
                    additionalTexts: message.additionalTexts,
                    imageUrl: message.imageUrl,
                    linkUrl: message.linkUrl,
                },
                optInMessage: optInMessage ? {
                    text: optInMessage.text,
                    additionalTexts: optInMessage.additionalTexts,
                    imageUrl: optInMessage.imageUrl,
                    linkUrl: optInMessage.linkUrl,
                } : undefined,
                optInConfirmationMessage: optInConfirmationMessage ? {
                    text: optInConfirmationMessage.text,
                    additionalTexts: optInConfirmationMessage.additionalTexts,
                    imageUrl: optInConfirmationMessage.imageUrl,
                    linkUrl: optInConfirmationMessage.linkUrl,
                } : undefined,
            });
        });

    }, [args, argsRoute]);

    const [validity, setValidity] = useState({
        keywords: false,
        name: false,
        message: false,
    } as { [key: string]: boolean });


    if (!campaign) {
        return (
            <MainLayout store={store}>
                {loading && <Loading loading={loading} />}
                {error && <ErrorBox error={error} />}
            </MainLayout>
        );
    }

    const updateCampaign = (update: () => { key: string, isValid: boolean }) => {
        const result = update();

        const newValidity = { ...validity, [result.key]: result.isValid };
        campaign.isValid = Object.values(newValidity).every(x => x);

        setValidity(newValidity);
        setCampaign({ ...campaign });
    };


    // Render
    return (
        <MainLayout store={store}>
            <CampaignCard store={store}>
                <TextView style={theme.card.cardTitle} text={campaign.id ? `Interactive Campaign: '${campaign.name}'` : "Create Interactive Campaign"} />
                <TextView style={theme.card.cardSummary} text="With an Interactive Campaign, your customer will send a keyword to your phone number and receive a reply message back." />

                {!loading && (
                    <>
                        <CampaignPhoneNumber fromPhoneNumberOptions={fromPhoneNumberOptions} />
                    </>
                )}

                {loading && <Loading loading={loading} />}
                {error && <ErrorBox error={error} />}
                {!loading && (
                    <>
                        <EditContactListKeywords
                            preventDuplicates='campaign'
                            label={`Campaign Keyword(s)`}
                            hint={`This is the word that your customer will send to your phone number to receive your reply message. You can choose more than one keyword if you want. You might choose to do this to track the effectiveness of your marketing. Separate multiple keywords with a comma between each.`}
                            store={store}
                            value={campaign.contactList.keywords}
                            initialValue={campaign.contactList.keywords}
                            onChange={x => updateCampaign(() => {
                                campaign.contactList.keywords = x.keywords;
                                return { key: 'keywords', isValid: campaign.contactList.keywords && !x.hasDuplicate || false };
                            })} />

                        <TextField style={theme.card.textField}
                            field={{
                                __type: 'ui', label: 'Campaign Name', isRequired: true,
                                hint: 'It is best to name the campaign so that you have a record of the promotion.',
                            }}
                            value={campaign.name}
                            onChangeValue={x => updateCampaign(() => {
                                campaign.name = x?.value ?? '';
                                return { key: 'name', isValid: x?.isValid ?? false };
                            })} />
                        <TextField style={theme.card.textField}
                            field={{
                                __type: 'ui', label: 'Campaign Notes', isRequired: false,
                                hint: 'This field will help you recall the details of the campaign.',
                            }}
                            value={campaign.description}
                            onChangeValue={x => updateCampaign(() => {
                                campaign.description = x?.value ?? '';
                                return { key: 'description', isValid: true };
                            })} />

                        <MessageTemplateFieldEditorV2
                            style={store.theme.card.messageTemplateField}
                            field={messageTemplate}
                            value={campaign.message ?? { text: '' }}
                            onChangeValue={x => updateCampaign(() => {
                                campaign.message = x.message;
                                return { key: 'message', isValid: x.isValid };
                            })}
                            store={store}
                        />

                        <OptInMessageControl
                            store={store}
                            isOptional={true}
                            value={campaign.optInMessage ? { message: campaign.optInMessage?.text ?? '', confirmationMessage: campaign.optInConfirmationMessage?.text ?? '' } : null}
                            onChangeMessage={x => updateCampaign(() => {
                                campaign.optInMessage = x ? { text: x.message, additionalTexts: [] } : undefined;
                                campaign.optInConfirmationMessage = x ? { text: x.confirmationMessage, additionalTexts: [] } : undefined;
                                const isValid = (!campaign.optInMessage && !campaign.optInConfirmationMessage)
                                    || (Gsm.validation(true, () => 'sms', false).validate(campaign.optInMessage?.text ?? '').isValid
                                        && Gsm.validation(true, () => 'sms', false).validate(campaign.optInConfirmationMessage?.text ?? '').isValid);
                                return { key: 'optIn', isValid };
                            })}
                        />

                        <DateFieldEditor
                            fieldKey="endDate"
                            Control={DatePicker}
                            ToggleLabel={`Automatically End Campaign on Date`}
                            store={store}
                            field={{
                                endDate: {
                                    __type: 'ui',
                                    isRequired: false,
                                    label: 'Date to End Campaign',
                                    hint: 'If you want the campaign to automatically disable on a certain date, enter that date here. Otherwise, the campaign will be active indefinitely.',
                                    defaultValue: () => undefined,
                                    validation: Validation_DateAfterOrOnToday(),
                                    isMinDateToday: true,
                                },
                            }}
                            value={{ endDate: { value: campaign.endDate, isValid: true } }}
                            onChangeValue={(x) => {
                                updateCampaign(() => {
                                    campaign.endDate = x.endDate?.value || undefined;
                                    return { key: 'endDate', isValid: campaign.endDate ? (x.endDate?.isValid ?? false) : true };
                                });
                            }}
                        />

                        {/* <Text>
                            {JSON.stringify(validity)}
                        </Text>
                        <Text>
                            {JSON.stringify(campaign)}
                        </Text> */}

                        <CampaignResultViewer store={store} campaignValue={campaign} />
                    </>
                )}
            </CampaignCard>
        </MainLayout>
    );
};
