import { pricing, PricingCreditsPerMessage } from "./pricing";

export type AppConfig_Client = {
    adminApiDomain: string;
    appApiDomain: string;
    authApiDomain: string;
    paymentApiDomain: string;
    relationalDataApiDomain: string;
    uploadApiDomain: string;
    stripePublicKey: string;
    authTimeoutSec: number;

    version: string;

    webSiteDomain: string;
    webAppDomain: string;
    supportEmail: string;

    creditsPerMessage: PricingCreditsPerMessage;
};

// TESTING
// const rootPath = 'https://2681eb0xpk.execute-api.us-east-1.amazonaws.com/dev/';

// PRODUCTION
const rootPath = 'https://2681eb0xpk.execute-api.us-west-2.amazonaws.com/prod/';
export const appConfig_client: AppConfig_Client = {
    adminApiDomain: rootPath + 'admin-api',
    appApiDomain: rootPath + 'app-api',
    authApiDomain: rootPath + 'auth-api',
    // Json-Rpc doesn't attach a path, so need to supply one
    paymentApiDomain: rootPath + 'payment-api/payment',
    relationalDataApiDomain: rootPath + 'relational-data-api',
    uploadApiDomain: rootPath + 'upload-api',

    // TEST
    // stripePublicKey: 'pk_test_hB4VRQY8ICsC6mVsRo8jjnIh',
    // LIVE
    stripePublicKey: 'pk_live_51HEPiNKs5ioKqqbuWoyPBeX8ieGbfjKiTBGdAgtBJRnVAMk7DDGPlXunXjbaYOxN2li15T50VGObLex6SZGgb4Mf00J8ybpPnO',

    // 90 days
    authTimeoutSec: 90 * 24 * 60 * 60,

    version: '1.0.6.1039',

    webSiteDomain: 'https://www.gatortext.com',
    webAppDomain: 'https://app.gatortext.com',
    supportEmail: 'support@purplegator.com',

    creditsPerMessage: pricing.creditsPerMessage,
};