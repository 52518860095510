import { AppConfig_Client } from "../_config/client/appConfig_client";
import { UploadApiAccess } from "./types/UploadApiAccess";
import { UserCredentialsProvider } from "../data-types/SecurityDataTypes";
import { createUploadApiWebClient } from "../api/upload/client/UploadApiWebClient";

export const createUploadApiAccess = (appConfig: AppConfig_Client, userCredentialsProvider: UserCredentialsProvider) => {

    const uploadApi = createUploadApiWebClient(appConfig, userCredentialsProvider);

    const uploadApiAccess: UploadApiAccess = {
        createUploadUrl: async (contentType: string) => {
            return await uploadApi.createUploadUrl({ contentType });
        },
    };

    return uploadApiAccess;
};