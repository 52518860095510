import React, { useEffect, useState } from 'react';
import { View, Text, ActivityIndicator, TouchableOpacity, Platform } from 'react-native';
import { Store } from '../../store/Store';
import { useLoadData, useAutoLoadingError } from '../../../utils/Hooks';
import { List } from '../../components/List';
import { ContactModel, ContactListModel, ContactModel_Edit, ContactModel_BulkInfo, RoleKind } from '../../../data-types/ModelTypes';
import { ButtonTextView, TextView, TextField, TextViewWithHint } from '../../components/TextView';
import { MainLayout } from '../../components/Layout';
import { formatPhoneNumber_UsaCanada, toStandardPhoneNumber, toStandardPhoneNumber_optional, phoneNumberMask_UsaCanada } from '../../../utils/PhoneNumbers';
import { ContactListLink, CampaignLink, useReference, useReferenceList_LoadAll } from '../Common/ReferenceLinks';
import { EditContact } from './EditContact';
import { ErrorBox } from '../../components/ErrorBox';
import { NavLinks } from '../Common/NavLinks';
import { Validation_PhoneNumber_UsaCanada } from '../../../utils/Validation';
import { distinct } from '../../../utils/Arrays';
import { getReferenceOrUndefined, PhoneNumber, getReference } from '../../../data-types/SimpleDataTypes';
import { delay } from '../../../utils/Async';
import { AppError } from '../../../utils/Errors';
import { saveFileAs } from '../../../utils/FileSaver';
import { CampaignCard } from '../Campaigns/EditCampaignComponents';
import { theme } from '../../style/Theme';
import { useAlertModal } from '../../components/AlertModal';
import { getContactStatusText } from '../../../data-types/contactStatus';

const ContactItem = (props: { store: Store, contactList?: ContactListModel, item: ContactModel, onItemChanged?: (item: ContactModel) => void, RenderButtons?: () => React.ReactElement }) => {

    const { store, item: itemInit } = props;
    const { nav, theme } = store;

    const [isEditing, setIsEditing] = useState(false);
    const onCancelEdit = () => setIsEditing(false);

    const [itemState, setItemState] = useState({
        item: itemInit,
        hasChanged: false,
        isRemoved: props.item.status.wasRemoved ?? false,
    });
    const { item, hasChanged, isRemoved } = itemState;
    const onItemChanged = (x: ContactModel) => {
        setItemState(s => ({ ...s, item: x, hasChanged: true }));
        setIsEditing(false);
        props.onItemChanged?.(x);
    };
    const onItemRemoved = () => {
        setItemState(s => ({ ...s, isRemoved: true }));
        setIsEditing(false);
    };

    const contactLists = useReferenceList_LoadAll(store, item.state.contactLists, (s, x) => s.api.loadItemsContactLists(x));
    // const campaigns = useReferenceList_LoadAll(store, item.state.campaigns, (s, x) => s.api.loadItemsContactCampaignStates(x));

    const canAccountSendNew = store.api.getAccountState().accountAdminSettings?.canSendToImportedContacts ?? false;
    const isEnabled = item.status.enabled && (item.status.optInStatus === 'accepted' || (canAccountSendNew && item.status.optInStatus === 'new'));

    const viewStyle = isRemoved ? theme.card.view_deleted
        : hasChanged ? theme.card.view_changed
            : !isEnabled ? theme.card.view_disabled
                : theme.card.view;

    if (isRemoved) {
        return (
            <View style={viewStyle}>
                <Text style={theme.card.headerText}>Removed</Text>
                <Text style={theme.card.headerText}>{formatPhoneNumber_UsaCanada(item.phoneNumber)}</Text>
                <Text style={theme.card.paragraphText}>{item.firstName} {item.lastName}</Text>
            </View>
        );
    }

    return (
        <View style={viewStyle}>
            <View style={theme.card.row}>
                <View style={{ flex: 1 }}>
                    <Text style={theme.card.headerText}>{formatPhoneNumber_UsaCanada(item.phoneNumber)}</Text>
                    <Text style={theme.card.paragraphText}>{item.firstName} {item.lastName}</Text>
                </View>
                <View>
                    {/* {item.status.enabled && <Text style={theme.card.fieldLabelText}>Enabled</Text>} */}
                    {!isEnabled && <Text style={theme.card.fieldLabelText}>Sending Disabled</Text>}
                    <Text style={theme.card.fieldLabelText}>Status: {getContactStatusText(item.status)}</Text>
                    {item.status.hasDeliveryFailure && <Text style={theme.card.fieldLabelText}>Delivery Failed</Text>}
                </View>
            </View>
            {/* Contact Lists */}
            <TextView style={theme.card.headerTextView} text={'Lists'} />
            {contactLists?.map(x => (
                <View key={x.id} style={theme.card.row}>
                    <ContactListLink store={store} style={theme.card.navTextView} itemRef={getReference(x)} />
                </View>
            ))}
            {/* Campaigns */}
            {/* <TextView style={theme.card.headerTextView} text={'Campaigns'} />
            {campaigns?.map(x => (
                <View key={x.campaign.id} style={theme.card.row}>
                    <CampaignLink store={store} style={theme.card.navTextView} itemRef={x.campaign} />
                </View>
            ))} */}
            {/* Buttons */}
            {props.RenderButtons && (
                <props.RenderButtons />
            )}
            {!props.RenderButtons && (
                <View style={theme.card.buttonRow}>
                    <View style={theme.card.buttonSpacer} />
                    <ButtonTextView style={theme.card.buttonTextView_minor}
                        onPress={() => nav.ViewContactMessageThreadPage.open({ contact: item })}
                        text="View Messages" />
                    <ButtonTextView style={theme.card.buttonTextView_major}
                        onPress={() => setIsEditing(!isEditing)}
                        text="Edit" />
                </View>
            )}
            {isEditing && <EditContact {...props}
                onCancelEdit={onCancelEdit}
                onItemChanged={onItemChanged}
                onItemRemoved={onItemRemoved} />}
        </View>
    );
};

export const ViewContactPage = (props: { store: Store, args?: { contact: ContactModel }, argsRoute?: { contactId: string } }) => {
    const { store } = props;
    const { api, nav, theme } = store;
    const { loading, error, doWork } = useAutoLoadingError();
    const contactData = useLoadData(doWork, async () => {
        const contact = props.args?.contact ?? await api.getContact(props.argsRoute?.contactId!);
        return {
            contact,
        };
    }, () => [props.args]);

    const contact = contactData.data?.contact;

    return (
        <MainLayout store={store}>
            <View style={theme.nav.row}>
                <NavLinks.ViewContactLists store={store} style={store.theme.nav.breadcrumbButton} />
                <NavLinks.ViewContact store={store} style={store.theme.nav.breadcrumbButton} itemRef={getReferenceOrUndefined(contact)} />
            </View>
            <View style={theme.page.titleView}>
                <Text style={theme.page.titleText}>View Contact</Text>
                <View style={theme.page.titleUnderlineView}>
                </View>
            </View>
            {loading && <ActivityIndicator size="large" />}
            {error && <ErrorBox error={error} />}
            {contact && (<ContactItem store={store} item={contact} />)}
        </MainLayout>
    );
};

const AddContact = (props: { store: Store, contactList: ContactListModel, onContactAdded: (contact: ContactModel) => void }) => {
    const { store, contactList } = props;
    const { api, nav, theme } = store;

    const [phoneNumber, setPhoneNumber] = useState(undefined as undefined | PhoneNumber);
    const [contact, setContact] = useState(null as ContactModel | null);
    const changePhoneNumber = async (value: PhoneNumber | undefined) => {
        setPhoneNumber(value);
        if (!value) { setContact(null); }

        // Find contact by phone number
        const existingContact = await api.findContactByPhoneNumber(value!);
        setContact(existingContact);
    };
    const createContact = async () => {
        const contact = await api.createContact({ phoneNumber, });
        // setContact(contact);
        addContactToList(contact);
    };

    const addContactToList = async (contactToAdd: ContactModel) => {
        await api.addContactToList(contactList, contactToAdd!);
        setPhoneNumber(undefined);
        setContact(null);
        props.onContactAdded(contactToAdd!);
    };

    const contactLists = useReferenceList_LoadAll(store, contact?.state.contactLists, (s, x) => s.api.loadItemsContactLists(x));
    const isInContactList = contactLists?.find(x => x.id === contactList.id);

    return (
        <View style={theme.card.view_white}>
            <TextView style={theme.card.cardTitle} text={`Add Contact`} />
            <TextField style={theme.card.textField}
                field={{ __type: 'ui', isRequired: false, label: 'Phone Number', placeholder: 'Phone Number', validation: Validation_PhoneNumber_UsaCanada() }}
                value={phoneNumber}
                onChangeValue={x => changePhoneNumber(toStandardPhoneNumber_optional(x?.value))} />

            {!contact && (
                <View style={theme.card.buttonRow}>
                    <ButtonTextView style={theme.card.buttonTextView_major}
                        onPress={() => createContact()}
                        text="Create Contact" />
                </View>
            )}
            {contact && (
                <>
                    <ContactItem store={store} item={contact} RenderButtons={() => (
                        <View style={theme.card.buttonRow}>
                            {!isInContactList && (<ButtonTextView style={theme.card.buttonTextView_major}
                                onPress={() => addContactToList(contact)}
                                text="Add to List" />)}
                            {isInContactList && (<TextView style={theme.card.infoTextView}
                                text="Already in List" />)}
                        </View>
                    )} />
                </>
            )}
        </View>
    );
};

type BulkContactData = ContactModel_Edit & {
    existing?: ContactModel_BulkInfo,
    id?: string,
    phoneNumber: PhoneNumber,
    firstName?: string,
    lastName?: string,
    bulkStatus: 'new' | 'inList' | 'addToList',
    hasChanged: boolean, changes: string[],
};
const BulkContactItemPreview = (props: { store: Store, item: BulkContactData }) => {
    const { store, item } = props;
    const { api, nav, theme } = store;

    const [isExpanded, setIsExpanded] = useState(false);
    const bulkStatusText = item.bulkStatus === 'new' ? 'New'
        : item.bulkStatus === 'inList' ? 'Already in List'
            : 'Add to List';

    return (
        <>
            <TouchableOpacity onPress={() => setIsExpanded(!isExpanded)}>
                <View>
                    <View style={theme.grid.row}>
                        <View style={theme.grid.cell}>
                            <Text style={theme.card.headerText}>{formatPhoneNumber_UsaCanada(item.phoneNumber)}</Text>
                        </View>
                        <View style={theme.grid.cell}>
                            <Text style={theme.card.paragraphText}>{item.firstName} {item.lastName}</Text>
                        </View>
                        <View style={theme.grid.cell3}>
                            <TextView style={theme.card.infoTextView} text={bulkStatusText} />
                            {item.hasChanged && (<TextView style={theme.card.warnTextView} text="Changed" />)}
                            {item.hasChanged && item.changes.map(x => (<TextView key={x} style={theme.card.warnTextView} text={`${x}`} />))}
                        </View>
                    </View>
                    {/* {isExpanded && item.existing && (
                        <ContactItem store={store} item={item.existing} RenderButtons={() => (<></>)} />
                    )} */}
                </View>
            </TouchableOpacity>
        </>
    );
};
const BulkAddContactListContacts = (props: { store: Store, contactList: ContactListModel, onContactAdded: (contact: ContactModel) => void }) => {
    const { store, contactList } = props;
    const { api, nav, theme } = store;

    const [bulkText, setBulkText] = useState('');

    const getContactsDataFromBulkText = () => {
        const lines = bulkText.replace(/\r\n/g, '\n').split('\n').map(x => x.trim()).filter(x => x);
        const contactItemsRaw = lines
            .map(x => x.split(','))
            .map(x => ({
                phoneNumber: toStandardPhoneNumber(x[0]?.trim() ?? ''),
                firstName: x[1]?.trim() ?? '',
                lastName: x[2]?.trim() ?? ''
            } as BulkContactData))
            .filter(x => phoneNumberMask_UsaCanada(x.phoneNumber).valid);
        const contactItems = distinct(contactItemsRaw, x => x.phoneNumber).filter(x => x.phoneNumber);
        return contactItems;
    };

    const [contacts, setContacts] = useState([] as BulkContactData[]);

    const { loading, error, doWork } = useAutoLoadingError();

    const previewChanges = () => doWork(async (stopIfObsolete) => {
        await delay(500);

        const contactItems = getContactsDataFromBulkText();

        console.log('previewChanges', { contactItems });

        const getChange = (newValue: string | null | undefined, oldValue: string | null | undefined) => oldValue != newValue ? `${oldValue ?? ''} => ${newValue ?? ''}` : '';

        const contactsServerData = await api.bulkGetContactInfo(contactItems.map(x => x.phoneNumber), getReference(contactList));

        for (const c of contactItems) {
            const existingContact = contactsServerData.find(x => x.phoneNumber === c.phoneNumber);
            stopIfObsolete();
            // console.log('previewChanges existingContact', { c, existingContact });

            c.existing = existingContact ?? undefined;
            c.id = existingContact?.id;
            c.firstName = c.firstName ?? existingContact?.firstName;
            c.lastName = c.lastName ?? existingContact?.lastName;

            const isNew = !existingContact;
            c.changes = [
                getChange(c.firstName, existingContact?.firstName),
                getChange(c.lastName, existingContact?.lastName),
            ].filter(x => x);
            c.hasChanged = !isNew && c.changes.length > 0;

            // const existingContactLists = existingContact?.contactLists ?? [];
            stopIfObsolete();

            const isInList = existingContact?.bulkStatus.isInList;
            //const isInList = existingContactLists.find(x => x.id === contactList.id);
            c.bulkStatus = isNew ? 'new'
                : isInList ? 'inList'
                    : 'addToList';

            // console.log('previewChanges isInList', { c, existingContact, isInList, });
        }

        const contactItemsSorted = contactItems
            .map((x, i) => ({ x, order: `${x.bulkStatus === 'addToList' ? 0 : x.bulkStatus === 'new' ? 1 : 9} ${`${i}`.padStart(9, '0')}` }))
            .sort((a, b) => a.order.localeCompare(b.order))
            .map(x => x.x);

        stopIfObsolete();
        setContacts(contactItemsSorted);
    });

    const [progress, setProgress] = useState(null as null | { total: number, done: number });
    const addContacts = () => doWork(async (stopIfObsolete) => {
        // Add New Items, Update Changed Items

        const contactItems = getContactsDataFromBulkText();
        const dataToUpdate = contactItems.map(x => ({
            phoneNumber: x.phoneNumber,
            firstName: x.firstName,
            lastName: x.lastName,
        }));

        // Do in Batches
        setProgress({ total: contactItems.length, done: 0 });

        const remaining = dataToUpdate;
        while (remaining.length > 0) {
            const batchItems = remaining.splice(0, 10000);
            let attempt = 0;
            while (attempt < 5) {
                try {
                    // This can result in timeout or network failure, so try again
                    const bulkUpdateResult = await api.bulkUpdateContacts(batchItems, getReference(contactList));
                    break;
                } catch (error) { }
                attempt++;
            }
            stopIfObsolete();

            setProgress({ total: contactItems.length, done: contactItems.length - remaining.length });
        }

        // Navigate back to main list after adding
        nav.ViewContactListContactsPage.open({ contactList });
    });

    return (
        <View style={theme.card.view_white}>
            <TextView style={theme.card.cardTitle} text={`Bulk Add Contacts`} />
            <TextView style={theme.card.cardSummary} text={`Enter Contacts (One Entry Per Line)`} />

            <TextField style={theme.card.textFieldMultiline}
                field={{
                    __type: 'ui',
                    isRequired: false,
                    label: 'Phone Number, First Name, Last Name',
                    placeholder: '(987)-654-3210, John, Doe\n(987)-654-3211, Jane, Doe\n(987)-654-3212, Bobby, Doe',
                }}
                value={bulkText}
                onChangeValue={x => setBulkText(x?.value ?? '')} />

            {/* <View style={theme.card.buttonRow}>
                <ButtonTextView style={theme.card.buttonTextView_minor}
                    onPress={() => previewChanges()}
                    text="Preview" />
            </View>
            {!!contacts.length && (
                <>
                    <TextView style={theme.card.cardSectionTitle_small} text={`Preview`} />
                    <TextView style={theme.card.cardSummary} text={`${contacts.filter(x => x.bulkStatus === 'new').length} New Contacts - to Add to List`} />
                    <TextView style={theme.card.cardSummary} text={`${contacts.filter(x => x.bulkStatus === 'addToList').length} Existing Contacts - to Add to List`} />
                    <TextView style={theme.card.cardSummary} text={`${contacts.filter(x => x.bulkStatus === 'inList').length} Existing Contacts - Already in List`} />
                </>
            )}
            {!!contacts.length && (
                <View style={theme.card.buttonRow}>
                    <ButtonTextView style={theme.card.buttonTextView_major}
                        isDisabled={!contacts.length}
                        onPress={() => addContacts()}
                        text="Add Contacts" />
                </View>
            )} */}

            <View style={theme.card.buttonRow}>
                <ButtonTextView style={theme.card.buttonTextView_major}
                    isDisabled={!bulkText.length && !loading}
                    onPress={() => addContacts()}
                    text="Add Contacts" />
            </View>

            {loading && <ActivityIndicator />}
            {error && <ErrorBox error={error} />}
            {progress && (
                <>
                    <TextView style={theme.card.cardSummary} text={`${progress.done} / ${progress.total} ${(100 * progress.done / progress.total).toFixed(0)}% Done`} />
                </>
            )}

            {/* {contacts.map((item: BulkContactData, i) => (
                <BulkContactItemPreview key={item.id ?? i} store={store} item={item} />
            ))} */}


        </View>
    );
};


const AddContactListContacts = (props: { store: Store, contactList: ContactListModel }) => {
    const { store } = props;
    const { api, nav, theme } = store;

    const { loading, error, doWork } = useAutoLoadingError();

    const [addedContacts, setAddedContacts] = useState([] as ContactModel[]);
    const addContact = (item: ContactModel) => {
        console.log('addContact', { item });
        setAddedContacts(s => [item, ...s]);
    };

    const contacts = addedContacts;
    const contactList = props.contactList;

    return (
        <>
            <AddContact store={store} contactList={contactList} onContactAdded={(x) => addContact(x)} />
            {contacts.map(x => (
                <ContactItem key={x.id} store={store}
                    contactList={contactList}
                    item={x}
                />
            ))}
        </>
    );
};

export const ViewContactListContactsPage = (props: { store: Store, args?: { contactList: ContactListModel }, argsRoute?: { contactListId: string } }) => {
    const { store } = props;
    const { api, nav, theme } = store;

    const { loading, error, doWork } = useAutoLoadingError();
    const contactsData = useLoadData(doWork, async () => {
        const contactList = props.args?.contactList ?? await api.getContactList(props.argsRoute?.contactListId!);
        const contacts = await api.getContacts(contactList);


        return {
            contactList,
            contacts,
        };
    }, () => [props.args]);

    // Load all items in background
    const [contacts, setContacts] = useState(contactsData.data?.contacts.itemsLoaded ?? []);

    useEffect(() => {
        const contacts = contactsData.data?.contacts;
        if (!contacts) { return; }

        setContacts(contacts.itemsLoaded);
        if (contacts.hasMore()) {
            doWork(async (stopIfObsolete) => {
                // Load all contacts
                const totalCount = await contacts.totalCount().valueOrLoad();
                console.log('Loading all contacts', { totalCount });
                while (contacts.hasMore()) {
                    console.log('Load more', { contactsLength: contacts.itemsLoaded.length, totalCount });
                    await contacts.loadMore();
                }

                console.log('useEffect all loaded', { contactsLength: contacts.itemsLoaded.length });

                stopIfObsolete();
                setContacts(contacts.itemsLoaded);
            });
        }
    }, [contactsData.data?.contacts]);

    const [isAdding, setIsAdding] = useState(false);

    const exportList = (filename: string | undefined) => {
        doWork(async () => {
            while (contactsData.data?.contacts.hasMore()) {
                await contactsData.data?.contacts.loadMore();
            }

            const columnHeaders = "Phone, First Name, Last Name, Status\n";

            const content = contacts
                .map(x => `${formatPhoneNumber_UsaCanada(x.phoneNumber)},${x.firstName},${x.lastName},${getContactStatusText(x.status)}\n`)
                .join('');

            const filenameWithExtension = filename ? `${filename}.csv` : 'list.csv';

            saveFileAs(
                columnHeaders + content, 
                filenameWithExtension
            );
        });
    };

    const contactList = contactsData.data?.contactList;
    const canSendToImported = store.api.getAccountState().accountAdminSettings.canSendToImportedContacts ?? false;

    console.log('ViewContactListContactsPage RENDER', { contactsLength: contacts.length });
    return (
        <MainLayout store={store}>
            <View style={theme.nav.row}>
                <NavLinks.ViewContactLists store={store} style={store.theme.nav.breadcrumbButton} />
                <NavLinks.ViewContactList store={store} style={store.theme.nav.breadcrumbButton} itemRef={getReferenceOrUndefined(contactList)} />
                <NavLinks.ViewContactListContacts store={store} style={store.theme.nav.breadcrumbButton} itemRef={getReferenceOrUndefined(contactList)} />
            </View>

            <TextView style={theme.card.cardTitle} text={'View Contacts: ' + (contactsData.data?.contactList.name ?? '')} />
            {!canSendToImported && (<TextView style={theme.card.cardSummary} text='Contact Customer Service to send to Imported Contacts that are Manually Opted In' />)}

            {contactList && (
                <>
                    {RoleKind.isAdmin(store.api.getAccountState().userRole) && Platform.OS === 'web' && (
                        <View style={theme.card.buttonRow}>
                            <ButtonTextView
                                style={theme.card.buttonTextView_admin}
                                onPress={() => exportList(contactsData.data?.contactList.name)}
                                text="Admin: Export List" />
                        </View>
                    )}
                    <View style={theme.card.buttonRow}>
                        <ButtonTextView style={theme.card.buttonTextView_major}
                            onPress={() => setIsAdding(!isAdding)}
                            text="Add Contacts" />
                    </View>
                    {isAdding && (
                        <BulkAddContactListContacts store={store} contactList={contactList} onContactAdded={() => { setIsAdding(false); }} />
                    )}
                </>
            )}
            {contactList && !isAdding && (
                <>
                    <List
                        style={theme.list} error={error}
                        items={contacts}
                        extractKey={x => x.id}
                        extractSearchKey={x => [
                            x.firstName ?? '',
                            x.lastName ?? '',
                            formatPhoneNumber_UsaCanada(x.phoneNumber),
                            toStandardPhoneNumber(x.phoneNumber),
                        ]}
                        extractSortKey={x => [x.phoneNumber]}
                        renderHeader={() => <SimpleContactItemHeader />}
                        renderItem={x => <SimpleContactItem store={store} item={x.item} contactList={contactList} />}
                    />
                    {loading && <ActivityIndicator size="large" color={theme.list.loadingColor} />}
                </>
            )}

        </MainLayout>
    );
};

const SimpleContactItemHeader = (props: {}) => {
    return (
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', marginTop: 8 }}>
            <View style={{ flex: 1, }}><Text style={{ fontWeight: 'bold' }}>{'Phone'}</Text></View>
            <View style={{ flex: 1, }}><Text style={{ fontWeight: 'bold' }}>{'First Name'}</Text></View>
            <View style={{ flex: 1, }}><Text style={{ fontWeight: 'bold' }}>{'Last Name'}</Text></View>
            <View style={{ flex: 1, }}><Text style={{ fontWeight: 'bold' }}>{'Status'}</Text></View>
            <View style={{ flex: 1, }}></View>
        </View>
    );
};
const SimpleContactItem = ({ store, item: itemRaw, contactList }: { store: Store, item: ContactModel, contactList: ContactListModel }) => {
    const [item, setItem] = useState(itemRaw);
    const [isEditing, setIsEditing] = useState(false);
    const [isRemoved, setIsRemoved] = useState(false);

    if (isRemoved) {
        return <></>;
    }

    return (
        <>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
                <View style={{ flex: 1 }}><Text style={{}}>{!item?.phoneNumber ? '' : formatPhoneNumber_UsaCanada(item?.phoneNumber)}</Text></View>
                <View style={{ flex: 1 }}><Text style={{}}>{item.firstName}</Text></View>
                <View style={{ flex: 1 }}><Text style={{}}>{item.lastName}</Text></View>
                <View style={{ flex: 1 }}><Text style={{}}>{getContactStatusText(item.status)}</Text></View>
                <View style={{ flex: 1, }}><ButtonTextView style={theme.card.buttonTextView_minor} text={isEditing ? 'Cancel' : 'Edit'} onPress={() => setIsEditing(s => !s)} /></View>
            </View>
            {isEditing && (
                <EditContact
                    store={store}
                    item={item}
                    contactList={contactList}
                    onCancelEdit={() => setIsEditing(false)}
                    onItemChanged={setItem}
                    onItemRemoved={() => setIsRemoved(true)} />
            )}
        </>
    );
};


export const ViewAllContactsPage = (props: {
    store: Store, options?: {
        title?: string,
        hint?: string,
        description?: string,
        showResultsOnSearchOnly?: boolean,
        renderItem?: (props: { item: ContactModel, onItemChanged: (item: ContactModel) => void }) => React.ReactElement;
    }
}) => {
    const { store } = props;
    const { api, nav, theme } = store;

    const { loading, error, doWork } = useAutoLoadingError();
    const contactsData = useLoadData(doWork, async () => {
        const contacts = await api.getContacts_all();

        // TODO: PERFORMANCE! Download All
        while (contacts.hasMore()) {
            await contacts.loadMore();
        }

        const contactsActive = contacts.itemsLoaded.filter(x => x.status.enabled);

        return {
            contacts: contactsActive,
        };
    }, () => []);

    const contacts = contactsData.data?.contacts ?? [];

    return (
        <MainLayout store={store}>
            <View style={theme.nav.row}>
                <NavLinks.ViewContactLists store={store} style={store.theme.nav.breadcrumbButton} />
                <NavLinks.ViewAllContacts store={store} style={store.theme.nav.breadcrumbButton} />
            </View>
            <CampaignCard store={store}>
                {!props.options?.hint && (
                    <TextView style={theme.card.cardTitle} text={props.options?.title ?? 'View All Contacts'} />
                )}
                {props.options?.hint && (
                    <TextViewWithHint style={theme.card.cardTitle} text={props.options?.title ?? 'View All Contacts'} hint={props.options.hint} />
                )}
                <TextView style={theme.card.cardSummary} text={props.options?.description ?? ''} />
                <List style={theme.list} loading={loading} error={error}
                    items={contacts}
                    showResultsOnSearchOnly={props.options?.showResultsOnSearchOnly}
                    extractKey={x => x.id}
                    extractSearchKey={x => [
                        x.firstName ?? '',
                        x.lastName ?? '',
                        formatPhoneNumber_UsaCanada(x.phoneNumber),
                        toStandardPhoneNumber(x.phoneNumber),
                    ]}
                    extractSortKey={x => [x.phoneNumber]}
                    renderItem={props.options?.renderItem ?? ((x) => (
                        <ContactItem store={store}
                            item={x.item}
                            onItemChanged={x.onItemChanged}
                        />
                    ))} />
            </CampaignCard>
        </MainLayout>
    );
};

const QuickRemoveContactItem = (props: { store: Store, item: ContactModel }) => {
    const { store, item } = props;
    const { theme } = store;

    const [isRemoved, setIsRemoved] = useState(false);
    const { loading, error, doWork } = useAutoLoadingError();
    const removeContact = () => {
        doWork(async (stopIfObsolete) => {
            await store.api.removeContactFromAllLists(props.item);
            stopIfObsolete();
            setIsRemoved(true);
        });
    };

    const { AlertHost, showAlert: showRemove } = useAlertModal({
        title: `Remove Contact - ${formatPhoneNumber_UsaCanada(item.phoneNumber)}`,
        message: `Are you sure you want to remove this contact - ${formatPhoneNumber_UsaCanada(item.phoneNumber)}`,
        buttons: [
            { text: 'Cancel', onPress: () => { } },
            { text: 'Remove', onPress: () => removeContact(), webStyle: theme.alertModalWeb.buttonDanger },
        ]
    });

    if (isRemoved) {
        return (
            <View style={theme.card.view_deleted}>
                <Text style={theme.card.headerText}>Removed</Text>
                <Text style={theme.card.headerText}>{formatPhoneNumber_UsaCanada(item.phoneNumber)}</Text>
                <Text style={theme.card.paragraphText}>{item.firstName} {item.lastName}</Text>
            </View>
        );
    }

    return (
        <View>
            <ContactItem {...props} RenderButtons={() => (
                <ButtonTextView style={theme.card.buttonTextView_danger}
                    onPress={showRemove}
                    loading={loading}
                    error={error}
                    text="Remove from All Lists" />
            )} />
            <AlertHost />
        </View>
    );
    // return (
    //     <>
    //         <View style={theme.card.view}>
    //             <View style={theme.card.row}>
    //                 <View>
    //                     <Text style={theme.card.headerText}>{formatPhoneNumber_UsaCanada(item.phoneNumber)}</Text>
    //                     <Text style={theme.card.paragraphText}>{item.firstName} {item.lastName}</Text>
    //                 </View>
    //                 <View style={theme.card.buttonSpacer} />
    //                 <ButtonTextView style={theme.card.buttonTextView_danger}
    //                     onPress={removeContact}
    //                     text="Remove from All Lists" />
    //             </View>
    //             {/* Contact Lists */}
    //             <TextView style={theme.card.headerTextView} text={'Lists'} />
    //             {item.state.contactLists?.map(x => (
    //                 <View style={theme.card.row}>
    //                     <ContactListLink store={store} style={theme.card.navTextView} itemRef={x} />
    //                 </View>
    //             ))}

    //         </View>
    //     </>
    // );
};


export const QuickRemoveContactPage = (props: { store: Store }) => (
    <ViewAllContactsPage {...props} options={{
        title: 'Quick Remove',
        hint: 'This feature enables you to remove a list member that has contacted you personally to be removed from the list. You could also encourage them to respond to a message with “STOP” and they will be automatically removed from your list.',
        description: 'Enter the phone number of name of the person you would like to remove from the database.',
        showResultsOnSearchOnly: true,
        renderItem: (x) => (
            <QuickRemoveContactItem store={props.store} item={x.item} />
        )
    }} />
);