/* eslint-disable no-console */
import React from 'react';
import { C } from '../../../lib/controls-react';
import { createAuthenticationClient, createAuthenticationClient_serverAccess } from '../../../lib/auth/client/login';
import { AuthenticationStatus, AuthError } from '../../../lib/auth/common/types';
import { Store } from '../../store/Store';
import { EmailAddress as AuthEmailAddress } from '../../../lib/utils/email-address';
import { EmailAddress } from '../../../data-types/SimpleDataTypes';
import { NotImplementedError, AppError } from '../../../utils/Errors';
import { MainLayout } from '../../components/Layout';
import { toStandardPhoneNumber } from '../../../utils/PhoneNumbers';
import { Platform } from 'react-native';

export const LoginPage = (props: { store: Store }) => {


    const getInvite = () => {
        const isInvite = props.store.initialUrl?.URL.toLowerCase().includes('invite');
        const inviteUsername = isInvite ?
            props.store.initialUrl?.search.split(';').find(x => x.startsWith('?username') || x.startsWith('username'))?.split('=')[1]
            || props.store.initialUrl?.pathname.split('/').slice(-1)[0]
            : undefined;

        if( inviteUsername === 'invite' ){
            return {isInvite, inviteUsername:''};
        }

        return { isInvite, inviteUsername };
    };

    const { isInvite, inviteUsername } = getInvite();

    // console.log('LoginPage', { isInvite, inviteUsername });

    const auth = props.store.auth;

    const getStatus = (): { result: AuthenticationStatus } => {
        const s = auth.getAuthenticationState();
        // console.log('getStatus', { s });
        return {
            result: {
                isAuthenticated: s.state !== 'loggedOut',
                username: s.email,
                email: s.email as unknown as AuthEmailAddress,
                phone: s.phoneNumber,
                requiresVerifiedPhone: s.state === 'requiresPhoneVerification',
                requiresVerifiedEmail: s.state === 'requiresEmailVerification',
                requiresPasswordReset: false,
            }
        };
    };

    const redirectIfAuthenticated = (options?: { shouldRefresh?: boolean }) => {
        console.log('redirectIfAuthenticated', { options });
        const status = getStatus();
        if (!status.result.isAuthenticated
            && status.result.requiresPasswordReset
            && status.result.requiresVerifiedEmail
            && status.result.requiresVerifiedPhone
        ) { return; }

        if (props.store.nav.LoginPage.isActive) {
            props.store.nav.RootPage.open();
            return;
        }

        if (options?.shouldRefresh) {
            props.store.nav._refresh();
        }
    };

    const authClient = createAuthenticationClient_serverAccess({
        serverAccess: {
            refreshStatus: async () => {
                await auth.reloadState();
                return getStatus();
            },
            logout: async () => {
                await auth.logout();
                return getStatus();
            },
            createAccount: async (username, password) => {
                await auth.createAccount(username as EmailAddress, password);
                return getStatus();
            },
            login: async (username, password) => {
                await auth.login(username as EmailAddress, password);
                redirectIfAuthenticated({ shouldRefresh: true });
                return getStatus();
            },
            changeUsername: async (username) => {
                throw new NotImplementedError('changeUsername is not supported');
            },
            changePassword: async (password, oldPassword) => {
                const username = auth.getAuthenticationState().email;
                if (!username) {
                    throw new AppError('Change Password can only be done once authenticated');
                }

                await auth.changePassword(username, oldPassword, password);
                return getStatus();
            },

            // Phone
            requestPhoneLoginCode: async (data) => {
                await auth.requestPasswordReset(data.username as EmailAddress, toStandardPhoneNumber(data.phone));
            },
            loginWithPhoneCode: async (email, code, newPassword) => {
                await auth.resetPassword(email as EmailAddress, code, newPassword);
                redirectIfAuthenticated({ shouldRefresh: true });
                return getStatus();
            },
            registerPhoneAndSendVerification: async (phone) => {
                await auth.requestPhoneVerificationCode(toStandardPhoneNumber(phone));
                return getStatus();
            },
            verifyPhone: async (phone, code) => {
                const result = await auth.verifyPhone(toStandardPhoneNumber(phone), code);

                console.log('LoginPage.verifyPhone', { result });
                redirectIfAuthenticated({ shouldRefresh: result });
                return getStatus();
            },

            // Email
            requestEmailLoginCode: async (email) => {
                throw new NotImplementedError('login with email is not supported');
            },
            loginWithEmailCode: async (email, code) => {
                throw new NotImplementedError('login with email is not supported');
            },
            registerEmailAndSendVerification: async (email) => {
                throw new NotImplementedError('login with email is not supported');
            },
            verifyEmail: async (email, code) => {
                throw new NotImplementedError('login with email is not supported');
            },
        },
        config: {
            minPasswordLength: 8,
            isInvite,
            inviteUsername,
        },
    });
    const AuthComponent = () => {
        return (
            <C.View_Panel>
                <authClient.AuthenticationView />
            </C.View_Panel>
        );
    };

    if (props.store.auth.getAuthenticationState().state !== 'loggedOut') {
        return (
            <MainLayout store={props.store}>
                <AuthComponent />
            </MainLayout>
        );
    }

    return (
        <MainLayout store={props.store}>
            <AuthComponent />
        </MainLayout>
    );
}

export const VerifyPhonePage = LoginPage;