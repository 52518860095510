import React, { useEffect, useState } from 'react';
import { Store } from '../../store/Store';
import { MainLayout } from '../../components/Layout';
import { CampaignCard } from '../Campaigns/EditCampaignComponents';
import { TextView, ButtonTextView, TextViewWithHint } from '../../components/TextView';
import { Timestamp } from '../../../utils/Time';
import { View, Platform, ActivityIndicator } from 'react-native';
import { phone } from 'faker';
import { useAutoLoadingError } from '../../../utils/Hooks';
import { delay } from '../../../utils/Async';
import { Price } from '../../../data-types/SimpleDataTypes';
import { ErrorBox } from '../../components/ErrorBox';

export const SubscriptionPaymentView = (props: { store: Store, isPaymentsReady: boolean }) => {
    const { theme } = props.store;

    const isPaymentsReady = props.isPaymentsReady;
    const [requiresSubscriptionPayment, setRequiresSubscriptionPayment] = useState({ required: true, amount: Price.toPrice(0) });
    const { loading, error, doWork } = useAutoLoadingError();
    useEffect(() => {
        populateRequiresSubscription();
    }, []);

    const populateRequiresSubscription = () => {
        doWork(async (stopIfObsolete) => {
            const result = await props.store.api.requiresSubscriptionPayment();
            stopIfObsolete();

            setRequiresSubscriptionPayment(result);
        });
    };

    const processSubscriptionPayment = () => doWork(async (stopIfObsolete) => {
        await props.store.api.processSubscriptionPayment();
        populateRequiresSubscription();

        if (!props.store.api.getAccountState().hasPhoneNumber) {
            props.store.nav.EditPhoneNumbersPage.open();
        }
    });

    return (
        <>
            {!isPaymentsReady && (
                <View style={theme.card.buttonRow}>
                    <View style={theme.card.buttonSpacer} />
                    <TextView style={theme.card.warnTextView} text="Please add your primary credit card (and a secondary credit card if possible) to ensure we can renew your plan with each cycle." />
                    <ButtonTextView style={theme.card.buttonTextView_major_noFlex} text="Pay for Subscription" onPress={processSubscriptionPayment} isDisabled={true} />
                </View>
            )}
            {isPaymentsReady && requiresSubscriptionPayment.required && (

                <View style={theme.card.buttonRow}>
                    <View style={theme.card.buttonSpacer} />
                    <ButtonTextView style={theme.card.buttonTextView_major_noFlex} text={`Pay ${Price.formatPrice(requiresSubscriptionPayment.amount)} to Begin Subscription`} onPress={processSubscriptionPayment} loading={loading} />
                </View>

            )}
            {isPaymentsReady && !requiresSubscriptionPayment.required && (
                <TextView style={theme.card.cardSummary} text={`Next cycle begins on ${Timestamp.formatTimestamp_asDateOnly(props.store.api.getAccountState().stats.cycleEndTime)}`} />
            )}
        </>
    );
};


export const PaymentEditorView = (props: { store: Store }) => {
    const { theme } = props.store;
    const comp = props.store.paymentsComponents;

    const { loading, error, doWork } = useAutoLoadingError();
    const onPaymentMethodChange = () => doWork(async () => {
        console.log('PaymentEditorView.onPaymentMethodChange START');
        try {
            // For some reason the server is not updated yet
            console.log('PaymentEditorView.onPaymentMethodChange verifyPaymentsReady');
            await props.store.api.verifyPaymentsReady();
        } catch (error) {
            // Ignore Error (in case payment was deleted)
            console.log('PaymentEditorView.onPaymentMethodChange verifyPaymentsReady ERROR', { error });
        }

        console.log('PaymentEditorView.onPaymentMethodChange reloadState');
        await props.store.api.reloadState();

        console.log('PaymentEditorView.onPaymentMethodChange END');
    });

    const isPaymentsReady = props.store.api.getAccountState().isPaymentsReady;
    const showPaymentHistory = props.store.api.getAccountState().hasPhoneNumber;

    console.log('PaymentEditorView render', { isPaymentsReady, accountState: props.store.api.getAccountState() });

    return (
        <>
            <CampaignCard store={props.store}>
                <TextViewWithHint style={theme.card.cardTitle}
                    text="Payment Methods"
                    hint={`If you wish to make arrangements for an alternative payment solution such as Paypal, Venmo, or direct billing, please contact customer support at ${props.store.config.supportEmail}.`} />
                <TextView style={theme.card.cardSummary} text="Enter your credit card." />

                {Platform.OS === 'web' ? (
                    <comp.PaymentMethodView onChange={onPaymentMethodChange} />
                ) : (
                    <View>
                        <ButtonTextView style={theme.card.buttonTextView_major_noFlex} text="Manage Payment Methods" onPress={() => props.store.navigateWebApp('ManagePaymentDetails')} />
                    </View>
                )}

                {!isPaymentsReady && (
                    <TextView style={theme.card.cardSummary_warning} text="Please add your primary credit card (and a secondary credit card if possible) to ensure we can renew your plan with each cycle." />
                )}
                {isPaymentsReady && (
                    <>
                        <TextView style={theme.card.cardTitle} text="Payments" />
                        <SubscriptionPaymentView store={props.store} isPaymentsReady={isPaymentsReady} />
                    </>
                )}
                {error && <ErrorBox error={error} />}
                {loading && <ActivityIndicator />}

                {showPaymentHistory && (<>
                    {/* <TextView style={theme.card.cardTitle} text="Subscription" /> */}
                    <comp.PaymentHistoryView />
                </>)}
            </CampaignCard>
        </>
    );
};

export const PaymentEditorPage = (props: { store: Store }) => {
    return (
        <MainLayout store={props.store}>
            <PaymentEditorView store={props.store} />
        </MainLayout>
    );
}