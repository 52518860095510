import { TimeOnly, Timestamp, DayOfWeek, DateOnly, DayOfMonth, TimeZone } from '../utils/Time';
import { Reference, PhoneNumber, ReferenceList, Price, getReferenceFromId } from './SimpleDataTypes';
import { SecureToken, ServerSalt, PasswordServerHashed, AuthStateKind_LoggedIn } from './SecurityDataTypes';

// System Model (User, etc.)
export type RoleKind = 'none' | 'admin' | 'super-admin';
export const RoleKind = {
    isAdmin: (role?: RoleKind) => role === 'admin' || role === 'super-admin',
    isSuperAdmin: (role?: RoleKind) => role === 'super-admin',
};

export type UserModel = {
    id: string;
    username: string;
    passwordHash: PasswordServerHashed;
    passwordSalt: ServerSalt;

    email: string;
    phone?: PhoneNumber;
    
    deleted?: boolean;

    // Verification
    email_pendingVerificationCode?: string;
    email_pendingVerificationTime?: Timestamp;
    phone_pendingVerificationPhone?: PhoneNumber;
    phone_pendingVerificationCode?: string;
    phone_pendingVerificationTime?: Timestamp;

    activeAccount: Reference<AccountModel>;

    role?: RoleKind;
};

export type UserPasswordClientSaltModel = {
    id: string;
    /** This is the username claimed by the login request, this may not actually exist 
     * The salt is created the same for any username attempt to prevent detection of existing user names
     * This will essentially log username guesssing
     */
    usernameClaim: string;
    salt: ServerSalt;
}

export type UserSessionModel = {
    id: string;
    user: Reference<UserModel>;
    activeAccount: Reference<AccountModel>;
    role: RoleKind;
    authState: AuthStateKind_LoggedIn;

    sessionToken: SecureToken;
};

export type UserAccountModel = {
    id: string;
    user: Reference<UserModel>;
    account: Reference<AccountModel>;
};

// Data
// Each user will have a default account created, though it may not be used (if user is a manager for another account)
export type AccountModel = {
    id: string;
    name: string;

    // // TODO: Consider moving this somewhere else
    // // Account Setup (copy of data for readonly purposes?)
    // planDetails?: PlanDetails;
    // accountDetails?: AccountDetails;
    // paymentDetails?: PaymentDetails;

    // Account Settings
    campaignDefaults: {
        // Default hour of day to deliver messages in contact's time zone
        hourOfDay: TimeOnly;
    };
};

export type AccountSystemModel = {
    id: string;
    account: Reference<AccountModel>;

    // Secured account data
    planDetails?: PlanDetails;
    accountDetails?: AccountDetails;
    paymentDetails?: PaymentDetails;
    providerAccountDetails?: ProviderAccountDetails;

    subscriptionDetails?: SubscriptionDetails;

    accountAdminSettings?: AccountAdminSettings;
}

export type AccountAdminSettings = {
    canSendAtNight?: boolean;
    canSendToImportedContacts?: boolean;
    disabled?: boolean;
    // canSkipModeration?: boolean;
};

export type AccountSubscriptionEdit = {
    planCreditsMonthly?: number;
    planPriceMonthly?: Price;
    cycleExtraCredits?: number;
    cycleEndDate?: DateOnly;
};

export type AccountInfo = {
    accountName: string,
    createdAt: Timestamp,
}

export type AccountState = {
    accountRef: Reference<AccountModel>;
    accountDetails?: AccountDetails;
    accountBusinessName: string;
    hasPlan: boolean;
    hasAccountDetails: boolean;
    isPaymentsReady: boolean;
    paymentFailureCount: number;
    hasPhoneNumber: boolean;

    stats: AccountStatsState;
    accountAdminSettings: AccountAdminSettings;

    userRole?: RoleKind,
    isImpersonatingAccount?: boolean,
}

export const getEmptyAccountState = () => {
    const emptyAccountState: AccountState = {
        accountRef: getReferenceFromId(''),
        accountBusinessName: '',
        hasPlan: false,
        hasAccountDetails: false,
        isPaymentsReady: false,
        paymentFailureCount: 0,
        hasPhoneNumber: false,
        stats: {
            // Make sure is in past
            cycleEndTime: Timestamp.addSeconds(Timestamp.now(), -60),
            cycleSubscriptionCredits: 0,
            cycleExtraCredits: 0,
            cycleRemainingCredits: 0,
            planCredits: 0,
            paymentDue: Price.toPrice(0),
            accountPhoneNumbers: [],
        },
        accountAdminSettings: {},
        userRole: 'none',
    };
    return emptyAccountState;
};

export type AccountStatsState = {

    // If today is on or past this, then the account has not been paid yet (disabled)
    cycleEndTime: Timestamp;
    cycleSubscriptionCredits: number;
    cycleExtraCredits: number;

    // If the remaining credits are 0, then the account owner needs to purchase more credits (disabled)
    cycleRemainingCredits: number;

    // Pending Payment
    paymentDue: Price;

    // The credits for the selected plan
    planCredits: number;

    // The phone numbers purchased
    accountPhoneNumbers: PhoneNumber[];
}

export type PlanDetails_Edit = {
    planId: string;
}

export type PlanDetails = {
    planId: string;
    name: string;
    priceMonthly: Price;
    messagesMonthly: number;
};

export type SubscriptionDetails = {
    // Payments are made at midnight EST
    // Payments are made at the beginning of each month
    // First month payment is pro-rated based on remaining ratio of days in month

    // Updated when payment succeeds (normally cycleNextStartTime + 1 month, unless disabled for a while)
    cycleEndTime: Timestamp;
    // Updated when payment succeeds (= last cycleEndTime)
    cycleStartTime: Timestamp;
    // Cutoff time to stop delivery of messages if payment for next cycle has not succeeded
    // Updated when payment succeeds (= new cycleEndTime + 1 day)
    cycleCutoffTime: Timestamp;

    // Updated when payment succeeds (PlanDetails.messagesMonthly)
    cycleSubscriptionCredits: number;
    // Updated when payment succeeds (0)
    cycleExtraCredits: number;
    // Starts on 0 when purchased
    cycleExtraCreditsAge: number;

    // Updated when payment succeeds (PlanDetails.messagesMonthly)
    // Updated after each message delivery is created (cycleSubscriptionCredits + cycleExtraCredits - # messageDeliveries since cycleStartTime)
    cycleRemainingCredits: number;

    // For Reference
    cyclePlanDetails: PlanDetails;
    cyclePaymentAmount: Price;
};

export type PlanModel = {
    id: string;
    name: string;
    priceMonthly: Price;
    messagesMonthly: number;
};
export type PlanExtraCreditsModel = {
    id: string;
    name: string;
    price: Price;
    messages: number;
};

export type PaymentDetails_Edit = {
    provider: 'Paypal' | 'Stripe';
    creditCard: {
        creditCardNumber: string;
        securityCode: string;
        fullName: string;
    };
    address: {
        street: string;
        city: string;
        state: string;
        zip: string;
        country: 'United States' | 'Canada';
        // addressType: 'Service';
    }
}

export type PaymentDetails = {
    /** Key-Value storage for Payment System  */
    providerPaymentData?: { [key: string]: string };

    // System State
    paymentSystemState?: {
        paymentsReady: boolean;
        paymentFailureCount?: number;
        // lastPaymentTime: Timestamp;
        // lastPaymentSuccessful: boolean;
    };
};

export type PaymentDetails_Saved = PaymentDetails;

export type AccountDetails_Edit = {
    // site.name - Max 10 Chars (unique) - Generated from Business Name
    // siteCode: string;
    businessName: string;
    businessEmail?: string;
    address: {
        street: string;
        city: string;
        state: string;
        zip: string;
        country: 'United States' | 'Canada';
        // addressType: 'Service';
    }
};

export type AccountDetails = AccountDetails_Edit;
export type ProviderAccountDetails = unknown;

export type AccountPhoneNumberModel = {
    id: string;
    /** DEPRECTATED */
    name: string;
    phoneNumber: PhoneNumber;
    account: Reference<AccountModel>;
    orderStatus: 'pending' | 'success' | 'fail';
    providerData: unknown;
};

export type ContactStatus = {
    enabled: boolean;

    /** DEPRECTATED */
    optInStatus: 'new' | 'requested' | 'refused' | 'accepted';
    hasDeliveryFailure?: boolean;
    wasRemoved?: boolean;

    lastKeyword?: string;
    lastCampaign?: Reference<CampaignModel_Common>;
};

export type ContactModel = {
    id: string;
    // Unique to contact (but can be changed)
    phoneNumber: PhoneNumber;

    // Generated by Zip Code
    deliveryTimeZone?: TimeZone;

    firstName?: string;
    lastName?: string;

    status: ContactStatus;

    state: {
        // messageDeliveryHistory?: {
        //     messageDelivery: Reference<MessageDeliveryData>;
        //     time: Timestamp;
        // }[];

        // messageResponseHistory?: {
        //     text: string;
        //     time: Timestamp;
        // }[];

        // linkVisitHistory?: {
        //     linkToken: string;
        //     time: Timestamp;
        // }[];

        // TODO: Change this to campaignStages and campaigns
        // CampaignState may not exist for a contact until the campaign begins,
        // So to list all campaigns, will require going through the contactList with a custom query
        //campaignStates: ReferenceList<ContactCampaignStateModel>;
        // campaigns: ReferenceList<CampaignModel>;
        campaigns: ReferenceList<ContactCampaignStateModel>;
        contactLists: ReferenceList<ContactListModel>;

    };
};

export type ContactModel_BulkInfo = {
    id: string;
    phoneNumber: PhoneNumber;
    firstName?: string;
    lastName?: string;
    bulkStatus: {
        isInList: boolean;
        wasAdded?: boolean;
    };
};

export type ContactModel_Edit = {
    phoneNumber?: PhoneNumber;
    firstName?: string;
    lastName?: string;
};

export type ContactCampaignStateModel_Common = {
    id: string;
    contact: Reference<ContactModel>;
    campaign: Reference<CampaignModel>;
};

// Contact Lists can be shared by multiple campaigns
// Specific state about a contact is stored in the contact itself
export type ContactListModel = {
    id: string;

    name: string;
    description: string;
    keywords: string;
    deleted?: boolean;

    // Contacts
    contacts: ReferenceList<ContactModel>;

    // Dates
    createdTime: Timestamp;
    modifiedTime: Timestamp;

    usedByCampaigns: ReferenceList<CampaignModel>;
};

export type ContactListModel_Edit = {
    id?: string;
    name?: string;
    description?: string;
    keywords?: string;
};

export type DateRangeIntervals_Weekly = {
    kind: 'weekly';
    weekly: {
        // Example: Monday
        // startDayOfWeek: DayOfWeek;
        // Example: Friday
        endDayOfWeek: DayOfWeek;

        // Enabled Dates
        // startDate: DateOnly;
        // endDate?: DateOnly;
    }
};
export type DateRangeIntervals_Monthly = {
    kind: 'monthly';
    monthly: {
        // Example: 1st
        // startDayOfMonth: DayOfMonth;
        // Example: 31st (31=>last day of month)
        endDayOfMonth: DayOfMonth;

        // Enabled Dates
        // startDate: DateOnly;
        // endDate?: DateOnly;
    }
};
export type DateRangeIntervals_Dates = {
    kind: 'dates';
    dates: {
        dateRanges: {
            // startDate: DateOnly;
            endDate: DateOnly;
        }[];
    }
};

export type DateRangeIntervals = DateRangeIntervals_Weekly | DateRangeIntervals_Monthly | DateRangeIntervals_Dates;
export type DateRangeIntervalKinds = DateRangeIntervals['kind'];

export type DateRangeEditorState = {
    hourOfDay?: TimeOnly,
    startDate?: DateOnly,
    endDate?: DateOnly,
    startDayOfWeek?: DayOfWeek,
    endDayOfWeek?: DayOfWeek,
    startDayOfMonth?: DayOfMonth,
    endDayOfMonth?: DayOfMonth,
};


export type LinkModel = {
    id: string;
    linkToken: string;

    destinationUrl: string;

    // Each delivered message has a unique token to track link opens
    messageDelivery: Reference<MessageDeliveryModel>;
};

export type CampaignVars_Common = {
    // [name: string]: string;
    // firstName: string;
    // lastName: string;
    // date: string;
};
export type MessageTemplateModel<TVars extends CampaignVars_Common> = {
    id: string;

    // Campaign Context
    campaign: Reference<CampaignModel>;
    // Example: messages[10].message
    // Example: messages.contactAdded_inviteToContest
    campaignPath: string;
    /** This is shown with a message in the message lists (with the campaign name) */
    campaignRole: string;

    text: string;

    additionalTexts: string[];

    // The imageUrl is a separate value (not in the text)
    imageUrl?: string;

    // This is just metadata (it should be embedded at the beginning of the text)
    linkUrl?: string;
};

export type MessageGeneratedModel = {
    id: string;
    deliveredAt?: Timestamp;

    campaign: Reference<CampaignModel_Common>;
    messageTemplate: Reference<MessageTemplateModel<any>>;
    message: { text: string, imageUrl?: string },
    fromPhoneNumber: Reference<AccountPhoneNumberModel>,
    contactListIds?: string[],
    contacts?: Reference<ContactModel>[],
    schedule: {
        immediate: boolean,
        onDate?: DateOnly,
        hourOfDay?: TimeOnly,
        absoluteTimeZone?: TimeZone;
    };
    isOptInMessage: boolean;
};

// Messages are only created when they are ready to send
export type MessageDeliveryModel = {
    id: string;
    toContact: Reference<ContactModel>;
    // Time when it was created 
    createdTime: Timestamp;

    // Necessary Delivery Data
    fromPhoneNumber: PhoneNumber;
    toPhoneNumber: PhoneNumber;
    deliverAtTime: Timestamp;
    deliverImmediately: boolean;
    isOptInMessage: boolean;

    // Processing Delivery
    /** When the message is selected to be processed */
    deliveryProcessingStartTime?: Timestamp;
    deliveryTime?: Timestamp;
    deliveryResult?: {
        deliveredToNetworkTime?: Timestamp;
        deliveryFailureTime?: Timestamp;
        failureMessage?: string;
        failureData?: unknown;
    };

    // Should this include other info?
    content: {
        text: string;
        additionalTexts: string[];
        imageUrl?: string;
    };

    // Source
    lastCampaign?: Reference<CampaignModel_Common>;
    messageTemplate?: Reference<MessageTemplateModel<any>>;
    messageGenerated?: Reference<MessageGeneratedModel>;
};

export type MessageReplyModel = {
    id: string;
    fromContact: Reference<ContactModel>;

    // These times are the same
    createdTime: Timestamp;
    deliveredTime: Timestamp;

    content: {
        text: string;
        /** These are the urls of media stored at bandwidth for 2 days */
        mediaUrlsOriginal?: string[];
        /** Publicly Available Urls to display the media
         *  
         * These are the urls that have been downloaded to own servers
         */
        mediaUrls?: string[];
    };

    // Most likely trigger
    lastCampaign?: Reference<CampaignModel_Common>;
    /** @deprecated */
    mostRecentMessageDelivery?: Reference<MessageDeliveryModel>;

    // Info
    fromContactPhoneNumber: PhoneNumber;
    toAccountPhoneNumber: PhoneNumber;
};

export type MessageContentAll = {
    text: string;
    additionalTexts?: string[];
    imageUrl?: string;
    mediaUrlsOriginal?: string[];
    mediaUrls?: string[];
};

// Message Thread is possibly Runtime Only
export type MessageThread = (MessageDeliveryModel | MessageReplyModel)[];
export type MessageThreadInfoMessage = {
    kind: 'delivery' | 'reply',
    item: MessageDeliveryModel | MessageReplyModel,
    contact: Reference<ContactModel>,
    createdTime: Timestamp,
};
export type MessageThreadInfo = {
    contact: Reference<ContactModel>;
    lastMessage: MessageThreadInfoMessage;
};

export type MessageTemplateEdit = {
    id?: string;
    text: string;
    additionalTexts: string[];
    imageUrl?: string;
    linkUrl?: string;
};

export type CampaignModel_Common = {
    id: string;

    name: string;
    description: string;
    fromPhoneNumber: Reference<AccountPhoneNumberModel>;
    // toContactList: Reference<ContactListModel>;
    toContactLists: ReferenceList<ContactListModel>;

    // autoReplyMessage: Reference<MessageTemplateModel<CampaignVars_Common>>;

    // Calculated
    status?: {
        createdTime: Timestamp;
        lastActivityTime: Timestamp;
        /** [Deprecated] */
        nextActivityTime: Timestamp;
        isActive: boolean;
    };
};

export type CampaignMessageStats = {
    // generatedMessageContactsCount: number;
    messagesDeliveriesCreated: number;
    messagesDeliveriesDelivered: number;
    messagesDeliveriesSuccessful: number;
    messagesDeliveriesFailed: number;
};
export type CampaignStats = CampaignMessageStats & {
    contactsToDeliverTotal: number;
};


// Common ---
export type CampaignStateModel_Common = {
    id: string;
    // campaignRef: Reference<CampaignModel>;
};

// Standard ---
export type CampaignModel_Standard = CampaignModel_Common & {
    // Single Messsage Campaign
    // Immediate or Scheduled Delivery 
    // To contacts on contactList at startTime

    kind: 'standard';

    messages: {
        message: Reference<MessageTemplateModel<CampaignVars_Common>>;
        schedule: {
            // Begin delivery after this date (Immediate delivery can just use this time)
            startDate: DateOnly;
            // Deliver to each contact at this hour in their time zone
            hourOfDay?: TimeOnly;

            // HourOfDay is an absolute time (instead of recipients time)
            absoluteTimeZone?: TimeZone;
            sendNow?: boolean;
        };
    };
};
export type CampaignEdit_Standard = {
    isValid: boolean;
    status: 'new' | 'active' | 'inactive';

    id?: string;
    kind: 'standard';

    name: string;
    description: string;
    toContactLists: ContactListModel_Edit[];
    fromPhoneNumber?: Reference<AccountPhoneNumberModel>;
    message: MessageTemplateEdit;
    schedule: {
        startDate: DateOnly;
        hourOfDay?: TimeOnly;
        absoluteTimeZone?: TimeZone;
        sendNow?: boolean;
    };
};

export type ContactCampaignStateModel_Standard = ContactCampaignStateModel_Common & {
    kind: 'standard';
};

// Interactive ---
export type CampaignModel_Interactive = CampaignModel_Common & {
    // Single Response to Opt-In
    kind: 'interactive';

    messages: {
        message: Reference<MessageTemplateModel<CampaignVars_Common>>;
        optInMessage?: Reference<MessageTemplateModel<CampaignVars_Common>>;
        optInConfirmationMessage?: Reference<MessageTemplateModel<CampaignVars_Common>>;
    };
    endDate?: DateOnly;
};
export type CampaignEdit_Interactive = {
    isValid: boolean;
    status: 'new' | 'active' | 'inactive';

    id?: string;
    kind: 'interactive'

    name: string;
    description: string;
    contactList: ContactListModel_Edit;
    fromPhoneNumber?: Reference<AccountPhoneNumberModel>;
    message: MessageTemplateEdit;
    optInMessage?: MessageTemplateEdit;
    optInConfirmationMessage?: MessageTemplateEdit;
    endDate?: DateOnly;
};
export type ContactCampaignStateModel_Interactive = ContactCampaignStateModel_Common & {
    kind: 'interactive';
};


// Coupon ---
export type CampaignModel_Coupon = CampaignModel_Common & {
    // Single Response to Opt-In
    kind: 'coupon';

    messages: {
        message: Reference<MessageTemplateModel<CampaignVars_Common>>;
        optInMessage?: Reference<MessageTemplateModel<CampaignVars_Common>>;
        optInConfirmationMessage?: Reference<MessageTemplateModel<CampaignVars_Common>>;
    };
    endDate?: DateOnly;
};
export type CampaignEdit_Coupon = {
    isValid: boolean;
    status: 'new' | 'active' | 'inactive';

    id?: string;
    kind: 'coupon'

    name: string;
    description: string;
    contactList: ContactListModel_Edit;
    fromPhoneNumber?: Reference<AccountPhoneNumberModel>;
    message: MessageTemplateEdit;
    optInMessage?: MessageTemplateEdit;
    optInConfirmationMessage?: MessageTemplateEdit;
    endDate?: DateOnly;
};
export type ContactCampaignStateModel_Coupon = ContactCampaignStateModel_Common & {
    kind: 'coupon';
};

// Sweepstakes ---
export type CampaignModel_Sweepstakes = CampaignModel_Common & {
    // Single Response to Opt-In
    kind: 'sweepstakes';

    /** Maximum Entries per Phone Number */
    maxEntriesPerParticipant: number;

    messages: {
        /** Welcome message to every participant */
        welcomeMessage: Reference<MessageTemplateModel<CampaignVars_Common>>;
        optInMessage?: Reference<MessageTemplateModel<CampaignVars_Common>>;
        optInConfirmationMessage?: Reference<MessageTemplateModel<CampaignVars_Common>>;
    };

    instantWinPrizes: {
        /** Unique Id to Identify Prize - User Entered */
        prizeId: string;
        /** User Entered Name - to Identify Prize in Lists */
        prizeName: string;
        /** Number or prizes available */
        prizeLimit: number;
        /** 1 out of N chance to win */
        winChancePoolSize: number;
        /** Message to send if winner */
        winMessage: Reference<MessageTemplateModel<CampaignVars_Common>>;
    }[];

    endDate: DateOnly;
};
export type CampaignEdit_Sweepstakes = {
    isValid: boolean;
    status: 'new' | 'active' | 'inactive';

    id?: string;
    kind: 'sweepstakes';

    name: string;
    description: string;
    contactList: ContactListModel_Edit;
    fromPhoneNumber?: Reference<AccountPhoneNumberModel>;

    maxEntriesPerParticipant: number;

    welcomeMessage: MessageTemplateEdit;
    optInMessage?: MessageTemplateEdit;
    optInConfirmationMessage?: MessageTemplateEdit;
    instantWinPrizes: {
        prizeId: string;
        prizeName: string;
        prizeLimit: number;
        winChancePoolSize: number;
        winMessage: MessageTemplateEdit;
    }[];
    endDate: DateOnly;
};
export type ContactCampaignStateModel_Sweepstakes = ContactCampaignStateModel_Common & {
    kind: 'sweepstakes';

    /** The number of entries for this contact */
    entryCount: number;

    /** The name of the instant win prize */
    wonPrizes: {
        prizeId: string,
        timestamp: Timestamp,
    }[];
};

export type CampaignStateModel_Sweepstakes = CampaignStateModel_Common & {
    kind: 'sweepstakes';
    campaign: Reference<CampaignModel_Sweepstakes>;

    entryCount: number;
    entries: {
        contact: Reference<ContactModel>,
        timestamp: Timestamp,
        wonPrizeId?: string,
    }[];

    wonPrizes: {
        prizeId: string,
        timestamp: Timestamp,
        contact: Reference<ContactModel>,
    }[];

    // State for each contest instance
    instantWinPrizeState: {
        prizeId: string;
        winnerCount: number;
    }[];
};


// <OBSOLETE>
export type CampaignModel_Drip = CampaignModel_Common & {
    // Chain of messages sent on specific day intervals after a contact joins a group

    kind: 'drip';

    messages: {
        messages: {
            message: Reference<MessageTemplateModel<CampaignVars_Common>>;
            schedule: {
                // The order of the messages
                // 0 is the first message
                // Each message should have a unique value
                sequence: number;
                // The number of days to wait since the last message was sent
                waitDays: number;
                // Restrict delivery to specific days of the week 
                // (messages ready will be delayed until that week day)
                daysOfWeek?: DayOfWeek[];
                // Deliver to each contact at this hour in their time zone
                hourOfDay?: TimeOnly;
            };
        }[];
    };
};
export type ContactCampaignStateModel_Drip = ContactCampaignStateModel_Common & {
    kind: 'drip';
    joinedTime?: Date;
};
export type CampaignVars_Contest = CampaignVars_Common & {
    endDate: DateOnly;
};
export type CampaignModel_Contest = CampaignModel_Common & {
    // Repeated contest that occurs on regular intervals

    kind: 'contest';

    numberOfWinners: number;
    maxEntries: number;
    contestIntervals: DateRangeIntervals;

    messages: {
        // Delivered upon joining list (immediately if sign-up)
        // contactAdded_introduction: Reference<MessageTemplateModel<CampaignVars_Common>>;
        // Delivered at beginning of each contest interval
        // contestIntervalStarted_inviteToContest: Reference<MessageTemplateModel<CampaignVars_Contest>>;
        // Delivered immediately in response to contact
        joinedContest_welcome: Reference<MessageTemplateModel<CampaignVars_Contest>>;
        // joinFailed_contestOver: Reference<MessageTemplateModel<CampaignVars_Common>>;
        // Delivered at end of contest
        contestOver_lost: Reference<MessageTemplateModel<CampaignVars_Contest>>;
        contestOver_won: Reference<MessageTemplateModel<CampaignVars_Contest & { prizeLink: string }>>;

        schedule: {
            // Delivery time for non-immediate messages
            hourOfDay?: TimeOnly;
        };
    };

    // The link sent to a winner to claim the prize
    prizeUrl: string;
};
export type CampaignStateModel_Contest = CampaignStateModel_Common & {
    kind: 'contest';
    campaign: Reference<CampaignModel_Contest>;

    // State for each contest instance
    contests: {
        contestInstanceId: string;
        startDate: DateOnly;
        endDate: DateOnly;

        // OBSOLETE:
        // Used by task runner to trigger creating messages at correct times
        // new => before start date
        // open => after start date before end date (accepts joining)
        // done => after end date
        // new -> open (Send invitation messages)
        // open -> done (Send Winner/Loser messages)
        // status: 'new' | 'open' | 'done';

        // Not Needed -> In contact campaign state
        // joinedContactRefs: Reference<ContactData>[];
        // winnerContactRefs: Reference<ContactData>[];
    }[];
};
export type ContactCampaignStateModel_Contest = ContactCampaignStateModel_Common & {
    kind: 'contest';
    contests: {
        contestInstanceId: string;
        joinedTime?: Timestamp;
        isWinner?: boolean;
    }[];
};
// </OBSOLETE>

// Combined
// NOTE: Extra properties added to help compatibility with relationalConverters.generated
export type CampaignModel = { message?: Reference<MessageTemplateModel<{}>> } & (CampaignModel_Standard | CampaignModel_Interactive | CampaignModel_Sweepstakes | CampaignModel_Contest | CampaignModel_Coupon | CampaignModel_Drip);
export type CampaignModel_WithOptIn = (CampaignModel_Interactive | CampaignModel_Sweepstakes | CampaignModel_Coupon);
export type CampaignStateModel = CampaignStateModel_Sweepstakes | CampaignStateModel_Contest; // CampaignStateData_Standard | CampaignStateData_Drip | CampaignStateData_Contest | CampaignStateData_Coupon;
export type ContactCampaignStateModel = { openedTime?: Timestamp, expirationTime?: Timestamp } & (ContactCampaignStateModel_Standard | ContactCampaignStateModel_Interactive | ContactCampaignStateModel_Sweepstakes | ContactCampaignStateModel_Drip | ContactCampaignStateModel_Contest | ContactCampaignStateModel_Coupon);


// Server Only
export type BandwidthEventModel = {
    id: string;
    createdAt: Timestamp;
    json: string;
};

export type TaskStateModel = {
    id: string;
    taskKey: string;
    startTime?: Timestamp;
    endTime?: Timestamp;
};