import { UploadApi, UploadApiEndpointName, UploadApiRequestData } from "./UploadApiWebClientTypes";
import { AppConfig_Client } from "../../../_config/client/appConfig_client";
import { webRequest } from "../../../utils/WebRequest";
import { UserCredentials, UserCredentialsProvider } from "../../../data-types/SecurityDataTypes";
import { PlanModel, AccountState, MessageDeliveryModel } from "../../../data-types/ModelTypes";
import { PhoneNumber, Reference } from "../../../data-types/SimpleDataTypes";
import { AuthError } from "../../../utils/Errors";

export const createUploadApiWebClient = (appConfig: AppConfig_Client, userCredentialsProvider: UserCredentialsProvider): UploadApi => {

    async function apiRequest<T>(endpoint: UploadApiEndpointName, data: unknown): Promise<T> {
        const url = `${appConfig.uploadApiDomain}/${endpoint}`;
        const userCredentials = userCredentialsProvider.getUserCredentials();
        if (!userCredentials) {
            throw new AuthError('Cannot call UploadApi without UserCredentials', { userCredentials, endpoint });
        }

        const requestData: UploadApiRequestData = {
            userCredentials,
            endpoint,
            data,
        };

        const resultObj = (await webRequest(url, requestData)) as { data: T, newUserCredentials: UserCredentials | null | undefined };

        if (resultObj.newUserCredentials !== undefined) {
            await userCredentialsProvider.setUserCredentials(resultObj.newUserCredentials);
        }

        return resultObj.data;
    };

    const api: UploadApi = {
        createUploadUrl: (data) => apiRequest('createUploadUrl', data),
        // createUploadUrl: async (data) => {
        //     return {
        //         uploadUrl: 'https://gator-texting-uploads.s3.amazonaws.com/test1599571607867?AWSAccessKeyId=AKIARL6KMSZ3Z7R3W3QL&Content-Type=image%2Fpng&Expires=1599575207&Signature=%2FVyRG5%2BHVBDArrdZv77vfOCVTnk%3D',
        //         readUrl: 'https://gator-texting-uploads.s3.amazonaws.com/test1599571607867',
        //     };
        // },
    };

    return api;
}
