import { ContactModel, CampaignModel_Contest, CampaignModel_Drip, CampaignModel_Common, MessageTemplateModel } from './ModelTypes';
import { JsonTyped } from '../utils/Json';
import { ReferenceList, Reference } from './SimpleDataTypes';
import { LazyItems } from '../utils/LazyItems';
import { ValueOrPromise } from '../utils/ValueOrPromise';
import { Timestamp } from '../utils/Time';

// Stored In Database
export type ModelJson<T> = JsonTyped<Model_InJson<T>>;
export type Model_InJson<T> = Model_CleanReferences<T>;

type Model_CleanReferences<T> = { [K in keyof T]: Model_CleanReferences_Child<T[K]> };
type Model_CleanReferences_Child<T> =
    T extends ReferenceList<infer L> ? undefined
    : T extends { id: string } ? Reference<T>
    : T extends string | Date | number | boolean ? T
    : T extends object ? { [K in keyof T]: Model_CleanReferences_Child<T[K]> }
    : T;

export function cleanModelReferences<T extends { id: string }>(model: T): Model_CleanReferences<T> {
    const clone = { ...model };
    for (const k in clone) {
        clone[k] = cleanModelReferences_child(clone[k]) as any;
    }
    return clone as unknown as Model_CleanReferences<T>;
}

function cleanModelReferences_child<T>(model: T): Model_CleanReferences_Child<T> {
    if (model == null) {
        return null as unknown as Model_CleanReferences_Child<T>;
    }

    if (typeof model !== 'object') {
        return model as Model_CleanReferences_Child<T>;
    }

    if (typeof model === 'object' && model instanceof Array) {
        return model.map(x => cleanModelReferences_child(x)) as unknown as Model_CleanReferences_Child<T>;
    }

    if (typeof model === 'object' && '__type' in model && (model as any)['__type'] === 'ReferenceList') {
        return undefined as unknown as Model_CleanReferences_Child<T>;
    }

    if (typeof model === 'object' && 'id' in model && typeof (model as any)['id'] === 'string') {
        return { id: (model as any)['id'] } as unknown as Model_CleanReferences_Child<T>;
    }

    // Object
    const clone = { ...model } as any;
    for (const k in clone) {
        clone[k] = cleanModelReferences_child(clone[k]);
    }
    return clone as Model_CleanReferences_Child<T>;
}

// Based on Widen
// export type Model_InJson<T> = PartialKeys<{
//     [K in AllKeys<T>]: Model_InJsonChild<Idx<T, K>>
// }, Exclude<AllKeys<T>, keyof T> | OptionalKeys<T>>;

// type Model_InJsonChild<T> =
//     // References are null
//     T extends ReferenceList<infer L> ? undefined
//     // Keep Typed-Scalars : string & {__type:'PhoneNumber'}, etc.
//     : T extends string | Date | number | boolean ? T
//     // References and Embedded References (anything with an id:string)
//     : T extends { id: string } ? Reference<T>
//     : T extends object ? PartialKeys<{
//         [K in AllKeys<T>]: Model_InJsonChild<Idx<T, K>>
//     }, Exclude<AllKeys<T>, keyof T> | OptionalKeys<T>>
//     : T;

// type Widen<T> =
// [T] extends [Array<infer E>] ? { [K in keyof T]: Widen<T[K]> } :
// [T] extends [object] ? PartialKeys<
//     { [K in AllKeys<T>]: Widen<Idx<T, K>> },
//     Exclude<AllKeys<T>, keyof T> | OptionalKeys<T>
// > :
// T;


export type ServerApiTransforms<T> = { [K in keyof T]:
    // Load the next page of items
    // Example: loadMoreContacts: (lazyItems: LazyItems<ContactModel>, shouldLoadAll: boolean) => Promise<ContactModel[]>;
    T[K] extends ((lazyItems: LazyItems<infer TItem>) => any) ? ((skipCount: number, takeCount: number) => Promise<Model_FromServer<TItem>[]>)
    // Load the first page of items inside a lazyItems instance
    : T[K] extends ((...args: any[]) => Promise<LazyItems<infer TItem>>) ? ((...args: Parameters<T[K]>) => Promise<LazyItems_FromServer<Model_FromServer<TItem>>>)

    : T[K] extends ((...args: any[]) => Promise<infer TItem>) ? ((...args: Parameters<T[K]>) => Promise<Model_FromServer<TItem>>)
    : T[K] extends ((...args: any[]) => ValueOrPromise<infer TItem>) ? ((...args: Parameters<T[K]>) => Promise<Model_FromServer<TItem>>)
    // loadItemsContacts: (listRef: ReferenceList<ContactModel>) => LazyItems<ContactModel>;
    : T[K] extends ((...args: any[]) => LazyItems<infer TItem>) ? ((listRef: ReferenceList_FromServer<TItem>) => Promise<LazyItems_FromServer<Model_FromServer<TItem>>>)
    : never
};

// Data from the server (read from database and reconstructed)
export type Model_FromServer<T> = { [K in keyof T]: Model_FromServer_Child<T[K]> } & { _row: { insertedAt: Timestamp, updatedAt: Timestamp } };
type Model_FromServer_Child<T> =
    // Use ReferenceList_FromServer that contains data needed for future requests
    T extends ReferenceList<infer L> ? ReferenceList_FromServer<L>
    // Embedded References as References
    : T extends { id: string } ? Reference<T>
    : T extends string | Date | number | boolean ? T
    : T extends object ? { [K in keyof T]: Model_FromServer_Child<T[K]> }
    : T;

export type Model_ToServer<T> = Model_CleanReferences<T>;




// All Info needed to be able to load the correct data into a lazy items 
// RefList could have multiple sources, so it needs to identify which db source to use (which is known at original query when RefList is created)
export type ReferenceList_FromServer<T> = {
    __type: 'ReferenceList',
    __subType: 'ReferenceList_FromServer',
    queryName: string;
    queryArgs: unknown;
};

// Paged Data with the information to get next page
export type LazyItems_FromServer<T> = {
    items: T[];
    skipCount: number;
    takeCount: number;
    totalCount: number;
};


// let contact = null as any as ContactModel;
// let contact2 = null as any as ModelInJson<ContactModel>;
// let cState = contact.state;
// let cState2 = contact2.state;

// let campaign1 = null as any as CampaignModel_Drip;
// let campaign2 = null as any as ModelInJson<CampaignModel_Drip>;

// let cMessages1 = campaign1.messages;
// let cMessages2 = campaign2.messages;


// contact2 = contact;
// contact = contact2;


// Widen:
// From: https://stackoverflow.com/a/60118644/567524

type AllKeys<T> = T extends any ? keyof T : never;
type OptionalKeys<T> = T extends any ?
    { [K in keyof T]-?: {} extends Pick<T, K> ? K : never }[keyof T] : never;
type Idx<T, K extends PropertyKey, D = never> =
    T extends any ? K extends keyof T ? T[K] : D : never;
type PartialKeys<T, K extends keyof T> =
    Omit<T, K> & Partial<Pick<T, K>> extends infer O ? { [P in keyof O]: O[P] } : never;
// type Widen<T> =
//     [T] extends [Array<infer E>] ? { [K in keyof T]: Widen<T[K]> } :
//     [T] extends [object] ? PartialKeys<
//         { [K in AllKeys<T>]: Widen<Idx<T, K>> },
//         Exclude<AllKeys<T>, keyof T> | OptionalKeys<T>
//     > :
//     T;

export type Widen<T> =
    // Ignore Typed-Scalars
    [T] extends [string | Date | number | boolean] ? T
    : [T] extends [Array<infer E>] ? { [K in keyof T]: Widen<T[K]> }
    : [T] extends [object] ? PartialKeys<{
        [K in AllKeys<T>]: Widen<Idx<T, K>>
    }, Exclude<AllKeys<T>, keyof T> | OptionalKeys<T>>
    : T;



// Runtime
export type CampaignEdit<T> = {
    [K in keyof T]: CampaignEdit_Child<T[K]>
};
type CampaignEdit_Child<T> =
    // Embed Message Templates
    T extends Reference<infer L> ? (L extends MessageTemplateModel<infer M> ? MessageTemplateModel<M> : T)
    : T extends string | Date | number | boolean ? T
    : T extends object ? { [K in keyof T]: CampaignEdit_Child<T[K]> }
    : T;