import React, { useState, useEffect } from 'react';
import { View, Text } from "react-native";
import { CampaignModel_Standard, CampaignEdit_Standard, ContactListModel, ContactListModel_Edit } from '../../../data-types/ModelTypes';
import { Store } from '../../store/Store';
import { CampaignCard, } from './EditCampaignComponents';
import { theme } from '../../style/Theme';
import { TextView, OptionField, TextField } from '../../components/TextView';
import { MessageTemplateFieldEditorV2 } from './components/MessageTemplateEditor';
import { MainLayout } from '../../components/Layout';
import { AppError } from '../../../utils/Errors';
import { useAutoLoadingError } from '../../../utils/Hooks';
import { getReferenceFromId } from '../../../data-types/SimpleDataTypes';
import { Timestamp, DateOnly, TimeZone, TimeOnly } from '../../../utils/Time';
import { CampaignEdit } from '../../../data-types/TypeTransformations';
import { Loading } from '../../../lib/controls-react/loading';
import { ErrorBox } from '../../../lib/controls-react/error-box';
import { MessageTemplateField } from '../../../logic/campaigns/campaignFieldDefinitions';
import { formatPhoneNumber_UsaCanada } from '../../../utils/PhoneNumbers';
import { DateFieldEditor, HourOfDayEditor } from './components/DateRangeFieldsEditor';
import { DatePicker, TimePicker } from '../../components/DateTimePicker';
import { Validation_DateAfterOrOnToday } from '../../../utils/Validation';
import { CampaignPreviewSaveArea } from './components/CampaignPreviewSaveArea';
import { CheckBoxControl } from './components/CheckBoxControl';
import { ListEditor } from '../../components/ListEditor';
import { CampaignPhoneNumber } from './components/CampaignPhoneNumber';
import { useAlertModal } from '../../components/AlertModal';


type TCampaign = CampaignModel_Standard;
type TCampaignEdit = CampaignEdit_Standard;

export const EditCampaignPage_Standard = (props: {
    store: Store,
    args?: { campaign: TCampaign }, argsRoute?: { campaignId: string }
}) => {
    return <EditCampaignPage_Standard_Inner {...props} />
}

const CampaignResultViewer = ({
    store,
    campaignValue,
}: {
    store: Store,
    campaignValue: TCampaignEdit,
}) => {
    // Save Campaign
    const { loading, error, doWork } = useAutoLoadingError();

    const saveCampaign = () => {
        if (!campaignValue.isValid) {
            throw new AppError('Cannot save invalid campaign - this should not be enabled');
        }
        doWork(async (stopIfObsolete) => {

            // Save contact list
            const campaignObj: CampaignEdit<TCampaign> = {
                // New campaigns should use a null id
                id: campaignValue.id ?? null as any,
                kind: 'standard',

                name: campaignValue.name,
                description: campaignValue.description,

                fromPhoneNumber: campaignValue.fromPhoneNumber!,
                messages: {
                    message: {
                        id: campaignValue.message.id ?? null as any,
                        text: campaignValue.message.text,
                        additionalTexts: campaignValue.message.additionalTexts,
                        imageUrl: campaignValue.message.imageUrl,
                        linkUrl: campaignValue.message.linkUrl,
                        // This will be set
                        campaign: { id: null } as any,
                        campaignPath: 'messages.message',
                        campaignRole: 'Message',
                    },
                    schedule: {
                        startDate: campaignValue.schedule.startDate,
                        hourOfDay: campaignValue.schedule.hourOfDay,
                        absoluteTimeZone: campaignValue.schedule.absoluteTimeZone,
                        sendNow: campaignValue.schedule.sendNow,
                    },
                },
                toContactLists: campaignValue.toContactLists.map(x => x.id ? getReferenceFromId(x.id) : null).filter(x => x) as any,
            };
            if (!campaignObj.status?.createdTime) {
                campaignObj.status = {
                    isActive: true,
                    createdTime: Timestamp.now(),
                    lastActivityTime: Timestamp.now(),
                    nextActivityTime: Timestamp.now(),
                };
            }
            await store.api.saveCampaign(campaignObj);

            stopIfObsolete();
            showConfirmation();
        });
    };
    const { AlertHost, showAlert: showConfirmation } = useAlertModal({
        title: `Campaign Sent`,
        message: campaignValue.schedule.sendNow ? `Your campaign is being sent.` : `Your campaign will be sent.`,
        buttons: [
            { text: 'OK', onPress: () => { store.nav.ActiveCampaignsPage.open(); } },
        ]
    });

    return (
        <>
            <CampaignPreviewSaveArea
                store={store}
                campaign={{...campaignValue, status: campaignValue.status === 'new' ? 'new': 'inactive' }}
                campaignMessages={[
                    { title: 'Message', message: campaignValue.message },
                ]}
                campaignSendTime={campaignValue.schedule}
                saveCampaign={saveCampaign}
                buttonText={campaignValue.schedule.sendNow ? 'Send Now' : 'Send at Scheduled Time'}
                isDisabled={!campaignValue.isValid} loading={loading} error={error} />
            <AlertHost useFixed={true} />
        </>
    );
}

const messageTemplate = MessageTemplateField({
    templateId: 'message',
    label: 'Message',
    hint: 'The message that will be sent to your list(s).',
    placeholder: 'Enter broadcast message.',
    imageMode: 'optional', linkMode: 'optional',
    supportsMultipleMessages: true,
});

const EditCampaignPage_Standard_Inner = ({
    store,
    args,
    argsRoute,
}: {
    store: Store,
    args?: { campaign: TCampaign },
    argsRoute?: { campaignId: string },
}) => {

    const newCampaign = {
        isValid: false,
        status: 'new',
        id: undefined,
        kind: 'standard',
        name: '',
        description: '',
        toContactLists: [],
        message: {
            text: '',
            additionalTexts: [],
        },
        schedule: {
            startDate: DateOnly.today(),
            hourOfDay: '00:01' as TimeOnly,
            absoluteTimeZone: TimeZone.earliestTimeZone,
            sendNow: true,
        },
    } as TCampaignEdit;
    const [campaign, setCampaign] = useState(null as null | TCampaignEdit);

    const [fromPhoneNumberOptions, setFromPhoneNumberOptions] = useState([] as { value: string, label: string }[]);

    const [campaignLists, setCampaignLists] = useState([] as (ContactListModel & { contactsCount: number; })[]);

    const [useContactsTimeZone, setUseContactsTimeZone] = useState(false);
    const changeUseContactsTimeZone = (value: boolean) => {
        if (!campaign) { return; }

        const s = campaign.schedule ?? {};
        s.absoluteTimeZone = value ? undefined : (s.absoluteTimeZone ?? TimeZone.default_easternUs);
        campaign.schedule = s;
        validity['timeZone'] = true;

        setUseContactsTimeZone(value);
    };

    const [sendTimeMode, setSendTimeMode] = useState('now' as 'now' | 'later');
    const changeSentTimeMode = (value: 'now' | 'later') => {
        if (!campaign) { return; }

        if (value === 'now') {
            campaign.schedule = {
                startDate: DateOnly.today(),
                hourOfDay: '00:01' as TimeOnly,
                absoluteTimeZone: TimeZone.earliestTimeZone,
                sendNow: true,
            };
        } else {
            campaign.schedule = {
                startDate: DateOnly.today(),
                hourOfDay: TimeOnly.inNextHourTimeOnly(),
                absoluteTimeZone: TimeZone.default_easternUs,
                sendNow: false,
            };
        }

        setSendTimeMode(value);
    };
    const isTimeInPast = campaign && sendTimeMode === 'later' && campaign.schedule.startDate === DateOnly.today() && (campaign.schedule.hourOfDay ?? '24:00') <= TimeOnly.nowTimeOnly();

    // Load Campaign
    const { loading, error, doWork } = useAutoLoadingError();
    useEffect(() => {

        doWork(async (stopIfObsolete) => {

            // Get campaign lists
            const campaignListsResult = await store.api.getContactLists_withContactsCount();
            stopIfObsolete();
            setCampaignLists(campaignListsResult);

            // Get Phone
            const phoneNumbers = await store.api.getAccountPhoneNumbers();
            stopIfObsolete();

            setFromPhoneNumberOptions(phoneNumbers.map(x => ({ value: x.id, label: `${formatPhoneNumber_UsaCanada(x.phoneNumber)}` })));
            const fromPhoneNumber = getReferenceFromId(phoneNumbers[0].id);

            if (!args && !argsRoute) {
                setCampaign({ ...newCampaign, fromPhoneNumber });
                return;
            }

            const campaign_loaded = args?.campaign
                || argsRoute?.campaignId && await store.api.loadCampaign(getReferenceFromId(argsRoute.campaignId)).valueOrLoad() as TCampaign
                || null;
            if (!campaign_loaded) {
                setCampaign({ ...newCampaign, fromPhoneNumber });
                return;
            }
            stopIfObsolete();

            const contactLists = await store.api.loadItemsContactLists(campaign_loaded.toContactLists).valueOrLoad();
            const message = await store.api.loadMessageTemplate(campaign_loaded.messages.message).valueOrLoad();
            stopIfObsolete();

            setValidity({});
            setCampaign({
                isValid: true,
                status: campaign_loaded.status?.isActive ? 'active' : 'inactive',
                id: campaign_loaded.id,
                kind: 'standard',
                name: campaign_loaded.name,
                description: campaign_loaded.description,
                fromPhoneNumber: campaign_loaded.fromPhoneNumber,
                toContactLists: contactLists,
                schedule: campaign_loaded.messages.schedule,
                message: {
                    text: message.text,
                    additionalTexts: message.additionalTexts,
                    imageUrl: message.imageUrl,
                    linkUrl: message.linkUrl,
                },
            });


        });

    }, [args, argsRoute]);

    const [validity, setValidity] = useState({
        // Required fields
        name: false,
        message: false,
        toContactLists: false,
    } as { [key: string]: boolean });

    if (!campaign) {
        return (
            <MainLayout store={store}>
                {loading && <Loading loading={loading} />}
                {error && <ErrorBox error={error} />}
            </MainLayout>
        );
    }

    const updateCampaign = (update: () => { key: string, isValid: boolean }) => {
        const result = update();

        const newValidity = { ...validity, [result.key]: result.isValid };
        campaign.isValid = Object.values(newValidity).every(x => x);

        setValidity(newValidity);
        setCampaign({ ...campaign });
    };


    // Render
    return (
        <MainLayout store={store}>
            <CampaignCard store={store}>
                <TextView style={theme.card.cardTitle} text={campaign.id ? `Broadcast Campaign: '${campaign.name}'` : "Create Broadcast Campaign"} />
                <TextView style={theme.card.cardSummary} text="Send a broadcast message to your list(s)." />

                {(loading) && <Loading loading={loading} />}
                {(error) && <ErrorBox error={error} />}

                {!loading && (
                    <>
                        <TextField style={theme.card.textField}
                            field={{
                                __type: 'ui', label: 'Broadcast Name', isRequired: true,
                                hint: 'Provide a name for the broadcast campaign.',
                                placeholder: 'Enter a name for the campaign.'
                            }}
                            value={campaign.name}
                            onChangeValue={x => updateCampaign(() => {
                                campaign.name = x?.value ?? '';
                                return { key: 'name', isValid: x?.isValid ?? false };
                            })} />
                        <TextField style={theme.card.textField}
                            field={{
                                __type: 'ui', label: 'Broadcast Description', isRequired: false,
                                hint: 'Overview of the broadcast campaign.',
                                placeholder: 'Enter broadcast campaign description.'
                            }}
                            value={campaign.description}
                            onChangeValue={x => updateCampaign(() => {
                                campaign.description = x?.value ?? '';
                                return { key: 'description', isValid: true };
                            })} />

                        <MessageTemplateFieldEditorV2
                            style={store.theme.card.messageTemplateField}
                            field={messageTemplate}
                            value={campaign.message ?? { text: '' }}
                            onChangeValue={x => updateCampaign(() => {
                                campaign.message = x.message;
                                return { key: 'message', isValid: x.isValid };
                            })}
                            store={store}
                        />

                        <View style={{ marginTop: 32, marginBottom: 32 }}>
                            <CheckBoxControl store={store} useRadioIcon={true} label={`Send the Broadcast Message Now`} value={sendTimeMode === 'now'} onChange={() => changeSentTimeMode('now')} />
                            <CheckBoxControl store={store} useRadioIcon={true} label={`Send the Broadcast Message at a Later Time`} value={sendTimeMode === 'later'} onChange={() => changeSentTimeMode('later')} />
                            {sendTimeMode === 'later' && (
                                <View style={theme.card.rowWrap}>
                                    <View style={{ ...theme.row.cell, minWidth: 300 }}>
                                        <DateFieldEditor
                                            fieldKey="startDate"
                                            Control={DatePicker}
                                            store={store}
                                            field={{
                                                startDate: {
                                                    __type: 'ui',
                                                    isRequired: true,
                                                    label: 'Send on Date',
                                                    hint: 'Choose the date when you wish to send the message.',
                                                    defaultValue: () => DateOnly.today(),
                                                    validation: Validation_DateAfterOrOnToday(),
                                                    isMinDateToday: true,
                                                },
                                            }}
                                            value={{ startDate: { value: campaign.schedule.startDate, isValid: true } }}
                                            onChangeValue={(x) => {
                                                updateCampaign(() => {
                                                    campaign.schedule.startDate = x.startDate?.value ?? DateOnly.today();
                                                    return { key: 'startDate', isValid: x.startDate?.isValid ?? false };
                                                });
                                            }}
                                        />
                                    </View>
                                    <View style={{ ...theme.row.cell, minWidth: 300 }}>
                                        <HourOfDayEditor
                                            fieldKey="hourOfDay"
                                            Control={TimePicker}
                                            store={store}
                                            field={{
                                                hourOfDay: {
                                                    __type: 'ui',
                                                    isRequired: true,
                                                    label: 'Time to Send Message',
                                                    hint: 'This is the time that you wish to send the message. You can either send all of the messages at the same time or you can check the “Adjust Time Zone Based on Recipient’s Area Code” below.',
                                                },
                                            }}
                                            value={{ hourOfDay: { value: campaign.schedule.hourOfDay, isValid: true } }}
                                            onChangeValue={(x) => {
                                                updateCampaign(() => {
                                                    campaign.schedule.hourOfDay = x.hourOfDay?.value;
                                                    return { key: 'hourOfDay', isValid: x.hourOfDay?.isValid ?? false };
                                                });
                                            }}
                                        />
                                        {isTimeInPast && (
                                            <TextView style={theme.card.warnTextView} text='Time is in the past, so messages may be sent immediately.' />
                                        )}
                                        <CheckBoxControl store={store}
                                            label={`Adjust Time Zone Based on Recipient’s Area Code`} value={useContactsTimeZone} onChange={changeUseContactsTimeZone}
                                            hint={useContactsTimeZone ? 'For example, if you are in the eastern time zone and you wish to send your broadcast message at 7pm, you can choose to send it at 7pm for all recipients, regardless of what time zone they are in. The time zone is based on the area code of the recipient, not where the recipient is located now.' : ''} />
                                        <TextView style={theme.card.cardSummary}
                                            text={useContactsTimeZone ? 'Messages will be sent at the time noted above, but will be delayed or sent earlier based on the area code of the recipient.' : ``}
                                        />
                                        {!useContactsTimeZone && (
                                            <OptionField style={theme.card.optionField}
                                                label="Time Zone"
                                                options={async () => TimeZone.options.map(x => ({ ...x, value: x.value + '' }))}
                                                value={campaign.schedule?.absoluteTimeZone + '' ?? ''}
                                                onChangeValue={(value) => {
                                                    updateCampaign(() => {
                                                        const s = campaign.schedule ?? {};
                                                        s.absoluteTimeZone = value?.value ? parseInt(value.value, 10) as TimeZone : undefined;
                                                        campaign.schedule = s;
                                                        if (!value?.value) { changeUseContactsTimeZone(true); }

                                                        return { key: 'timeZone', isValid: value?.isValid ?? false };
                                                    });
                                                }}
                                            />
                                        )}
                                    </View>
                                </View>
                            )}
                        </View>
                    </>
                )}

                {!loading && (
                    <>
                        <ContactListsEditorV2
                            store={store}
                            options={campaignLists}
                            value={campaign.toContactLists}
                            onChange={value => {
                                updateCampaign(() => {
                                    campaign.toContactLists = value.filter(x => x.id);
                                    return { key: 'toContactLists', isValid: campaign.toContactLists.length > 0 };
                                });
                            }}
                        />
                        <CampaignPhoneNumber fromPhoneNumberOptions={fromPhoneNumberOptions} />

                        {/* <Text>
                            {JSON.stringify(validity)}
                        </Text>
                        <Text>
                            {JSON.stringify(campaign)}
                        </Text> */}

                        <CampaignResultViewer store={store} campaignValue={campaign} />
                    </>
                )}
            </CampaignCard>
        </MainLayout>
    );
};



const ContactListsEditorV2 = (props: {
    store: Store,
    options: (ContactListModel & { contactsCount: number; })[],
    value: ContactListModel_Edit[],
    onChange: (value: ContactListModel_Edit[]) => void,
}) => {
    const theme = props.store.theme;

    const getContactList = (i: number) => {
        return props.value[i].id || null;
    };

    return (
        <ListEditor
            store={props.store}
            label="Send to the Following List(s)"
            hint='You can choose multiple lists by clicking on the plus button to the right.'
            items={props.value}
            renderItem={({ i }) => (
                <View style={{ flexGrow: 1 }}>
                    <OptionField style={theme.card.optionField}
                        options={async () => props.options
                            .filter(x => props.value[i].id === x.id || !props.value.find(v => v.id === x.id))
                            .map(x => ({ value: x.id!, label: `${x.name} (${x.contactsCount} Contacts)` }))}
                        value={getContactList(i)}
                        onChangeValue={value => {
                            const newContactList = props.options.find(x => x.id === value?.value);
                            if (!newContactList) {
                                props.onChange(props.value);
                                return;
                            }

                            const v = [...props.value];
                            v[i] = { id: newContactList.id };
                            props.onChange(v);
                        }}
                        noOptionsMessage={'No Campaign Lists - Create a Campaign List First'}
                    />
                </View>
            )}
        />
    );
};
