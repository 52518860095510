import React, { useState, useRef, useEffect } from 'react';
import { Store } from '../../store/Store';
import { MainLayout } from '../../components/Layout';
import { CampaignCard, BuilderDebugInfo } from '../Campaigns/EditCampaignComponents';
import { TextView, TextField, ButtonTextView } from '../../components/TextView';
import { theme } from '../../style/Theme';
import { Builder, ValidValue, BuilderResult } from '../../../utils/Builder';
import { Validation_MaxLengthRequired, Validation_MaxLengthRequiredRegex } from '../../../utils/Validation';
import { delay, setValueWithCallback, SetValueWithCallback } from '../../../utils/Async';
import { View, TouchableOpacity, ActivityIndicator } from 'react-native';
import { buildBuilder } from '../../../utils/BuilderFieldDefinitions';
import { useAutoLoadingError, useLoadData } from '../../../utils/Hooks';
import { getReference, PhoneNumber, Reference } from '../../../data-types/SimpleDataTypes';
import { formatPhoneNumber_UsaCanada } from '../../../utils/PhoneNumbers';
import { phone } from 'faker';
import { phoneNumberMask_UsaCanada } from '../../../lib/utils/phone-number';
import { ErrorBox } from '../../../lib/controls-react/error-box';
import { useAlertModal } from '../../components/AlertModal';
import { AccountPhoneNumberModel } from '../../../data-types/ModelTypes';

const PhoneNumberSearch = (props: { store: Store, onSelectNumber: (phoneNumber: PhoneNumber) => void }) => {

    const { theme } = props.store;

    const [vanityInputText, setVanityInputText] = useState('');
    const [vanityNumbersText, setVanityNumbersText] = useState('');
    const [phoneNumbersVanity, setPhoneNumbersVanity] = useState(null as null | PhoneNumber[]);
    const [phoneNumbers, setPhoneNumbers] = useState([] as PhoneNumber[]);

    const { loading, error, doWork } = useAutoLoadingError();

    const changeVanityInputText = (value: string) => {
        setVanityInputText(value);
        const numbersText = value
            .replace(/[1]/gi, '1')
            .replace(/[2ABC]/gi, '2')
            .replace(/[3DEF]/gi, '3')
            .replace(/[4GHI]/gi, '4')
            .replace(/[5JKL]/gi, '5')
            .replace(/[6MNO]/gi, '6')
            .replace(/[7PQRS]/gi, '7')
            .replace(/[8TUV]/gi, '8')
            .replace(/[9WXYZ]/gi, '9')
            .replace(/[^0-9]/gi, '0')
            ;
        setVanityNumbersText(numbersText);
    };

    const searchPhoneNumbers = () => doWork(async (stopIfObsolete) => {
        // try {
        const results = await props.store.api.searchPhoneNumbers(vanityNumbersText);
        stopIfObsolete();

        const sorted = [...results]
            .map(x => ({ item: x, order: ((99 - phoneNumberMask_UsaCanada(x).digitsNo1.indexOf(vanityNumbersText)) + '').padStart(2, '0') + ':' + phoneNumberMask_UsaCanada(x).digitsNo1 }))
            .sort((a, b) => a.order.localeCompare(b.order))
            .map(x => x.item);

        if (vanityNumbersText) {
            setPhoneNumbersVanity(sorted);
        } else {
            //setPhoneNumbers(results);
            setPhoneNumbers(sorted);
        }
        // } catch (err) {
        //     console.log('PhoneNumberSearch.searchPhoneNumbers ERROR', { err });
        //     setPhoneNumbersVanity([]);

        //     // Find other numbers
        //     if (!phoneNumbers.length) {
        //         const results = await props.store.api.searchPhoneNumbers('');
        //         stopIfObsolete();

        //         setPhoneNumbers(results);
        //     }
        // }
    });

    const selectNumber = (phoneNumber: PhoneNumber) => {
        setVanityInputText('');
        setPhoneNumbers([]);
        props.onSelectNumber(phoneNumber);
    };

    useEffect(() => {
        // Load some numbers by default
        searchPhoneNumbers();
    }, []);

    return (
        <>
            <TextField style={theme.card.textField}
                field={{
                    __type: 'ui',
                    isRequired: false,
                    label: 'Vanity Number Search',
                    hint: 'It is unlikely that you are going to find a 7-digit vanity number. Instead, try searching for the final four numbers only. Try to avoid using “O” in your search as it can cause confusion with Operator since “O” is on the 6.',
                    validation: Validation_MaxLengthRequiredRegex({
                        isRequired: false,
                        maxLength: 7,
                        pattern: /^[0-9A-Za-z]{4,7}$/,
                        patternFailMessage: 'Must be 4-7 digits or letters',
                    }),
                }}
                value={vanityInputText}
                onChangeValue={value => changeVanityInputText(value?.value ?? '')} />

            <View style={theme.card.buttonRow}>
                <View style={theme.card.buttonSpacer} />
                {/* <TextView style={theme.card.cardTextInline} text={vanityInputText + ' '} /> */}
                <TextView style={theme.card.cardTextInline} text={vanityNumbersText + ' '} />
                <ButtonTextView style={theme.card.buttonTextView_major} text="Search" onPress={searchPhoneNumbers} isDisabled={loading} />
            </View>
            {loading && <ActivityIndicator />}


            {/* Results */}
            {phoneNumbersVanity && !phoneNumbersVanity.length && (
                <TextView style={theme.card.infoTextView} text="No numbers found for that pattern" />
            )}
            {phoneNumbersVanity && !!phoneNumbersVanity.length && phoneNumbersVanity.map(x => {
                const text = `  ${formatPhoneNumber_UsaCanada(x)}  `;
                return (
                    <View key={x} style={theme.card.buttonRow}>
                        <ButtonTextView style={theme.card.buttonTextView_minor} text={text} onPress={() => selectNumber(x)} />
                        <View style={theme.card.buttonSpacer} />
                    </View>
                );
            })}

            <TextView style={{ ...theme.card.cardSummary, view: { ...theme.card.cardSummary.view, marginTop: 16 } }} text="Other Numbers" />
            {!!phoneNumbers.length && phoneNumbers.map(x => (
                <View key={x} style={theme.card.buttonRow}>
                    <ButtonTextView style={theme.card.buttonTextView_minor} text={`  ${formatPhoneNumber_UsaCanada(x)}  `} onPress={() => selectNumber(x)} />
                    <View style={theme.card.buttonSpacer} />
                </View>
            ))}

        </>
    );
};

const PhoneNumberDetailsEditorView = (props: { store: Store }) => {

    const [renderId, setRenderId] = useState(0);

    const { loading, error, doWork } = useAutoLoadingError();
    const purchasePhoneNumber = (phoneNumber: PhoneNumber) => doWork(async (stopIfObsolete) => {
        if (props.store.api.getAccountState().hasPhoneNumber) {
            // Disable purchasing 2nd number
            showConfirmation();
            return;
        }

        await props.store.api.purchasePhoneNumber(phoneNumber);
        const accountPhoneNumbersResult = await props.store.api.getAccountPhoneNumbers();
        const accountPhoneNumber = accountPhoneNumbersResult.find(x => x.phoneNumber === phoneNumber);

        if (!accountPhoneNumber) {
            // Warn user and refresh phone number list
            showFailConfirmation();
            return;
        }

        await confirmPurchaseStatus(getReference(accountPhoneNumber), stopIfObsolete);
    });

    const confirmPurchaseStatus = async (accountPhoneNumber: Reference<AccountPhoneNumberModel>, stopIfObsolete: () => void, attempt = 0) => {
        const result = await props.store.api.updatePhoneNumberStatus(accountPhoneNumber);

        stopIfObsolete();

        if (result.orderStatus === 'success') {
            showConfirmation();
            return;
        }

        if (attempt > 10 || result.orderStatus === 'fail') {
            // Warn user and refresh phone number list
            showFailConfirmation();
            // throw new AppError('Purchase Failed - Try another number');
            return;
        }

        await delay(1000);
        confirmPurchaseStatus(accountPhoneNumber, stopIfObsolete, attempt + 1)
    };

    const { AlertHost, showAlert: showConfirmation } = useAlertModal({
        title: `Account Saved`,
        message: `Your account has been saved.`,
        buttons: [
            { text: 'OK', onPress: () => { props.store.nav.EditCampaignPage_Interactive.open(undefined); } },
        ]
    });

    const { AlertHost: AlertHostFail, showAlert: showFailConfirmation } = useAlertModal({
        title: `Purchase Failed`,
        message: `The phone number was not purchased - try another number.`,
        buttons: [
            { text: 'OK', onPress: () => { props.store.nav.EditCampaignPage_Interactive.open(undefined); } },
        ]
    });

    const phoneNumbers = useLoadData(doWork, props.store.api.getAccountPhoneNumbers, () => [renderId]);

    return (
        <CampaignCard store={props.store}>
            <TextView style={theme.card.cardTitle} text="Select Phone Number" />
            <TextView style={theme.card.cardSummary} text="Select a phone number to be used with your account. This number will be used for interactive and broadcast messages." />

            {/* {!phoneNumbers.data?.length && (
                <TextView style={theme.card.infoTextView} text="You have not yet selected a phone number." />
            )} */}

            {phoneNumbers.data && phoneNumbers.data.length > 0 && phoneNumbers.data.map(x => (
                <View key={x.id} style={theme.card.view_white}>
                    <View key={x.id} style={theme.card.row}>
                        <TextView style={theme.card.cardSummary} text={formatPhoneNumber_UsaCanada(x.phoneNumber)} />
                        {x.orderStatus === 'fail' && <TextView style={theme.card.cardSummary} text={`The number was not purchased, try a different number.`} />}
                        {x.orderStatus === 'pending' && <TextView style={theme.card.cardSummary} text={`The number purchase is still processing.`} />}
                    </View>
                </View>
            ))}

            <View style={{ height: 64 }} />
            <TextView style={theme.card.cardSummary}
                text="Search our database of numbers to find the perfect number. You can enter up to 7 digits, but it’s best to use only 4. You can enter the numbers you desire or you can enter the vanity word that you wish to find."
            />

            {!loading && (
                <PhoneNumberSearch store={props.store} onSelectNumber={purchasePhoneNumber} />
            )}
            {error && <ErrorBox error={error} />}
            {loading && <ActivityIndicator />}

            <AlertHost useFixed={true} />
            <AlertHostFail useFixed={true} />
        </CampaignCard>
    );
};


export const PhoneNumberEditorPage = (props: { store: Store }) => {
    return (
        <MainLayout store={props.store}>
            <PhoneNumberDetailsEditorView store={props.store} />
        </MainLayout>
    );
}