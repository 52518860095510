import moment from 'moment';
import { LogicError } from './Errors';

export type DateOnly = string & {
    __type: 'DateOnly';
    __format: 'YYYY-MM-DD';
};

export type TimeOnly = string & {
    _type: 'TimeOnly';
    _format: 'HH:mm';
};

export type Timespan = number & {
    __type: 'Timespan';
};
export type Timestamp = number & {
    __type: 'Timestamp';
};

export enum DayOfWeek {
    sunday = 'sunday',
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
}
export type DayOfMonth = number & { __type: 'DayOfMonth' };
export type TimeZone = number & { __type: 'deliveryTimeZone' };

export const TimeZone = {
    formatTimeZone: (timeZone: TimeZone): string => {
        return TimeZone.options.find(x => x.value === timeZone)?.label ?? '';
    },
    calculateTime(date: DateOnly, hourOfDay: TimeOnly, timeZone: TimeZone): Timestamp {
        const dateTimeAtYearStart = new Date(`${date.substr(0, 4)}-01-01 ${hourOfDay}:00.000-05:00`).toLocaleString('en-US', { timeZone: 'America/New_York' });
        const dateTimeAtTargetDate = new Date(`${date} ${hourOfDay}:00.000-05:00`).toLocaleString('en-US', { timeZone: 'America/New_York' });
        const timeAtYearStart = dateTimeAtYearStart.substr(dateTimeAtYearStart.indexOf(','));
        const timeAtTargetDate = dateTimeAtTargetDate.substr(dateTimeAtTargetDate.indexOf(','));

        const shouldAddOneHour = timeAtYearStart !== timeAtTargetDate;
        const timeZoneCorrected = shouldAddOneHour ? timeZone + 1 : timeZone;
        const isoTime = `${date} ${hourOfDay}:00.000${`${timeZoneCorrected < 0 ? `-` : `+`}${Math.abs(timeZoneCorrected)}`.padStart(2, `0`)}:00`;
        // console.log('isoTime', { isoTime, dateTimeAtYearStart, dateTimeAtTargetDate, timeAtYearStart, timeAtTargetDate, timeZone, timeZoneCorrected });
        const dateTime = new Date(isoTime);
        return dateTime.getTime() as Timestamp;
    },
    default_easternUs: -5 as TimeZone,
    earliestTimeZone: 12 as TimeZone,
    options: [
        // Based on standard time
        { value: -4 as TimeZone, label: 'Atlantic Time' },
        { value: -5 as TimeZone, label: 'Eastern Time' },
        { value: -6 as TimeZone, label: 'Central Time' },
        { value: -7 as TimeZone, label: 'Mountain Time' },
        { value: -8 as TimeZone, label: 'Pacific Time' },
        { value: -9 as TimeZone, label: 'Alaska Time' },
        { value: -10 as TimeZone, label: 'Hawaii Time' },
    ] as { value: TimeZone, label: string }[],
};

// setTimeout(() => {
//     const result = TimeZone.calculateTime('2020-10-27' as DateOnly, '8:30' as TimeOnly, -6 as TimeZone);
//     const d = new Date(result);
//     console.log('d', { d });
//     const debug = true;
// });

export const Timespan = {
    difference(a: Timestamp, b: Timestamp) {
        return Math.abs(a - b) as Timespan;
    },
    toDays(time: Timespan) {
        return time / (1000 * 60 * 60 * 24);
    },
    toSeconds(time: Timespan) {
        return time / 1000;
    },
};

export const Timestamp = {
    toTimestamp(time: Date) { return time.getTime() as Timestamp; },
    formatTimestamp(time: Timestamp | null | undefined) {
        if (!time) { return ''; }
        return moment(time).calendar(null as any, {
            sameElse: 'l [at] LT'
        });
    },
    formatTimestamp_asDateOnly(time: Timestamp | null | undefined) {
        if (!time) { return ''; }
        return moment(time).format('l');
    },
    toDateOnly(time: Timestamp | null | undefined) {
        if (!time) { return null; }
        return DateOnly._momentToDateOnly(moment(time));
    },
    fromDateOnly(time: DateOnly | null | undefined) {
        if (!time) { return null; }
        const d = DateOnly.toDate(time);
        return Timestamp.toTimestamp(d);
    },
    fromDateTime(date: DateOnly, time: TimeOnly | null | undefined, timeZone: TimeZone | null | undefined): Timestamp {
        if (timeZone) {
            return TimeZone.calculateTime(date, time ?? TimeOnly.nowTimeOnly(), timeZone);
        }
        const d = moment(date + ' ' + time ?? TimeOnly.nowTimeOnly()).toDate();
        return Timestamp.toTimestamp(d);
    },

    now: () => Timestamp.toTimestamp(new Date()),

    addSeconds: (time: Timestamp, deltaSeconds: number) => (time + deltaSeconds * 1000) as Timestamp,
    addHours(time: Timestamp, detalHours: number): Timestamp {
        return Timestamp.toTimestamp(moment(time).add(detalHours, 'hours').toDate());
    },
    addDays(time: Timestamp, deltaDays: number): Timestamp {
        return Timestamp.toTimestamp(moment(time).add(deltaDays, 'days').toDate());
    },
    addMonths(time: Timestamp, deltaMonths: number): Timestamp {
        return Timestamp.toTimestamp(moment(time).add(deltaMonths, 'months').toDate());
    },
};

export const DateOnly = {
    _momentToDateOnly(m: moment.Moment) { return m.format('YYYY-MM-DD') as DateOnly; },
    addDays(dateOnly: DateOnly, days: number): DateOnly {
        return DateOnly._momentToDateOnly(moment(dateOnly).add(days, 'days'));
    },
    addMonths(dateOnly: DateOnly, months: number): DateOnly {
        return DateOnly._momentToDateOnly(moment(dateOnly).add(months, 'months'));
    },
    daysBetween(a: DateOnly, b: DateOnly) {
        return moment(a).diff(moment(b), 'days') + 1;
    },
    getDayOfWeek(dateOrDateOnly: Date | DateOnly) {
        const date = DateOnly.toDate(dateOrDateOnly);
        const d = date.getDay();
        switch (d) {
            case 0: return DayOfWeek.sunday;
            case 1: return DayOfWeek.monday;
            case 2: return DayOfWeek.tuesday;
            case 3: return DayOfWeek.wednesday;
            case 4: return DayOfWeek.thursday;
            case 5: return DayOfWeek.friday;
            case 6: return DayOfWeek.saturday;
            default: throw new LogicError('Unknown day of week');
        }
    },
    getDayOfMonth(dateOrDateOnly: Date | DateOnly) {
        const date = DateOnly.toDate(dateOrDateOnly);
        const d = date.getDate();
        return d;
    },
    getStartOfMonth(date: DateOnly) {
        return DateOnly._momentToDateOnly(moment(date).subtract(DateOnly.toDate(date).getDate() - 1, 'days'));
    },
    getStartOfWeek(date: DateOnly) {
        return DateOnly._momentToDateOnly(moment(date).subtract(DateOnly.toDate(date).getDay(), 'days'));
    },
    getEndOfMonth(date: DateOnly) {
        const d = DateOnly.toDate(date);
        // Day zero of next month is last day of this month (this works even if wrapping months past year also)
        const lastDayInMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0);
        return DateOnly._toDateOnlyFromDate(lastDayInMonth);
    },
    getEndOfWeek(date: DateOnly) {
        return DateOnly._momentToDateOnly(moment(date).add(6 - DateOnly.toDate(date).getDay(), 'days'));
    },
    getMonthName(date: DateOnly) {
        return moment(date).format('MMMM');
    },


    // export function formatDateOnly(time: Date) {
    //     return moment.utc(time).format('L');
    // }
    formatDateOnly(dateOnly: DateOnly) {
        const date = new Date(DateOnly.toIsoDateString(dateOnly));
        return moment.utc(date).format('L');
    },

    parseDateOnly(text: string): Date | null {
        let m = moment.utc(text);
        if (!m.isValid()) { return null; }

        // Use this year as default
        if (m.year() < moment().year()) {
            m = m.year(moment().year());
        }

        return m.startOf('day').toDate();
    },

    toIsoDateString(v: DateOnly): string { return v; },
    fromIsoDateString(v: string): DateOnly { return v as DateOnly; },

    toDate(date: Date | DateOnly): Date {
        // const dateOnly = DateOnly._dateOnlyRoundTrip(date as DateOnly) ?? DateOnly._toDateOnlyFromDate(date as Date);
        // const d = new Date(DateOnly.toIsoDateString(dateOnly!));
        // return d;
        return moment(date).toDate();
    },
    toDateOnly(date: Date | DateOnly): DateOnly {
        return DateOnly._dateOnlyRoundTrip(date as DateOnly) ?? DateOnly._toDateOnlyFromDate(date as Date)!;
    },
    _toDateOnlyFromDate(date: Date): DateOnly {
        const d = date.toISOString();
        const v = DateOnly.fromIsoDateString(d.split('T')[0]);
        return v;
    },
    today(): DateOnly {
        // Ignore Local Time
        const date = new Date();
        const d = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
        const v = DateOnly.fromIsoDateString(d.split('T')[0]);
        return v;
    },

    _dateOnlyRoundTrip(dateOnly: DateOnly): DateOnly | null {
        try {
            const d = new Date(DateOnly.toIsoDateString(dateOnly));
            const v = DateOnly._toDateOnlyFromDate(d);
            return v;
        } catch { return null; }
    },
    isValidDateOnly(dateOnly: DateOnly) {
        const v = DateOnly._dateOnlyRoundTrip(dateOnly);
        return dateOnly === v;
    },
};

export const TimeOnly = {
    formatTimeOnly(time: TimeOnly) {
        return moment(DateOnly.today() + ' ' + time).format('LT');
    },
    formatHourOfDay(hourOfDay: number) {
        const hour = hourOfDay | 0;
        const minutes = hourOfDay * 60 % 60 | 0;
        return moment(new Date(2000, 1, 1, hour, minutes, 0)).format('LT');
    },
    parseHourOfDay(text: string): number | null {
        const m = moment(text, moment.HTML5_FMT.TIME);
        if (!m.isValid()) { return null; }
        const hour = m.hour();
        const minutes = m.minutes();
        return hour + minutes / 60;
    },

    nowTimeOnly(): TimeOnly {
        return moment().format('HH:mm') as TimeOnly;
    },
    inNextHourTimeOnly(): TimeOnly {
        return (moment().add(1, 'hour').format('HH:mm').substr(0, 2) + ':00') as TimeOnly;
    },
    nowHourOfDay(): number {
        return moment().hour();
    },

    toIsoTimeHoursMinutesString(v: TimeOnly): string { return v; },
    fromIsoTimeHoursMinutesString(v: string): TimeOnly {
        // Only get hours and minutes
        const p = v.split(':');
        return `${p[0]}:${p[1]}` as TimeOnly;
    },
    toTimeOnly(date: Date): TimeOnly | null {
        try {
            return moment(date).format('HH:mm') as TimeOnly;
        } catch { return null; }
    },
    toDate(time: TimeOnly): Date | null {
        try {
            return moment(DateOnly.today() + ' ' + time).toDate();
        } catch { return null; }
    },
    timeOnlyRoundTrip(timeOnly: TimeOnly): TimeOnly | null {
        try {
            const d = new Date(`2020-01-01T${TimeOnly.toIsoTimeHoursMinutesString(timeOnly)}:00Z`);
            const v = TimeOnly.toTimeOnly(d);
            return v;
        } catch { return null; }
    },
    isValidTimeOnly(timeOnly: TimeOnly) {
        const v = TimeOnly.timeOnlyRoundTrip(timeOnly);
        return timeOnly === v;
    },
};


// export function convertDateLocalToDateOnlyUtc(dateLocal: Date): DateOnly {
//     return moment(dateLocal).startOf('day').utc(false).toDate();
// }

// export function convertDateOnlyUtcToDateLocal(dateOnly: DateOnly): Date {
//     return moment.utc(dateOnly).startOf('day').local(false).toDate();
// }

// export function convertDateLocalToTimeOnly(date: Date): TimeOnly {
//     const hour = date.getHours() | 0;
//     const minutes = date.getMinutes() | 0;
//     return hour + minutes / 60;
// }

// export function convertTimeOnlyToDateLocal(hourOfDay: TimeOnly): Date {
//     const hour = hourOfDay | 0;
//     const minutes = hourOfDay * 60 % 60 | 0;
//     return moment(new Date(2000, 1, 1, hour, minutes, 0).getUTCDate()).toDate();
// }
