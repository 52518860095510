import React, { useState } from 'react';
import { View, Text, ActivityIndicator } from 'react-native';
import { Store } from '../../store/Store';
import { useAutoLoadingError } from '../../../utils/Hooks';
import { ContactModel, ContactListModel, ContactModel_Edit } from '../../../data-types/ModelTypes';
import { ButtonTextView, TextView, TextField } from '../../components/TextView';
import { ErrorBox } from '../../components/ErrorBox';
import { Validation_PhoneNumber_UsaCanada } from '../../../utils/Validation';
import { formatPhoneNumber_UsaCanada, toStandardPhoneNumber, toStandardPhoneNumber_optional } from '../../../utils/PhoneNumbers';
import { useAlertModal } from '../../components/AlertModal';
import { PhoneNumber } from '../../../data-types/SimpleDataTypes';
import { getContactStatusText } from '../../../data-types/contactStatus';

export const EditContact = (props: {
    store: Store, contactList?: ContactListModel,
    item: ContactModel, onItemChanged: (item: ContactModel) => void,
    onCancelEdit?: () => void, onItemRemoved?: (item: ContactModel) => void
}) => {

    const { store, item: itemInit } = props;
    const { theme } = store;

    const { loading, error, doWork } = useAutoLoadingError();
    const [item, setItem] = useState({ ...itemInit });
    const [itemEdit, setItemEdit] = useState({ ...itemInit } as ContactModel_Edit);

    const saveChanges = () => {
        doWork(async () => {
            const result = await store.api.setContactData(item, itemEdit);
            setItem(result);
            props.onItemChanged(result);
        });
    };

    const { AlertHost, showAlert: showRemove } = useAlertModal({
        title: `Remove Contact - ${formatPhoneNumber_UsaCanada(item.phoneNumber)}`,
        message: `Are you sure you want to remove this contact - ${formatPhoneNumber_UsaCanada(item.phoneNumber)}`,
        buttons: [
            { text: 'Cancel', onPress: () => { } },
            { text: 'Remove', onPress: () => removeItem(), webStyle: theme.alertModalWeb.buttonDanger },
        ]
    });
    const removeItem = () => {
        doWork(async (stopIfObsolete) => {
            if (props.contactList) {
                await store.api.removeContactFromList(props.contactList!, item);
                stopIfObsolete();
            } else {
                await store.api.removeContactFromAllLists(item);
                stopIfObsolete();
            }

            // setItem(result);
            props.onItemRemoved!(item);
        });
    };

    const changeItemValue = (value: ContactModel_Edit) => {
        setItemEdit(x => ({ ...x, ...value }));
    };

    return (
        <View style={theme.card.view_white}>

            <View style={theme.card.row}>
                <View style={{ flex: 1 }}>
                    <TextView style={theme.card.cardTitle} text={`Edit Contact - ${formatPhoneNumber_UsaCanada(item.phoneNumber)}`} />
                </View>
                <View>
                    <Text style={theme.card.fieldLabelText}>Status: {getContactStatusText(item.status)}</Text>
                </View>
            </View>

            <TextField style={theme.card.textField}
                field={{
                    __type: 'ui',
                    isDisabled: true,
                    isRequired: false, label: 'Phone Number', placeholder: 'Phone Number', validation: Validation_PhoneNumber_UsaCanada()
                }}
                value={formatPhoneNumber_UsaCanada(itemEdit.phoneNumber ?? '')}
                onChangeValue={x => changeItemValue({ phoneNumber: toStandardPhoneNumber_optional(x?.value) })} />
            <TextField style={theme.card.textField}
                field={{ __type: 'ui', isRequired: false, label: 'First Name', placeholder: 'First Name' }}
                value={itemEdit.firstName}
                onChangeValue={x => changeItemValue({ firstName: x?.value ?? '' })} />
            <TextField style={theme.card.textField}
                field={{ __type: 'ui', isRequired: false, label: 'Last Name', placeholder: 'Last Name' }}
                value={itemEdit.lastName}
                onChangeValue={x => changeItemValue({ lastName: x?.value ?? '' })} />

            <View style={theme.card.buttonRow}>
                {props.onItemRemoved && !!props.contactList && (<ButtonTextView style={theme.card.buttonTextView_danger} text="Remove" onPress={showRemove} />)}
                {props.onItemRemoved && !props.contactList && (<ButtonTextView style={theme.card.buttonTextView_danger} text="Remove from All Lists And Disable" onPress={showRemove} />)}
                <View style={theme.card.buttonSpacer}></View>
                {props.onCancelEdit && (<ButtonTextView style={theme.card.buttonTextView_minor} text="Cancel" onPress={props.onCancelEdit} />)}
                <ButtonTextView style={theme.card.buttonTextView_major} text="Save" onPress={saveChanges} />
            </View>

            {loading && <ActivityIndicator size="large" />}
            {error && <ErrorBox error={error} />}

            <AlertHost />
        </View>
    );
};


