import { Validation } from './Validation';

export const Gsm = {
    // FROM: https://stackoverflow.com/a/12673229/567524
    isGsm7(text: string) {
        const regex = new RegExp('[^A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!"#$%&\'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]', 'g');
        const textWithGsmCharsReplaced = text.replace(regex, '*');
        const textWithoutGsmChars = text.replace(regex, '');
        return {
            isPlainText: textWithoutGsmChars.length === text.length,
            textWithGsmCharsReplaced,
        };
    },
    validation: (isRequired: boolean, getMode: () => 'sms' | 'mms', supportsMultipleMessages = false) => {
        // SSM/MMS MaxLength = 160;
        // 160 GSM-7 characters or 67 UCS-2 characters
        const GSM_MAX_LENGTH = 160;
        const UCS_MAX_LENGTH = 67;

        const checkText = (text: string) => {
            const { isPlainText, textWithGsmCharsReplaced } = Gsm.isGsm7(text);
            const maxLength = isPlainText ? GSM_MAX_LENGTH : UCS_MAX_LENGTH;
            return {
                isPlainText,
                maxLength,
                textWithGsmCharsReplaced,
            };
        };

        const result: Validation<string> = {
            getInitialMessage: () => getMode() === 'sms' ? `Max Length is ${GSM_MAX_LENGTH}` : '',
            validate: (text: string) => {

                const { isPlainText, maxLength, textWithGsmCharsReplaced } = checkText(text);

                // Empty
                if (isRequired && !text.length) {
                    return {
                        isValid: false,
                        message: 'Required'
                    };
                }

                // No max length for mms
                if (getMode() === 'mms') {
                    return {
                        isValid: true,
                        message: ''
                    };
                }

                // Too Long
                if (text.length > maxLength) {
                    if (!isPlainText) {
                        return {
                            isValid: false,
                            message: `Plain text: ${textWithGsmCharsReplaced}\n\nThe text with special characters is too long: ${text.length} out of ${maxLength} characters`,
                        };
                    } else {
                        return {
                            isValid: false,
                            message: `${supportsMultipleMessages ? `A text message that is longer than the allowed length will require another text message. ` : 'Too long: '}${text.length} out of ${maxLength} characters.`,
                        };
                    }
                }

                // Ok
                if (!isPlainText) {
                    return {
                        isValid: true,
                        message: `Plain text: ${textWithGsmCharsReplaced}\n\n${text.length} out of ${maxLength} characters  - special characters reduce the max length`,
                    };
                } else {
                    return {
                        isValid: true,
                        message: `${text.length} out of ${maxLength} characters`,
                    };
                }
            }
        };

        return result;
    }
};
