import React, { useState, useEffect, useRef } from 'react';
import { ActivityIndicator, TouchableOpacity, View, Image } from 'react-native';
import { CampaignModel_Common, CampaignStats, ContactListModel, ContactModel, MessageContentAll, MessageDeliveryModel, MessageReplyModel, MessageTemplateModel, MessageThread } from '../../../../data-types/ModelTypes';
import { getReferenceFromId, getReferenceOrUndefined, PhoneNumber, Reference, ReferenceList } from '../../../../data-types/SimpleDataTypes';
import { formatDate } from '../../../../lib/utils/dates';
import { useAutoLoadingError, useLoadData } from '../../../../utils/Hooks';
import { formatPhoneNumber_UsaCanada } from '../../../../utils/PhoneNumbers';
import { Timestamp } from '../../../../utils/Time';
import { ErrorBox } from '../../../components/ErrorBox';
import { MainLayout } from '../../../components/Layout';
import { List } from '../../../components/List';
import { ButtonTextView, TextView } from '../../../components/TextView';
import { Store } from '../../../store/Store';
import { NavLinks } from '../../Common/NavLinks';
import { CampaignLink, ContactListLink, ContactListLinks } from '../../Common/ReferenceLinks';
import { DirectMessage, MessageItem } from '../../Messages/ViewMessageThread';


// export const CampaignMessages = <TCampaign extends CampaignModel_Common>(props: {
//     store: Store, campaign: TCampaign,
// }) => {
//     const { store, campaign } = props;
//     const { loading, error, doWork } = useAutoLoadingError();

//     const [campaignReplies, setCampaignReplies] = useState(null as null | MessageThread);

//     useEffect(() => {
//         doWork(async (stopIfObsolete) => {
//             const campaignReplies = await store.api.getCampaignReplies(campaign);
//             stopIfObsolete();

//             setCampaignReplies(campaignReplies ?? null);
//         });
//     }, []);

//     return (
//         <View style={{ margin: 8 }}>
//             {loading && <ActivityIndicator />}
//             {error && <ErrorBox error={error} />}

//             {campaignReplies && (
//                 <>
//                     <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
//                         <View style={{ flex: 1 }}>
//                             <TextView style={store.theme.card.cardSectionTitle_small} text={'Lists'} />
//                             <StatItem store={store} title={`Total Contacts`} value={`${campaignStats.contactsToDeliverTotal}`} />
//                             <ContactListLinks store={store} style={store.theme.grid.dataLink} listRef={props.getContactLists?.(campaign)} />
//                         </View>
//                         <View style={{ flex: 1 }}>
//                             <TextView style={store.theme.card.cardSectionTitle_small} text={'Deliveries'} />
//                             <StatItem store={store} title={`Total`} value={`${campaignStats.messagesDeliveriesCreated}`} />
//                             <StatItem store={store} title={`Pending`} value={`${campaignStats.messagesDeliveriesPending}`} />
//                             <StatItem store={store} title={`Sent`} value={`${campaignStats.messagesDeliveriesDelivered}`} ratio={campaignStats.messagesDeliveriesDelivered / (campaignStats.messagesDeliveriesCreated - campaignStats.messagesDeliveriesPending)} />
//                             <StatItem store={store} style={{ color: campaignStats.messagesDeliveriesFailed > 0 ? store.theme.colors.warnRed : undefined }} title={`Failed`}
//                                 value={`${campaignStats.messagesDeliveriesFailed}`} ratio={campaignStats.messagesDeliveriesFailed / campaignStats.messagesDeliveriesCreated - campaignStats.messagesDeliveriesPending} />
//                         </View>
//                     </View>
//                 </>
//             )}
//         </View>
//     );
// }

type ContactMessages = { contactId: string, messages: MessageThread, lastTime: Timestamp };
export const ViewCampaignMessageThreadPage = (props: { store: Store, args?: { campaign: Reference<CampaignModel_Common> }, argsRoute?: { campaignId: string } }) => {
    const { store } = props;
    const { api, nav, theme } = store;
    const { loading, error, doWork } = useAutoLoadingError();

    const messageData = useLoadData(doWork, async () => {
        const campaignRef = props.args?.campaign ?? getReferenceFromId(props.argsRoute?.campaignId ?? '');
        const campaignName = (await api.loadCampaign(campaignRef).valueOrLoad()).name;
        const messages = await api.getCampaignReplies(campaignRef);
        const messagesGroupedObj = {} as { [contactId: string]: MessageThread };
        messages.forEach(m => {
            const messageDelivery = m as MessageDeliveryModel;
            const messageReply = m as MessageReplyModel;
            const contactRef = messageDelivery.toContact || messageReply.fromContact;

            if (!messagesGroupedObj[contactRef.id]) {
                messagesGroupedObj[contactRef.id] = [];
            };

            messagesGroupedObj[contactRef.id].push(m);
        });

        const messagesGrouped = [] as ContactMessages[];
        Object.keys(messagesGroupedObj).forEach(k => {
            const messages = messagesGroupedObj[k];
            messages.sort((a, b) => -(a.createdTime - b.createdTime));
            // messages.sort((a, b) => (a.createdTime - b.createdTime));
            messagesGrouped.push({ contactId: k, messages, lastTime: messages[0].createdTime });
        });
        messagesGrouped.sort((a, b) => -(a.lastTime - b.lastTime));
        // messagesGrouped.sort((a, b) => (a.lastTime - b.lastTime));

        return {
            campaignName,
            campaignRef,
            //  messages,
            messagesGrouped,
        };
    }, () => [props.args]);

    // const [sentMessages, setSentMessages] = useState([] as MessageDeliveryModel[]);
    // const onMessageSent = (sentMessage: MessageDeliveryModel) => {
    //     setSentMessages(s => [sentMessage, ...s]);
    // };

    const messages = [...messageData.data?.messagesGrouped ?? []];
    return (
        <MainLayout store={store} options={{ isFullWidth: false, isFullHeight: true }}>
            <TextView style={store.theme.card.cardTitle} text={`Replies for Campaign\n\n${messageData.data?.campaignName ?? ''}`} />
            <View style={theme.message.thread.view}>
                <List
                    style={theme.list}
                    items={messages}
                    inverted={true}
                    loading={loading}
                    error={error}
                    extractKey={x => x.contactId}
                    // extractSearchKey={x => [x.content.text]}
                    // extractSortKey={x => [x.deliveryTime]}
                    renderItem={x => <CampaignContactMessages store={store} item={x.item} />} />
                <View style={theme.message.thread.spacer} />
            </View>
        </MainLayout>
    );
};

const CampaignContactMessages = (props: { store: Store, item: ContactMessages }) => {

    const { item, store } = props;
    const { theme } = store;

    const contactRef = getReferenceFromId(item.contactId);

    return (<>

        <TouchableOpacity onPress={() => store.nav.ViewContactMessageThreadPage.open({ contactRef: contactRef })}>
            <View style={{ padding: 8, backgroundColor: theme.colors.bodyWhite }}>
                <View style={theme.grid.row}>
                    <View style={theme.grid.cell}>
                        <NavLinks.ViewContact style={theme.grid.dataLink} store={store} itemRef={contactRef} />
                        {/* <NavLinks.ViewContactMessages style={theme.grid.dataLink} store={store} itemRef={contactRef} /> */}
                    </View>
                    <View style={theme.grid.cell}>
                        <TextView style={theme.message.received.info.campaignName} text={Timestamp.formatTimestamp(item.lastTime)} />
                    </View>
                    <View style={theme.grid.cell}>
                        <TextView style={theme.message.received.info.campaignName} text={`${item.messages.length} reply message(s)`} />
                    </View>
                </View>
                {item.messages.map(m => (
                    <React.Fragment key={m.id}>
                        <TextView style={theme.message.received.content} text={m.content.text} />
                        <MessageMediaView content={m.content} />
                        <View style={theme.grid.row}>
                            <View style={theme.grid.cell3}></View>
                            <View style={theme.grid.cell}>
                                <TextView style={theme.message.received.info.campaignName} text={Timestamp.formatTimestamp(m.createdTime)} />
                            </View>
                        </View>
                    </React.Fragment>
                ))}
            </View>
        </TouchableOpacity>
    </>);
};

export const MessageMediaView = ({ content }:{ content: MessageContentAll }) => {
    return (
        <>
            <MessageMediaItem mediaUrl={content.imageUrl}/>
            {content.mediaUrls?.map(x=><MessageMediaItem key={x} mediaUrl={x}/>)}
        </>
    );
};

const MessageMediaItem = ({ mediaUrl }:{ mediaUrl?: string })=>{
    return (
        <>
            {mediaUrl && <Image source={{ uri: mediaUrl }} style={{ width: 200, height: 200 }} resizeMode='contain' />}
        </>
    );
};

// const CampaignMessageItem = (props: { store: Store, item: MessageDeliveryModel | MessageReplyModel }) => {

//     const { item, store } = props;
//     const { theme } = store;

//     const messageDelivery = item as MessageDeliveryModel;
//     const messageReply = item as MessageReplyModel;
//     const contactRef = messageDelivery.toContact || messageReply.fromContact;

//     return (<>

//         <TouchableOpacity onPress={() => store.nav.ViewContactMessageThreadPage.open({ contactRef: contactRef })}>
//             <View style={theme.message.received.view}>
//                 <View style={theme.grid.row}>
//                     <View style={theme.grid.cell}>
//                         <NavLinks.ViewContact style={theme.grid.dataLink} store={store} itemRef={contactRef} />
//                         {/* <NavLinks.ViewContactMessages style={theme.grid.dataLink} store={store} itemRef={contactRef} /> */}
//                     </View>
//                     <View style={theme.grid.cell}>
//                         <TextView style={theme.message.received.info.campaignName} text={Timestamp.formatTimestamp(item.createdTime)} />
//                     </View>
//                 </View>
//                 <TextView style={theme.message.received.content} text={item.content.text} />
//             </View>
//         </TouchableOpacity>
//     </>);
// };