import React from 'react';
import { Image, View } from 'react-native';

export const LogoImage = (props: { size?: 'small' | 'large' }) => {
    if (props.size === 'small') {
        return (
            <View style={{ flex: 1, minWidth: 100, minHeight: 50, }}>
                <Image source={require('../../../assets/logo.png')} style={{ flex: 1, alignSelf: 'stretch', maxWidth: 221, maxHeight: 73 }} resizeMode='contain' />
            </View>
        );
    }
    else {
        return (<Image source={require('../../../assets/logo.png')} style={{ width: 221, height: 73 }} />);
    }
}