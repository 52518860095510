import React from 'react';
import { AppApiAccess } from '../../client-api-access/types/AppApiAccess';
import { theme } from '../style/Theme';
import { createAppViews, AppViews } from '../views/_ViewsCreate';
import { createAppApiAccess } from '../../client-api-access/AppApiAccess';
import { appConfig_client } from '../../_config/client/appConfig_client';
import { createAuthApiAccess } from '../../client-api-access/AuthApiAccess';
import { AppAuthApiAccess } from '../../client-api-access/types/AppAuthApiAccess';
import { UploadApiAccess } from '../../client-api-access/types/UploadApiAccess';
import { createUploadApiAccess } from '../../client-api-access/UploadApiAccess';
import { AdminApiAccess } from '../../client-api-access/types/AdminApiAccess';
import { createAdminApiAccess } from '../../client-api-access/AdminApiAccess';
import { createPaymentClientComponents_extra } from '../../lib/payments/client/payment-react';
import { createPaymentServerAccess } from '../../client-api-access/PaymentServerAccess';
import * as Linking from 'expo-linking';
import { Platform } from 'react-native';

export type PaymentClientComponents_Extra = ReturnType<typeof createPaymentClientComponents_extra>;

export class Store {
    admin: AdminApiAccess;
    api: AppApiAccess;
    auth: AppAuthApiAccess;
    uploadApi: UploadApiAccess;
    nav: AppViews;
    theme: typeof theme;
    paymentsComponents: PaymentClientComponents_Extra;
    version: string;
    config = {
        supportEmail: appConfig_client.supportEmail,
        webAppDomain: appConfig_client.webAppDomain,
        creditsPerMessage: appConfig_client.creditsPerMessage,
    };
    initialUrl = null as null | {
        URL: String,
        pathname: string,
        search: string,
    };
    clearInitialUrl = () => {
        this.initialUrl = null;
        localStorage.removeItem('initialUrl');
    };

    navigateWebSiteHome = () => {
        Linking.openURL(appConfig_client.webSiteDomain);
    }
    navigateWebApp = (path: string) => {
        Linking.openURL(appConfig_client.webAppDomain + '/' + path.replace(/^\//g, ''));
    }

    constructor() {
        this.version = appConfig_client.version;

        if( Platform.OS === 'web' ){
            try {
                this.initialUrl = JSON.parse(localStorage.getItem('initialUrl') as string) || null;
            } catch {}

            if( !this.initialUrl?.URL ){
                this.initialUrl = {
                    URL: document.URL,
                    pathname: document.location.pathname,
                    search: document.location.search,
                };
                localStorage.setItem('initialUrl', JSON.stringify(this.initialUrl));
            }
        }

        this.nav = createAppViews(() => this);
        //this.auth = createAuthApiAccess_mock(SystemControls.mockOptions);

        const onAuthStateChange = async () => {
            this.clearInitialUrl();
            await this.api.reloadState();
        };

        this.auth = createAuthApiAccess(appConfig_client, onAuthStateChange);
        this.admin = createAdminApiAccess(appConfig_client, this.auth, async () => {
            await onAuthStateChange();
            this.nav._refresh();
            this.nav.ViewAccountsPage.open({ search: '' });
        });

        // this.api = createAppApiAccess_mock(SystemControls.mockOptions);
        this.api = createAppApiAccess(appConfig_client, this.auth);

        this.uploadApi = createUploadApiAccess(appConfig_client, this.auth);
        this.theme = theme;

        this.paymentsComponents = createPaymentClientComponents_extra({
            stripePublicKey: appConfig_client.stripePublicKey,
            serverAccess: createPaymentServerAccess(appConfig_client, this.auth),
        });

    }

    StoreAppWrapper = (props: { children: any }) => {
        const AppWrapperComponent = this.paymentsComponents.AppWrapperComponent!;
        return (
            <AppWrapperComponent>
                {props.children}
            </AppWrapperComponent>
        );
    };

}