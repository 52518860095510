import { CampaignModel_Standard, MessageTemplateModel, ContactListModel, CampaignModel_Common, AccountPhoneNumberModel, CampaignModel_Contest, DateRangeEditorState, CampaignModel_Interactive, CampaignModel_Sweepstakes } from '../../data-types/ModelTypes';
import { FieldDefinitions, CalculatedFieldDefinition, UIFieldDefinition, AutomaticFieldDefinition, ObjectDefinition, UIFieldDefinitions } from '../../utils/BuilderFieldDefinitions';
import { DateOnly, TimeOnly, DayOfWeek, DayOfMonth, TimeZone } from '../../utils/Time';
import { Gsm } from '../../utils/Gsm';
import { Validation_DateAfterOrOnToday, Validation_PositiveInteger } from '../../utils/Validation';
import { MessageTemplateVariable } from '../../utils/MessageTemplates';
import { formatPhoneNumber_UsaCanada } from '../../utils/PhoneNumbers';
import { Memoize } from '../../utils/Memoize';

export type CampaignFieldDefinitionsApiAccess = {
    getContactLists_withContactsCount(): Promise<(ContactListModel & { contactsCount: number })[]>;
    getAccountPhoneNumbers(): Promise<AccountPhoneNumberModel[]>;
};

const Auto = (kind: string): AutomaticFieldDefinition => ({ __type: 'automatic' as const, kind });
const AutoId = () => Auto('id');
const ConstantField = <T>(value: T) => ({ __type: 'constant' as const, value });

const CalculatedPath = (calculatePath: (path: string[]) => string): CalculatedFieldDefinition<string> => ({
    __type: 'calculated',
    calculate: calculatePath,
});
const CalculatedPath_Self = () => CalculatedPath((path: string[]) => path.join('.'));
const CalculatedPath_Parent = () => CalculatedPath((path: string[]) => path.slice(-1).join('.'));

const F = {
    uiText: {} as UIFieldDefinition<string>,
    uiNumber: {} as UIFieldDefinition<number>,
    uiRef: {} as UIFieldDefinition<string>,
    uiDateOnly: {} as UIFieldDefinition<DateOnly>,
    uiTimeOnly: {} as UIFieldDefinition<TimeOnly>,
    uiTimeZone: {} as UIFieldDefinition<TimeZone>,
    uiDayOfWeek: {} as UIFieldDefinition<DayOfWeek>,
    uiDayOfMonth: {} as UIFieldDefinition<number>,
    objectDef: {} as ObjectDefinition['__objectDefinition'],
};

export type DateRangeEditorFieldDefinitions = UIFieldDefinitions<DateRangeEditorState>;


// const asUIFieldDefinition = <T>(value: FieldDefinition<T>) => {
//     let ui_kind = ('__type' in value) ? value.__type === 'ui' ? value : null : null;
//     return ui_kind!;
// };

export const MessageTemplateField = (props: {
    templateId: string,
    label: string,
    hint: string,
    placeholder: string,
    imageMode: 'required' | 'disabled' | 'optional',
    linkMode: 'required' | 'disabled' | 'optional',
    defaultValue?: () => string,
    // TODO: This could be typed possibly
    variables?: MessageTemplateVariable[],
    supportsMultipleMessages?: boolean,

}) => {
    const isRequired = true;
    let _mode = 'sms' as 'sms' | 'mms';
    const result = {
        id: AutoId(),
        // templateId: ConstantField(props.templateId),
        text: F.uiText = {
            __type: 'ui',
            isRequired,
            label: props.label,
            hint: props.hint,
            placeholder: props.placeholder,
            validation: Gsm.validation(isRequired, () => _mode, props.supportsMultipleMessages ?? false),
            defaultValue: props.defaultValue,
            variables: props.variables,
        },
        campaignPath: CalculatedPath_Parent(),
        campaign: { id: AutoId() },
        campaignRole: ConstantField(props.label),
        imageUrl: F.uiText = {
            __type: 'ui',
            isRequired: props.imageMode == 'required',
            isDisabled: props.imageMode == 'disabled',
            label: 'Message Image',
        },
        linkUrl: F.uiText = {
            __type: 'ui',
            isRequired: props.linkMode == 'required',
            isDisabled: props.linkMode == 'disabled',
            label: 'Message Link',
        },
        setMode: (mode: 'sms' | 'mms') => {
            _mode = mode;
        },
    };

    const __typeCheck: FieldDefinitions<MessageTemplateModel<any>> = result;
    return result;
};

export type MessageTemplateFieldDefinition = ReturnType<typeof MessageTemplateField>;

const OptionField = <T extends { id: string }>(props: {
    isRequired: boolean,
    label: string,
    getItems: () => Promise<T[]>
    getValueChoice: (item: T) => { value: string, label: string },
}) => ({
    id: F.uiRef = {
        __type: 'ui' as const,
        isRequired: props.isRequired,
        label: props.label,
        // defaultValue: () => '',
        getOptionValues: async () => {
            const items = await props.getItems();
            const options = items.map(props.getValueChoice);
            return options;
        },
    },
});

const Campaign_CommonFields = (api: CampaignFieldDefinitionsApiAccess) => {

    const result = {
        id: AutoId(),
        name: F.uiText = {
            __type: 'ui',
            isRequired: true,
            label: 'Campaign Name',
            hint: 'What will help you identify this campaign later?',
            placeholder: 'Enter the Campaign Name',
            // validation: Validation_MaxLengthRequired({ isRequired: true, maxLength: 80 }),
        },
        description: F.uiText = {
            __type: 'ui',
            isRequired: true,
            label: 'Campaign Description',
            hint: 'What is the purpose of this campaign (for future reference)?',
            placeholder: 'Enter the Campaign Description',
            // validation: Validation_MaxLengthRequired({ isRequired: true, maxLength: 250 }),
        },
        toContactLists: [OptionField<ContactListModel & { contactsCount: number }>({
            isRequired: true,
            label: 'Campaign List',
            getItems: Memoize(async () => await api.getContactLists_withContactsCount()),
            getValueChoice: x => ({
                value: x.id,
                label: `${x.name} (${x.contactsCount} Contacts)`
            }),
        })],
        fromPhoneNumber: OptionField<AccountPhoneNumberModel>({
            isRequired: true,
            label: 'From Phone Number',
            getItems: Memoize(async () => (await api.getAccountPhoneNumbers()).filter(x => x.orderStatus === 'success')),
            getValueChoice: x => ({
                value: x.id,
                //label: `${x.name} (${formatPhoneNumber_UsaCanada(x.phoneNumber)})`
                label: `${formatPhoneNumber_UsaCanada(x.phoneNumber)}`
            }),
        }),
        // autoReplyMessage: MessageTemplateField({
        //     templateId: 'autoReplyMessage',
        //     label: 'Auto Reply Message',
        //     hint: 'What message should be sent in response if someone replies?',
        //     placeholder: 'Enter the Auto Reply Message',
        //     imageMode: 'disabled', linkMode: 'disabled',
        // }),
        status: {
            createdTime: Auto('timestamp'),
            lastActivityTime: Auto('timestamp'),
            nextActivityTime: Auto('timestamp'),
            isActive: Auto('boolean'),
        },
    };

    const __typeCheck: FieldDefinitions<CampaignModel_Common> = result;
    return result;
};
export type Campaign_CommonFieldsDefinition = ReturnType<typeof Campaign_CommonFields>;


export const Campaign_StandardFields = (api: CampaignFieldDefinitionsApiAccess) => {

    const common = Campaign_CommonFields(api);
    const standard = {
        kind: ConstantField('standard' as const),
        id: common.id,
        name: common.name,
        description: common.description,
        toContactLists: common.toContactLists,
        messages: {
            message: MessageTemplateField({
                templateId: 'message',
                label: 'Message',
                hint: 'What message should be sent?',
                placeholder: 'Enter the Message',
                imageMode: 'optional', linkMode: 'optional',
            }),
            schedule: {
                startDate: F.uiDateOnly = {
                    __type: 'ui',
                    isRequired: true,
                    label: 'Send Date',
                    hint: 'What date should the message be sent?',
                    defaultValue: () => DateOnly.toDateOnly(new Date())!,
                    validation: Validation_DateAfterOrOnToday(),
                    isMinDateToday: true,
                },
                hourOfDay: F.uiTimeOnly = {
                    __type: 'ui',
                    isRequired: false,
                    label: 'Time of Day',
                    hint: 'What time of the day should the message be sent?',
                },
                absoluteTimeZone: F.uiTimeZone = {
                    __type: 'ui',
                    isRequired: false,
                    label: 'Delivery Time Zone',
                    hint: 'What time zone is the delivery time?',
                },
            },
        },
        // autoReplyMessage: common.autoReplyMessage,
        fromPhoneNumber: common.fromPhoneNumber,
        status: common.status,
    };

    const __typeCheck: FieldDefinitions<CampaignModel_Standard> = standard;
    return standard;
};

export const Campaign_ContestFields = (api: CampaignFieldDefinitionsApiAccess) => {

    const common = Campaign_CommonFields(api);

    //    const __fakeCampaign = null as unknown as CampaignData_Contest;
    //type type_contestIntervals_kind = typeof __fakeCampaign.contestIntervals.kind;
    // type fieldDefinition_contestIntervals_kind = FieldDefinitions<DateRangeIntervals>['kind'];
    // let ui_contestIntervals_kind = null as unknown as UIFieldDefinition<'weekly' | 'monthly' | 'dates', string>;

    //    const contestIntervals: FieldDefinitions<DateRangeIntervals> = {
    const contestIntervals = {
        kind: {
            __type: 'ui' as const,
            isRequired: true,
            label: 'Contest Recurrence',
            hint: 'When should the contest repeat?',
            defaultValue: () => 'weekly' as const,
            getValueChoices: () => Promise.resolve([
                { value: 'weekly' as const, label: 'Weekly', },
                { value: 'monthly' as const, label: 'Monthly', },
                { value: 'dates' as const, label: 'Specific Dates', },
            ]),
        },
        weekly: {
            __objectDefinition: F.objectDef = {
                isRequired: false,
            },
            // startDayOfWeek: F.uiDayOfWeek = {
            //     __type: 'ui',
            //     isRequired: true,
            //     label: 'Weekly Contest Start Day',
            //     hint: 'Which day should the contest begin each week?',
            // },
            endDayOfWeek: F.uiDayOfWeek = {
                __type: 'ui',
                isRequired: true,
                defaultValue: () => DayOfWeek.friday,
                label: 'Weekly Contest End Day',
                hint: 'Which day should the contest end each week?',
            },
            // startDate: F.uiDateOnly = {
            //     __type: 'ui',
            //     isRequired: true,
            //     label: 'Contests Begin On',
            //     hint: 'When should the contests begin?',
            // },
            // endDate: F.uiDateOnly = {
            //     __type: 'ui',
            //     isRequired: false,
            //     label: 'Contests Continue Until',
            //     hint: 'What date should the contests end?',
            // },
        },
        monthly: {
            __objectDefinition: F.objectDef = {
                isRequired: false,
            },
            // startDayOfMonth: F.uiDayOfMonth = {
            //     __type: 'ui',
            //     isRequired: true,
            //     label: 'Monthly Contest Start Day',
            //     hint: 'Which day should the contest begin each month?',
            // },
            endDayOfMonth: F.uiDayOfMonth = {
                __type: 'ui',
                isRequired: true,
                defaultValue: () => 31 as DayOfMonth,
                label: 'Monthly Contest End Day',
                hint: 'Which day should the contest end each month?',
            },
            // startDate: F.uiDateOnly = {
            //     __type: 'ui',
            //     isRequired: true,
            //     label: 'Contests Begin On',
            //     hint: 'When should the contests begin?',
            // },
            // endDate: F.uiDateOnly = {
            //     __type: 'ui',
            //     isRequired: false,
            //     label: 'Contests Continue Until',
            //     hint: 'What date should the contests end?',
            // },
        },
        dates: {
            __objectDefinition: F.objectDef = {
                isRequired: false,
            },
            dateRanges: [{
                // startDate: F.uiDateOnly = {
                //     __type: 'ui',
                //     isRequired: true,
                //     label: 'Contest Start Date',
                //     hint: 'What date should the contest begin?',
                // },
                endDate: F.uiDateOnly = {
                    __type: 'ui',
                    isRequired: true,
                    label: 'Contest End Date',
                    hint: 'What date should the contest end?',
                },
            }],
        }
    };
    const __typeCheck_contestIntervals: FieldDefinitions<CampaignModel_Contest['contestIntervals']> = contestIntervals;

    //const def: FieldDefinitions<CampaignData_Contest> = {
    const def = {
        kind: ConstantField('contest' as const),
        id: common.id,
        name: common.name,
        description: common.description,
        toContactLists: common.toContactLists,
        messages: {
            // contactAdded_introduction: MessageTemplateField({
            //     label: 'Message: Introduction',
            //     hint: 'What is the first message that should be sent to a new member (to introduce the contests)?',
            //     placeholder: 'Enter the Message',
            //     defaultValue: () => 'These contests are awesome! Get ready to have fun and win big!',
            //     imageMode: 'optional', linkMode: 'optional',
            // }),
            // contestIntervalStarted_inviteToContest: MessageTemplateField({
            //     label: 'Message: Contest Started',
            //     hint: 'What message should be sent to everyone when a new contest begins?',
            //     placeholder: 'Enter the Message',
            //     defaultValue: () => 'The contest has begun! Register before {end} here: {link}',
            //     variables: [
            //         { name: 'end', description: 'The end date of the current contest.' },
            //         { name: 'link', description: 'The link to join the current contest.' },
            //     ],
            //     imageMode: 'optional', linkMode: 'disabled',
            // }),
            joinedContest_welcome: MessageTemplateField({
                templateId: 'joinedContest_welcome',
                label: 'Message: Welcome to Contest',
                hint: 'What message should be sent when someone joins a contest?',
                placeholder: 'Enter the Message',
                defaultValue: () => 'You have registered for the contest! The contest ends on {end}. Hope you win!',
                variables: [
                    { name: 'end', description: 'The end date of the current contest.' },
                ],
                imageMode: 'optional', linkMode: 'optional',
            }),
            // joinFailed_contestOver: MessageTemplateField({
            //     label: 'Message: If Contests Are Over',
            //     hint: 'What message should be sent is someone tries to register after the contest is over?',
            //     placeholder: 'Enter the Message',
            //     defaultValue: () => 'Sorry the contest is already over.',
            //     variables: [
            //         { name: 'end', description: 'The end date of the current contest.' },
            //     ],
            //     imageMode: 'optional', linkMode: 'optional',
            // }),

            contestOver_won: MessageTemplateField({
                templateId: 'contestOver_won',
                label: 'Message: Contest Won',
                hint: 'What message should be sent when someone wins a contest?',
                placeholder: 'You WON! Claim your prize here: ...',
                // defaultValue: () => 'You WON! Claim your prize at: ...',
                // variables: [
                //     //{ name: 'link', description: 'The link to claim the prize.' },
                // ],
                imageMode: 'optional', linkMode: 'disabled',
            }),
            contestOver_lost: MessageTemplateField({
                templateId: 'contestOver_lost',
                label: 'Message: Contest Lost',
                hint: 'What message should be sent when someone loses a contest?',
                placeholder: 'Enter the Message',
                defaultValue: () => 'The contest is over! Sorry you didn\'t win this time. Maybe next time!',
                imageMode: 'optional', linkMode: 'optional',
            }),
            schedule: {
                hourOfDay: F.uiTimeOnly = {
                    __type: 'ui',
                    isRequired: false,
                    label: 'Time of Day',
                    hint: 'What time of the day should the message be sent?',
                },
            },
        },
        numberOfWinners: F.uiNumber = {
            __type: 'ui',
            isRequired: true,
            defaultValue: () => 1,
            label: 'Number of Winners',
            hint: 'How many winners for each contest interval?',
            validation: Validation_PositiveInteger(),
        },
        maxEntries: F.uiNumber = {
            __type: 'ui',
            isRequired: true,
            defaultValue: () => 1,
            label: 'Max Entries Allowed',
            hint: 'How many entries allowed for a single person each contest interval?',
            validation: Validation_PositiveInteger(),
        },
        prizeUrl: F.uiText = {
            __type: 'ui',
            isRequired: true,
            label: 'Prize Url',
            hint: 'What url should be sent when someone wins?',
        },
        contestIntervals,
        // contests: Auto('contests'),
        // autoReplyMessage: common.autoReplyMessage,
        fromPhoneNumber: common.fromPhoneNumber,
        status: common.status,
    };

    const __typeCheck: FieldDefinitions<CampaignModel_Contest> = def;
    return def;
};

