import React, { useState, ReactNode, useEffect, useRef } from 'react';
import { Text, Picker, TextStyle, TouchableOpacity } from 'react-native';
import { TextFieldStyle, TextViewStyle } from '../style/Theme';
import { View } from 'react-native';
import { DateOnly, TimeOnly, DayOfMonth, DayOfWeek } from '../../utils/Time';
import { Icon, IconKind } from './Icon';
import { getKeysTyped } from '../../utils/TypeFunctions';
import { UIFieldDefinition } from '../../utils/BuilderFieldDefinitions';
import { ValidValue } from '../../utils/Builder';
import { capitalize } from '../../utils/Strings';
import { HintPopup, TextInputView } from './TextView';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimePicker.css";

type PickerControl<T> = (props: {
    style: TextViewStyle,
    value: T | null,
    onChangeValue: (value: T | null) => void,
    field: UIFieldDefinition<T>,
}) => JSX.Element;

function PickerWrapper<T>(props: {
    style: TextFieldStyle,
    field: UIFieldDefinition<T>,
    value: T | null | undefined,
    onChangeValue: (date: ValidValue<T> | null) => void,
    Control: PickerControl<T>,
}) {

    const { style, field, Control } = props;

    const [lastValue, setLastValue] = useState({
        value: props.value || props.field.defaultValue?.() || null,
        // Allow Empty initially
        ...(props.value && field.validation?.validate(props.value) || { isValid: true, message: '' }),
    });
    useEffect(() => {
        setLastValue({
            value: props.value || props.field.defaultValue?.() || null,
            // Allow Empty initially
            ...(props.value && field.validation?.validate(props.value) || { isValid: true, message: '' }),
        });
    }, [props.value]);
    const styleText = lastValue.isValid ? style.text : style.text_error;

    const onChangeValueInner = (value: T | null) => {
        const isMissing = field.isRequired && !value;
        const requiredValidation = { isValid: !isMissing, message: isMissing ? 'Required' : '' };
        const { isValid, message } = !field.validation ? requiredValidation
            : value && field.validation.validate(value) || (field.isRequired ? { isValid: false, message: 'Invalid' } : { isValid: true, message: '' });

        const l = {
            value: value,
            isValid,
            message,
        };
        setLastValue(l);

        props.onChangeValue(value && { value, isValid, error: !isValid ? message : undefined } || null);
    };



    return (
        <View style={style.view}>
            {field.label && <Text style={style.label}>{field.label} {field.isRequired ? '' : ''}<HintPopup hint={field.hint} /></Text>}
            <Control
                style={styleText}
                value={lastValue.value}
                onChangeValue={onChangeValueInner}
                field={field}
            />
            {!lastValue.isValid && (
                <View style={[style.statusView, { alignSelf: 'flex-start' }]}>
                    <Icon kind={IconKind.error} style={style.errorIcon} />
                    <Text style={style.error}>{lastValue.message}</Text>
                </View>
            )}
        </View>
    );
}


const DatePickerControl = (props: {
    style: TextViewStyle,
    value: DateOnly | null,
    onChangeValue: (value: DateOnly | null) => void,
    field: UIFieldDefinition<DateOnly>,
}) => {


    const changeDate = (value: DateOnly | null) => {
        props.onChangeValue(value);
    };

    const pickerRef = useRef(null as null | ReactDatePicker);
    useEffect(() => {
        const p = pickerRef.current
        if (!p) { return; }

        const input = (p as { input?: HTMLInputElement }).input;
        if (!input) { return; }

        console.log('DatePickerControl', { p, s: props.style });

        input.style.borderColor = props.style.view.borderColor ?? 'unset';
        input.style.borderStyle = props.style.view.borderStyle ?? 'unset';
        input.style.borderWidth = props.style.view.borderWidth + 'px';
        input.style.borderRadius = props.style.view.borderRadius + 'px';
        input.style.color = props.style.text.color ?? 'unset';
        input.style.fontFamily = props.style.text.fontFamily + 'px';
        input.style.fontSize = props.style.text.fontSize + 'px';
        input.style.padding = props.style.text.padding + 'px';

    }, [pickerRef.current]);


    const v = props.value && DateOnly.toDate(props.value) || null;
    //console.log('DatePickerControl render', { v, pickerRef });
    return (
        <View>
            <ReactDatePicker
                ref={pickerRef}
                popperClassName='popperHide'
                minDate={props.field.isMinDateToday ? new Date() : undefined}
                selected={v}
                onChange={x => changeDate(x && DateOnly.toDateOnly(x as Date) || null)}
            />
            <ReactDatePicker
                inline={true}
                showPopperArrow={false}
                disabledKeyboardNavigation={true}
                minDate={props.field.isMinDateToday ? new Date() : undefined}
                selected={v}
                onChange={x => changeDate(x && DateOnly.toDateOnly(x as Date) || null)}
            />
        </View>
    );
}

export const DatePicker = (props: {
    style: TextFieldStyle,
    field: UIFieldDefinition<DateOnly>,
    value: DateOnly | null | undefined,
    onChangeValue: (date: ValidValue<DateOnly> | null) => void,
}) => (
    <PickerWrapper {...props} Control={DatePickerControl} />
);



const TimePickerControl = (props: {
    style: TextViewStyle,
    value: TimeOnly | null,
    onChangeValue: (value: TimeOnly | null) => void,
    field: UIFieldDefinition<TimeOnly>,
}) => {

    const pickerRef = useRef(null as null | ReactDatePicker);
    useEffect(() => {
        const p = pickerRef.current
        if (!p) { return; }

        const input = (p as { input?: HTMLInputElement }).input;
        if (!input) { return; }

        console.log('TimePickerControl', { p, s: props.style });

        input.style.borderColor = props.style.view.borderColor ?? 'unset';
        input.style.borderStyle = props.style.view.borderStyle ?? 'unset';
        input.style.borderWidth = props.style.view.borderWidth + 'px';
        input.style.borderRadius = props.style.view.borderRadius + 'px';
        input.style.color = props.style.text.color ?? 'unset';
        input.style.fontFamily = props.style.text.fontFamily + 'px';
        input.style.fontSize = props.style.text.fontSize + 'px';
        input.style.padding = props.style.text.padding + 'px';

    }, [pickerRef.current]);

    const onSelectTime = (value: TimeOnly | null) => {
        console.log('onSelectTime', { value });
        props.onChangeValue(value);
    };

    const timeAsDate = props.value && TimeOnly.toDate(props.value) || null;
    const minTimeAsDate = props.field.minTime && TimeOnly.toDate(props.field.minTime) || undefined;
    const maxTimeAsDate = props.field.maxTime && TimeOnly.toDate(props.field.maxTime) || undefined;

    // console.log('TimePickerControl render', { value: props.value, timeAsDate, minTimeAsDate, maxTimeAsDate });

    return (
        <View>
            <ReactDatePicker
                ref={pickerRef}
                popperClassName='popperHide'
                showPopperArrow={false}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                minTime={minTimeAsDate}
                maxTime={maxTimeAsDate}
                selected={timeAsDate}
                onChange={x => onSelectTime(x && TimeOnly.toTimeOnly(x as Date) || null)}
            />
            <ReactDatePicker
                inline={true}
                showPopperArrow={false}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                minTime={minTimeAsDate}
                maxTime={maxTimeAsDate}
                selected={timeAsDate}
                onChange={x => onSelectTime(x && TimeOnly.toTimeOnly(x as Date) || null)}
            />
        </View>
    );
}

// const TimePickerControl = (props: {
//     style: TextViewStyle
//     value: TimeOnly | null,
//     onChangeValue: (value: TimeOnly | null) => void,
// }) => (
//         <input type="time"
//             style={{ ...props.style.view, ...props.style.text } as any}
//             placeholder="HH:MM"
//             value={props.value && TimeOnly.toIsoTimeHoursMinutesString(props.value) || ''}
//             onChange={x => props.onChangeValue(x.target.value && TimeOnly.fromIsoTimeHoursMinutesString(x.target.value) || null)} />
//     );

export const TimePicker = (props: {
    style: TextFieldStyle,
    field: UIFieldDefinition<TimeOnly>,
    value: TimeOnly | null | undefined,
    onChangeValue: (date: ValidValue<TimeOnly> | null) => void,
}) => (
    <PickerWrapper {...props} Control={TimePickerControl} />
);


const DayOfMonthPickerControl = (props: {
    style: TextViewStyle
    value: DayOfMonth | null,
    onChangeValue: (value: DayOfMonth | null) => void,
}) => (
    <input type="number"
        min={1}
        max={31}
        style={{ ...props.style.view, ...props.style.text } as any}
        value={props.value || 0 + ''}
        onChange={x => props.onChangeValue(parseInt(x.target.value) as DayOfMonth)} />
);

export const DayOfMonthPicker = (props: {
    style: TextFieldStyle,
    field: UIFieldDefinition<DayOfMonth>,
    value: DayOfMonth | null | undefined,
    onChangeValue: (date: ValidValue<DayOfMonth> | null) => void,
}) => (
    <PickerWrapper {...props} Control={DayOfMonthPickerControl} />
);


const daysOfWeek = getKeysTyped(DayOfWeek).map(k => DayOfWeek[k]);
// const daysOfWeekValues = daysOfWeek.map(x => capitalize(x));
// const isValidDayOfWeek = (v: DayOfWeek | null | undefined) => v && daysOfWeekValues.indexOf(v) >= 0 || false;
const DayOfWeekPickerControl = (props: {
    style: TextViewStyle
    value: DayOfWeek | null,
    onChangeValue: (value: DayOfWeek | null) => void,
}) => (
    <Picker
        style={{ ...props.style.view, ...props.style.text, ...{ fontFamily: undefined, fontSize: undefined, fontWeight: undefined, } }}
        itemStyle={props.style.text}
        selectedValue={props.value}
        onValueChange={(x: DayOfWeek) => props.onChangeValue(x)}
    >
        {daysOfWeek.map(x => (
            <Picker.Item label={capitalize(x)} value={x} />
        ))}
    </Picker>
);

export const DayOfWeekPicker = (props: {
    style: TextFieldStyle,
    field: UIFieldDefinition<DayOfWeek>,
    value: DayOfWeek | null | undefined,
    onChangeValue: (date: ValidValue<DayOfWeek> | null) => void,
}) => (
    <PickerWrapper {...props} Control={DayOfWeekPickerControl} />
);

