import { AdminApi, AdminApiEndpointName, AdminApiRequestData } from "./AdminApiWebClientTypes";
import { AppConfig_Client } from "../../../_config/client/appConfig_client";
import { webRequest } from "../../../utils/WebRequest";
import { UserCredentials, UserCredentialsProvider } from "../../../data-types/SecurityDataTypes";
import { AuthError } from "../../../utils/Errors";

export const createAdminApiWebClient = (appConfig: AppConfig_Client, userCredentialsProvider: UserCredentialsProvider): AdminApi => {

    async function apiRequest<T>(endpoint: AdminApiEndpointName, data: unknown): Promise<T> {
        const url = `${appConfig.adminApiDomain}/${endpoint}`;
        const userCredentials = userCredentialsProvider.getUserCredentials();
        if (!userCredentials) {
            throw new AuthError('Cannot call AdminApi without UserCredentials', { userCredentials, endpoint });
        }

        const requestData: AdminApiRequestData = {
            userCredentials,
            endpoint,
            data,
        };

        const resultObj = (await webRequest(url, requestData)) as { data: T, newUserCredentials: UserCredentials | null | undefined };

        if (resultObj.newUserCredentials !== undefined) {
            await userCredentialsProvider.setUserCredentials(resultObj.newUserCredentials);
        }

        return resultObj.data;
    };

    const api: AdminApi = {
        getAdmins: (data) => apiRequest('getAdmins', data),
        findUsers: (data) => apiRequest('findUsers', data),
        setAdminRole: (data) => apiRequest('setAdminRole', data),

        getAccounts: (data) => apiRequest('getAccounts', data),
        setAccountAdminSettings: (data) => apiRequest('setAccountAdminSettings', data),
        setAccountSubscription: (data) => apiRequest('setAccountSubscription', data),

        addUserToAccount: (data) => apiRequest('addUserToAccount', data),
        removeUserFromAccount: (data) => apiRequest('removeUserFromAccount', data),
        
        createNewAccount: (data) => apiRequest('createNewAccount', data),
        impersonateAccount: (data) => apiRequest('impersonateAccount', data),
        stopImpersonation: (data) => apiRequest('stopImpersonation', data),
        getPlans: (data) => apiRequest('getPlans', data),
        savePlans: (data) => apiRequest('savePlans', data),
    };

    return api;
}
