import { DateOnly, TimeOnly, Timestamp } from './Time';
import { ReferenceList } from '../data-types/SimpleDataTypes';

// type Builder<T> = {
//     [P in keyof T]?: { value: T[P], isValid: boolean };
// };

// null extends T[P] ? never : P
export type ValidValue<T> = { value: T, isValid: boolean, error?: string, };
export type Builder<T> = {
    [P in keyof T]?: (
        T[P] extends Function ? never
        : T[P] extends string ? ValidValue<T[P]>
        : T[P] extends number ? ValidValue<T[P]>
        : T[P] extends boolean ? ValidValue<T[P]>
        : T[P] extends Date ? ValidValue<T[P]>
        : T[P] extends DateOnly ? ValidValue<T[P]>
        : T[P] extends TimeOnly ? ValidValue<T[P]>
        : T[P] extends Timestamp ? ValidValue<T[P]>
        : T[P] extends (string | undefined) ? ValidValue<T[P]>
        : T[P] extends (number | undefined) ? ValidValue<T[P]>
        : T[P] extends (boolean | undefined) ? ValidValue<T[P]>
        : T[P] extends (Date | undefined) ? ValidValue<T[P]>
        : T[P] extends (DateOnly | undefined) ? ValidValue<T[P]>
        : T[P] extends (TimeOnly | undefined) ? ValidValue<T[P]>
        : T[P] extends (Timestamp | undefined) ? ValidValue<T[P]>
        : T[P] extends ReferenceList<infer U> ? ValidValue<{ id: string }>[]
        : Builder<T[P]>
    );
};

export type BuilderResult<T> = {
    value: T,
    isValid: boolean,
    errors: {
        path: string,
        error?: string,
    }[]
};


export function isValidValue(validValue: unknown | ValidValue<unknown>): validValue is ValidValue<unknown> {
    return (validValue as ValidValue<unknown>)?.value !== undefined;
}
