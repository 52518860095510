import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { CampaignModel_Coupon, CampaignEdit_Coupon, MessageTemplateEdit, CampaignModel } from '../../../../data-types/ModelTypes';
import { Reference } from '../../../../data-types/SimpleDataTypes';
import { DateOnly, TimeOnly, Timestamp, TimeZone } from '../../../../utils/Time';
import { ButtonTextView, TextView } from '../../../components/TextView';
import { Store } from '../../../store/Store';
import { MessageItemDisplay } from '../../Messages/ViewMessageThread';
import { CampaignEnableDisableButton } from './CampaignDisable';

export const CampaignPreviewSaveArea = ({
    store,
    isDisabled,
    campaign,
    campaignMessages,
    campaignSendTime,
    saveCampaign,
    loading,
    error,
    buttonText = 'Save',
}: {
    store: Store,
    isDisabled: boolean,
    campaign: { id?: string, kind: CampaignModel['kind'], status: 'new' | 'active' | 'inactive', },
    campaignMessages: { title: string, message?: MessageTemplateEdit }[],
    campaignSendTime?: {
        startDate: DateOnly;
        hourOfDay?: TimeOnly;
        absoluteTimeZone?: TimeZone;
    }
    saveCampaign: () => void,
    loading: boolean,
    error: unknown,
    buttonText?: 'Save' | 'Send Now' | 'Send at Scheduled Time',
}) => {
    const { theme } = store;

    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        setShowPreview(false);
    }, [campaignMessages.flatMap(x => [x.message?.text ?? '', ...x.message?.additionalTexts ?? []]).join(';')]);

    if (campaign.status === 'inactive') {
        return (<></>);
    }

    return (
        <>
            {!showPreview && (
                <View style={theme.card.actionArea}>
                    <View style={theme.card.buttonRow}>
                        {campaign.id && (<CampaignEnableDisableButton store={store} campaign={{ id: campaign.id, kind: campaign.kind, status: { isActive: campaign.status === 'active' } }} onCampaignChanged={() => store.nav.ActiveCampaignsPage.open()} />)}
                        <View style={theme.card.buttonSpacer} />
                        <ButtonTextView style={theme.card.buttonTextView_major} text="Preview" onPress={() => setShowPreview(true)} isDisabled={isDisabled} loading={loading} error={error} />
                    </View>
                </View>
            )}

            {/* Preview */}
            {showPreview && (
                <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <View style={{ maxWidth: 450 }}>
                        <View style={{ ...theme.card.view_white, backgroundColor: theme.colors.borderGray }}>
                            <TextView style={theme.card.cardTitle} text={`Message Preview`} />
                            {campaignMessages.filter(x => x.message).map((x, i) => (
                                <React.Fragment key={i}>
                                    <TextView style={theme.card.infoTextView} text={x.title} />
                                    <MessageItemDisplay store={store} style={theme.campaignMessagesPreview.message} content={x.message!}
                                        futureDate={campaignSendTime?.startDate}
                                        futureTime={campaignSendTime?.hourOfDay}
                                        futureTimeZone={campaignSendTime?.absoluteTimeZone}
                                    />
                                </React.Fragment>
                            ))}
                        </View>
                    </View>
                </View>
            )}

            {/* Save */}
            {showPreview && (
                <View style={theme.card.actionArea}>
                    <View style={theme.card.buttonRow}>
                        <View style={theme.card.buttonSpacer} />
                        <ButtonTextView style={theme.card.buttonTextView_major} text={buttonText} onPress={saveCampaign} isDisabled={isDisabled} loading={loading} error={error} />
                    </View>
                </View>
            )}
        </>
    );
};