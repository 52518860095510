import { PhoneNumber } from "../data-types/SimpleDataTypes";

export function formatPostalCode_Usa(text: string): string {
    const result = postalCodeMask_Usa(text, '#####-####').corrected as string;
    console.log('formatPostalCode_UsaCanada', { text, result });
    return result;
}

export function postalCodeMask_Usa(x: string | null | undefined, mask: string) {
    const digits = x
        ?.replace(/[^0-9]/g, '')
        .split('') ?? [];
    const letters = mask.split('');
    let iDigit = 0;
    let iLast = -1;
    for (let i = 0; i < letters.length; i++) {
        if (letters[i] === '#' && iDigit < digits.length) {
            letters[i] = digits[iDigit];
            iDigit++;
            iLast = i;
        }
    }
    const full = letters.join('');
    const corrected = letters.slice(0, iLast + 1).join('');
    return { valid: corrected === full, corrected, full, digitsNo1: digits.join('') };
}