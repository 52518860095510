import React, { ReactNode, useState } from 'react';
import { Text, View, ActivityIndicator, Modal, TouchableOpacity, Clipboard } from 'react-native';
import { TError } from '../../utils/Hooks';
import { SystemControls } from '../SystemControls';
import { Icon, IconKind } from './Icon';
import { theme } from '../style/Theme';

type ErrorMessageObj = { message?: string, error?: ErrorMessageObj, data?: ErrorMessageObj };
const getInnermostErrorMessage = (error: ErrorMessageObj): undefined | string => {
    return (error.error && getInnermostErrorMessage(error.error))
        ?? (error.data && getInnermostErrorMessage(error.data))
        ?? error.message;
};

export const ErrorBox = (props: { error: Partial<TError>, style?: typeof theme.errorBox | typeof theme.errorBox_inline }) => {
    const [expanded, setExpanded] = useState(false);

    console.log('ErrorBox', { error: props.error, expanded });

    const errorAsString = typeof props.error === 'string' ? props.error + '' : null;
    const errorMessage = getInnermostErrorMessage(props.error) ?? 'Unknown Error';
    const errorDetails = props.error.details;
    const errorObjText = Object.keys(props.error).filter(k => k !== 'message').length > 0 ? SystemControls.isDebug && props.error && JSON.stringify(props.error, null, 2) : undefined;
    const canExpand = !!errorDetails || !!errorObjText;

    const errorBoxStyle = props.style ?? theme.errorBox;

    return (
        <View style={errorBoxStyle.view}>
            <View style={{ flexDirection: 'row' }}>
                <TouchableOpacity style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }} onPress={() => setExpanded(!expanded)}>
                    {canExpand && (expanded ? <Icon style={errorBoxStyle.icon} kind={IconKind.expanded} /> : <Icon style={errorBoxStyle.icon} kind={IconKind.collapsed} />)}
                    <View style={{ paddingRight: 8 }}>
                        <Icon style={errorBoxStyle.icon} kind={IconKind.error} />
                    </View>
                    <Text style={[errorBoxStyle.text, { flex: 1, overflow: 'hidden' }]}>{errorMessage}</Text>
                </TouchableOpacity>
                {SystemControls.isDebug && canExpand && (
                    <TouchableOpacity onPress={() => Clipboard.setString(JSON.stringify({ errorMessage, errorDetails, errorObjText }, null, 2))}>
                        <Icon style={errorBoxStyle.icon} kind={IconKind.copy} />
                    </TouchableOpacity>
                )}
                {props.error.retryCallback && (
                    <TouchableOpacity onPress={props.error.retryCallback}>
                        <Icon style={errorBoxStyle.icon} kind={IconKind.retry} />
                    </TouchableOpacity>
                )}
            </View>
            {canExpand && expanded && !!errorDetails && (<Text style={errorBoxStyle.text}>{errorDetails}</Text>)}
            {canExpand && expanded && !!errorObjText && (<Text style={errorBoxStyle.text}>{errorObjText}</Text>)}
        </View>
    );
};