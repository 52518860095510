import { useEffect, useState, useRef } from 'react';
import { sameArrayContents } from './Arrays';

export type TError = { message: string, details?: string, error: any, retryCallback: () => void };
type TSetError = (error: TError) => void;
type TDoWork = (
    doWorkInner: (stopIfObsolete: (() => void)) => Promise<void>,
    getContext?: () => any[]) => void;


export function useMounted() {
    const mounted = useRef(true);
    useEffect(() => {
        // Unmount on unsub
        return () => {
            mounted.current = false;
        };
    }, [/* Init Only */]);
    return { mounted };
}

/** Automatically handle loading and error objects with asyncronous calls
 * @return The { loading, error, doWork } values
 * @example
 *
 *      const { loading, error, doWork } = ChatHooks.useAutoLoadingError();
 *      ...
 *          doWork(async (stopIfObsolete) => {
 *              ...
 *              const result = async DoAsyncWork());
 *              stopIfObsolete(); // Stop work if component has been unmounted, or if contextValues have changed since beginning of doWork
 *              ...
 *              setResult(result);
 *          }, () => [ contextValueA, contextValueB ]); // Optional context values to stop work if changed
 *    
 */
export function useAutoLoadingError() {
    const { mounted } = useMounted();
    const [loadingError, setLoadingError] = useState({ loading: false, error: null as any });

    const doWork: TDoWork = async (
        doWorkInner: (stopIfObsolete: (() => void)) => Promise<void>,
        getContext?: () => any[]) => {

        let contextInit = undefined as undefined | any[];

        const stopIfObsolete = () => {
            if (!mounted.current) {
                throw 'unmounted';
            }
            const c = getContext?.();

            if (!sameArrayContents(contextInit, c)) {
                throw 'changedContext';
            }
        };

        const doCall = async () => {
            contextInit = getContext?.();
            setLoadingError({ loading: true, error: null });

            let hadError = false;

            try {
                try {
                    await doWorkInner(stopIfObsolete);
                    stopIfObsolete();
                    setLoadingError({ loading: false, error: null });
                }
                catch (err) {
                    // Ignore unmounted or changed context
                    if (err !== 'unmounted' && err !== 'changedContext') {
                        throw err;
                    }
                }
            } catch (err) {
                console.log('doWork catch', { err });
                hadError = true;

                if (!mounted.current) {
                    console.warn('doWork Error when not Mounted', { err });
                    return;
                }

                setLoadingError({ loading: false, error: { message: err.message ?? 'Unknown Error in doWork', error: err, retryCallback: doCall } });
            }
        };

        doCall();
    };

    return { loading: loadingError.loading, error: loadingError.error, doWork };
}

export function useLoadData<T>(doWork: TDoWork, getData: (stopIfObsolete: () => void) => Promise<T>, getDataDeps?: () => any[]) {
    const [data, setData] = useState(null as null | T);
    useEffect(() => {
        console.log('useLoadData.useEffect');
        doWork(async (stopIfObsolete) => {
            console.log('useLoadData.useEffect.doWork');
            const d = await getData(stopIfObsolete);
            stopIfObsolete();
            setData(d);
        }, getDataDeps);
    }, getDataDeps?.() ?? []);
    return { data };
}
