export async function delay(timeout: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(resolve, timeout);
    });
}

export function setValueWithCallback(onValueSet: () => void) {
    return function setValueWithCallback<T>(setValue: (value: T) => void) {
        return (value: T) => {
            setValue(value);
            onValueSet();
        };
    };
}

export type SetValueWithCallback = ReturnType<typeof setValueWithCallback>;