import React, { ReactNode } from 'react';
import { View, ActivityIndicator, Modal } from 'react-native';

export const ActivityModal = () => (
    <View style={{ position: 'fixed' as any, left: 0, right: 0, top: 0, bottom: 0 }}>
        <View style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, backgroundColor: '#888888', opacity: 0.5 }}></View>
        <View style={{ flex: 1, flexDirection: 'row' }}>
            <View style={{ flex: 1, alignContent: 'center', justifyContent: 'center' }}>
                <ActivityIndicator size="large" />
            </View>
        </View>
    </View>
);