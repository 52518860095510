import { TextStyle, ViewStyle, View, Platform, KeyboardTypeOptions } from 'react-native';
import { ListStyle } from '../components/List';
import { MarkdownStyle } from '../components/Markdown';
import { MessageTemplateFieldStyle } from '../views/Campaigns/components/MessageTemplateEditor';
import { AlertModalStyle as AlertModalWebStyle } from '../components/AlertModal';
import { MessageItemStyle } from '../views/Messages/ViewMessageThread';
import { CampaignMessagesPreviewStyle } from '../views/Campaigns/components/CampaignMessagesPreview';

type TextWithFontStyle = TextStyle & { fontFamily: FontFamily };
export type TextViewStyle = {
    view: ViewStyle,
    text: TextWithFontStyle,
    icon?: TextWithFontStyle,
    underline?: ViewStyle,
};
export type TextFieldStyle = {
    view: ViewStyle,
    text: TextViewStyle,
    text_error: TextViewStyle,
    text_disabled: TextViewStyle,
    multiline?: boolean,
    numberOfLines?: number,
    isPassword?: boolean,
    label: TextWithFontStyle,
    hint: TextWithFontStyle,
    statusView: ViewStyle,
    info: TextWithFontStyle,
    error: TextWithFontStyle,
    errorIcon: TextWithFontStyle,
};
export type OptionCommonStyle = {
    noOptionsMessage: TextViewStyle,
};
export type TabBarStyle = OptionCommonStyle & {
    controlKind: 'tabbar',
    container: ViewStyle,
    activeItem: TextViewStyle,
    inactiveItem: TextViewStyle,
};
export type PickerStyle = OptionCommonStyle & {
    controlKind: 'picker',
    picker: ViewStyle,
    pickerItem: TextWithFontStyle,
};
export type OptionControlStyle = PickerStyle | TabBarStyle;
export type OptionFieldStyle = {
    view: ViewStyle,
    label: TextWithFontStyle,
    control: OptionControlStyle,
};
export type HeaderStyle = {
    container: ViewStyle,
    containerInner: ViewStyle,
    itemContainer: ViewStyle,
    item: TextViewStyle,
    item_active: TextViewStyle,
    item_disabled: TextViewStyle,
};
type HeadersStyle = {
    site: HeaderStyle & {
        account: {
            text: TextViewStyle,
        }
    },
    header0: HeaderStyle,
    header1: HeaderStyle,
    header2: HeaderStyle,
};

// Artificial Style Types (it also allows immediately re-using a style)
const S = {
    View: null as any as ViewStyle,
    Text: null as any as TextWithFontStyle,
    TextView: null as any as TextViewStyle,
    TextField: null as any as TextFieldStyle,
    OptionField: null as any as OptionFieldStyle,
    List: null as any as ListStyle,
    Header: null as any as HeaderStyle,
    Markdown: null as any as MarkdownStyle,
    TabBarStyle: null as any as TabBarStyle,
    MessageTemplateFieldStyle: null as any as MessageTemplateFieldStyle,
    Headers: null as any as HeadersStyle,
    AlertModalWeb: null as any as AlertModalWebStyle & { buttonDanger: TextViewStyle },
    MessageItem: null as any as MessageItemStyle,
    CampaignMessagesPreview: null as any as CampaignMessagesPreviewStyle,
    CampaignMessagesPreview_Date: null as any as CampaignMessagesPreviewStyle['dayCell']['normal'],
};


export const colors = {
    purple: '#863d8f',
    orange: '#CC8800',
    buttonWhite_orange: '#FFFFFF',
    purpleLite: '#a75da7',
    purpleBackgroundLite: '#ddbfdd',
    purpleBackgroundLiteLite: '#ffefff',
    blue: '#3baccf',
    gray: '#656364',
    lightGray: '#f8f6f7',
    lightBackgroundGray: '#e8e6e7',
    borderGray: '#d7c7d7',
    headerWhite: '#ffffff',
    headerGray: '#cecece',
    menuWhite: '#ffffff',
    bodyWhite: '#ffffff',
    buttonGray: '#a0a1a5',
    buttonWhite: '#e2e3e9',
    headingGray: '#f8f6f7',
    cardLightGray: '#f8f8f8',
    textWhite: '#ffffff',
    textBlack: '#2b2b2b',
    textGray: '#696768',

    // New Colors
    warnRed: '#C56364',
    errorRed: '#C56364',
    deleteRed: '#C56364',
    changedYellow: '#F5F5C5',
};

const sizes = {
    contentMaxWidth: 800,
    fontSize_tooltip: 12,
};

const fontFamily = Platform.OS === 'ios' ? 'Helvetica'
    : Platform.OS === 'android' ? 'Roboto'
        : '"Trebuchet MS", Helvetica, sans-serif';
type FontFamily = typeof fontFamily;

export const theme = {
    colors,
    sizes,
    default: {
        // fontFamily: 'sans-serif',
        fontSize: 14,
    },
    textInput: S.Text = {
        fontFamily,
        fontSize: 16,
        padding: 4,
        margin: 4,
        borderColor: colors.purple,
        borderWidth: 1,
        borderRadius: 4,
    },
    fieldLabel: S.Text = {
        fontFamily,
        fontSize: 14,
        width: 100,
        fontWeight: 'bold',
        textAlign: 'right',
        color: colors.purple,
    },
    button: S.Text = {
        fontFamily,
        padding: 4,
        margin: 4,
        borderRadius: 4,
        alignContent: 'center',
        justifyContent: 'center',
        backgroundColor: colors.orange,
    },
    buttonText: S.Text = {
        fontFamily,
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        color: colors.buttonWhite_orange,
    },
    header_gap: 4,
    header_fontSize: 24,
    header_back: colors.headerWhite,
    header_text: colors.textBlack,
    headerButton_back: colors.buttonGray,
    headerButton_text: colors.buttonWhite,
    headerNav_text: colors.headerGray,
    menuBar_back: colors.purple,
    menuBar_text: colors.menuWhite,

    errorBox: {
        ...(S.TextView = {
            view: { padding: 8, backgroundColor: colors.errorRed },
            text: { fontFamily, fontSize: 14, fontWeight: 'bold', color: colors.textWhite },
        }),
        icon: S.Text = { fontFamily, fontSize: 30, fontWeight: 'bold', color: colors.textWhite },
    },
    errorBox_inline: {
        ...(S.TextView = {
            view: { padding: 0, },
            text: { fontFamily, fontSize: 14, fontWeight: 'bold', color: colors.errorRed },
        }),
        icon: S.Text = { fontFamily, fontSize: 22, fontWeight: 'bold', color: colors.errorRed },
    },
    alertModalWeb: S.AlertModalWeb = {
        container: {
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            alignContent: 'center',
            justifyContent: 'center',
        },
        background: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            opacity: 0.5,
            backgroundColor: colors.gray,
        },
        view: {
            alignSelf: 'center',
            padding: 8,
            backgroundColor: colors.bodyWhite,
            borderColor: colors.purple,
            borderWidth: 2,
            borderRadius: 8,
        },
        title: S.TextView = {
            view: {
                padding: 8,
            },
            text: {
                fontFamily,
                fontSize: 20, fontWeight: 'bold', color: colors.purple,
            },
        },
        message: S.TextView = {
            view: {
                padding: 8,
            },
            text: {
                fontFamily,
                fontSize: 12, fontWeight: 'bold', color: colors.purple,
            },
        },
        buttonContainer: S.View = {
            flexDirection: 'row',
        },
        button: S.TextView = {
            view: { minWidth: 120, margin: 4, padding: 4, backgroundColor: colors.orange, borderRadius: 4, },
            text: {
                fontFamily,
                fontSize: 14, fontWeight: 'bold', color: colors.buttonWhite_orange,
                textAlign: 'center',
            },
        },
        buttonDanger: S.TextView = {
            view: { minWidth: 120, margin: 4, padding: 4, backgroundColor: colors.deleteRed, borderRadius: 4, },
            text: {
                fontFamily,
                fontSize: 14, fontWeight: 'bold', color: colors.buttonWhite,
                textAlign: 'center',
            },
        },
    },
    nav: {
        row: S.View = { flexDirection: 'row', },
        breadcrumbButton: S.TextView = {
            view: { margin: 4, paddingLeft: 4, paddingRight: 4, backgroundColor: colors.bodyWhite, borderColor: colors.buttonGray, borderWidth: 0, borderLeftWidth: 1, },
            text: { fontFamily, fontSize: 12, fontWeight: 'bold', color: colors.purple, },
        },

        headerView: { padding: 4, backgroundColor: colors.headerWhite },
        buttonTextView: S.TextView = {
            view: { margin: 4, padding: 3, backgroundColor: colors.bodyWhite, borderColor: colors.buttonGray, borderWidth: 1, borderRadius: 4, },
            text: { fontFamily, fontSize: 12, fontWeight: 'bold', color: colors.textGray },
        },
    },
    page: {
        titleView: { padding: 8, marginTop: 16, },
        titleText: S.Text = { fontFamily, fontSize: 18, fontWeight: 'bold', color: colors.purple },
        titleUnderlineView: { marginTop: 8, backgroundColor: colors.purple, width: 40, height: 4, },
    },
    row: {
        container: S.View = { flexDirection: 'row', flexWrap: 'wrap', },
        cell: S.View = { flex: 1, padding: 4, minWidth: 180 },
    },
    info: {
        versionText: S.Text = {
            fontFamily, color: colors.borderGray,
        },
    },
    headers: S.Headers = {
        site: {
            container: S.View = {},
            containerInner: S.View = {
                flexDirection: 'row', justifyContent: 'space-around',
                alignItems: 'center',
                backgroundColor: colors.headerWhite,
                flexWrap: 'wrap',
            },
            itemContainer: S.View = {
                flexShrink: 1,
                flexDirection: 'row', justifyContent: 'space-around',
                flexWrap: 'wrap',
            },
            item: S.TextView = {
                view: { padding: 16, },
                text: { fontFamily, color: colors.purple, },
            },
            item_active: S.TextView = {
                view: { padding: 16, },
                text: { fontFamily, color: colors.purple, fontWeight: 'bold', },
            },
            item_disabled: S.TextView = {
                view: { padding: 16, },
                text: { fontFamily, color: colors.purple, opacity: 0.5 },
            },

            account: {
                text: S.TextView = {
                    view: { padding: 16, },
                    text: { fontFamily, color: colors.purple, },
                },
            }
        },
        header0: S.Header = {
            container: S.View = {},
            containerInner: S.View = {
                flexDirection: 'row', justifyContent: 'space-around',
                alignItems: 'center',
                backgroundColor: colors.purple,
                flexWrap: 'wrap',
            },
            itemContainer: S.View = {
                // Full Width
                flex: 1,
                flexDirection: 'row', justifyContent: 'space-around',
                flexWrap: 'wrap',
            },
            item: S.TextView = {
                view: { padding: 16, },
                text: { fontFamily, color: colors.menuWhite, },
            },
            item_active: S.TextView = {
                view: { padding: 16, backgroundColor: colors.purpleLite, },
                text: { fontFamily, color: colors.menuWhite, fontWeight: 'bold', },
            },
            item_disabled: S.TextView = {
                view: { padding: 16, },
                text: { fontFamily, color: colors.menuWhite, opacity: 0.5 },
            },
        },
        header1: S.Header = {
            container: S.View = {
                flexDirection: 'row', justifyContent: 'center',
            },
            containerInner: S.View = {
                flex: 1,
                flexDirection: 'row', justifyContent: 'space-around',
                // backgroundColor: colors.headerWhite,
                maxWidth: sizes.contentMaxWidth,

                // backgroundColor: colors.bodyWhite,
                backgroundColor: colors.lightGray,

                padding: 2,
                paddingBottom: 0,

                borderColor: colors.purpleLite,
                borderWidth: 2,
                borderTopWidth: 0,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
            },
            itemContainer: S.View = {
                flexShrink: 1,
                flexDirection: 'row', justifyContent: 'space-around',
                flexWrap: 'wrap',
            },
            item: S.TextView = {
                view: { padding: 8, },
                text: { fontFamily, color: colors.purple, },
            },
            item_active: S.TextView = {
                view: {
                    padding: 8,
                    marginTop: 2,
                    marginBottom: -2,

                    backgroundColor: colors.bodyWhite,

                    borderColor: colors.purpleLite,
                    borderWidth: 2,
                    borderBottomWidth: 0,
                    borderRadius: 8,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                },
                text: { fontFamily, color: colors.purple, fontWeight: 'bold' },
            },
            item_disabled: S.TextView = {
                view: { padding: 8, },
                text: { fontFamily, color: colors.textBlack, opacity: 0.35 },
            },
        },
        header2: S.Header = {
            ...S.Header,
            containerInner: {
                ...S.Header.containerInner,
                backgroundColor: colors.bodyWhite,
            },
        },
    },
    headers_fullWidth: {
        ...S.Headers,
        header1: {
            ...S.Headers.header1,
            containerInner: {
                ...S.Headers.header1.containerInner,
                maxWidth: undefined,
            },
        },
        header2: {
            ...S.Headers.header2,
            containerInner: {
                ...S.Headers.header2.containerInner,
                maxWidth: undefined,
            },
        },
    },
    accountInfo: {
        container: S.View = {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            backgroundColor: colors.bodyWhite,
            borderBottomColor: colors.purple,
            borderBottomWidth: 1,
        },
        info: {
            container: S.View = {
                flex: 1,
                minWidth: 100,
                alignItems: 'center',
                borderColor: colors.purple,
                borderWidth: 1,
            },
            label: S.TextView = {
                view: { padding: 4, },
                text: { fontFamily, color: colors.purple, },
            },
            value: S.TextView = {
                view: { padding: 4, },
                text: { fontFamily, color: colors.purple, fontWeight: 'bold', },
            }
        },
    },
    root: {
        normal: {
            view: S.View = {
                backgroundColor: colors.bodyWhite,
                flex: 1,
            },
        },
        fullWidth: {
            view: S.View = {
                backgroundColor: colors.bodyWhite,
                flex: 1,
            },
        },
    },
    content: {
        normal: {
            viewOuter: S.View = {
                flex: 1,
                flexDirection: 'row', justifyContent: 'center',
                // backgroundColor: colors.lightGray,
                minHeight: 'unset',
            },
            view: S.View = {
                flex: 1,
                maxWidth: sizes.contentMaxWidth,
                margin: 8,
                // backgroundColor: colors.bodyWhite,
            },
        },
        fullWidth: {
            viewOuter: S.View = {
                // backgroundColor: colors.lightGray,
                flex: 1,
                minHeight: 'unset',
            },
            view: S.View = {
                flex: 1,
            },
        },
    },
    grid: {
        row_header: S.View = {
            flexDirection: 'row',
            padding: 4,
        },
        row: S.View = {
            flexDirection: 'row',
            flexWrap: 'wrap',
            padding: 4,
            backgroundColor: colors.bodyWhite,
            borderBottomWidth: 2, borderColor: colors.lightGray,
        },
        row_centered: S.View = {
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            padding: 4,
            backgroundColor: colors.bodyWhite,
            borderBottomWidth: 2, borderColor: colors.lightGray,
        },
        row_simple: S.View = {
            flexDirection: 'row',
        },
        cell: S.View = { flex: 1, minWidth: 180, },
        cell2: S.View = { flex: 2, minWidth: 180, },
        cell3: S.View = { flex: 3, minWidth: 180, },
        titleText: S.Text = {
            fontFamily,
            fontSize: 14, fontWeight: 'bold', color: colors.purple,
        },
        descriptionText: S.Text = {
            fontFamily,
            fontSize: 10, color: colors.textGray,
        },
        warningText: S.Text = {
            fontFamily,
            fontSize: 14, color: colors.warnRed,
        },
        dataText: S.Text = {
            fontFamily,
            fontSize: 12, color: colors.textGray,
        },
        dataLink: S.TextView = {
            view: {},
            text: {
                fontFamily,
                fontSize: 12, color: colors.purple,
            }
        },
    },
    messageThreads: {
        item: {
            view: S.View = { borderWidth: 1, borderColor: colors.purple, backgroundColor: colors.lightGray, borderRadius: 4, padding: 8, flex: 1, },
            row: S.View = { flexDirection: 'row', },
            spacer: S.View = { flex: 1, },
            title: S.TextView = {
                view: {},
                text: { fontFamily, fontSize: 12, color: colors.textBlack, },
            },
            message: S.TextView = {
                view: {},
                text: { fontFamily, fontSize: 12, color: colors.textBlack, },
            },
            time: S.TextView = {
                view: {},
                text: { fontFamily, fontSize: 12, color: colors.textBlack, },
            },
        },
        item_reply: {
            view: S.View = { borderWidth: 1, borderColor: colors.purple, backgroundColor: colors.purpleLite, borderRadius: 4, padding: 8, flex: 1, },
            row: S.View = { flexDirection: 'row', },
            spacer: S.View = { flex: 1, },
            title: S.TextView = {
                view: {},
                text: { fontFamily, fontSize: 12, color: colors.textWhite, },
            },
            message: S.TextView = {
                view: {},
                text: { fontFamily, fontSize: 12, color: colors.textWhite, },
            },
            time: S.TextView = {
                view: {},
                text: { fontFamily, fontSize: 12, color: colors.textBlack, },
            },
        },
    },
    message: {
        thread: {
            view: S.View = { borderWidth: 2, borderColor: colors.purple, backgroundColor: colors.lightGray, borderRadius: 4, padding: 8, flex: 1, minHeight: 300 },
            spacer: S.View = { flexGrow: 100 },
        },
        directMessage: {
            view: S.View = {
                marginTop: 4,
                padding: 4,
                backgroundColor: colors.cardLightGray,

                borderColor: colors.purple,
                borderRadius: 4,
                borderBottomWidth: 4,
                paddingBottom: 16 - 4,
            },
        },
        sent: S.MessageItem = {
            view: S.View = { margin: 8, marginLeft: 32, },
            content: S.TextView = {
                view: { borderWidth: 2, borderColor: colors.purple, backgroundColor: colors.bodyWhite, borderRadius: 8, padding: 8, },
                text: { fontFamily, fontSize: 12, color: colors.purple, },
            },
            info: {
                view: { flexDirection: 'row', marginTop: 4, },
                campaignName: S.TextView = {
                    view: { paddingRight: 8, },
                    text: { fontFamily, fontSize: 12, color: colors.purple, },
                },
                campaignRole: S.TextView = {
                    view: { paddingRight: 8, },
                    text: { fontFamily, fontSize: 12, color: colors.textBlack, },
                },
                spacer: S.View = { flex: 1, },
                time: S.TextView = {
                    view: {},
                    text: { fontFamily, fontSize: 12, color: colors.textBlack, },
                },
                time_pending: S.TextView = {
                    view: {},
                    text: { fontFamily, fontSize: 12, color: colors.purple, },
                },
            }
        },
        received: S.MessageItem = {
            ...S.MessageItem,
            view: S.View = { margin: 8, marginRight: 32, },
            content: {
                ...S.MessageItem.content,
                view: { ...S.MessageItem.content.view, backgroundColor: colors.purpleBackgroundLiteLite },
                text: { fontFamily, fontSize: 12, },
            }
        },
    },
    campaignMessagesPreview: S.CampaignMessagesPreview = {
        container: S.View = { flexDirection: 'row' },
        monthsBox: S.View = {},
        monthView: S.View = {},
        monthTitle: S.TextView = {
            view: { height: 32, width: 32 * 7, backgroundColor: colors.purpleLite, justifyContent: 'center', alignContent: 'center' },
            text: { fontFamily, fontSize: 12, color: colors.textWhite, fontWeight: 'bold', textAlign: 'center', },
        },
        weekRow: S.View = { flexDirection: 'row' },
        dayCell: {
            normal: S.CampaignMessagesPreview_Date = {
                container: { height: 32, width: 32, borderWidth: 1, borderColor: colors.purple, },
                label: S.TextView = {
                    view: {},
                    text: { fontFamily, fontSize: 12, color: colors.textBlack },
                },
            },
            selected: S.CampaignMessagesPreview_Date = {
                container: { height: 32, width: 32, borderWidth: 1, borderColor: colors.purple, backgroundColor: colors.purple, },
                label: S.TextView = {
                    view: {},
                    text: { fontFamily, fontSize: 12, color: colors.textWhite, fontWeight: 'bold' },
                },
            },
            startDate: S.CampaignMessagesPreview_Date = {
                container: { height: 32, width: 32, borderWidth: 1, borderColor: colors.purple, backgroundColor: colors.gray, },
                label: S.TextView = {
                    view: {},
                    text: { fontFamily, fontSize: 12, color: colors.textWhite, fontWeight: 'bold' },
                },
            },
            endDate: { ...S.CampaignMessagesPreview_Date },
            dataDate: {
                container: { height: 32, width: 32, borderWidth: 1, borderColor: colors.purple, backgroundColor: colors.gray, },
                label: S.TextView = {
                    view: {},
                    text: { fontFamily, fontSize: 12, color: colors.textWhite, fontWeight: 'bold' },
                },
            },
            empty: {
                container: { height: 32, width: 32 },
                label: S.TextView = {
                    view: {},
                    text: { fontFamily, fontSize: 12, color: colors.textWhite, fontWeight: 'bold' },
                },
            },
        },
        messagesContainer: { flex: 1 },
        messagesHeading: S.TextView = {
            view: { padding: 4, },
            text: { fontFamily, fontSize: 14, fontWeight: 'bold', color: colors.purple, textAlign: 'center' },
        },
        messageReply: {
            container: { marginLeft: 32, },
            header: S.TextView = {
                view: { marginLeft: 32, },
                text: { fontFamily, fontSize: 12, fontWeight: 'bold', color: colors.purple, textAlign: 'left' },
            },
        },
        message: S.MessageItem = {
            view: S.View = { margin: 8, marginLeft: 32, },
            content: S.TextView = {
                view: { borderWidth: 2, borderColor: colors.purple, backgroundColor: colors.bodyWhite, borderRadius: 8, padding: 8, },
                text: { fontFamily, fontSize: 12, color: colors.purple, },
            },
            info: {
                view: { flexDirection: 'row', marginTop: 4, },
                campaignName: S.TextView = {
                    view: { paddingRight: 8, },
                    text: { fontFamily, fontSize: 12, color: colors.purple, },
                },
                campaignRole: S.TextView = {
                    view: { paddingRight: 8, },
                    text: { fontFamily, fontSize: 12, color: colors.textBlack, },
                },
                spacer: S.View = { flex: 1, },
                time: S.TextView = {
                    view: {},
                    text: { fontFamily, fontSize: 12, color: colors.textBlack, },
                },
                time_pending: S.TextView = {
                    view: {},
                    text: { fontFamily, fontSize: 12, color: colors.purple, },
                },
            }
        },
    },
    card: {
        // Old
        headerText: S.Text = { fontFamily, fontSize: 14, fontWeight: 'bold', color: colors.textBlack },
        header2Text: S.Text = { fontFamily, fontSize: 12, fontWeight: 'bold', color: colors.textBlack },
        paragraphText: S.Text = { fontFamily, fontSize: 12, color: colors.textBlack },
        fieldView: S.View = { flexDirection: 'row', paddingTop: 4, paddingBottom: 4, alignItems: 'center' },
        fieldView_top: S.View = { flexDirection: 'row', paddingTop: 4, paddingBottom: 4, alignItems: 'flex-start' },
        fieldLabelText: S.Text = { fontFamily, minWidth: 80, paddingRight: 4, fontSize: 14, fontWeight: 'bold', color: colors.textBlack },
        fieldValueText: S.Text = { fontFamily, fontSize: 14, color: colors.textBlack },
        buttonView: { margin: 4, padding: 4, backgroundColor: colors.orange, borderRadius: 4, },
        buttonText: S.Text = { fontFamily, fontSize: 16, fontWeight: 'bold', color: colors.buttonWhite_orange },
        button2View: { margin: 4, padding: 3, backgroundColor: colors.bodyWhite, borderColor: colors.buttonGray, borderWidth: 1, borderRadius: 4, },
        button2Text: S.Text = { fontFamily, fontSize: 16, fontWeight: 'bold', color: colors.textGray },

        // New
        headerRow: S.View = { flexDirection: 'row', justifyContent: 'space-between' },

        headerTextView: S.TextView = {
            view: {},
            text: { fontFamily, fontSize: 14, fontWeight: 'bold', color: colors.textBlack }
        },
        paragraphTextView: S.TextView = {
            view: {},
            text: { fontFamily, fontSize: 12, color: colors.textBlack }
        },
        navTextView: S.TextView = {
            view: {},
            text: { fontFamily, fontSize: 12, color: colors.purple }
        },
        infoTextView: S.TextView = {
            view: { alignContent: 'center', justifyContent: 'center' },
            text: { fontFamily, textAlign: 'center', fontSize: 14, fontWeight: 'bold', color: colors.purple }
        },
        warnTextView: S.TextView = {
            view: { alignContent: 'center', justifyContent: 'center' },
            text: { fontFamily, textAlign: 'center', fontSize: 14, fontWeight: 'bold', color: colors.warnRed }
        },

        view: S.View = {
            marginBottom: 8,
            marginTop: 8,
            padding: 16,
            backgroundColor: colors.cardLightGray,

            borderColor: colors.purple,
            borderRadius: 4,
            borderBottomWidth: 4,
            paddingBottom: 16 - 4,
        },
        view_changed: {
            ...S.View,
            backgroundColor: colors.changedYellow,
        },
        view_deleted: {
            ...S.View,
            backgroundColor: colors.deleteRed,
        },
        view_disabled: {
            ...S.View,
            // opacity: 0.5,
            backgroundColor: colors.headerGray,
            borderColor: colors.gray,
        },
        view_white: S.View = {
            ...S.View,
            opacity: undefined,
            backgroundColor: colors.bodyWhite,

            borderColor: colors.lightGray,
            borderWidth: 2,
            padding: 16 - 2,
            borderRadius: 4,

            borderBottomColor: colors.purple,
            borderBottomWidth: 4,
            paddingBottom: 16 - 4,
        },
        view_white_disabled: {
            ...S.View,
            // opacity: 0.5,
            backgroundColor: colors.lightGray,
            borderBottomColor: colors.lightGray,
        },

        view_selectionActive: {
            ...S.View,
            // opacity: 0.5,
            backgroundColor: colors.purpleBackgroundLite,
            borderBottomColor: colors.purple,
        },
        view_selectionInactive: {
            ...S.View,
            // opacity: 0.5,
            backgroundColor: colors.lightBackgroundGray,
            borderBottomColor: colors.gray,
        },

        tab_selectionActive: {
            ...S.View,
            margin: 4,
            padding: 4,
            // opacity: 0.5,
            backgroundColor: colors.purpleBackgroundLite,
            borderBottomColor: colors.purple,
        },
        tab_selectionInactive: {
            ...S.View,
            margin: 4,
            padding: 4,
            // opacity: 0.5,
            backgroundColor: colors.lightBackgroundGray,
            borderBottomColor: colors.gray,
        },
        tabTextView: S.TextView = {
            view: {
                padding: 4, paddingLeft: 0,
            },
            text: {
                fontFamily,
                fontSize: 16, fontWeight: 'bold', color: colors.purple,
            },
            underline: {
                marginTop: 4,
                backgroundColor: colors.purple,
                width: 16, height: 2,
            },
        },

        cardTitle: S.TextView = {
            view: {
                padding: 8, paddingLeft: 0,
                marginTop: 16, marginBottom: 16,
            },
            text: {
                fontFamily,
                fontSize: 24, fontWeight: 'bold', color: colors.purple,
            },
            underline: {
                marginTop: 8,
                backgroundColor: colors.purple,
                width: 40, height: 4,
            },
        },

        cardSectionTitle: S.TextView = {
            view: {
                padding: 4,
            },
            text: {
                fontFamily,
                fontSize: 18, fontWeight: 'bold', color: colors.purple,
            },
            underline: {
                marginTop: 8,
                backgroundColor: colors.purple,
                width: 20, height: 4,
            },
        },
        cardSectionTitle_small: S.TextView = {
            view: {
                padding: 4,
                paddingLeft: 0,
            },
            text: {
                fontFamily,
                fontSize: 14, fontWeight: 'bold', color: colors.purple,
            },
            underline: {
                marginTop: 4,
                backgroundColor: colors.purple,
                width: 40, height: 2,
            },
        },

        cardSummary: S.TextView = {
            view: {
                padding: 8, paddingLeft: 0, paddingTop: 0,
                marginTop: 0, marginBottom: 16,
            },
            text: {
                fontFamily,
                fontSize: 14, color: colors.purple,
            },
        },
        cardSummary_warning: S.TextView = {
            view: {
                padding: 8, paddingLeft: 0, paddingTop: 0,
                marginTop: 0, marginBottom: 16,
            },
            text: {
                fontFamily,
                fontSize: 14, color: colors.warnRed,
            },
        },
        cardTextInline: S.TextView = {
            view: {
            },
            text: {
                fontFamily,
                fontSize: 14, color: colors.purple,
            },
        },
        cardTextInline_error: S.TextView = {
            view: {
            },
            text: {
                fontFamily,
                fontSize: 14, color: colors.errorRed,
            },
        },


        textField: S.TextField = {
            view: { paddingTop: 4, paddingBottom: 4, marginRight: 16, },
            text: S.TextView = {
                view: S.View = {
                    borderStyle: 'solid', borderWidth: 2, borderRadius: 4, borderColor: colors.borderGray,
                },
                text: S.Text = {
                    fontFamily,
                    padding: 8,
                    fontSize: 16, color: colors.textBlack,
                },
            },
            text_error: {
                ...S.TextView,
                text: {
                    ...S.Text,
                    color: colors.errorRed,
                },
            },
            text_disabled: {
                ...S.TextView,
                view: {
                    ...S.View,
                    borderStyle: 'solid', borderWidth: 2, borderRadius: 4, borderColor: colors.borderGray,
                    backgroundColor: colors.lightGray,
                },
                text: {
                    ...S.Text,
                    color: colors.textGray,
                },
            },
            label: {
                fontFamily,
                fontSize: 18, fontWeight: 'bold', color: colors.textGray,
                padding: 4,
            },
            hint: {
                fontFamily,
                fontSize: 14, color: colors.textGray,
                padding: 4,
            },
            statusView: {
                flexDirection: 'row',
                alignSelf: 'flex-end',
                alignItems: 'center',
                padding: 4,
                minHeight: 32,
            },
            info: {
                fontFamily,
                fontSize: 12, color: colors.textGray,
            },
            error: {
                fontFamily,
                fontSize: 14, fontWeight: 'bold', color: colors.errorRed,
            },
            errorIcon: {
                fontFamily,
                fontSize: 20, fontWeight: 'bold', color: colors.errorRed,
            },
        },
        textField_password: S.TextField = {
            ...S.TextField,
            isPassword: true,
        },
        header_fieldSection: S.TextView = {
            view: S.TextField.view,
            text: { ...S.TextField.label, fontSize: 20, },
        },
        listEditor: {
            label: S.TextField.label,
        },
        messageTemplateField: S.MessageTemplateFieldStyle = {
            container: S.View = {
                borderColor: colors.lightGray,
                backgroundColor: colors.lightGray,
                borderWidth: 2,
                borderRadius: 4,
                padding: 8,
                margin: 4,
            },
            editor: {
                ...S.TextField,
                multiline: true,
                numberOfLines: 3,
                text: { ...S.TextField.text, view: { ...S.TextField.text.view, backgroundColor: colors.bodyWhite, borderColor: colors.purple, } },
                text_error: { ...S.TextField.text_error, view: { ...S.TextField.text.view, backgroundColor: colors.bodyWhite, borderColor: colors.purple, } },
            },
            tabBar: {
                controlKind: 'tabbar',
                container: { flexDirection: 'row', alignSelf: 'flex-end', },
                activeItem: {
                    ...S.TextField.text,
                    view: { ...S.TextField.text.view, backgroundColor: colors.purple, },
                    text: { ...S.TextField.text.text, color: colors.textWhite, fontWeight: 'bold', },
                },
                inactiveItem: {
                    ...S.TextField.text,
                    view: { ...S.TextField.text.view, backgroundColor: colors.bodyWhite, },
                },
                noOptionsMessage: {
                    text: { ...S.TextField.text.text, color: colors.errorRed, },
                    view: { ...S.TextField.text.view, backgroundColor: colors.bodyWhite, },
                },
            },
        },
        messageTemplateField_small: S.MessageTemplateFieldStyle = {
            ...S.MessageTemplateFieldStyle,
            container: S.View = {
                borderColor: colors.lightGray,
                backgroundColor: colors.lightGray,
                borderWidth: 2,
                borderRadius: 4,
                padding: 4,
                margin: 2,
            },
            editor: {
                ...S.TextField,
                multiline: true,
                numberOfLines: 3,
                label: { ...S.TextField.label, fontSize: 14, padding: 2, },
                hint: { ...S.TextField.hint, fontSize: 12, padding: 2, },
                info: { ...S.TextField.info, fontSize: 12, padding: 0, },
                statusView: { ...S.TextField.statusView, minHeight: undefined, padding: 2, },
                text: { ...S.TextField.text, text: { ...S.TextField.text.text, fontSize: 14 }, view: { ...S.TextField.text.view, backgroundColor: colors.bodyWhite } },
            },
        },
        textFieldMultiline: S.TextField = {
            ...S.TextField,
            multiline: true,
            numberOfLines: 3,
        },
        optionField: S.OptionField = {
            ...S.TextField,
            control: {
                controlKind: 'picker',
                picker: {
                    padding: 8,
                    borderStyle: 'solid', borderWidth: 2, borderRadius: 4, borderColor: colors.lightGray,
                },
                pickerItem: {
                    ...S.TextField.text.text,
                },
                noOptionsMessage: {
                    text: { ...S.TextField.text.text, color: colors.errorRed, },
                    view: { ...S.TextField.text.view, backgroundColor: colors.bodyWhite, },
                },
            }
        },
        actionArea: S.View = { marginTop: 20, },

        row: S.View = { flexDirection: 'row' },
        rowWrap: S.View = { flexDirection: 'row', flexWrap: 'wrap' },
        buttonRow: S.View = { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
        buttonRow_center: S.View = { flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' },
        buttonSpacer: S.View = { flexGrow: 3 },
        buttonTextView_major_stripe: S.TextView = {
            view: { margin: 4, padding: 4, minWidth: 100, backgroundColor: colors.orange, borderRadius: 4 },
            text: {
                fontFamily,
                fontSize: 14, fontWeight: 'bold', color: colors.buttonWhite_orange,
                textAlign: 'center',
            },
        },
        buttonTextView_major_noFlex: S.TextView = {
            view: { margin: 4, padding: 4, minWidth: 100, backgroundColor: colors.orange, borderRadius: 4 },
            text: {
                fontFamily,
                fontSize: 14, fontWeight: 'bold', color: colors.buttonWhite_orange,
                textAlign: 'center',
            },
        },
        buttonTextView_major: S.TextView = {
            view: { minWidth: 100, margin: 4, padding: 4, backgroundColor: colors.orange, borderRadius: 4 },
            text: {
                fontFamily,
                fontSize: 14, fontWeight: 'bold', color: colors.buttonWhite_orange,
                textAlign: 'left',
            },
        },
        buttonTextView_admin: S.TextView = {
            view: { minWidth: 100, margin: 4, padding: 4, backgroundColor: colors.purple, borderRadius: 4 },
            text: {
                fontFamily,
                fontSize: 14, fontWeight: 'bold', color: colors.buttonWhite_orange,
                textAlign: 'left',
            },
        },
        buttonTextView_major_disabled: S.TextView = {
            view: { minWidth: 100, margin: 4, padding: 4, backgroundColor: colors.orange, borderRadius: 4, opacity: 0.5 },
            text: {
                fontFamily,
                fontSize: 14, fontWeight: 'bold', color: colors.buttonWhite_orange,
                textAlign: 'left',
            },
        },
        buttonTextView_minor: S.TextView = {
            view: { minWidth: 100, margin: 4, padding: 3, backgroundColor: colors.bodyWhite, borderColor: colors.purple, borderWidth: 1, borderRadius: 4, },
            text: {
                fontFamily,
                fontSize: 14, fontWeight: 'bold', color: colors.purple,
                textAlign: 'left',
            },
        },
        buttonTextView_minor_inline: S.TextView = {
            view: { minWidth: 100, margin: 2, padding: 2, backgroundColor: colors.bodyWhite, borderColor: colors.purple, borderWidth: 1, borderRadius: 4, alignItems: 'center', justifyContent: 'center' },
            text: {
                fontFamily,
                fontSize: 14, fontWeight: 'bold', color: colors.purple,
                textAlign: 'left',
            },
        },
        buttonTextView_danger: S.TextView = {
            view: { minWidth: 100, margin: 4, padding: 3, backgroundColor: colors.deleteRed, borderColor: colors.buttonGray, borderWidth: 1, borderRadius: 4, },
            text: {
                fontFamily,
                fontSize: 14, fontWeight: 'bold', color: colors.textWhite,
                textAlign: 'left',
            },
        },
        rowActionButton: {
            view: S.View = { alignContent: 'center', justifyContent: 'center', },
            buttonTextView: S.TextView = {
                view: { margin: 4, padding: 3, backgroundColor: colors.bodyWhite, borderColor: colors.purple, borderWidth: 1, borderRadius: 4, },
                text: {
                    fontFamily,
                    fontSize: 14, fontWeight: 'bold', color: colors.purple,
                    textAlign: 'center',
                    width: 14,
                    height: 14,
                },
            },
        },

        shortcutHeader: S.TextView = {
            view: { marginLeft: 4 },
            text: {
                fontFamily,
                fontSize: 14, fontWeight: 'bold', color: colors.textBlack,
                textAlign: 'center',
            },
        },
        shortcutButton: S.TextView = {
            view: {},
            text: {
                fontFamily,
                fontSize: 14, fontWeight: 'bold', color: colors.purple,
                textAlign: 'center',
            },
        },

    },
    list: S.List = {
        info: S.TextView = {
            view: { margin: 4, padding: 2 },
            text: {
                fontFamily,
                fontSize: 14, fontWeight: 'bold', color: colors.textGray, textAlign: 'center'
            },
        },
        loadingColor: colors.purple,
        search: S.TextView = {
            view: { margin: 4, padding: 4, paddingLeft: 8, paddingRight: 8, backgroundColor: colors.bodyWhite, borderColor: colors.buttonGray, borderWidth: 1, borderRadius: 100, },
            text: {
                fontFamily,
                fontSize: 16, fontWeight: 'bold', color: colors.textGray
            },
        },
        button: S.TextView = {
            view: { minWidth: 100, margin: 4, padding: 4, backgroundColor: colors.orange, borderRadius: 4, alignSelf: 'center' },
            text: {
                fontFamily,
                fontSize: 14, fontWeight: 'bold', color: colors.buttonWhite_orange,
                textAlign: 'left',
            },
        },
    },

    markdown: S.Markdown = {
        root: {},
        heading: S.Text = { fontFamily, padding: 8, color: colors.purple },
        heading1: S.Text = { ...S.Text, fontSize: 24 },
        heading2: S.Text = { ...S.Text, fontSize: 20 },
        heading3: S.Text = { ...S.Text, fontSize: 18 },
        heading4: S.Text = { ...S.Text, fontSize: 16 },
        heading5: S.Text = { ...S.Text, fontSize: 14 },
        heading6: S.Text = { ...S.Text, fontSize: 12 },
        paragraph: S.Text = { fontFamily, fontSize: 12, padding: 8 },
    },
};
