export function itemIdsMatch<TA, TB>(a: TA[], b: TB[], aGetId: (a: TA) => string, bGetId: (b: TB) => string) {
    const aIds = a?.filter(x => x).map(x => aGetId(x) ?? '').filter(x => x);
    const bIds = b?.filter(x => x).map(x => bGetId(x) ?? '').filter(x => x);

    const aObj = aIds.reduce((out, x) => { out[x] = true; return out; }, {} as { [key: string]: boolean }) ?? {};
    const bObj = bIds.reduce((out, x) => { out[x] = true; return out; }, {} as { [key: string]: boolean }) ?? {};

    const aKeys = Object.keys(aObj).sort().join(';');
    const bKeys = Object.keys(bObj).sort().join(';');

    console.log('itemIdsMatch', { isMatch: aKeys === bKeys, aKeys, bKeys, a, b });
    return aKeys === bKeys;
}

export function sameArrayContents<T>(a: undefined | null | T[], b?: undefined | null | T[]) {
    if (a === b) { return true; }
    if (a == null && b == null) { return true; }
    if (a == null || b == null) { return false; }
    if (a.length !== b.length) { return false; }
    for (const i in a) {
        if (a[i] !== b[i]) { return false; }
    }
    return true;
}

// Unique Items - last one wins (preserving order)
export function distinct<TA>(a: TA[], aGetId: (a: TA) => string) {
    const final = [] as TA[];
    const keys = {} as { [key: string]: boolean };

    // Go through items (reversed) and copy if key doesn't exist yet
    let i = 0;
    for (let iSource = a.length - 1; iSource >= 0; iSource--) {
        const key = aGetId(a[iSource]);
        if (keys[key]) { continue; }
        keys[key] = true;
        final[i] = a[iSource];
        i++;
    }

    final.reverse();
    return final;
}

export function distinctMap<TA>(a: TA[], aGetId: (a: TA) => string, reduceSame: (prior: TA, match: TA) => TA) {
    const final = [] as TA[];
    const keys = {} as { [key: string]: boolean };

    // Go through items (reversed) and copy if key doesn't exist yet
    let i = 0;
    for (let iSource = a.length - 1; iSource >= 0; iSource--) {
        const key = aGetId(a[iSource]);
        if (keys[key]) {
            const iSame = final.findIndex(x => aGetId(x) === key);
            final[iSame] = reduceSame(final[iSame], a[iSource]);
            continue;
        }
        keys[key] = true;
        final[i] = a[iSource];
        i++;
    }

    final.reverse();
    return final;
}

/** Merge b into a via keys - will also reduce a to items with distinct keys, preserving order, last item wins */
export function merge<TA, TB, TResult>(a: TA[], b: TB[], aGetId: (a: TA) => string, bGetId: (b: TB) => string, map: (a: TA, b?: TB) => TResult) {
    const final = [] as TResult[];
    const keys = {} as { [key: string]: boolean };
    const bItemsWithKeys = b.map(x => ({ x, key: bGetId(x) }));
    const bItemsDict = bItemsWithKeys.reduce((out, x) => { out[x.key] = x.x; return out; }, {} as { [key: string]: TB });

    // Go through items (reversed) and copy if key doesn't exist yet
    let i = 0;
    for (let iSource = a.length - 1; iSource >= 0; iSource--) {
        const key = aGetId(a[iSource]);
        if (keys[key]) { continue; }
        keys[key] = true;

        final[i] = map(a[iSource], bItemsDict[key]);
        i++;
    }

    final.reverse();
    return final;
}

export function toDictionary<T>(items: T[], getKey: (t: T) => string) {
    const result = {} as { [key: string]: T };
    items.forEach(t => result[getKey(t)] = t);
    return result;
}

export function shuffle<T>(items: T[]) {
    return items.map(x => ({ x, i: Math.random() })).sort((a, b) => a.i - b.i).map(x => x.x);
}