import { AppConfig_Client } from "../../../_config/client/appConfig_client";
import { webRequest } from "../../../utils/WebRequest";
import { UserCredentials } from "../../../data-types/SecurityDataTypes";
import { AuthApi, AuthApiEndpointName, AuthApiRequestData } from "./AuthApiWebClientTypes";
import { AccountModel } from "../../../data-types/ModelTypes";
import { Reference } from "../../../data-types/SimpleDataTypes";

export const createAuthApiWebClient = (appConfig: AppConfig_Client): AuthApi => {

    async function authRequest<T>(endpoint: AuthApiEndpointName, data: unknown): Promise<T> {
        const url = `${appConfig.authApiDomain}/${endpoint}`;
        const requestData: AuthApiRequestData = {
            endpoint,
            data,
        };

        const resultObj = (await webRequest(url, requestData)) as { data: T };

        return resultObj.data;
    };

    const api: AuthApi = {
        checkAndRefreshUserCredentials: (data) => authRequest('checkAndRefreshUserCredentials', data),
        createAccount: (data) => authRequest('createAccount', data),
        login: (data) => authRequest('login', data),
        requestEmailVerificationCode: (data) => authRequest('requestEmailVerificationCode', data),
        requestPhoneVerificationCode: (data) => authRequest('requestPhoneVerificationCode', data),
        verifyEmail: (data) => authRequest('verifyEmail', data),
        verifyPhone: (data) => authRequest('verifyPhone', data),
        requestPasswordReset: (data) => authRequest('requestPasswordReset', data),
        changePassword: (data) => authRequest('changePassword', data),
        resetPassword: (data) => authRequest('resetPassword', data),
    };

    return api;
}
