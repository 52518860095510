import React from 'react';
import { MediaUploaderWeb } from "./MediaUploaderWeb";
import { UploadApiAccess } from "../../client-api-access/types/UploadApiAccess";
import { Platform, View } from 'react-native';

export const MediaUploader = (props: { uploadApi: UploadApiAccess, onUploadingStart?: () => void, onUploaded: (readUrl: string) => void, onError: (message: string) => void, maxFileSize?: number, onClear: () => void }) => {

    if (Platform.OS === 'web') {
        return (
            <MediaUploaderWeb {...props} />
        );
    }

    return (<View></View>);
};