import React, { ReactNode, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Store } from '../../store/Store';
import { Builder, BuilderResult } from '../../../utils/Builder';
import { SystemControls } from '../../SystemControls';
import { TextView } from '../../components/TextView';

export function BuilderDebugInfo<TCampaign>(props: {
    store: Store,
    builder: Builder<TCampaign>,
    builderResult: BuilderResult<TCampaign>,
}) {
    const theme = props.store.theme;

    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpanded = () => {
        setIsExpanded(s => !s);
    };

    if (!SystemControls.isDebug) { return null; }
    return (
        <TouchableOpacity onPress={toggleExpanded}>
            {!isExpanded && (
                <View style={{ height: 20 }}></View>
            )}
            {isExpanded && (
                <>
                    <View style={theme.card.view} >
                        <TextView style={theme.card.cardTitle} text='Debug (Ignore)' />
                        <Text style={theme.card.headerText}>Result</Text>
                        <Text>{JSON.stringify(props.builderResult, null, 2)}</Text>
                        <Text style={theme.card.headerText}>Builder</Text>
                        <Text>{JSON.stringify(props.builder, null, 2)}</Text>

                    </View>
                </>
            )}
        </TouchableOpacity>
    );
}