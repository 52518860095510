// Scalars
export type PhoneNumber = string & { __type: 'PhoneNumber', format: '+19785551234' };
export type EmailAddress = string & { __type: 'EmailAddress', format: 'email@domain.com' };
export type Price = number & { __type: 'Price', format: 'US Dollars with decimal cents' };

export const Price = {
    /** This would round up to the next cent */
    toPrice(amount: number) {
        return Math.ceil(amount * 100) / 100 as Price;
    },
    formatPrice(price: Price) {
        return `$${Price.toPrice(price)}`;
    },
    parsePrice(text: string | undefined | null) {
        const t = text || '0';
        const tTrimmed = t.replace(/^\$/, '');
        const v = parseFloat(tTrimmed);
        return Price.toPrice(v);
    },
    toUsdCents(price: Price) {
        return Math.floor(price * 100);
    }
};

export type Reference<T extends { id: string, }> = {
    __type: 'Reference';
    id: string;
};
// export function getReference<T extends { id: string, }>(item: T): Reference<T>
export function getReference<T extends { id: string, }>(item: T | Reference<T>): Reference<T> {
    return { id: item.id, __type: 'Reference', };
}
export function getReferenceOrUndefined<T extends { id: string, }>(item: T | Reference<T> | undefined): Reference<T> | undefined {
    return item && { id: item.id, __type: 'Reference', } || undefined;
}
export function getReferenceFromId<T extends { id: string }>(id: string): Reference<T> {
    return { id, __type: 'Reference' };
}

export function getReference_Empty<T extends { id: string }>(): Reference<T> {
    return { id: '00000000-0000-0000-0000-000000000000', __type: 'Reference' };
}

export type ReferenceList<T extends { id: string }> = {
    __type: 'ReferenceList';
    // lookupId: string;
    // load: () => LazyItems<T>;
};

// export type ReferenceList_Load<T extends { id: string }> = ReferenceList<T> & {
//     load: () => LazyItems<T>;
// };

export const ReferenceList = {
    empty<T extends { id: string }>(): ReferenceList<T> {
        return { __type: 'ReferenceList' };
    }
};

