import { AppApiAccess } from "./types/AppApiAccess";
import { ContactListModel, ContactModel, MessageThread, CampaignModel, MessageTemplateModel, ContactCampaignStateModel, MessageThreadInfo, AccountState, AccountPhoneNumberModel, getEmptyAccountState, CampaignStateModel, CampaignStats, CampaignModel_Standard } from "../data-types/ModelTypes";
import { ReferenceList, PhoneNumber, getReferenceFromId, getReference, Reference, Price, getReference_Empty } from "../data-types/SimpleDataTypes";
import { LazyItems } from "../utils/LazyItems";
import { AppConfig_Client } from "../_config/client/appConfig_client";
import { createRelationalWebClient } from "../api/relational-data/client/RelationalApiWebClient";
import { ValueOrPromise } from "../utils/ValueOrPromise";
import { distinct } from "../utils/Arrays";
import { ReferenceList_FromServer, Model_FromServer, Model_ToServer, cleanModelReferences } from "../data-types/TypeTransformations";
import { TimeZone, Timestamp, DateOnly } from "../utils/Time";
import { getCampaignData } from "../logic/campaigns/campaignData";
import { ApiError } from "../utils/Errors";
import { getKeysTyped } from "../utils/TypeFunctions";
import { UserCredentials, UserCredentialsProvider } from "../data-types/SecurityDataTypes";
import { createAppApiWebClient } from "../api/app/client/AppApiWebClient";
import { splitKeywords } from "../utils/Keywords";

const pagedRequestToLazyList = <T extends { id: string }>(
    debug_name: string,
    getItems: (paging: { skip: number, take: number }) => Promise<T[]>,
    getCount: () => Promise<number>,
    options?: { take: number },
) => {
    let items: T[] = [];
    let count = null as null | number;
    let hasFailed = false;
    let isLoading = false;

    const countValueOrPromise: ValueOrPromise<number> = {
        value: count,
        load: async () => {
            if (count !== null) { return count; }
            count = await getCount().catch(err => {
                hasFailed = true;
                throw new ApiError(`Paged Api Request '${debug_name}': Cannot get item count`, undefined, err);
            });
            return count;
        },
        valueOrLoad: async () => await countValueOrPromise.load()
    };

    // const firstPage = { skip: 0, take: options?.take ?? 50 };
    // let items = await getItems(firstPage);
    // const count = await getCount();

    // This assumes an unchanging list (count does not change)
    const lazyItems: LazyItems<T> = {
        totalCount: () => countValueOrPromise,
        hasMore: () => !hasFailed && (count === null || count > items.length),
        itemsLoaded: items,
        loadMore: async () => {

            if (isLoading) {
                console.log(`lazyItems.loadMore SKIPPED - Already loading ${debug_name}`, { count, items, countValueOrPromise });
                return;
            }

            console.log(`lazyItems.loadMore START ${debug_name}`, { count, items, countValueOrPromise });

            try {
                isLoading = true;

                if (hasFailed) {
                    console.log(`lazyItems.loadMore PREVIOUSLY FAILED to get more ${debug_name}`, { count, items, countValueOrPromise });
                    return;
                }

                // Ensure count is loaded
                if (count == null) {
                    await countValueOrPromise.load();
                }

                const countStart = count ?? 0;

                // Skip if no more
                if (!lazyItems.hasMore()) {
                    console.log(`lazyItems.loadMore NO MORE ${debug_name}`, { count, items, countValueOrPromise });
                    return;
                }

                const paging = { skip: items.length, take: options?.take ?? 50 };

                console.log(`lazyItems.loadMore getItems ${debug_name}`, { paging, count });
                const moreItems = await getItems(paging)
                    .catch(err => {
                        hasFailed = true;
                        throw new ApiError(`Paged Api Request '${debug_name}': Cannot get items`, { paging }, err);
                    });

                console.log(`lazyItems.loadMore getItems pageDone ${debug_name}`, { moreItems });

                if (moreItems.length === 0) {
                    hasFailed = true;
                    throw new ApiError(`Paged Api Request '${debug_name}': Failed to load more items`, { paging });
                }

                const newItems = moreItems.filter(x => !items.some(y => y.id === x.id));
                items.push(...newItems);

                // if (countStart <= (count ?? 0)) {
                //     hasFailed = true;
                // }

                console.log(`lazyItems.loadMore END ${debug_name}`, { count, items });
            } finally {
                isLoading = false;
            }
        },
    };
    return lazyItems;
};

async function loadAllLazyList<T extends {
    id: string;
}>(lazyItems: LazyItems<T>) {
    while (lazyItems.hasMore()) {
        await lazyItems.loadMore();
    }
    return lazyItems.itemsLoaded;
}



export type PhoneNumberInfoApi = {
    getPhoneNumberTimeZone(phoneNumber: PhoneNumber): Promise<TimeZone>;
};

export function createAppApiAccess(appConfig: AppConfig_Client, userCredentialsProvider: UserCredentialsProvider): AppApiAccess {

    const phoneInfoApi: PhoneNumberInfoApi = {
        getPhoneNumberTimeZone: () => {
            console.warn('getPhoneNumberTimeZone is Not Implemented');
            return Promise.resolve(0 as TimeZone);
        },
    };

    const dataApi = createRelationalWebClient(appConfig, userCredentialsProvider);
    const appApi = createAppApiWebClient(appConfig, userCredentialsProvider);

    const defaultAccountState: AccountState = getEmptyAccountState();
    const state = {
        accountState: defaultAccountState,
    };

    const cache = {} as { [key: string]: unknown };
    const getValueOrPromiseItemWithCache = <T extends { id: string }>(ref: Reference<T>, getResult: () => ValueOrPromise<T>): ValueOrPromise<T> => {
        const obj = cache[ref.id];
        console.log('getValueOrPromiseItemWithCache', { ref, obj });
        if (obj) { return obj as ValueOrPromise<T>; }

        return cache[ref.id] = getResult();
    };
    const getValueOrPromiseListWithCache = <T extends { id: string }>(listRef: ReferenceList<T>, getResult: () => ValueOrPromise<T[]>): ValueOrPromise<T[]> => {
        const listRefKey = JSON.stringify(listRef);
        const obj = cache[listRefKey];
        console.log('getValueOrPromiseListWithCache', { listRefKey, obj });
        if (obj) { return obj as ValueOrPromise<T[]>; }

        return cache[listRefKey] = getResult();
    };

    const refListToLazyItems = <T extends { id: string }>(
        listRef: ReferenceList<T>,
        toModel: (modelFromServer: Model_FromServer<T>) => T,
        options?: { take: number },
    ): LazyItems<T> => {
        const listRef_server = listRef as ReferenceList_FromServer<T>;
        if (!listRef_server.queryName) {
            throw new ApiError('Reference List Error: Unknown QueryName', { listRef });
        }

        // const lazyListKey = JSON.stringify(listRef);
        // const obj = cache[lazyListKey];
        // console.log('refListToLazyItems', { lazyListKey, obj });
        // if (obj) { return obj as LazyItems<T>; }

        const lazyItems = pagedRequestToLazyList(
            `queryName: ${listRef_server.queryName}`,
            async (paging) => (await dataApi.namedEndpoint<T>(listRef_server.queryName, listRef_server.queryArgs, paging)).items.map(toModel),
            async () => (await dataApi.namedEndpointCount(listRef_server.queryName.replace('_Select_', '_SelectCount_'), listRef_server.queryArgs)).count,
            options,
        );
        // return cache[lazyListKey] = lazyItems;
        return lazyItems;
    };

    const refListToArray = async <T extends { id: string }>(
        listRef: ReferenceList<T>,
        toModel: (modelFromServer: Model_FromServer<T>) => T,
    ): Promise<T[]> => {
        const lazyItems = refListToLazyItems(listRef, toModel, { take: 10 * 1000 });
        return await loadAllLazyList<T>(lazyItems);
    };

    const refListToValueOrPromise = <T extends { id: string }>(
        listRef: ReferenceList<T>,
        toModel: (modelFromServer: Model_FromServer<T>) => T,
    ): ValueOrPromise<T[]> => {
        return getValueOrPromiseListWithCache(listRef, () => ValueOrPromise.fromAsync(async () => await refListToArray(listRef, toModel)));
    };

    const refToValueOrPromise = <T extends { id: string }>(
        itemRef: Reference<T>,
        selectById: (args: { value: Reference<T> }) => Promise<{ item: Model_FromServer<T> }>,
        toModel: (modelFromServer: Model_FromServer<T>) => T,
    ): ValueOrPromise<T> => {
        return getValueOrPromiseItemWithCache(itemRef, () => ValueOrPromise.fromAsync(async () => toModel((await selectById({ value: itemRef })).item)));
    };

    const toModel_contact = (modelFromServer: Model_FromServer<ContactModel>): ContactModel => {
        if (modelFromServer == null) { throw new ApiError('Null Received from Server'); }
        return {
            ...modelFromServer,
        };
    }
    const toModel_contactList = (modelFromServer: Model_FromServer<ContactListModel>): ContactListModel => {
        if (modelFromServer == null) { throw new ApiError('Null Received from Server'); }
        return {
            ...modelFromServer,
        };
    }
    const toModel_campaign = (modelFromServer: Model_FromServer<CampaignModel>): CampaignModel => {
        if (modelFromServer == null) { throw new ApiError('Null Received from Server'); }
        return {
            ...modelFromServer,
        };
    }
    const toModel_contactCampaignState = (modelFromServer: Model_FromServer<ContactCampaignStateModel>): ContactCampaignStateModel => {
        if (modelFromServer == null) { throw new ApiError('Null Received from Server'); }
        return {
            ...modelFromServer,
        };
    }
    const toModel_messageTemplate = <T>(modelFromServer: Model_FromServer<MessageTemplateModel<T>>): MessageTemplateModel<T> => {
        if (modelFromServer == null) { throw new ApiError('Null Received from Server'); }
        return {
            ...modelFromServer,
        };
    }

    // const getUserAndActiveAccount = async () => {
    //     const user = (await dataApi.UserModel_Select_All({})).items[0];
    //     const account = (await dataApi.AccountModel_Select_By_id({ value: user.activeAccount })).item;
    //     const accountSystem = (await dataApi.AccountModel_Select_By_id({ value: user.activeAccount })).item;
    //     return { user, account, accountSystem };
    // };

    const appApiAccess: AppApiAccess = {
        // No Auth Required
        reloadState: async () => {
            const cred = userCredentialsProvider.getUserCredentials();
            if (!cred) {
                state.accountState = defaultAccountState;
                return;
            }

            state.accountState = await appApi.getAccountState({});

            // // Load user state
            // const { account, accountSystem } = await getUserAndActiveAccount();
            // const accountPhones = (await dataApi.AccountPhoneNumberModel_Select_By_account({ value: getReference(account) })).items;
            // state.accountState = {
            //     hasPlan: !!accountSystem.planDetails,
            //     hasAccountDetails: !!accountSystem.accountDetails,
            //     hasPaymentDetails: !!accountSystem.paymentDetails,
            //     hasPhoneNumber: accountPhones.length > 0,
            // };
        },
        getAccountState: () => state.accountState,

        // Account Setup
        getPlans: async () => {
            return await appApi.getPlans({});
        },
        getPlans_extraCredits: async () => {
            return await appApi.getPlans_extraCredits({});
        },
        savePlan: async (plan) => {
            await appApi.savePlan({ plan });
            state.accountState = await appApi.getAccountState({});

            // const { account } = await getUserAndActiveAccount();

            // // TODO: This should be done server side and stored in a system-controlled data table
            // const planDetails = (await appApiAccess.getPlans()).find(x => x.id === plan.planId);
            // if (!planDetails) { throw new ApiError('Plan not found', { plan }); }
            // account.planDetails = { ...planDetails, planId: planDetails.id };

            // await dataApi.AccountModel_Update({ value: account });
        },
        saveAccountDetails: async (accountDetails) => {
            const result = await appApi.saveAccountDetails({ accountDetails });
            state.accountState = await appApi.getAccountState({});

            return result;
            // const { account } = await getUserAndActiveAccount();
            // account.accountDetails = accountDetails;

            // await dataApi.AccountModel_Update({ value: account });
        },

        verifyPaymentsReady: async () => {
            await appApi.verifyPaymentsReady({});
        },
        requiresSubscriptionPayment: async () => {
            const accountState = await appApi.getAccountState({});
            const required = Timestamp.now() > accountState.stats.cycleEndTime;
            const amount = accountState.stats.paymentDue;
            return { required, amount };
        },
        processSubscriptionPayment: async () => {
            await appApi.verifyPaymentAndProcessSubscription({});
            await appApiAccess.reloadState();
        },
        // savePayment: async (payment) => {
        //     await appApi.savePayment({ payment });
        //     state.accountState = await appApi.getAccountState({});

        //     // const { account } = await getUserAndActiveAccount();

        //     // // TODO: Register with payment provider
        //     // account.paymentDetails = {
        //     //     provider: payment.provider,
        //     //     providerPaymentId: '',
        //     // };

        //     // await dataApi.AccountModel_Update({ value: account });
        // },

        // Message Delivery Account Setup
        searchPhoneNumbers: async (tollFreeVanity) => {
            return await appApi.searchPhoneNumbers({ tollFreeVanity });
        },
        purchasePhoneNumber: async (phoneNumber) => {
            await appApi.purchasePhoneNumber({ phoneNumber });
            state.accountState = await appApi.getAccountState({});
        },
        updatePhoneNumberStatus: async (accountPhoneNumber) => {
            await appApi.updatePhoneNumberStatus({ accountPhoneNumber });
            const result = await dataApi.AccountPhoneNumberModel_Select_By_id({ value: accountPhoneNumber });
            return result.item;
        },

        purchaseExtraCredits: async (credits, cost) => {
            await appApi.purchaseExtraCredits({ credits, cost });
            state.accountState = await appApi.getAccountState({});
        },

        getContactList: async (contactListId) => {
            return await appApiAccess.loadContactList(getReferenceFromId(contactListId)).valueOrLoad();
            // const serverResult = await webApi.ContactListModel_Select_By_id({ value: getReferenceFromId(contactListId) });
            // return serverResult.item;
        },
        getContactLists: async () => {
            const serverResult = await dataApi.ContactListModel_Select_All({});
            return serverResult.items.filter(x => !x.deleted);
        },
        getContactLists_withContactsCount: async () => {
            const contactListsResult = await dataApi.ContactListModel_Select_All({});
            const contactLists = contactListsResult.items.filter(x => !x.deleted);
            const itemsPromises = contactLists.map(async x => {
                const contactsCount = await appApiAccess.getContactsCount(x);
                return { ...x, contactsCount };
            });

            return await Promise.all(itemsPromises);
        },
        getContact: async (contactId) => {
            return await appApiAccess.loadContact(getReferenceFromId(contactId)).valueOrLoad();
            // const serverResult = await webApi.ContactModel_Select_By_id({ value: getReferenceFromId(contactId) });
            // return serverResult.item;
        },
        getContactsCount: async (contactList) => {
            const lazyItems: LazyItems<ContactModel> = pagedRequestToLazyList(
                'getContactsCount: ContactModel_Select_By_state_contactLists',
                async (paging) => (await dataApi.ContactModel_Select_By_state_contactLists({ value: getReference(contactList), paging })).items,
                async () => (await dataApi.ContactModel_SelectCount_By_state_contactLists({ value: getReference(contactList) })).count,
            );
            return await lazyItems.totalCount().valueOrLoad();
        },
        getContacts: async (contactList) => {
            // const allContacts = await dataApi.ContactModel_Select_By_state_contactLists({ value: getReference(contactList) });
            // return LazyItems.fromArray(allContacts.items);
            const lazyItems: LazyItems<ContactModel> = pagedRequestToLazyList(
                'getContacts: ContactModel_Select_By_state_contactLists',
                async (paging) => (await dataApi.ContactModel_Select_By_state_contactLists({ value: getReference(contactList), paging })).items,
                async () => (await dataApi.ContactModel_SelectCount_By_state_contactLists({ value: getReference(contactList) })).count,
                { take: 5000 },
            );
            // Load first page
            console.log('getContacts - loadFirstPage', { itemsLoadedLength: lazyItems.itemsLoaded.length });
            await lazyItems.loadMore();

            // // Load all
            // while (lazyItems.hasMore()) {
            //     console.log('getContacts - loadMore', { itemsLoadedLength: lazyItems.itemsLoaded.length });
            //     await lazyItems.loadMore();
            // }

            return lazyItems;
        },
        getContacts_all: async () => {
            const lazyItems: LazyItems<ContactModel> = pagedRequestToLazyList(
                'getContacts_all: ContactModel_Select_All',
                async (paging) => (await dataApi.ContactModel_Select_All({ paging })).items,
                async () => (await dataApi.ContactModel_SelectCount_All({})).count,
            );
            // Load first page
            await lazyItems.loadMore();
            return lazyItems;
        },
        getAccountPhoneNumbers: async () => {
            const serverResult = await dataApi.AccountPhoneNumberModel_Select_All({});
            const items = serverResult.items as AccountPhoneNumberModel[];

            // Update status for any pending numbers
            for (const i in items) {
                const accountPhoneNumber = items[i];
                if (accountPhoneNumber.orderStatus !== 'pending') { continue; }
                const updated = await appApiAccess.updatePhoneNumberStatus(getReference(accountPhoneNumber));
                items[i] = updated;
            }

            // Order by status
            const itemsSorted = items.map(x => ({ x, order: x.orderStatus === 'success' ? 0 : x.orderStatus === 'pending' ? 1 : 2 })).sort((a, b) => a.order - b.order).map(x => x.x);

            return itemsSorted;
        },
        getCampaigns: async (options: { shouldIncludeActive?: boolean, shouldIncludeInactive?: boolean }) => {
            const serverResult = await dataApi.CampaignModel_Select_All({});
            console.log('getCampaigns', { allCampaigns: serverResult.items });
            return serverResult.items
                .filter(x => {

                    let isActive = x.status?.isActive;

                    if (x.kind === 'standard') {
                        const campaignStandard = x as CampaignModel_Standard;
                        isActive = DateOnly.daysBetween(campaignStandard.messages.schedule.startDate, DateOnly.today()) >= 0;
                    }

                    return (isActive && options.shouldIncludeActive)
                        || (!isActive && options.shouldIncludeInactive);
                });
        },

        getContactMessages: async (contact) => {
            const messageDeliveriesResult = await dataApi.MessageDeliveryModel_Select_By_toContact({ value: getReference(contact) });
            const messageRepliesResult = await dataApi.MessageReplyModel_Select_By_fromContact({ value: getReference(contact) });
            const messageThread: MessageThread = [...messageDeliveriesResult.items, ...messageRepliesResult.items];
            messageThread.sort((a, b) => -(a.createdTime - b.createdTime));
            return messageThread;
        },
        getRecentMessageThreads: async () => {
            console.warn('getRecentMessageThreads TEMP Implementation - getting all data and sorting client side');
            // TEMP: Get all the data
            const allDeliveriesResult = pagedRequestToLazyList('Recent Message Threads - Deliveries',
                async (paging) => (await dataApi.MessageDeliveryModel_Select_All({ paging })).items,
                async () => (await dataApi.MessageDeliveryModel_SelectCount_All({})).count,
            );
            const allDeliveries = await loadAllLazyList(allDeliveriesResult);

            const allRepliesResult = pagedRequestToLazyList('Recent Message Threads - Replies',
                async (paging) => (await dataApi.MessageReplyModel_Select_All({ paging })).items,
                async () => (await dataApi.MessageReplyModel_SelectCount_All({})).count,
            );
            const allReplies = await loadAllLazyList(allRepliesResult);

            const allThreads: MessageThreadInfo[] = [
                ...allDeliveries.map(x => ({
                    contact: x.toContact,
                    lastMessage: {
                        kind: 'delivery' as const,
                        contact: x.toContact,
                        createdTime: x.createdTime,
                        item: x,
                    },
                })),
                ...allReplies.map(x => ({
                    contact: x.fromContact,
                    lastMessage: {
                        kind: 'reply' as const,
                        contact: x.fromContact,
                        createdTime: x.createdTime,
                        item: x,
                    },
                }))
            ];

            allThreads.sort((a, b) => -(a.lastMessage.createdTime - b.lastMessage.createdTime));

            // Group by id
            const allThreadsGrouped = allThreads.reduce((out, x) => {
                out[x.contact.id] = out[x.contact.id] ?? [];
                const array = out[x.contact.id];
                array.push(x);
                return out;
            }, {} as { [contactId: string]: MessageThreadInfo[] });

            const allThreadsSimplified = getKeysTyped(allThreadsGrouped).map(k => allThreadsGrouped[k][0]);
            allThreadsSimplified.sort((a, b) => -(a.lastMessage.createdTime - b.lastMessage.createdTime));

            return allThreadsSimplified;
        },
        getCampaignReplies: async (campaign) => {
            // const messageDeliveriesResult = await dataApi.MessageDeliveryModel_Select_By_lastCampaign({ value: getReference(campaign) });
            const messageRepliesResult = await dataApi.MessageReplyModel_Select_By_lastCampaign({ value: getReference(campaign) });
            const messageThread: MessageThread = [...messageRepliesResult.items];
            messageThread.sort((a, b) => -(a.createdTime - b.createdTime));
            return messageThread;
        },

        createContact: async (value) => {
            // Lookup Delivery Time Zone based on phone number
            const deliveryTimeZone = await phoneInfoApi.getPhoneNumberTimeZone(value.phoneNumber!);
            const insertResult = await dataApi.ContactModel_Insert({
                value: {
                    id: '',
                    phoneNumber: value.phoneNumber!,
                    firstName: value.firstName,
                    lastName: value.lastName,
                    deliveryTimeZone,
                    state: {
                        campaigns: undefined,
                        contactLists: undefined,
                    },
                    status: {
                        enabled: true,
                        optInStatus: 'new',
                        hasDeliveryFailure: false,
                    }
                }
            });
            return await appApiAccess.getContact(insertResult.newId);
        },
        setContactData: async (item, value) => {
            const data: Model_ToServer<ContactModel> = { ...item, ...value, state: { campaigns: undefined, contactLists: undefined } };
            const result = await dataApi.ContactModel_Update({ value: data });
            const newItem = await dataApi.ContactModel_Select_By_id({ value: getReference(item) });
            return toModel_contact(newItem.item);
        },
        addContactToList: async (contactList, item) => {
            const result = await dataApi.ContactListModel_Contacts_2_ContactModel_State_ContactLists_Insert({
                value: {
                    id: '',
                    contacts_mmfk: getReference(item),
                    state_contactLists_mmfk: getReference(contactList)
                }
            });

            return;
        },
        removeContactFromList: async (contactList, item) => {
            const itemResult = await dataApi.ContactListModel_Contacts_2_ContactModel_State_ContactLists_Select_By_contacts_mmfk({
                value: getReference(item)
            });
            const row = itemResult.items.find(x => x.contacts_mmfk.id === item.id && x.state_contactLists_mmfk.id === contactList.id);
            if (!row) { throw new ApiError('Contact not found in ContactList', { contact: item, contactList }); }

            const result = await dataApi.ContactListModel_Contacts_2_ContactModel_State_ContactLists_Delete({
                value: getReference(row),
            });
            return;
        },
        removeContactFromAllLists: async (item) => {
            // // Disable contact first (in case contact list removal is incomplete)
            // await dataApi.ContactModel_Update({
            //     value: {
            //         ...item,
            //         state: {
            //             campaigns: undefined,
            //             contactLists: undefined,
            //         },
            //         status: {
            //             ...item.status,
            //             enabled: false,
            //         },
            //     },
            // });

            const itemResult = await dataApi.ContactListModel_Contacts_2_ContactModel_State_ContactLists_Select_By_contacts_mmfk({
                value: getReference(item)
            });
            const rows = itemResult.items;
            // if (!rows.length) { throw new ApiError('Contact not found in any ContactLists', { contact: item }); }

            for (const row of rows) {
                const result = await dataApi.ContactListModel_Contacts_2_ContactModel_State_ContactLists_Delete({
                    value: getReference(row),
                });
            }

            // Mark as removed
            const existingContactResult = await dataApi.ContactModel_Select_By_id({ value: getReference(item) });
            const existingContact = existingContactResult.item;
            if (!existingContactResult) { throw new ApiError('Contact not found', { contact: item }); }

            existingContact.status = { ...existingContact.status, wasRemoved: true };
            await dataApi.ContactModel_Update({ value: cleanModelReferences(existingContact) });

            return;
        },

        findContactByPhoneNumber: async (phoneNumber) => {
            const contactResult = await dataApi.ContactModel_Select_By_phoneNumber({ value: phoneNumber });
            const contact = contactResult.items[0];
            if (!contact) { return null; }

            return toModel_contact(contact);
        },
        bulkGetContactInfo: async (phoneNumbers, contactList) => {
            return await appApi.bulkGetContactInfo({ phoneNumbers, contactList });
        },
        bulkUpdateContacts: async (contacts, contactList) => {
            return await appApi.bulkUpdateContacts({ contacts, contactList });
        },
        createContactList: async (value) => {
            // Set list name to keywords as default
            value.name = value.name || value.keywords;

            const result = await dataApi.ContactListModel_Insert({
                value: {
                    id: '',
                    name: '',
                    description: '',
                    createdTime: Timestamp.now(),
                    modifiedTime: Timestamp.now(),
                    contacts: undefined,
                    usedByCampaigns: undefined,
                    keywords: '',

                    ...value,
                }
            });
            const newItem = await dataApi.ContactListModel_Select_By_id({ value: getReferenceFromId(result.newId) });
            return newItem.item;
        },
        getOrCreateContactList: async (value) => {
            console.log('getOrCreateContactList', { value });
            // Try to find contact list by keyword
            const allContactLists = await dataApi.ContactListModel_Select_All({});
            const nonDeletedContactLists = allContactLists.items.filter(x => !x.deleted);

            const existing = nonDeletedContactLists.find(x => x.keywords === value.keywords);

            console.log('getOrCreateContactList', { value, existing, allContactLists });
            if (existing) {
                return existing;
            }

            return appApiAccess.createContactList(value);
        },
        setContactListData: async (item, value) => {
            const result = await dataApi.ContactListModel_Update({
                value: {
                    ...item,
                    ...value,
                    contacts: undefined,
                    usedByCampaigns: undefined,
                }
            });
            const updatedItem = await dataApi.ContactListModel_Select_By_id({ value: getReference(item) });
            return toModel_contactList(updatedItem.item);
        },
        deleteList: async (item) => {
            // Delete list
            await dataApi.ContactListModel_Update({
                value: {
                    ...item,
                    keywords: '',
                    deleted: true,
                    contacts: undefined,
                    usedByCampaigns: undefined,
                }
            });
        },
        getKeywords: async () => {
            const allContactListsResult = await dataApi.ContactListModel_Select_All({});
            const allKeywords = splitKeywords(allContactListsResult.items.map(x => x.keywords).join(';'));
            const keywords = distinct(allKeywords, x => x).sort((a, b) => a.localeCompare(b));
            return keywords;
        },
        getKeywords_activeCampaigns: async () => {
            // const allContactListsResult = await dataApi.ContactListModel_Select_All({});
            const campaignResult = await dataApi.CampaignModel_Select_All({});
            const activeCampaigns = campaignResult.items.filter(x => x.status?.isActive);
            const campaignContactListsResults = await Promise.all(activeCampaigns.map(async c => await appApiAccess.loadItemsContactLists(c.toContactLists).valueOrLoad()));
            const activeContactLists = campaignContactListsResults.flatMap(x => x);

            const allKeywords = splitKeywords(activeContactLists.map(x => x.keywords).join(';'));
            const keywords = distinct(allKeywords, x => x).sort((a, b) => a.localeCompare(b));
            return keywords;
        },

        toggleCampaign: async (campaignRef, enabled) => {
            const modelBefore = await dataApi.CampaignModel_Select_By_id({ value: campaignRef });
            const campaign = modelBefore.item;
            if (!campaign.status) {
                campaign.status = {
                    isActive: enabled,
                    createdTime: Timestamp.now(),
                    lastActivityTime: Timestamp.now(),
                    nextActivityTime: Timestamp.now(),
                };
            }
            campaign.status.isActive = enabled;

            const campaignModel: Model_ToServer<CampaignModel> = {
                ...cleanModelReferences(campaign),
            };
            await dataApi.CampaignModel_Update({ value: campaignModel });
        },

        saveCampaign: async (campaign) => {
            // Get Inner Messages and save them as well
            const saveMessageTemplateModel = async (message: Model_ToServer<MessageTemplateModel<any>>) => {
                console.log('saveMessageTemplateModel: START', { message });

                try {
                    const modelBefore = await dataApi.MessageTemplateModel_Select_By_id({ value: getReference(message) });
                    if (modelBefore) {
                        const updateResult = await dataApi.MessageTemplateModel_Update({ value: message });
                        const modelAfter = await dataApi.MessageTemplateModel_Select_By_id({ value: getReference(message) });
                        console.log('saveMessageTemplateModel: END - Updated', { modelAfter });
                        return toModel_messageTemplate(modelAfter.item);
                    }
                } catch (err) {
                    console.log('saveMessageTemplateModel: Message Id Not Found - Assuming New', { messageId: message.id, error: err });
                }

                const insertResult = await dataApi.MessageTemplateModel_Insert({ value: message });
                const modelAfter = await dataApi.MessageTemplateModel_Select_By_id({ value: getReferenceFromId(insertResult.newId) });
                console.log('saveMessageTemplateModel: END - Created', { modelAfter });
                return toModel_messageTemplate(modelAfter.item);
            };
            const saveCampaignModel = async (campaign: Model_ToServer<CampaignModel>) => {
                console.log('saveCampaignModel: START', { campaign });

                try {
                    const modelBefore = await dataApi.CampaignModel_Select_By_id({ value: getReference(campaign) });
                    if (modelBefore) {
                        const updateResult = await dataApi.CampaignModel_Update({ value: campaign });
                        const modelAfter = await dataApi.CampaignModel_Select_By_id({ value: getReference(campaign) });
                        console.log('saveCampaignModel: END - Updated', { modelAfter });
                        return toModel_campaign(modelAfter.item);
                    }
                } catch (err) {
                    console.log('saveCampaignModel: Campaign Id Not Found - Assuming New', { campaignId: campaign.id, error: err });
                }

                const insertResult = await dataApi.CampaignModel_Insert({ value: campaign });
                const modelAfter = await dataApi.CampaignModel_Select_By_id({ value: getReferenceFromId(insertResult.newId) });
                console.log('saveCampaignModel: END - Created', { modelAfter });
                return toModel_campaign(modelAfter.item);
            };
            const saveCampaignContactLists = async (campaignRef: Reference<CampaignModel>, contactListRefs: Reference<ContactListModel>[]) => {
                console.log('saveCampaignContactList: START', { campaignRef, contactListRefs });

                const targetContactListIds = distinct(contactListRefs.map(x => x.id), x => x);

                const existingContactListsResult = await dataApi.CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_Select_By_usedByCampaigns_mmfk({ value: getReference(campaign) });
                const existingContactListIds = existingContactListsResult.items.map(x => x.toContactLists_mmfk.id);
                // const addContactListIds = targetContactListIds.filter(x => !existingContactListIds.includes(x));
                // const deleteContactListIds = existingContactListIds.filter(x => !targetContactListIds.includes(x));
                // const deleteRows = existingContactListsResult.items.filter(x => deleteContactListIds.includes(x.toContactLists_mmfk.id));

                // Delete all and add fresh
                const deleteRows = existingContactListsResult.items;
                const addContactListIds = targetContactListIds;

                const deletePromises = deleteRows.map(x => dataApi.CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_Delete({ value: getReference(x) }));
                await Promise.all(deletePromises);

                const addPromises = addContactListIds.map(x => dataApi.CampaignModel_ToContactLists_2_ContactListModel_UsedByCampaigns_Insert({ value: { id: '', usedByCampaigns_mmfk: getReference(campaign), toContactLists_mmfk: getReferenceFromId(x) } }));
                await Promise.all(addPromises);
            };

            console.log('saveCampaign: START', { campaign });

            // Save campaign without messages (to get campaign id)
            let emptyMessageRef = getReference_Empty<MessageTemplateModel<any>>();
            const campaignData = getCampaignData(campaign);
            campaignData.messages.forEach(x => x.messageRefInCampaignData.id = emptyMessageRef.id);

            // Disable until ready
            const actualStatus = campaignData.campaign.campaignToServer.status;
            campaignData.campaign.campaignToServer.status = {
                isActive: false,
                createdTime: Timestamp.now(),
                lastActivityTime: Timestamp.now(),
                nextActivityTime: Timestamp.now(),
            };

            const savedCampaign01 = await saveCampaignModel(campaignData.campaign.campaignToServer);

            // Copy id back into campaign objects and message objects
            const campaignId = savedCampaign01.id;
            console.log('saveCampaign: Get campaign id', { id: campaignId });
            campaignData.campaign.campaignOriginal.id = campaignId;
            campaignData.campaign.campaignToServer.id = campaignId;
            campaignData.messages.forEach(x => x.messageToServer.campaign.id = campaignId);
            campaignData.messages.forEach(x => x.messageOriginal.campaign.id = campaignId);

            // Save messages
            console.log('saveCampaign: Saving Messages', { campaign });
            const savedMessages = await Promise.all(campaignData.messages.map(async (m) => {
                const result = await saveMessageTemplateModel(m.messageToServer);
                return {
                    ...m,
                    messageSaved: result,
                };
            }));

            // Copy saved ids back into original and ref
            console.log('saveCampaign: copy saved ids', { campaign });
            savedMessages.forEach(m => m.messageRefInCampaignData.id = m.messageSaved.id);
            savedMessages.forEach(m => m.messageOriginal.id = m.messageSaved.id);

            // Save campaign with ref ids
            console.log('saveCampaign: save campaign with ref ids', { campaign });
            const savedCampaign = await saveCampaignModel(campaignData.campaign.campaignToServer);
            // Copy saved campaign id back to original
            campaignData.campaign.campaignOriginal.id = savedCampaign.id;

            // Save Campaign Contact Lists
            const contactListsRefs = campaign.toContactLists as unknown as { [index: string]: Reference<ContactListModel> };
            await saveCampaignContactLists(getReference(campaignData.campaign.campaignOriginal), getKeysTyped(contactListsRefs).map(k => contactListsRefs[k]));

            // This should have all the ids now
            campaignData.campaign.campaignToServer.status = actualStatus;
            const savedCampaign_activate = await saveCampaignModel(campaignData.campaign.campaignToServer);

            console.log('saveCampaign: END', { campaign });
            return campaignData.campaign.campaignOriginal;
        },
        sendDirectMessage: async (contact, text) => {
            // await webApi.message
            // throw new NotImplementedError('Need to process this with message delivery api?')
            const phoneNumbers = await appApiAccess.getAccountPhoneNumbers();
            const mainPhoneNumber = phoneNumbers[0].phoneNumber;
            console.log('sendDirectMessage START', { mainPhoneNumber, phoneNumbers });

            const messageRef = await appApi.sendDirectMessage({ message: text, fromNumber: mainPhoneNumber, toNumber: contact.phoneNumber });

            console.log('sendDirectMessage Message To Be Delivered', { messageRef, mainPhoneNumber, phoneNumbers });
            const result = await dataApi.MessageDeliveryModel_Select_By_id({ value: messageRef });

            console.log('sendDirectMessage DONE', { message: result.item, messageRef, mainPhoneNumber, phoneNumbers });

            // Update account state (credits have been used)
            state.accountState = await appApi.getAccountState({});

            return result.item;
        },
        getCampaignState: async <T extends CampaignStateModel>(campaign: Reference<CampaignModel>): Promise<T | undefined> => {
            const result = await dataApi.CampaignStateModel_Select_By_campaign({ value: campaign });
            return result.items?.[0] as unknown as T;
        },
        getCampaignStats: async (campaign, mainMessageTemplate, contactLists): Promise<CampaignStats | undefined> => {
            // const result = await dataApi.CampaignStateModel_Select_By_campaign({ value: campaign });
            const messageStats = await appApi.getMessageStats({ messageTemplate: mainMessageTemplate });

            let contactsToDeliverTotal = 0;
            if (contactLists) {
                const contactListsResults = await appApiAccess.loadItemsContactLists(contactLists).valueOrLoad();
                for (const l of contactListsResults) {
                    // TODO: Get only active contacts
                    const contactListCountResult = await dataApi.ContactModel_SelectCount_By_state_contactLists({ value: getReference(l) });
                    contactsToDeliverTotal += contactListCountResult.count;
                }
            }

            const stats: CampaignStats = {
                contactsToDeliverTotal,
                ...messageStats,
            };
            return stats;
        },

        // Links
        generateContestJoinLink: async (campaign, onDate) => {
            // throw new NotImplementedError('Need to process this with link api?')
            console.warn('generateContestJoinLink TEMP Implementation - mock url');
            return 'http://gt.com/1234/';
        },
        generateContestPrizeLink: async (campaign, onDate) => {
            //  throw new NotImplementedError('Need to process this with link api?')
            console.warn('generateContestJoinLink TEMP Implementation - mock url');
            return 'http://gt.com/1234/';
        },

        // Load References (Cached)
        loadContact: (itemRef) => refToValueOrPromise(itemRef, dataApi.ContactModel_Select_By_id, toModel_contact),
        loadContactList: (itemRef) => refToValueOrPromise(itemRef, dataApi.ContactListModel_Select_By_id, toModel_contactList),
        loadCampaign: (itemRef) => refToValueOrPromise(itemRef, dataApi.CampaignModel_Select_By_id, toModel_campaign),
        loadMessageTemplate: (itemRef) => refToValueOrPromise(itemRef, dataApi.MessageTemplateModel_Select_By_id, toModel_messageTemplate),

        // Load ReferenceList (Cached?)
        loadItemsContacts: (listRef) => refListToLazyItems(listRef, toModel_contact),
        loadItemsContactLists: (listRef) => refListToValueOrPromise(listRef, toModel_contactList),
        loadItemsCampaigns: (listRef) => refListToValueOrPromise(listRef, toModel_campaign),
        loadItemsContactCampaignStates: (listRef) => refListToValueOrPromise(listRef, toModel_contactCampaignState),

        // Load More - Lazy Items
        loadMoreContacts: async (lazyItems) => { await lazyItems.loadMore(); return { ...lazyItems }; }
    };
    return appApiAccess;
}

