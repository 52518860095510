import React, { useState, useEffect } from 'react';
import { Store } from '../../store/Store';
import { ContactListModel, CampaignModel, ContactModel } from '../../../data-types/ModelTypes';
import { ButtonTextView } from '../../components/TextView';
import { TextViewStyle, theme } from '../../style/Theme';
import { formatPhoneNumber_UsaCanada } from '../../../utils/PhoneNumbers';
import { ValueOrPromise } from '../../../utils/ValueOrPromise';
import { Reference, ReferenceList, getReference } from '../../../data-types/SimpleDataTypes';
import { LazyItems } from '../../../utils/LazyItems';
import { View } from 'react-native';
import { AppError } from '../../../utils/Errors';

export function useValueOrPromise<T>(valueOrPromise: ValueOrPromise<T> | null | undefined) {
    const [value, setValue] = useState<T | null>(valueOrPromise?.value ?? null);
    useEffect(() => {
        // Nothing
        if (valueOrPromise == null) { return; }
        // Already Loaded
        if (value != null) { return; }

        let isMounted = true;

        (async () => {
            const v = await valueOrPromise.load();
            if (!isMounted) { return; }

            setValue(v);
        })();

        return () => { isMounted = false; };
    }, [/* No Reload */]);
    return value;
}

export function useReferenceList_totalCount<TItem extends { id: string }>(listRef: ReferenceList<TItem> | null | undefined, loadReferenceList: (listRef: ReferenceList<TItem>) => LazyItems<TItem>,): number | null {
    function tryLoadReferenceList() {
        try {
            return listRef && loadReferenceList(listRef);
        } catch (err) {
            console.warn(`useReferenceList_totalCount Error: loadReferenceList ${err}`);
            return null;
        }
    }

    const result = tryLoadReferenceList();
    const [totalCount, setTotalCount] = useState(result?.totalCount().value);
    useEffect(() => {
        if (!result) { return; }
        if (result.totalCount().value != null) { return; }

        let isMounted = true;

        // It items (or totalCount) are not fully loaded, then loadAll
        (async () => {
            try {
                const r = await result.totalCount().valueOrLoad();
                if (!isMounted) { return; }

                setTotalCount(r);
            } catch (err) {
                // Ignore Errors
                console.warn(`useReference Error: ${err}`);
            }
        })();

        return () => { isMounted = false; };
    });

    return totalCount ?? null;
}

export function useReferenceList_LoadAll<TItem extends { id: string }>(store: Store,
    listRef: ReferenceList<TItem> | null | undefined,
    loadReferenceList: (store: Store, listRef: ReferenceList<TItem>) => ValueOrPromise<TItem[]>,
): TItem[] | null {

    const vop = listRef && loadReferenceList(store, listRef);
    const value = useValueOrPromise(vop);
    return value;
}

// export function useReferenceList_LoadAll_Lazy<TItem extends { id: string }>(store: Store,
//     listRef: ReferenceList<TItem> | null | undefined,
//     loadReferenceList: (store: Store, listRef: ReferenceList<TItem>) => LazyItems<TItem>, ): TItem[] | null {
//     function tryLoadReferenceList() {
//         try {
//             return listRef && loadReferenceList(store, listRef);
//         } catch (err) {
//             console.warn(`useReferenceList_LoadAll Error: loadReferenceList ${err}`);
//             return null;
//         }
//     }


//     const result = tryLoadReferenceList();
//     const [items, setItems] = useState(result?.itemsLoaded);
//     useEffect(() => {
//         if (!result) { return; }
//         if (!result.hasMore()) { return; }

//         // It items (or totalCount) are not fully loaded, then loadAll
//         (async () => {
//             try {
//                 const r = await result.loadAll();
//                 setItems(r);
//             } catch (err) {
//                 // Ignore Errors
//                 console.warn(`useReference Error: ${err}`);
//             }
//         })();
//     });

//     console.log(`useReferenceList_LoadAll`, { items, result, listRef });

//     return items ?? null;
// }



export function useReference<TItem extends { id: string }>(store: Store, itemRef: Reference<TItem> | null | undefined, loadItemReference: (store: Store, itemRef: Reference<TItem>) => ValueOrPromise<TItem>,): TItem | null {
    function tryLoadItemReference() {
        try {
            return itemRef && loadItemReference(store, itemRef);
        } catch {
            return null;
        }
    }

    const result = tryLoadItemReference();
    const [item, setItem] = useState(result?.value);
    useEffect(() => {
        if (item !== null) { return; }

        let isMounted = true;
        (async () => {
            try {
                const r = await result?.load();
                if (!isMounted) { return; }

                setItem(r);
            } catch (err) {
                // Ignore Errors
                console.warn(`useReference Error: ${err}`);
            }
        })();

        return () => { isMounted = false; };
    }, [/* No Reload */]);
    return item ?? null;
}


function ReferenceLink<TItem extends { id: string }>(props: {
    store: Store, style: TextViewStyle, itemRef: Reference<TItem> | null | undefined,
    loadItemReference: (store: Store, itemRef: Reference<TItem>) => ValueOrPromise<TItem>,
    onNav: (nav: Store['nav'], item: TItem) => void,
    getLabel: (item: TItem) => string,
}) {
    const { store, style, itemRef } = props;
    const { nav } = store;

    if (!itemRef) { return <></>; }

    const item = useReference(store, itemRef, props.loadItemReference);

    return (
        <>
            {!!item && (
                <ButtonTextView style={style} text={props.getLabel(item)} onPress={() => props.onNav(nav, item)} />
            )}
        </>
    );
}

export const ContactLink = (props: { store: Store, style: TextViewStyle, itemRef: Reference<ContactModel> | null | undefined }) => {
    return (
        <ReferenceLink
            {...props}
            loadItemReference={(s, x) => s.api.loadContact(x)}
            onNav={(nav, item) => nav.ViewContactPage.open({ contact: item })}
            getLabel={x => `${formatPhoneNumber_UsaCanada(x.phoneNumber)}`}
        />
    );
};

export const ContactMessagesLink = (props: { store: Store, style: TextViewStyle, itemRef: Reference<ContactModel> | null | undefined }) => {
    return (
        <ReferenceLink
            {...props}
            loadItemReference={(s, x) => s.api.loadContact(x)}
            onNav={(nav, item) => nav.ViewContactMessageThreadPage.open({ contact: item })}
            //getLabel={x => `${x.name} Contacts`}
            getLabel={x => `Messages`}
        />
    );
};

export const ContactListLink = (props: { store: Store, style: TextViewStyle, itemRef: Reference<ContactListModel> | null | undefined }) => {
    return (
        <ReferenceLink
            {...props}
            loadItemReference={(s, x) => s.api.loadContactList(x)}
            onNav={(nav, item) => nav.ViewContactListPage.open({ contactList: item })}
            getLabel={x => x.name}
        />
    );
};


export const ContactListLinks = (props: { store: Store, style: TextViewStyle, listRef: ReferenceList<ContactListModel> | null | undefined }) => {

    const { store } = props;
    const { theme } = store;
    const contactLists = useReferenceList_LoadAll(store, props.listRef, (s, x) => s.api.loadItemsContactLists(x));

    return (
        <>
            {contactLists?.map(x => (
                <View key={x.id} style={theme.card.row}>
                    <ContactListLink store={store} style={theme.card.navTextView} itemRef={getReference(x)} />
                </View>
            ))}
        </>

    );
};

export const ContactListContactsLink = (props: { store: Store, style: TextViewStyle, itemRef: Reference<ContactListModel> | null | undefined }) => {
    return (
        <ReferenceLink
            {...props}
            loadItemReference={(s, x) => s.api.loadContactList(x)}
            onNav={(nav, item) => nav.ViewContactListContactsPage.open({ contactList: item })}
            //getLabel={x => `${x.name} Contacts`}
            getLabel={x => `Contacts`}
        />
    );
};

export const CampaignLink = (props: { store: Store, style: TextViewStyle, itemRef: Reference<CampaignModel> | null | undefined }) => {
    return (
        <ReferenceLink
            {...props}
            loadItemReference={(s, x) => s.api.loadCampaign(x)}
            onNav={(nav, item) => NavigateToCampaign(nav, item)}
            getLabel={x => x.name}
        />
    );
};

export const NavigateToCampaign = (nav: Store['nav'], item: CampaignModel) => {
    console.log('NavigateToCampaign', { kind: item.kind, item });
    switch (item.kind) {
        case 'standard': nav.EditCampaignPage_Standard.open({ campaign: item }); return;
        case 'interactive': nav.EditCampaignPage_Interactive.open({ campaign: item }); return;
        case 'coupon': nav.EditCampaignPage_Coupon.open({ campaign: item }); return;
        case 'sweepstakes': nav.EditCampaignPage_Sweepstakes.open({ campaign: item }); return;
        case 'contest': nav.EditCampaignPage_Contest.open({ campaign: item }); return;
        default: throw new AppError('Unknown Campaign Kind', { kind: item.kind });
    }
};