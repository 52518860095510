import { parseIntOrZero } from './Strings';
import { phoneNumberMask_UsaCanada } from './PhoneNumbers';
import { DateOnly } from './Time';

export type Validation<T> = {
    getInitialMessage?: () => string,
    validate: (value: T) => {
        isValid: boolean,
        message: string,
        reject?: boolean,
        corrected?: string,
    };
};

export const Validation_TextRequired = () => {
    return {
        getInitialMessage: () => '',
        validate: (x: string) => ({
            isValid: x.length > 0,
            message: !x.length ? 'Required' : '',
            reject: undefined,
            corrected: undefined,
        })
    };
};

export const Validation_MaxLengthRequired = (props: { isRequired: boolean, maxLength: number }) => {
    const { isRequired, maxLength } = props;
    return {
        getInitialMessage: () => '',
        validate: (x: string) => ({
            isValid: (!isRequired || x.length > 0) && x.length <= maxLength,
            message: isRequired && !x.length ? 'Required'
                : x.length <= maxLength ? `${x.length} of ${maxLength}`
                    : `${x.length - maxLength} too many characters`,
        })
    };
};

export const Validation_MaxLengthRequiredRegex = (props: { isRequired: boolean, maxLength: number, pattern: RegExp, patternFailMessage: string }) => {
    const { isRequired, maxLength, pattern, patternFailMessage } = props;
    const regex = pattern;
    return {
        getInitialMessage: () => '',
        validate: (x: string) => ({
            isValid: (!isRequired || x.length > 0) && x.length <= maxLength && regex.test(x),
            message: isRequired && !x.length ? 'Required'
                : !regex.test(x) ? patternFailMessage
                    : x.length <= maxLength ? `${x.length} of ${maxLength}`
                        : `${x.length - maxLength} too many characters`,
        })
    };
};

export const Validation_DateAfterOrOnToday: () => Validation<DateOnly | null | undefined> = () => {
    return {
        validate: (x: DateOnly | null | undefined) => {
            const isValid = x && DateOnly.toDateOnly(x) >= DateOnly.today() || false;
            return {
                isValid,
                message: !isValid ? 'Date must be today or after' : '',
            };
        }
    };
};

export const Validation_PositiveInteger: () => Validation<number | null | undefined> = () => {
    return {
        validate: (x: number | null | undefined) => {
            const xParsed = parseIntOrZero(x + '');
            const isValid = xParsed >= 1 || false;
            // console.log('Validation_PositiveInteger.validate', { x, xParsed, isValid });
            return {
                isValid,
                message: !isValid ? 'This must be a positive number' : '',
                reject: !isValid && (x + '').length > 0,
            };
        }
    };
};

export const Validation_PhoneNumber_UsaCanada: () => Validation<string | null | undefined> = () => {
    return {
        validate: (x: string | null | undefined) => {
            const { corrected, full } = phoneNumberMask_UsaCanada(x ?? '');
            const isValid = corrected === full;
            return {
                isValid,
                message: !isValid ? `This must be a valid phone number ${full}` : '',
                corrected,
            };

            // const xCleaned = x
            //     ?.replace(/[\(\)\-\ \\\/]/g, '')
            //     .replace(/^(\+1|1)?/, '')
            //     ?? '';
            // const xNumbers = xCleaned.replace(/[^0-9]/g, '');
            // const isValid = xCleaned.length === xNumbers.length && xNumbers.length === 10;

            // return {
            //     isValid,
            //     message: !isValid ? `This must be a valid phone number ${xCleaned}` : xCleaned,
            //     //reject: !isValid && (x + '').length > 0,
            // };
        }
    };
};

export const Validation_State: () => Validation<string | null | undefined> = () => {
    return Validation_MaxLengthRequired({ isRequired: true, maxLength: 2 });
};