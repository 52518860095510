
export class AuthError {
    kind = 'AuthError';
    message: string;
    data: any;
    innerError: any;
    errorObject: Error;
    constructor(message: string, data?: any, innerError?: any) {
        console.warn(message, { data, innerError });
        this.message = message;
        this.data = data;
        this.innerError = innerError;
        this.errorObject = new Error();
    }
}

export class ApiError {
    kind = 'ApiError';
    message: string;
    data: any;
    innerError: any;
    errorObject: Error;
    constructor(message: string, data?: any, innerError?: any) {
        console.warn(message, { data, innerError });
        this.message = message;
        this.data = data;
        this.innerError = innerError;
        this.errorObject = new Error();
    }
}

export class DataError {
    kind = 'DataError';
    message: string;
    data: any;
    innerError: any;
    errorObject: Error;
    constructor(message: string, data?: any, innerError?: any) {
        console.warn(message, { data, innerError });
        this.message = message;
        this.data = data;
        this.innerError = innerError;
        this.errorObject = new Error();
    }
}

export class AppError {
    kind = 'AppError';
    message: string;
    data: any;
    innerError: any;
    errorObject: Error;
    constructor(message: string, data?: any, innerError?: any) {
        console.warn(message, { data, innerError });
        this.message = message;
        this.data = data;
        this.innerError = innerError;
        this.errorObject = new Error();
    }
}

export class LogicError {
    kind = 'LogicError';
    message: string;
    data: any;
    innerError: any;
    errorObject: Error;
    constructor(message: string, data?: any, innerError?: any) {
        console.warn(message, { data, innerError });
        this.message = message;
        this.data = data;
        this.innerError = innerError;
        this.errorObject = new Error();
    }
}

export class NotImplementedError {
    kind = 'NotImplementedError';
    message: string;
    data: any;
    innerError: any;
    errorObject: Error;
    constructor(message: string, data?: any, innerError?: any) {
        console.warn(message, { data, innerError });
        this.message = message;
        this.data = data;
        this.innerError = innerError;
        this.errorObject = new Error();
    }
}

export class MockError {
    kind = 'MockError';
    message: string;
    data: any;
    innerError: any;
    errorObject: Error;
    constructor(message: string, data?: any, innerError?: any) {
        console.warn(message, { data, innerError });
        this.message = message;
        this.data = data;
        this.innerError = innerError;
        this.errorObject = new Error();
    }
}

export class BandwidthError {
    kind = 'BandwidthError';
    message: string;
    data: any;
    innerError: any;
    errorObject: Error;
    constructor(message: string, data?: any, innerError?: any) {
        console.warn(message, { data, innerError });
        this.message = message;
        this.data = data;
        this.innerError = innerError;
        this.errorObject = new Error();
    }
}

export class ProcessError {
    kind = 'ProcessError';
    message: string;
    data: any;
    innerError: any;
    errorObject: Error;
    constructor(message: string, data?: any, innerError?: any) {
        console.warn(message, { data, innerError });
        this.message = message;
        this.data = data;
        this.innerError = innerError;
        this.errorObject = new Error();
    }
}


export class TimeoutSoonError {
    kind = 'TimeoutSoonError' as const;
    message: string;
    constructor(message: string) {
        this.message = message;
    }
}
