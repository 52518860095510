import { CampaignModel, MessageTemplateModel } from "../../data-types/ModelTypes";
import { CampaignEdit, cleanModelReferences, Model_ToServer } from "../../data-types/TypeTransformations";
import { Reference } from "../../data-types/SimpleDataTypes";
import { LogicError } from "../../utils/Errors";

const _state = {
    nextId: 1000,
};

export const getCampaignData = (campaign: CampaignEdit<CampaignModel>) => {
    const messagesRaw = getCampaignMessages_edit(campaign);
    const messages = messagesRaw.map(x => ({ messageOriginal: x, messageToServer: cleanModelReferences(x) }));
    const campaignModel: Model_ToServer<CampaignModel> = {
        ...cleanModelReferences(campaign),
    };
    const messageRefs = getCampaignMessages(campaignModel);
    const messageWithRef = messages.map((x, i) => ({ ...x, messageRefInCampaignData: messageRefs[i] }));
    return { messages: messageWithRef, campaign: { campaignOriginal: campaign, campaignToServer: campaignModel } };
}

function getCampaignMessages_edit(campaign: CampaignEdit<CampaignModel>): MessageTemplateModel<any>[] {
    return getCampaignMessages(campaign as any) as any[];
}
function getCampaignMessages(campaign: CampaignModel | Model_ToServer<CampaignModel>): Reference<MessageTemplateModel<any>>[] {
    switch (campaign.kind) {
        case 'standard':
            return [
                campaign.messages.message,
            ];
        case 'interactive':
            return [
                campaign.messages.message,
                campaign.messages.optInMessage,
                campaign.messages.optInConfirmationMessage,
            ].filter(x => x).map(x => x!);
        case 'coupon':
            return [
                campaign.messages.message,
                campaign.messages.optInMessage,
                campaign.messages.optInConfirmationMessage,
            ].filter(x => x).map(x => x!);
        case 'sweepstakes':
            return [
                campaign.messages.welcomeMessage,
                campaign.messages.optInMessage,
                campaign.messages.optInConfirmationMessage,
                ...campaign.instantWinPrizes.map(x => x.winMessage),
            ].filter(x => x).map(x => x!);
        case 'contest':
            return [
                campaign.messages.contestOver_lost,
                campaign.messages.contestOver_won,
                campaign.messages.joinedContest_welcome,
            ];
        default:
            throw new LogicError(`saveCampaign: Unknown campaign kind ${campaign.kind}`);
    }
}
