import React, { useState, useRef, useEffect } from 'react';
import { FlatList, ActivityIndicator, Platform, } from 'react-native';
import { TextViewStyle } from '../style/Theme';
import { TextView, TextInputView, ButtonTextView } from './TextView';
import { escapeRegExp } from '../../utils/Strings';
import { ErrorBox } from './ErrorBox';
import { useAutoLoadingError } from '../../utils/Hooks';
import { delay } from '../../utils/Async';

export type ListStyle = {
    info: TextViewStyle,
    button: TextViewStyle,
    loadingColor: string,
    search: TextViewStyle,
};

function ListInner<T>(props: {
    style: ListStyle,
    items: { item: T, searchKey: string }[],
    search: undefined | string,
    dataVersionId: string,
    extractKey: (item: T) => string,
    extractSearchKey?: (item: T) => string[],
    renderItem: (props: { item: T, onItemChanged: (item: T) => void }) => JSX.Element,
    renderHeader?: (props: {}) => JSX.Element,
    showResultsOnSearchOnly?: boolean,
    showCount?: boolean,
    inverted?: boolean,
}) {
    const { loading, doWork } = useAutoLoadingError();
    const [itemsFilteredAll, setItemsFilteredAll] = useState(null as null | { item: T, searchKey: string }[]);

    useEffect(() => {
        // console.log('ListInner.useEffect', { length: props.items.length });

        if (!props.search) {
            setItemsFilteredAll(props.items);
            return;
        }

        doWork(async () => {
            await delay(250);
            const searchRegex = escapeRegExp(props.search!.replace(/\W+/g, 'AsymbolZ')).replace(/AsymbolZ/g, '\\W*');
            console.log('searchRegex', { searchRegex });
            const searchPattern = new RegExp(`${searchRegex}`, 'i');
            const all = props.items
                .filter(x => searchPattern.test(x.searchKey))
                .map(x => x)
                ;
            setItemsFilteredAll(all);
        });
    }, [props.items.length, props.search, props.dataVersionId]);

    // Update search key to include new version (but keep old also)
    const onItemChanged = (item: T) => {
        const itemSearchObj = props.items.find(x => props.extractKey(x.item) === props.extractKey(item));
        if (itemSearchObj) {
            itemSearchObj.searchKey = itemSearchObj.searchKey + ';' + props.extractSearchKey?.(item) ?? '';
        }
    };

    const CHUNK_SIZE = 25;
    const [max, setMax] = useState(CHUNK_SIZE);
    const loadMore = () => {
        if (itemsFiltered.length >= (itemsFilteredAll?.length ?? 0)) { return; }
        setMax(max + CHUNK_SIZE);
    };

    const itemsFiltered = itemsFilteredAll?.slice(0, max) ?? [];
    const hasMax = itemsFilteredAll?.length !== itemsFiltered.length;


    // console.log('ListInner.BEFORE RETURN', { itemsFiltered_0_item: itemsFiltered[0]?.item, length: props.items.length });

    if (props.showResultsOnSearchOnly && !props.search) {
        return (
            <>
                {/* Nothing */}
            </>
        );
    }

    if (!itemsFiltered.length) {
        return (
            <>
                <TextView style={props.style.info} text="No Items" />
            </>
        );
    }

    const itemsMapped = itemsFiltered.map(x => x.item);
    // onEndReached={loadMore} // Doesn't seem to work correctly on web

    // Just use map:
    if (Platform.OS !== 'web') {
        if (props.inverted) {
            itemsMapped.reverse();

            return (
                <>
                    {hasMax && <ButtonTextView style={props.style.button} text={`Load ${CHUNK_SIZE} More Items`} onPress={loadMore} />}
                    {itemsMapped.map(x => (
                        <React.Fragment key={props.extractKey(x)}>
                            {props.renderItem({ item: x, onItemChanged })}
                        </React.Fragment>
                    ))}
                    {props.renderHeader && <props.renderHeader />}
                    {props.showCount && (<TextView style={props.style.info} text={`${itemsFilteredAll?.length} Items`} />)}
                    {loading && <ActivityIndicator size="large" color={props.style.loadingColor} />}
                </>
            );
        }
        return (
            <>
                {loading && <ActivityIndicator size="large" color={props.style.loadingColor} />}
                {props.showCount && (<TextView style={props.style.info} text={`${itemsFilteredAll?.length} Items`} />)}
                {props.renderHeader && <props.renderHeader />}
                {itemsMapped.map(x => (
                    <React.Fragment key={props.extractKey(x)}>
                        {props.renderItem({ item: x, onItemChanged })}
                    </React.Fragment>
                ))}
                {hasMax && <ButtonTextView style={props.style.button} text={`Load ${CHUNK_SIZE} More Items`} onPress={loadMore} />}
            </>
        );
    }

    return (
        <>
            {loading && <ActivityIndicator size="large" color={props.style.loadingColor} />}
            {props.showCount && (<TextView style={props.style.info} text={`${itemsFilteredAll?.length} Items`} />)}
            <FlatList
                data={itemsMapped}
                inverted={props.inverted}
                disableVirtualization={props.inverted}
                keyExtractor={props.extractKey}
                renderItem={({ item }) => props.renderItem({ item, onItemChanged })}
                ListHeaderComponent={props.renderHeader}
                ListFooterComponent={() => (
                    <>
                        {hasMax && <ButtonTextView style={props.style.button} text={`Load ${CHUNK_SIZE} More Items`} onPress={loadMore} />}
                    </>
                )}
            />
        </>
    );
}

export function List<T>(props: {
    loading?: boolean,
    error?: any,
    style: ListStyle,
    items: T[],
    renderItem: (props: { item: T, onItemChanged: (item: T) => void }) => React.ReactElement,
    renderSearchShortcuts?: (props: { onChangeSearch: (search: string) => void }) => React.ReactElement,
    extractKey: (item: T) => string,
    extractSearchKey?: (item: T) => string[],
    extractSortKey?: (item: T) => string[],
    renderHeader?: (props: {}) => React.ReactElement,
    showResultsOnSearchOnly?: boolean,
    showCount?: boolean,
    inverted?: boolean,
    defaultSearch?: string,
}) {
    const [loadingData, setLoadingData] = useState({ loading: false, dataVersionId: '' });
    const itemsWithState = useRef([] as { item: T, searchKey: string, sortKey: string, }[]);
    useEffect(() => {
        // console.log('List.useEffect', { items: props.items, length: props.items.length });

        setLoadingData({ loading: false, dataVersionId: 'LOADING' + Date.now() });
        const itemsWithSearchKeyUnsorted = props.items
            .map(x => ({
                item: x,
                searchKey: props.extractSearchKey?.(x).join(';') ?? '',
                sortKey: props.extractSortKey?.(x).join(';') ?? '',
            }));

        const itemsWithSearchKey = [...itemsWithSearchKeyUnsorted];
        if (props.extractSortKey) {
            itemsWithSearchKey.sort((a, b) => a.sortKey.localeCompare(b.sortKey));
        }

        itemsWithState.current = itemsWithSearchKey;
        setLoadingData({ loading: false, dataVersionId: '' + Date.now() });
    }, [props.items.length, props.extractSortKey]);

    const [search, setSearch] = useState(props.defaultSearch);

    const changeSearch = (value: string) => {
        if (value === search) { return; }
        setSearch(value);
    };

    console.log('List render BEFORE RETURN', { itemsWithState, length: props.items.length });

    if (props.loading) {
        return <ActivityIndicator size="large" color={props.style.loadingColor} />;
    }

    const RenderSearchShortcuts = props.renderSearchShortcuts;

    return (
        <>
            {!!props.extractSearchKey && (
                <TextInputView style={props.style.search} placeholder="Search" value={search} onChangeText={changeSearch} />
            )}
            {!!RenderSearchShortcuts && (
                <RenderSearchShortcuts onChangeSearch={changeSearch} />
            )}
            {loadingData.loading && <ActivityIndicator size="large" color={props.style.loadingColor} />}
            {props.error && <ErrorBox error={props.error} />}
            <ListInner {...props} items={itemsWithState.current} search={search} dataVersionId={loadingData.dataVersionId} />
        </>
    );

}