import React, { useEffect, useState, useRef } from 'react';
import { View, ActivityIndicator, Platform, Text } from 'react-native';
import { Store } from './store/Store';
import { LoginPage, VerifyPhonePage } from './views/Account/Login';
import { MainLayout } from './components/Layout';
import { useAutoLoadingError } from '../utils/Hooks';
import { RoleKind } from '../data-types/ModelTypes';

export const AppRoot = (props: { store: Store }) => {

    console.log('AppRoot START');
    const { nav, auth, api } = props.store;
    const [{ ActiveViewComponent, renderId }, setActiveViewComponent] = useState({
        ActiveViewComponent: nav._getActiveViewComponent(),
        renderId: 0,
    });

    const hasLoaded = useRef(false);
    const { loading, error, doWork } = useAutoLoadingError();
    useEffect(() => {
        nav._handleNavigate = () => setActiveViewComponent(s => {
            console.log('AppRoot:nav._handleNavigate', { s });

            return {
                ActiveViewComponent: nav._getActiveViewComponent(),
                renderId: s.renderId + 1,
            };
        });
        nav._subscribeWebRoute();

        // Reload state
        doWork(async () => {
            await auth.reloadState();

            if (auth.getAuthenticationState().state === 'loggedIn') {
                await api.reloadState();
            }

            hasLoaded.current = true;
            autoRedirect(getRedirectResult(true));
        });

    }, [/* Only once */]);


    // Security/Setup

    // Redirect to Account Setup
    type RedirectTarget = 'login' | 'verifyPhone' | 'plan' | 'account' | 'payment' | 'phoneNumber';
    const shouldRedirectToTarget = (shouldMoveForward: boolean, target: RedirectTarget, isPageRequired: () => boolean, isPageActive: () => boolean): { action: 'stay' | 'redirect', target: RedirectTarget, info: any } | null => {
        if (isPageRequired()) {
            if (!isPageActive()) {
                return { action: 'redirect', target, info: { shouldMoveForward, required: isPageRequired(), active: isPageActive() } };
            } else {
                return { action: 'stay', target, info: { shouldMoveForward, required: isPageRequired(), active: isPageActive() } };
            }
        }

        if (!shouldMoveForward) {
            if (isPageActive()) {
                return { action: 'stay', target, info: { shouldMoveForward, required: isPageRequired(), active: isPageActive() } };
            }
        }

        return null;
    };

    const getRedirectResult = (shouldMoveForward: boolean) => {
        if (!hasLoaded.current) { return null; }

        console.log('getRedirectResult', { authState: auth.getAuthenticationState().state, isSecure: nav._getIsActiveViewSecure() });

        // Handle Invite also
        let result = shouldRedirectToTarget(shouldMoveForward, 'login',
            () => auth.getAuthenticationState().state === 'loggedOut',
            () => nav.LoginPage.isActive || nav.InvitePage.isActive);
        if (result) { return result; }

        result = shouldRedirectToTarget(shouldMoveForward, 'verifyPhone', () => auth.getAuthenticationState().state === 'requiresPhoneVerification' && nav._getIsActiveViewSecure(), () => nav.VerifyPhonePage.isActive);
        if (result) { return result; }

        // Catch all for invalid authState
        result = shouldRedirectToTarget(shouldMoveForward, 'login', () => auth.getAuthenticationState().state !== 'loggedIn' && nav._getIsActiveViewSecure(), () => nav.LoginPage.isActive);
        if (result) { return result; }

        const accountState = api.getAccountState();

        // Don't redirect admin to account pages
        if (RoleKind.isAdmin(accountState.userRole)) {
            return null;
        }

        result = shouldRedirectToTarget(shouldMoveForward, 'plan', () => !accountState.hasPlan, () => nav.EditPlanPage.isActive);
        if (result) { return result; }
        result = shouldRedirectToTarget(shouldMoveForward, 'account', () => !accountState.hasAccountDetails, () => nav.EditAccountPage.isActive);
        if (result) { return result; }
        result = shouldRedirectToTarget(shouldMoveForward, 'payment', 
            () => (accountState.stats.paymentDue > 0 && (!accountState.isPaymentsReady || accountState.paymentFailureCount > 0)) 
                || !accountState.stats.cycleSubscriptionCredits, 
            () => nav.EditPaymentPage.isActive);
        if (result) { return result; }
        result = shouldRedirectToTarget(shouldMoveForward, 'phoneNumber', () => !accountState.hasPhoneNumber, () => nav.EditPhoneNumbersPage.isActive);
        if (result) { return result; }

        return null;
    }

    const [activeRedirectResult, setActiveRedirectResult] = useState(null as null | ReturnType<typeof getRedirectResult>);

    const autoRedirect = (redirectResult: ReturnType<typeof getRedirectResult>) => {
        console.log('autoRedirect', { redirectResult });

        if (activeRedirectResult?.action === redirectResult?.action
            && activeRedirectResult?.target === redirectResult?.target) { return; }

        setActiveRedirectResult(redirectResult);
        if (redirectResult === null) { return; }

        const sLogin = redirectResult?.target === 'login';
        if (sLogin) {
            return;
        }

        const sVerifyPhone = redirectResult?.target === 'verifyPhone';
        if (sVerifyPhone) {
            return;
        }

        if (redirectResult?.action === 'redirect') {
            if (redirectResult.target === 'plan') {
                return nav.EditPlanPage.open();
            }
            if (redirectResult.target === 'account') {
                return nav.EditAccountPage.open();
            }
            if (redirectResult.target === 'payment') {
                return nav.EditPaymentPage.open();
            }
            if (redirectResult.target === 'phoneNumber') {
                return nav.EditPhoneNumbersPage.open();
            }
        }
        return;
    };
    useEffect(() => autoRedirect(getRedirectResult(false)));

    console.log('AppRoot: render', { hasLoaded: hasLoaded.current, loading, redirectTarget: activeRedirectResult?.target, });

    // Show Blank Content if loading
    if (loading || !hasLoaded.current) {
        return (
            <MainLayout store={props.store}>
                <ActivityIndicator size='large' />
            </MainLayout>
        );
    }

    // Show Login
    if (activeRedirectResult?.target === 'login') {
        return (
            <LoginPage store={props.store} />
        );
    }

    // Show Verify
    if (activeRedirectResult?.target === 'verifyPhone') {
        return (
            <VerifyPhonePage store={props.store} />
        );
    }

    // Show Blank Page if redirecting
    if (activeRedirectResult?.action === 'redirect') {
        return (
            <View></View>
        );
    }

    return (
        <>
            <ActiveViewComponent />
        </>
    );
};


