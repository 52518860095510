import React, { useRef } from 'react';
import { UploadApiAccess } from '../../client-api-access/types/UploadApiAccess';


export const MediaUploaderWeb = (props: { uploadApi: UploadApiAccess, onUploadingStart?: () => void, onUploaded: (readUrl: string) => void, onError: (message: string) => void, maxFileSize?: number, onClear: () => void }) => {

    const onChange = async () => {
        console.log('MediaUploader.onSubmit START')

        const file = fileInput.current?.files?.[0] ?? null;
        if (!file) { return; }

        const fileSize = file.size;

        if (fileSize > (props.maxFileSize ?? 1000 * 1000 * 1)) {
            props.onError('This file is too large');
            return;
        }

        const fileName = file.name;
        const fileType = file.type;
        const urls = await props.uploadApi.createUploadUrl(fileType);

        console.log('MediaUploader.onSubmit createUrls', { urls, file });

        props.onUploadingStart?.();
        await uploadImage(urls.uploadUrl, file);

        console.log('MediaUploader.onSubmit DONE', { readUrl: urls.readUrl });
        props.onUploaded(urls.readUrl);
    };

    const onClear = () => {
        props.onClear();
    };

    const fileInput = useRef<HTMLInputElement | null>(null);

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 1, }}>
                <label style={{ display: 'block', margin: 4, padding: 4, fontSize: 16, borderWidth: 2, borderStyle: 'solid', borderColor: '#863d8f', borderRadius: 8, textAlign: 'center', }}>
                    Choose File
                <input type="file" ref={r => fileInput.current = r} onChange={onChange} style={{ width: 0.1, height: 0.1, opacity: 0, overflow: 'hidden', position: 'absolute', zIndex: -1 }} />
                </label>
            </div>
            <div style={{ flex: 1, }}>
                <div style={{ display: 'block', margin: 4, padding: 4, fontSize: 16, borderWidth: 2, borderStyle: 'solid', borderColor: '#863d8f', borderRadius: 8, textAlign: 'center', }} onClick={onClear}>
                    Clear File
                </div>
            </div>
        </div>
    );
};

const uploadImage = async (uploadUrl: string, data: File) => {
    // const formData = new FormData();
    // formData.append('file', data);
    // await fetch(uploadUrl, { method: 'PUT', body: formData });
    await fetch(uploadUrl, {
        headers: {
            // ACL: 'public-read',
            // 'x-amz-acl': 'public-read',
            // 'Content-Type': data.type,
        },
        method: "PUT",
        body: new Blob([data], { type: data.type }),
    });
};