export type ValueOrPromise<T> = { value: T | null, load: () => Promise<T>, valueOrLoad: () => Promise<T>, };

export const ValueOrPromise = {
    fromValue<T>(value: T): ValueOrPromise<T> {
        return {
            value,
            load: () => Promise.resolve(value),
            valueOrLoad: () => Promise.resolve(value),
        };
    },
    fromAsync<T>(getValue: () => Promise<T>): ValueOrPromise<T> {
        let value: T | null = null;
        const load = async () => {
            value = await getValue();
            return value;
        };
        return {
            value,
            load,
            valueOrLoad: async () => value ? Promise.resolve(value) : await load(),
        };

    }
};