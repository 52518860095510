import { Diff } from "./TypeUtils";

export function getKeysTyped<T>(obj: T): (keyof T)[] {
    return Object.keys(obj ?? {}) as (keyof T)[];
}

export function getKeysTypedExcept<T, TExcept>(obj: T, except: TExcept): Diff<(keyof T), (keyof TExcept)>[] {
    const kExcept = Object.keys(except ?? {});
    return Object.keys(obj ?? {}).filter(k => kExcept.indexOf(k) < 0) as any[];
}
