import React from 'react';
import { View, Text } from 'react-native';
import { Store } from '../store/Store';
import { ButtonTextView, TextView } from './TextView';
import { Timestamp } from '../../utils/Time';
import { formatPhoneNumber_UsaCanada } from '../../utils/PhoneNumbers';
import { RoleKind } from '../../data-types/ModelTypes';

export const AdminInfo = (props: {
    store: Store,
}) => {

    const { store } = props;
    const { api, theme } = store;
    const accountState = api.getAccountState();
    // const phoneNumber = accountState.stats.accountPhoneNumbers?.[0] ?? undefined;
    // const isReady = accountState.hasPlan && accountState.hasAccountDetails && accountState.isPaymentsReady && accountState.hasPhoneNumber;

    // if (!isReady) {
    //     return (<></>);
    // }
    if (!RoleKind.isAdmin(accountState.userRole)) {
        return (<></>);
    }
    if (!accountState.isImpersonatingAccount) {
        return (<></>);
    }

    return (
        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', backgroundColor: '#FFFFAA' }}>
            <TextView style={theme.accountInfo.info.label} text={'Logged in As: ' + (accountState.accountBusinessName || 'New Account')} />
            <ButtonTextView style={theme.card.buttonTextView_major_noFlex} text={'Stop'} onPress={() => store.admin.stopImpersonation()} />
            <ButtonTextView style={theme.card.buttonTextView_minor} text={'Manage'} onPress={() => store.nav.ViewAccountsPage.open({ search: accountState.accountBusinessName })} />
        </View>
    );
};