import React, { useState } from 'react';
import { View, Text, ActivityIndicator, ViewStyle } from 'react-native';
import { Store } from '../../store/Store';
import { useLoadData, useAutoLoadingError } from '../../../utils/Hooks';
import { List } from '../../components/List';
import { ContactModel, MessageDeliveryModel, MessageReplyModel, MessageTemplateModel } from '../../../data-types/ModelTypes';
import { Timestamp, DateOnly, TimeOnly, TimeZone } from '../../../utils/Time';
import { ButtonTextView, TextView } from '../../components/TextView';
import { MainLayout } from '../../components/Layout';
import { ErrorBox } from '../../components/ErrorBox';
import { NavLinks } from '../Common/NavLinks';
import { TextViewStyle } from '../../style/Theme';
import { useReference, CampaignLink } from '../Common/ReferenceLinks';
import { formatPhoneNumber_UsaCanada } from '../../../utils/PhoneNumbers';
import { MessageTemplateFieldEditor } from '../Campaigns/components/MessageTemplateEditor';
import { Builder, ValidValue } from '../../../utils/Builder';
import { MessageTemplateField } from '../../../logic/campaigns/campaignFieldDefinitions';
import { Reference, getReferenceOrUndefined } from '../../../data-types/SimpleDataTypes';
import { MessageMediaView } from '../Campaigns/components/CampaignMessages';

export type MessageItemStyle = {
    view: ViewStyle,
    content: TextViewStyle,
    info: {
        view: ViewStyle,
        campaignName: TextViewStyle,
        campaignRole: TextViewStyle,
        spacer: ViewStyle,
        time: TextViewStyle,
        time_pending: TextViewStyle,
    },
};
export const MessageItemDisplay = (props: {
    store: Store,
    style: MessageItemStyle,
    content: { text: string, additionalTexts: string[], imageUrl?: string, mediaUrls?: string[] },
    messageTemplateRef?: Reference<MessageTemplateModel<any>>,
    deliveredTime?: Timestamp,
    createdTime?: Timestamp,
    futureDate?: DateOnly,
    futureTime?: TimeOnly,
    futureTimeZone?: TimeZone,
}) => {
    const { store, style, content, messageTemplateRef, deliveredTime, createdTime, futureDate, futureTime, futureTimeZone } = props;

    const messageTemplate = useReference(store, messageTemplateRef, (s, x) => s.api.loadMessageTemplate(x));
    const campaign = useReference(store, messageTemplate?.campaign || null, (s, x) => s.api.loadCampaign(x));

    const isImmediate = props.futureDate && Timestamp.fromDateTime(props.futureDate, props.futureTime ?? TimeOnly.nowTimeOnly() as TimeOnly, props.futureTimeZone) < Timestamp.now();

    return (
        <View style={style.view}>
            {/* <TextView style={style.content} text={JSON.stringify(content, null, 2)} /> */}

            <TextView style={style.content} text={content.text} />
            {content.additionalTexts?.map((x, i) => (
                <TextView key={i} style={style.content} text={x} />
            ))}
            {(content.imageUrl || content.mediaUrls) && (
                <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <MessageMediaView content={content}/>
                </View>
            )}
            <View style={style.info.view}>
                <View>
                    {/* {campaign && <TextView style={style.info.campaignName} text={campaign.name} />} */}
                    {campaign && <CampaignLink store={store} style={style.info.campaignName} itemRef={getReferenceOrUndefined(campaign)} />}
                    {messageTemplate && <TextView style={style.info.campaignRole} text={messageTemplate.campaignRole} />}
                </View>
                <View style={style.info.spacer} />
                {!deliveredTime && !!createdTime && (
                    <TextView style={style.info.time} text={`Sending - ${Timestamp.formatTimestamp(createdTime)}`} />
                )}
                {!!deliveredTime && (
                    <TextView style={style.info.time} text={Timestamp.formatTimestamp(deliveredTime)} />
                )}
                {!!futureDate && isImmediate && (
                    <TextView style={style.info.time} text={`To be sent now`} />
                )}
                {!!futureDate && !isImmediate && (
                    <TextView style={style.info.time} text={`To be sent on ${DateOnly.formatDateOnly(futureDate)}${futureTime ? ' at ' + TimeOnly.formatTimeOnly(futureTime) : ''}${futureTimeZone ? ' ' + TimeZone.formatTimeZone(futureTimeZone) : ''}`} />
                )}
            </View>
        </View>
    );
};

export const MessageItem = (props: { store: Store, item: MessageDeliveryModel | MessageReplyModel }) => {
    const { store, item: itemInit } = props;
    const { nav, theme } = store;

    const item = props.item;
    const messageSent = (item as MessageDeliveryModel).toContact ? item as MessageDeliveryModel : null;

    const style: MessageItemStyle = !!messageSent ? theme.message.sent : theme.message.received;

    return <MessageItemDisplay store={store} style={style} content={{ additionalTexts: [], ...item.content }} messageTemplateRef={messageSent?.messageTemplate}
    />;
};

export const DirectMessage = (props: { store: Store, contact: ContactModel, onMessageSent: (message: MessageDeliveryModel) => void }) => {
    const { store } = props;
    const { api, nav, theme } = store;

    const field = MessageTemplateField({
        templateId: '',
        label: 'Send Direct Message',
        hint: 'Send a message immediately to the contact',
        placeholder: 'Enter the Message',
        imageMode: 'disabled', linkMode: 'disabled',
    });
    const [directMessage, setDirectMessage] = useState({ value: {}, isValid: true } as ValidValue<Builder<MessageTemplateModel<any>>>);
    const changeDirectMessage = (value: ValidValue<Builder<MessageTemplateModel<any>>>) => {
        setDirectMessage(value);
    };

    const { loading, error, doWork } = useAutoLoadingError();
    const sendMessage = () => {
        if (!directMessage.isValid) { return; }

        doWork(async (stopIfObsolete) => {
            const sentMessage = await api.sendDirectMessage(props.contact, directMessage.value.text?.value ?? '');
            stopIfObsolete();
            setDirectMessage({ value: {}, isValid: true });
            props.onMessageSent(sentMessage);
        });
    };

    const isReadyToSend = !!directMessage.value.text?.value && directMessage.isValid;
    const isDisabled = !props.contact.status.enabled || props.contact.status.optInStatus === 'refused';

    return (
        <View>
            {loading && <ActivityIndicator size="large" />}
            {error && <ErrorBox error={error} />}
            {isDisabled && <ErrorBox error={{ message: 'This contact is disabled' }} />}
            {!isDisabled && !loading && (
                <>
                    <MessageTemplateFieldEditor
                        style={theme.card.messageTemplateField_small}
                        field={field}
                        value={directMessage.value}
                        onChangeValue={changeDirectMessage}
                        store={props.store}
                    />
                    <View style={theme.card.buttonRow}>
                        <ButtonTextView style={isReadyToSend ? theme.card.buttonTextView_major : theme.card.buttonTextView_major_disabled} text="Send" onPress={sendMessage} isDisabled={!isReadyToSend} />
                    </View>
                </>
            )}
        </View>
    );
};

export const ViewContactMessageThreadPage = (props: { store: Store, args?: { contact?: ContactModel, contactRef?: Reference<ContactModel> }, argsRoute?: { contactId: string } }) => {
    const { store } = props;
    const { api, nav, theme } = store;
    const { loading, error, doWork } = useAutoLoadingError();

    const messageData = useLoadData(doWork, async () => {
        const contact = props.args?.contact ?? (props.args?.contactRef ? await api.getContact(props.args!.contactRef!.id) : await api.getContact(props.argsRoute?.contactId!));
        const messages = await api.getContactMessages(contact);
        return {
            contact,
            messages,
        };
    }, () => [props.args]);

    const [sentMessages, setSentMessages] = useState([] as MessageDeliveryModel[]);
    const onMessageSent = (sentMessage: MessageDeliveryModel) => {
        setSentMessages(s => [sentMessage, ...s]);
    };

    const contact = messageData.data?.contact;
    const messages = [...sentMessages, ...messageData.data?.messages ?? []];

    console.log('ViewContactMessageThreadPage RENDER', { sentMessages });
    return (
        <MainLayout store={store} options={{ isFullWidth: false, isFullHeight: true }}>
            <View style={theme.nav.row}>
                <NavLinks.ViewContactLists store={store} style={store.theme.nav.breadcrumbButton} />
                <NavLinks.ViewContact store={store} style={store.theme.nav.breadcrumbButton} itemRef={getReferenceOrUndefined(contact)} />
                <NavLinks.ViewRecentMessageThreads store={store} style={store.theme.nav.breadcrumbButton} />
                <NavLinks.ViewContactMessages store={store} style={store.theme.nav.breadcrumbButton} itemRef={getReferenceOrUndefined(contact)} />
            </View>
            <View style={theme.page.titleView}>
                <Text style={theme.page.titleText}>View Contact Messages</Text>
                <Text style={theme.page.titleText}>{formatPhoneNumber_UsaCanada(contact?.phoneNumber ?? '')} {contact?.firstName ?? ''} {contact?.lastName ?? ''}</Text>
                <View style={theme.page.titleUnderlineView}>
                </View>
            </View>
            <View style={theme.message.thread.view}>
                <List
                    style={theme.list}
                    items={messages}
                    inverted={true}
                    loading={loading}
                    error={error}
                    extractKey={x => x.id}
                    // extractSearchKey={x => [x.content.text]}
                    // extractSortKey={x => [x.deliveryTime]}
                    renderItem={x => <MessageItem store={store} item={x.item} />} />
                <View style={theme.message.thread.spacer} />
            </View>
            {/* Direct Message */}
            <View style={theme.message.directMessage.view}>
                {contact && <DirectMessage store={store} contact={contact} onMessageSent={onMessageSent} />}
            </View>
        </MainLayout>
    );
};


