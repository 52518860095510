import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Store } from '../../store/Store';
import { useLoadData, useAutoLoadingError } from '../../../utils/Hooks';
import { List } from '../../components/List';
import { MessageThreadInfo } from '../../../data-types/ModelTypes';
import { Timestamp } from '../../../utils/Time';
import { TextView } from '../../components/TextView';
import { MainLayout } from '../../components/Layout';
import { NavLinks } from '../Common/NavLinks';
import { useReference, } from '../Common/ReferenceLinks';
import { formatPhoneNumber_UsaCanada } from '../../../utils/PhoneNumbers';

const MessageThreadInfoComponent = (props: { store: Store, item: MessageThreadInfo }) => {
    const { store } = props;
    const { api, nav, theme } = store;

    const item = props.item;

    // TODO: Detect custom reply, direct message
    const isReply = item.lastMessage.kind === 'reply';
    const style = isReply ? theme.messageThreads.item_reply : theme.messageThreads.item;
    const contact = useReference(store, item.contact, (s, x) => s.api.loadContact(x));

    return (
        <TouchableOpacity onPress={() => nav.ViewContactMessageThreadPage.open({ contact: contact! })} disabled={!contact}>
            <View style={style.view}>
                <View style={style.row}>
                    <View>
                        <TextView style={style.title} text={`${formatPhoneNumber_UsaCanada(contact?.phoneNumber ?? '')} ${contact?.firstName ?? ''} ${contact?.lastName ?? ''}`} />
                        <TextView style={style.message} text={item.lastMessage.item.content.text} />
                    </View>
                    <View style={style.spacer} />
                    <View>
                        <TextView style={style.time} text={Timestamp.formatTimestamp(item.lastMessage.item.createdTime)} />
                    </View>
                </View>
            </View>
        </TouchableOpacity>
    );
};

export const ViewRecentMessageThreadsPage = (props: { store: Store }) => {
    const { store } = props;
    const { api, theme } = store;
    const { loading, error, doWork } = useAutoLoadingError();
    const data = useLoadData(doWork, () => api.getRecentMessageThreads());
    const messageThreads = data.data ?? [];

    return (
        <MainLayout store={store}>
            <View style={theme.nav.row}>
                <NavLinks.ViewRecentMessageThreads store={store} style={store.theme.nav.breadcrumbButton} />
            </View>
            <View style={theme.page.titleView}>
                <Text style={theme.page.titleText}>Recent Messages</Text>
                <View style={theme.page.titleUnderlineView} />
            </View>
            <List
                style={theme.list}
                items={messageThreads}
                loading={loading}
                error={error}
                extractKey={x => x.contact.id}
                // extractSearchKey={x => [x.contact.displayName]}
                // Already sorted
                // extractSortKey={x => [x.lastMessage.]}
                renderItem={x => <MessageThreadInfoComponent store={store} item={x.item} />} />
        </MainLayout>
    );
};

