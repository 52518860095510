import { ContactStatus } from "./ModelTypes";

export const getContactStatusText = (status: ContactStatus) => {
    if (status.wasRemoved) {
        return 'Removed';
    }
    if (status.hasDeliveryFailure) {
        return 'Delivery Failed';
    }
    if (status.optInStatus === 'refused') {
        return 'Stopped';
    }
    if (!status.enabled) {
        return 'Disabled';
    }
    if (status.optInStatus === 'accepted') {
        return 'Active';
    }
    if (status.optInStatus === 'new') {
        // return 'Imported';
        // return 'New';
        return 'Manually Opted In';
    }
    // requested is not used
    return 'Unknown';
};