import React, { } from 'react';
import { TextStyle } from 'react-native';
import Markdown from 'react-native-markdown-renderer';

export type MarkdownStyle = {
    root?: TextStyle,
    view?: TextStyle,
    codeBlock?: TextStyle,
    codeInline?: TextStyle,
    del?: TextStyle,
    em?: TextStyle,
    headingContainer?: TextStyle,
    heading?: TextStyle,
    heading1?: TextStyle,
    heading2?: TextStyle,
    heading3?: TextStyle,
    heading4?: TextStyle,
    heading5?: TextStyle,
    heading6?: TextStyle,
    hr?: TextStyle,
    blockquote?: TextStyle,
    inlineCode?: TextStyle,
    list?: TextStyle,
    listItem?: TextStyle,
    listUnordered?: TextStyle,
    listUnorderedItem?: TextStyle,
    listUnorderedItemIcon?: TextStyle,
    listUnorderedItemText?: TextStyle,
    listOrdered?: TextStyle,
    listOrderedItem?: TextStyle,
    listOrderedItemIcon?: TextStyle,
    listOrderedItemText?: TextStyle,
    paragraph?: TextStyle,
    hardbreak?: TextStyle,
    strong?: TextStyle,
    table?: TextStyle,
    tableHeader?: TextStyle,
    tableHeaderCell?: TextStyle,
    tableRow?: TextStyle,
    tableRowCell?: TextStyle,
    text?: TextStyle,
    strikethrough?: TextStyle,
    link?: TextStyle,
    blocklink?: TextStyle,
    u?: TextStyle,
    image?: TextStyle,
};

export const MarkdownContent = (props: { style: MarkdownStyle, children: string }) => {
    return (
        <Markdown style={props.style as any}>{props.children}</Markdown>
    );
};
