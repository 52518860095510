import React, { useState, useEffect } from 'react';
import { Store } from "../../../store/Store";
import { useAutoLoadingError } from "../../../../utils/Hooks";
import { ButtonTextView } from '../../../components/TextView';
import { getReferenceFromId, Reference } from '../../../../data-types/SimpleDataTypes';
import { CampaignModel, CampaignModel_Common } from '../../../../data-types/ModelTypes';

export const CampaignEnableDisableButton = <TCampaign extends CampaignModel_Common>({
    store,
    campaign,
    onCampaignChanged,
}: {
    store: Store,
    campaign: { id: string, kind: CampaignModel['kind'], status?: { isActive: boolean } },
    onCampaignChanged: (enabled:boolean) => void,
}) => {

    const { theme } = store;
    const { loading, error, doWork } = useAutoLoadingError();

    const toggleCampaign = (enable: boolean) => {
        doWork(async (stopIfObsolete) => {
            await store.api.toggleCampaign(getReferenceFromId(campaign.id), enable);
            stopIfObsolete();
            onCampaignChanged(enable);
        });
    };

    const shouldEnable = !campaign.status?.isActive;
    const canEnable = campaign.kind !== 'standard';

    if (shouldEnable && !canEnable) {
        return <></>;
    }

    return (
        <ButtonTextView style={theme.card.buttonTextView_danger} 
            text={shouldEnable ? "Enable Campaign" : "Disable Campaign"} 
            onPress={()=>toggleCampaign(shouldEnable)} loading={loading} error={error} />
    );
};