import React from 'react';
import { View, Text } from 'react-native';
import { Store } from '../store/Store';
import { TextView } from './TextView';
import { Timestamp } from '../../utils/Time';
import { formatPhoneNumber_UsaCanada } from '../../utils/PhoneNumbers';

export const AccountInfo = (props: {
    store: Store,
}) => {

    const { store } = props;
    const { api, theme } = store;
    const accountState = api.getAccountState();
    const phoneNumber = accountState.stats.accountPhoneNumbers?.[0] ?? undefined;
    const isReady = accountState.hasPlan && accountState.hasAccountDetails && accountState.isPaymentsReady && accountState.hasPhoneNumber;

    if (!isReady) {
        return (<></>);
    }

    const stats = accountState.stats;

    return (
        <View style={theme.accountInfo.container}>
            <AccountInfoItem store={store} label={`Remaining Credits`} value={stats.cycleRemainingCredits} />
            <AccountInfoItem store={store} label={`Plan Credits`} value={stats.planCredits} />
            <AccountInfoItem store={store} label={`Extra Credits`} value={stats.cycleExtraCredits} />
            <AccountInfoItem store={store} label={`End of Cycle`} value={Timestamp.formatTimestamp_asDateOnly(accountState.stats.cycleEndTime)} />
            <AccountInfoItem store={store} label={`Phone Number`} value={formatPhoneNumber_UsaCanada(phoneNumber)} />
        </View>
    );
};

const AccountInfoItem = (props: { store: Store, label: string, value: string | number }) => {
    const { store } = props;
    const { api, theme } = store;
    return (
        <View style={theme.accountInfo.info.container}>
            <TextView style={theme.accountInfo.info.label} text={props.label} />
            <TextView style={theme.accountInfo.info.value} text={props.value + ''} />
        </View>
    );
}