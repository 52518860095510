import { Gsm } from "../utils/Gsm";

export const getMessageMode = (message?: { text?: string, imageUrl?: string, additionalTexts?: string[], }): 'sms' | 'mms' => {
    if (!message) { return 'sms'; }

    if (message.imageUrl) { return 'mms'; }
    if (message.additionalTexts?.length ?? 0 > 0) { return 'sms'; }

    if (message.text && !Gsm.validation(true, () => 'sms', false).validate(message.text ?? '').isValid) { return 'mms'; }

    return 'sms';
};