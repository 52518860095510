import React, { useState } from 'react';
import { View, Text, ActivityIndicator, TouchableOpacity } from 'react-native';
import { Store } from '../../store/Store';
import { useLoadData, useAutoLoadingError } from '../../../utils/Hooks';
import { TextView, ButtonTextView } from '../../components/TextView';


export const ViewKeywords = (props: { store: Store, onSelectKeyword: (keyword: string) => void }) => {

    const { store } = props;
    const { nav, theme } = store;

    const { loading, error, doWork } = useAutoLoadingError();
    const keywords = useLoadData(doWork, async () => {
        return await store.api.getKeywords();
    });

    return (
        <View>
            <View style={theme.card.rowWrap}>
                <>
                    <TextView style={theme.card.shortcutHeader} text="Keywords:" />
                    {keywords && keywords.data?.map(x => (
                        <ButtonTextView key={x} style={theme.card.shortcutButton} text={x} onPress={() => props.onSelectKeyword(x)} />
                    ))}
                </>
            </View>
        </View>
    );
};