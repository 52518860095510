import React from 'react';
import { AppRoot } from './AppRoot';
import { Store } from './store/Store';

const store = new Store();

export default function App() {
    const StoreAppWrapper = store.StoreAppWrapper;
    return (
        <StoreAppWrapper>
            <AppRoot store={store} />
        </StoreAppWrapper>
    );
}

