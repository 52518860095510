import React, { useState, useEffect } from 'react';
import { Store } from '../../store/Store';
import { ButtonTextView } from '../../components/TextView';
import { TextViewStyle } from '../../style/Theme';
import { ContactLink, ContactListLink, ContactListContactsLink, ContactMessagesLink } from './ReferenceLinks';

function NavLink(props: { style: TextViewStyle, navItem: { open: (args: void) => void, title: string } }) {
    return (
        <ButtonTextView onPress={() => props.navItem.open()}
            style={props.style}
            text={props.navItem.title} />
    );
}

export const NavLinks = {
    ViewContact: ContactLink,
    ViewContactMessages: ContactMessagesLink,
    ViewRecentMessageThreads: (props: { store: Store, style: TextViewStyle, }) => (
        <NavLink style={props.style} navItem={props.store.nav.ViewRecentMessageThreadsPage} />
    ),
    ViewContactList: ContactListLink,
    ViewContactListContacts: ContactListContactsLink,
    ViewContactLists: (props: { store: Store, style: TextViewStyle, }) => (
        <NavLink style={props.style} navItem={props.store.nav.ViewContactListsPage} />
    ),
    ViewAllContacts: (props: { store: Store, style: TextViewStyle, }) => (
        <NavLink style={props.style} navItem={props.store.nav.ViewAllContactsPage} />
    ),
};