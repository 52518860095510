import { Price } from "../../data-types/SimpleDataTypes";

export const pricing = {
    creditsPerMessage: {
        sms: 1,
        mms: 3,
        smsCanada: 2,
        mmsCanada: 6,
    },
    plans: [
        { id: 'plan01', name: 'Swamp', messagesMonthly: 500, priceMonthly: 25 as Price, },
        { id: 'plan02', name: 'Gator', messagesMonthly: 1000, priceMonthly: 45 as Price, },
        { id: 'plan03', name: 'Croc', messagesMonthly: 2500, priceMonthly: 100 as Price, },
        { id: 'plan04', name: 'Fierce', messagesMonthly: 5000, priceMonthly: 175 as Price, },
        { id: 'plan05', name: 'Wally', messagesMonthly: 10000, priceMonthly: 300 as Price, },
        { id: 'plan06', name: 'Amphibious', messagesMonthly: 25000, priceMonthly: 675 as Price, },
        { id: 'plan07', name: 'Reptile', messagesMonthly: 50000, priceMonthly: 1200 as Price, },
        { id: 'plan08', name: 'Tail', messagesMonthly: 100000, priceMonthly: 2200 as Price, },
        { id: 'plan09', name: 'Savage', messagesMonthly: 250000, priceMonthly: 5000 as Price, },
    ],
    plans_extraCredits: [
        { id: 'extra01', name: '500', price: 30 as Price, messages: 500 },
        { id: 'extra02', name: '1000', price: 55 as Price, messages: 1000 },
        { id: 'extra03', name: '2000', price: 99 as Price, messages: 2000 },
    ],
}

export type PricingCreditsPerMessage = typeof pricing['creditsPerMessage'];