import React, { useState, useRef, useEffect } from 'react';
import { Store } from '../../store/Store';
import { AccountDetails_Edit } from '../../../data-types/ModelTypes';
import { MainLayout } from '../../components/Layout';
import { CampaignCard, BuilderDebugInfo } from '../Campaigns/EditCampaignComponents';
import { OptionField, TextView, TextField, ButtonTextView } from '../../components/TextView';
import { theme } from '../../style/Theme';
import { Builder, ValidValue, BuilderResult } from '../../../utils/Builder';
import { setValueWithCallback, SetValueWithCallback, delay } from '../../../utils/Async';
import { View, ActivityIndicator } from 'react-native';
import { buildBuilder } from '../../../utils/BuilderFieldDefinitions';
import { useAutoLoadingError, useMounted } from '../../../utils/Hooks';
import { ErrorBox } from '../../components/ErrorBox';
import { addressStates } from '../../../utils/AddressStates';
import { formatPostalCode_Usa } from '../../../utils/PostalCodes';
import { useAlertModal } from '../../components/AlertModal';

const AccountDetailsEditor = (props: { store: Store, account: Builder<AccountDetails_Edit>, setAccountValue: SetValueWithCallback }) => {

    const { account, setAccountValue } = props;
    const { theme } = props.store;

    if (!account.address) {
        account.address = {
            country: { value: 'United States', isValid: true },
        };
    }

    return (
        <>
            <TextField style={theme.card.textField}
                field={{
                    __type: 'ui',
                    isRequired: true,
                    label: 'Business Account Name',
                    hint: 'This is your business’s or organization’s name, not your personal name.',
                }}
                value={account.businessName?.value ?? ''}
                onChangeValue={setAccountValue((value) => account.businessName = value ?? { value: '', isValid: false })} />
            <TextField style={theme.card.textField}
                field={{
                    __type: 'ui',
                    isRequired: true,
                    label: 'Business Email',
                    hint: 'Please use your business email and not your personal email. Each account needs a unique email address.',
                }}
                value={account.businessEmail?.value ?? ''}
                onChangeValue={setAccountValue((value) => account.businessEmail = value ?? { value: '', isValid: false })} />

            <View style={theme.card.view_white}>
                <TextView style={theme.card.header_fieldSection} text='Business Address' />
                <OptionField style={theme.card.optionField}
                    label="Country"
                    hint="Gator Text currently only accepts USA and Canadian users."
                    options={() => Promise.resolve([
                        { value: 'United States' as const, label: 'United States', },
                        { value: 'Canada' as const, label: 'Canada', },
                    ])}
                    value={account.address?.country?.value ?? ''}
                    onChangeValue={setAccountValue((value) => account.address!.country = (value as ValidValue<'United States' | 'Canada'>) ?? { value: 'United States', isValid: true })}
                />

                <TextField style={theme.card.textField}
                    field={{
                        __type: 'ui',
                        isRequired: true,
                        label: 'Street',
                        hint: 'Include apartment number or suite number if applicable.'
                    }}
                    value={account.address?.street?.value ?? ''}
                    onChangeValue={setAccountValue((value) => account.address!.street = value ?? { value: '', isValid: false })} />

                <TextField style={theme.card.textField}
                    field={{
                        __type: 'ui',
                        isRequired: true,
                        label: 'City',
                    }}
                    value={account.address?.city?.value ?? ''}
                    onChangeValue={setAccountValue((value) => account.address!.city = value ?? { value: '', isValid: false })} />

                <OptionField style={theme.card.optionField}
                    key={account.address?.country?.value ?? ''}
                    label={account.address?.country?.value === 'Canada' ? 'Province' : 'State'}
                    options={() => Promise.resolve(account.address?.country?.value === 'Canada' ? addressStates.CanadaProvinces : addressStates.UnitedStatesStates)}
                    value={account.address?.state?.value ?? ''}
                    onChangeValue={setAccountValue((value) => account.address!.state = value ?? { value: '', isValid: false })}
                />
                {/* <TextField style={theme.card.textField}
                    field={{
                        __type: 'ui',
                        isRequired: true,
                        label: account.address?.country?.value === 'Canada' ? 'Province' : 'State',
                        validation: Validation_State(),
                    }}
                    value={account.address?.state?.value}
                    onChangeValue={setAccountValue((value) => account.address!.state = value ?? undefined)} /> */}

                <TextField style={theme.card.textField}
                    field={{
                        __type: 'ui',
                        isRequired: true,
                        label: account.address?.country?.value === 'Canada' ? 'Postal Code' : 'Zip Code',
                        validation: {
                            validate: x => {
                                if (account.address?.country?.value === 'Canada') {
                                    return {
                                        isValid: !!x.toUpperCase().match(/^[A-Z][0-9][A-Z] [0-9][A-Z][0-9]$/),
                                        corrected: x.toUpperCase(),
                                        message: 'Please enter a valid postal code.'
                                    };
                                }
                                return {
                                    isValid: true,
                                    message: '',
                                    corrected: formatPostalCode_Usa(x),
                                }
                            },
                        },
                    }}
                    value={account.address?.zip?.value ?? ''}
                    onChangeValue={setAccountValue((value) => account.address!.zip = value ?? { value: '', isValid: false })} />

            </View>


        </>
    );
}

const AccountDetailsEditorView = (props: { store: Store }) => {

    const [renderId, setRenderId] = useState(0);
    const accountValue = useRef({} as Builder<AccountDetails_Edit>);
    const accountResult = useRef({} as BuilderResult<AccountDetails_Edit>);

    const { mounted } = useMounted();
    const changeTimoutId = useRef(0);
    const changeAccount = () => {
        accountValue.current = { ...accountValue.current };

        // Debounce
        clearTimeout(changeTimoutId.current);
        changeTimoutId.current = setTimeout(() => {
            if (!mounted) { return; }

            accountResult.current = buildBuilder(accountValue.current, {} as any);
            console.log('AccountDetailsEditorView changeAccount', { result: accountResult.current, value: accountValue.current });

            setRenderId(s => s + 1);
        }, 250);

    };

    const account = accountValue.current;
    const setAccountValue = setValueWithCallback(changeAccount);

    const { loading, error, doWork } = useAutoLoadingError();
    const [customError, setCustomError] = useState(null as null | string);
    const saveAccount = () => doWork(async (stopIfObsolete) => {
        setCustomError(null);

        // This can fail with timeout
        // As a workaround, try up to 3 times

        for (let i = 0; true; i++) {
            try {
                const result = await props.store.api.saveAccountDetails(accountResult.current.value);
                stopIfObsolete();

                if (!result.success) {
                    console.log('saveAccount', { result });
                    if (result.errorKind === 'duplicateBusinessAccountName') {
                        setCustomError('That business account name is already being used.\r\nIf you need assistance, call or text us at 610-688-6000.');
                        return;
                    }
                    if (result.errorKind === 'duplicateBusinessAccountEmail') {
                        setCustomError('That email is already being used for another account.\r\nIf you need assistance, call or text us at 610-688-6000.');
                        return;
                    }

                    // const result.errorKind === 'duplicateBusinessAccountName';
                }
                break;
            } catch (err) {
                console.log('saveAccount', { err });

                if (i < 3) {
                    // Try again
                    await delay(500);
                    stopIfObsolete();
                } else {
                    throw err;
                }
            }
        }

        stopIfObsolete();
        showConfirmation();
    });
    const { AlertHost, showAlert: showConfirmation } = useAlertModal({
        title: `Account Saved`,
        message: `Your account has been saved.`,
        buttons: [
            { text: 'OK', onPress: () => { if (!props.store.api.getAccountState().isPaymentsReady) { props.store.nav.EditPaymentPage.open(); } else { props.store.nav.RootPage.open(); } } },
        ]
    });

    // const hide = props.store.api.getAccountState().hasAccountDetails;

    // Load account details
    const [loadedId, setLoadedId] = useState(0);
    useEffect(() => {
        const accountDetails = props.store.api.getAccountState().accountDetails;
        if (!accountDetails) {
            accountValue.current = {
                businessName: { isValid: false, value: '' },
                businessEmail: { isValid: false, value: '' },
                address: {
                    city: { isValid: false, value: '' },
                    state: { isValid: false, value: '' },
                    street: { isValid: false, value: '' },
                    zip: { isValid: false, value: '' },
                    country: { isValid: true, value: 'United States' },
                },
            };
            setLoadedId(s => s + 1);
            return;
        }

        accountValue.current = {
            businessName: { isValid: true, value: accountDetails.businessName },
            businessEmail: { isValid: true, value: accountDetails.businessEmail },
            address: {
                city: { isValid: true, value: accountDetails.address.city },
                state: { isValid: true, value: accountDetails.address.state + '' },
                street: { isValid: true, value: accountDetails.address.street },
                zip: { isValid: true, value: accountDetails.address.zip + '' },
                country: { isValid: true, value: accountDetails.address.country },
            },
        };
        accountResult.current = buildBuilder(accountValue.current, {} as any);
        setLoadedId(s => s + 1);
    }, []);

    return (
        <CampaignCard store={props.store} key={loadedId}>
            <TextView style={theme.card.cardTitle} text="My Account" />
            <TextView style={theme.card.cardSummary} text="Enter or edit your account information." />

            {customError && (<ErrorBox error={{ message: customError }} />)}

            <AccountDetailsEditor store={props.store} account={account} setAccountValue={setAccountValue} />

            {/* Save */}
            <View style={theme.card.actionArea}>
                {!accountResult.current.isValid && (
                    <TextView style={theme.card.warnTextView} text='Enter All the Required Data' />
                )}
                {!!accountResult.current.errors && accountResult.current.errors.map(x => (
                    <TextView key={x.path} style={theme.card.warnTextView} text={x.error ?? ''} />
                ))}
                <View style={theme.card.buttonRow}>
                    <View style={theme.card.buttonSpacer} />
                    <ButtonTextView style={theme.card.buttonTextView_major} text="Save" onPress={saveAccount} isDisabled={!accountResult.current.isValid} />
                </View>
            </View>
            {/* Debug */}
            <BuilderDebugInfo store={props.store}
                builderResult={accountResult.current}
                builder={accountValue.current}
            />


            {loading && (<ActivityIndicator size='large' />)}
            {error && (<ErrorBox error={error} />)}
            <AlertHost useFixed={true} />
        </CampaignCard>
    );
};


export const AccountEditorPage = (props: { store: Store }) => {
    return (
        <MainLayout store={props.store}>
            <AccountDetailsEditorView store={props.store} />
        </MainLayout>
    );
}