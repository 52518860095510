import React, { ReactNode } from 'react';
import { View } from 'react-native';
import { CampaignModel_Common } from '../../../data-types/ModelTypes';
import { TextField, OptionField } from '../../components/TextView';
import { Store } from '../../store/Store';
import { SetValueWithCallback } from '../../../utils/Async';
import { Builder, ValidValue } from '../../../utils/Builder';
import { Campaign_CommonFieldsDefinition } from '../../../logic/campaigns/campaignFieldDefinitions';
import { ListEditor } from '../../components/ListEditor';
export { BuilderDebugInfo } from '../Common/BuilderDebugInfo';

export const CampaignCard = (props: { children: ReactNode, store: Store, options?: { shouldUseGrayBackground?: boolean } }) => (
    <View style={props.options?.shouldUseGrayBackground ? props.store.theme.card.view : props.store.theme.card.view_white}>
        {props.children}
    </View>
);

export const EditCampaignView_Common_NameDescription = (props: {
    store: Store,
    campaign: Builder<CampaignModel_Common>,
    campaignDefinition: Campaign_CommonFieldsDefinition,
    setCampaignValue: SetValueWithCallback,
}) => {

    const theme = props.store.theme;
    const setCampaignValue = props.setCampaignValue;

    return (
        <>
            <TextField style={theme.card.textField}
                field={props.campaignDefinition.name}
                value={props.campaign.name?.value}
                onChangeValue={setCampaignValue((value) => props.campaign.name = value ?? undefined)} />
            <TextField style={theme.card.textFieldMultiline}
                field={props.campaignDefinition.description}
                value={props.campaign.description?.value}
                onChangeValue={setCampaignValue((value) => props.campaign.description = value ?? undefined)} />
        </>
    );
};


const ContactListsEditor = (props: {
    store: Store,
    campaign: Builder<CampaignModel_Common>,
    campaignDefinition: Campaign_CommonFieldsDefinition,
    setCampaignValue: SetValueWithCallback,
}) => {
    const contactLists = props.campaign.toContactLists ?? (props.campaign.toContactLists = []);
    const theme = props.store.theme;
    const setCampaignValue = props.setCampaignValue;

    const getContactList = (i: number) => {
        return contactLists[i]?.value?.id || null;
    };

    const setContactList = (i: number) => setCampaignValue((value: ValidValue<string>) => {
        contactLists[i] = value.isValid ? { isValid: true, value: { id: value.value } } : { isValid: false, value: null as any as { id: string } };
    });

    return (
        <ListEditor
            store={props.store}
            label="Campaign Lists *"
            items={contactLists}
            renderItem={({ i }) => (
                <View style={{ flexGrow: 1 }}>
                    <OptionField style={theme.card.optionField}
                        options={props.campaignDefinition.toContactLists[0].id.getOptionValues}
                        value={getContactList(i)}
                        onChangeValue={setContactList(i)}
                        noOptionsMessage={'No Campaign Lists - Create a Campaign List First'}
                    />
                </View>
            )}
        />
    );
};

export const EditCampaignView_Common_Details = (props: {
    store: Store,
    campaign: Builder<CampaignModel_Common>,
    campaignDefinition: Campaign_CommonFieldsDefinition,
    setCampaignValue: SetValueWithCallback,
}) => {

    const theme = props.store.theme;
    const setCampaignValue = props.setCampaignValue;

    return (
        <>
            <ContactListsEditor
                {...props}
            />

            <OptionField style={theme.card.optionField}
                label="From Phone Number *"
                options={props.campaignDefinition.fromPhoneNumber.id.getOptionValues}
                value={props.campaign.fromPhoneNumber?.id?.value || null}
                onChangeValue={setCampaignValue((value) => props.campaign.fromPhoneNumber = value && { id: value } || undefined)}
                noOptionsMessage={'No Phone Numbers - Purchase a Phone Number First'}
            />
        </>
    );
};
