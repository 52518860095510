import { relationalEndpointsDefinition, relationalEndpoints_server } from "../generated/relationalQueriesConvertedPostgresDefinition.generated";
import { AppConfig_Client } from "../../../_config/client/appConfig_client";
import { ReferenceList } from "../../../data-types/SimpleDataTypes";
import { Model_FromServer, ReferenceList_FromServer } from "../../../data-types/TypeTransformations";
import { HandleRelationalRequestData, HandleRelationalResponseData } from "./RelationalWebClientTypes";
import { UserCredentials, UserCredentialsProvider } from "../../../data-types/SecurityDataTypes";
import { ApiError } from "../../../utils/Errors";
import { webRequest } from "../../../utils/WebRequest";

// type RelationalApiEndpointClient<T> = { [K in keyof T]:
//     T[K] extends (arg1: infer A1, arg2: infer A2) => Promise<{ items: { __modelType: infer TOutput }[] }> ? (arg1: A1, arg2: A2) => Promise<{ items: TOutput[] }>
//     : T[K] extends (arg1: infer A1) => Promise<{ items: { __modelType: infer TOutput }[] }> ? (arg1: A1) => Promise<{ items: TOutput[] }>
//     : T[K] extends (arg1: infer A1, arg2: infer A2) => Promise<{ item: { __modelType: infer TOutput } }> ? (arg1: A1, arg2: A2) => Promise<{ item: TOutput }>
//     : T[K] extends (arg1: infer A1) => Promise<{ item: { __modelType: infer TOutput } }> ? (arg1: A1) => Promise<{ item: TOutput }>
//     // : T[K] extends (arg1: infer A1) => Promise<{ __modelType: infer TOutput }> ? (arg1: A1) => Promise<TOutput>
//     : never
// };

type ExtraEndpoints = {
    namedEndpoint: <T extends { id: string }>(endpoint: string, value: unknown, paging: { skip: number, take: number, }) => Promise<{ items: Model_FromServer<T>[] }>,
    namedEndpointCount: <T extends { id: string }>(endpoint: string, value: unknown) => Promise<{ count: number }>,
};

const createRelationalApiFromEndpoints = <T>(executeRequest: ExecuteRequest, endpoints: T, ): T & ExtraEndpoints => {
    const definitions = relationalEndpointsDefinition;
    const keys = Object.keys(endpoints);
    const resultEndpoints = { ...endpoints };

    keys.forEach(k => {
        const def = definitions.find(x => x.methodName === k);
        (resultEndpoints as any)[k] = async (args: { value: any, paging: any }) => {
            const response = await executeRequest({ endpoint: k, ...args, })
                .catch(err => { throw new ApiError(`excuteRequest Failed for '${k}'`, { endpoint: k, error: err }); });
            return response;
        };
    });

    const resultExtra: ExtraEndpoints = {
        namedEndpoint: async (endpoint, value, paging) => await executeRequest({ endpoint, value, paging })
            .catch(err => { throw new ApiError(`excuteRequest Failed for 'namedEndpoint=${endpoint}'`, { endpoint, error: err }); }) as Promise<any>,
        namedEndpointCount: async (endpoint, value) => await executeRequest({ endpoint: endpoint.replace('_Select_', '_SelectCount_'), value, paging: {} })
            .catch(err => { throw new ApiError(`excuteRequest Failed for 'namedEndpointCount=${endpoint}'`, { endpoint, error: err }); }) as Promise<any>,
    };

    const result = {
        ...resultEndpoints,
        ...resultExtra,
    };
    return result;
};

type ExecuteRequest = <TInput, TPaging, TOutput>(
    data: {
        endpoint: string,
        value: TInput,
        paging: TPaging,
    } | {
        listRef: ReferenceList<any>,
        paging: TPaging,
    }
) => Promise<TOutput>;

export const createRelationalApi = (executeRequest: ExecuteRequest) => createRelationalApiFromEndpoints(executeRequest, relationalEndpoints_server) as typeof relationalEndpoints_server & ExtraEndpoints;

export const createRelationalWebClient = (appConfig: AppConfig_Client, userCredentialsProvider: UserCredentialsProvider) => createRelationalApi(async (input) => {
    const data = ('endpoint' in input)
        ? {
            queryName: input.endpoint,
            args: input.value,
            paging: input.paging as any,
        } : {
            queryName: (input.listRef as ReferenceList_FromServer<any>).queryName,
            args: (input.listRef as ReferenceList_FromServer<any>).queryArgs,
            paging: input.paging as any,
        };

    if (!data.queryName) {
        throw new ApiError('Missing QueryName', { data });
    }

    const userCredentials = userCredentialsProvider.getUserCredentials();
    if (!userCredentials) {
        throw new ApiError('Missing UserCredentials');
    }

    const bodyData: HandleRelationalRequestData = {
        userCredentials,
        data,
    };

    const url = `${appConfig.relationalDataApiDomain.replace('/\/$/', '')}/${data.queryName}`;
    const resultObj = (await webRequest(url, bodyData)) as HandleRelationalResponseData;

    if (resultObj.userCredentials) {
        userCredentialsProvider.setUserCredentials(resultObj.userCredentials);
    }

    return resultObj.data as any;
});