import { HeaderData, HeaderItem } from '../components/LayoutHeader';
import { Store } from '../store/Store';
import { IconKind } from '../components/Icon';
import { appConfig_client } from '../../_config/client/appConfig_client';

export const createHeaderData = (store: Store): HeaderData => {
    const { nav } = store;
    let _HeaderItem = null as any as HeaderItem;

    const accountState = store.api.getAccountState();

    const headerData: HeaderData = {
        siteBar: _HeaderItem = {
            title: '',
            children: [
                // // TODO: Links
                // _HeaderItem = {
                //     title: 'Solutions',
                //     // viewNav: nav.RootView,
                // },
                // _HeaderItem = {
                //     title: 'Free Texting',
                //     // viewNav: nav.RootView,
                // },
                // _HeaderItem = {
                //     title: 'Pricing',
                //     // viewNav: nav.RootView,
                // },
                // _HeaderItem = {
                //     title: 'Download App',
                //     // viewNav: nav.RootView,
                // },
            ],
        },
        accountBar: _HeaderItem = {
            title: '',
            children: [
                _HeaderItem = {
                    title: 'Account',
                    viewNav: nav.EditAccountPage,
                },
                _HeaderItem = {
                    title: 'Log Out',
                    viewNav: nav.LogoutPage,
                }
            ],
        },
        main: _HeaderItem = {
            title: '',
            children: [
                // // TODO: Create Dashboard
                // _HeaderItem = {
                //     title: 'Dashboard',
                //     icon: IconKind.dashboard,
                //     viewNav: nav.RootPage,
                // },

                // TODO: Create Purchase Page
                _HeaderItem = {
                    title: 'Plan',
                    icon: IconKind.subscription,
                    viewNav: nav.EditPlanPage,
                    children: [
                        _HeaderItem = {
                            title: 'Account',
                            viewNav: nav.LoginPage,
                        },
                        _HeaderItem = {
                            title: 'Manage Plan',
                            viewNav: nav.EditPlanPage,
                        },
                        // _HeaderItem = {
                        //     title: 'Buy Extra Credits',
                        //     viewNav: nav.EditAccountPage,
                        // },
                        _HeaderItem = {
                            title: 'Manage Account Details',
                            // isHidden: accountState.hasAccountDetails,
                            viewNav: nav.EditAccountPage,
                        },
                        _HeaderItem = {
                            title: 'Manage Payment Details',
                            viewNav: nav.EditPaymentPage,
                        },
                        _HeaderItem = {
                            title: 'Manage Phone Numbers',
                            isHidden: accountState.hasPhoneNumber,
                            viewNav: nav.EditPhoneNumbersPage,
                        },
                    ],
                },


                _HeaderItem = {
                    title: 'Campaigns',
                    icon: IconKind.campaigns,
                    viewNav: nav.ActiveCampaignsPage,
                    children: [
                        _HeaderItem = {
                            title: 'Create Campaign',
                            viewNav: nav.EditCampaignPage_Interactive,
                            children: [
                                _HeaderItem = {
                                    title: 'Interactive',
                                    viewNav: nav.EditCampaignPage_Interactive,
                                },
                                _HeaderItem = {
                                    title: 'Broadcast',
                                    viewNav: nav.EditCampaignPage_Standard,
                                },
                                _HeaderItem = {
                                    title: 'Sweepstakes',
                                    viewNav: nav.EditCampaignPage_Sweepstakes,
                                },
                                _HeaderItem = {
                                    title: 'Coupon',
                                    viewNav: nav.EditCampaignPage_Coupon,
                                },
                            ],
                        },
                        _HeaderItem = {
                            title: 'Active Campaigns',
                            viewNav: nav.ActiveCampaignsPage,
                        },
                        _HeaderItem = {
                            title: 'Campaign History',
                            viewNav: nav.CampaignHistoryPage,
                        },
                    ],
                },

                _HeaderItem = {
                    title: 'Lists',
                    icon: IconKind.contacts,
                    viewNav: nav.ViewContactListsPage,
                    children: [
                        _HeaderItem = {
                            title: 'Create List',
                            viewNav: nav.CreateContactListPage,
                        },
                        _HeaderItem = {
                            title: 'Manage Lists',
                            viewNav: nav.ViewContactListsPage,
                            children: [
                                _HeaderItem = {
                                    title: 'View Contacts',
                                    isHidden: true,
                                    viewNav: nav.ViewContactListContactsPage,
                                },
                                _HeaderItem = {
                                    title: 'View List',
                                    isHidden: true,
                                    viewNav: nav.ViewContactListPage,
                                },
                                _HeaderItem = {
                                    title: 'View All Contacts',
                                    isHidden: true,
                                    viewNav: nav.ViewAllContactsPage,
                                },
                            ],
                        },
                        _HeaderItem = {
                            title: 'Quick Remove',
                            viewNav: nav.QuickRemoveContactPage,
                        },
                    ],
                },

                // Disable Messages Tab
                // _HeaderItem = {
                //     title: 'Messages',
                //     icon: IconKind.message,
                //     viewNav: nav.ViewRecentMessageThreadsPage,
                //     children: [
                //         _HeaderItem = {
                //             title: 'View Contact Messages',
                //             isHidden: true,
                //             viewNav: nav.ViewContactMessageThreadPage,
                //         },
                //     ],
                // },
                // What does this contain?
                _HeaderItem = {
                    title: 'Resources',
                    icon: IconKind.resources,
                    link: `${appConfig_client.webSiteDomain}/blog`
                },

                _HeaderItem = {
                    title: 'Admin',
                    icon: IconKind.admin,
                    isAdminOnly: true,
                    viewNav: nav.ViewAccountsPage,
                    children: [
                        _HeaderItem = {
                            title: 'Manage Accounts',
                            viewNav: nav.ViewAccountsPage,
                        },
                        _HeaderItem = {
                            title: 'Manage Administrators',
                            viewNav: nav.ViewAdminsPage,
                            isSuperAdminOnly: true,
                        },
                    ]
                },
            ]
        },
    };

    return headerData;
};
