import React, { useEffect } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Store } from '../store/Store';
import { ButtonTextView } from '../components/TextView';
import { MainLayout } from '../components/Layout';
import { introductionCopy } from '../copy/GeneralCopy';
import { MarkdownContent } from '../components/Markdown';

export const DashboardView = (props: { store: Store }) => {

    const { store } = props;
    const { nav, theme } = store;
    // const { loading, error, doWork } = useAutoLoadingError();
    // const { data: contactListsData } = useLoadData(doWork, () => api.getContactLists());

    useEffect(() => {
        // Redirect to Active Campaigns Page
        nav.ActiveCampaignsPage.open();
    });

    return (
        <MainLayout store={store}>
            <View style={theme.page.titleView}>
                <Text style={theme.page.titleText}>Dashboard</Text>
                <View style={theme.page.titleUnderlineView} />
            </View>
            <View style={theme.card.view_white}>
                <MarkdownContent style={theme.markdown}>{introductionCopy}</MarkdownContent>
            </View>
        </MainLayout>
    );
};
