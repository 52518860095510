import React, { useState } from 'react';
import { View, Text, ActivityIndicator } from 'react-native';
import { Store } from '../../store/Store';
import { useLoadData, useAutoLoadingError } from '../../../utils/Hooks';
import { List } from '../../components/List';
import { ContactListModel, ContactListModel_Edit } from '../../../data-types/ModelTypes';
import { Timestamp } from '../../../utils/Time';
import { ButtonTextView, TextView } from '../../components/TextView';
import { MainLayout } from '../../components/Layout';
import { EditContactList } from './EditContactList';
import { ErrorBox } from '../../components/ErrorBox';
import { NavLinks } from '../Common/NavLinks';
import { getReference, getReferenceOrUndefined } from '../../../data-types/SimpleDataTypes';
import { CampaignLink, useReferenceList_LoadAll } from '../Common/ReferenceLinks';
import { ViewKeywords } from '../Keywords/ViewKeywords';
import { CampaignCard } from '../Campaigns/EditCampaignComponents';

const ContactListItem = (props: { store: Store, item: ContactListModel, onItemChanged?: (item: ContactListModel) => void }) => {
    const { store, item: itemInit } = props;
    const { nav, theme } = store;

    const [item, setItem] = useState(itemInit);
    const [isEditing, setIsEditing] = useState(false);
    const onItemChanged = (item: ContactListModel) => {
        setItem(item);
        setIsEditing(false);
        props.onItemChanged?.(item);
    };

    const { loading, error, doWork } = useAutoLoadingError();
    const contactsCount = useLoadData(doWork, async () => {
        const count = await store.api.getContactsCount(item);
        return count;
    });

    const campaignsRaw = useReferenceList_LoadAll(store, item.usedByCampaigns, (s, x) => s.api.loadItemsCampaigns(x));
    const campaigns = campaignsRaw?.sort((a, b) => (a.status?.createdTime ?? 0) - (b.status?.createdTime ?? 0));

    return (
        <View style={theme.card.view}>
            <View style={theme.card.headerRow}>
                <Text style={theme.card.headerText}>{item.name}</Text>
                <Text style={theme.card.header2Text}>Keywords: {item.keywords}</Text>
            </View>

            <Text style={theme.card.paragraphText}>{item.description}</Text>
            <View style={theme.grid.row}>
                <View style={theme.grid.cell}>
                    <View style={theme.card.fieldView}>
                        <Text style={theme.card.fieldLabelText}>Created:</Text>
                        <Text style={theme.card.fieldValueText}>{Timestamp.formatTimestamp(item.createdTime)}</Text>
                    </View>
                    <View style={theme.card.fieldView}>
                        <Text style={theme.card.fieldLabelText}>Edited:</Text>
                        <Text style={theme.card.fieldValueText}>{Timestamp.formatTimestamp(item.modifiedTime)}</Text>
                    </View>
                    <View style={theme.card.fieldView}>
                        <Text style={theme.card.fieldLabelText}>Contacts:</Text>
                        <Text style={theme.card.fieldValueText}>{contactsCount.data ?? ''}</Text>
                    </View>
                </View>
                <View style={theme.grid.cell}>
                    <View style={theme.card.fieldView_top}>
                        <Text style={theme.card.fieldLabelText}>Active Campaigns:</Text>
                        <View>
                            {campaigns?.filter(x => x.status?.isActive)?.map(x => (
                                <View key={x.id} style={theme.card.row}>
                                    <CampaignLink store={store} style={theme.card.navTextView} itemRef={getReference(x)} />
                                </View>
                            ))}
                        </View>
                    </View>
                </View>
                <View style={theme.grid.cell}>
                    <View style={theme.card.fieldView_top}>
                        <Text style={theme.card.fieldLabelText}>Campaign History:</Text>
                        <View>
                            {campaigns?.filter(x => !x.status?.isActive)?.slice(0, 10).map(x => (
                                <View key={x.id} style={theme.card.row}>
                                    <CampaignLink store={store} style={theme.card.navTextView} itemRef={getReference(x)} />
                                </View>
                            ))}
                        </View>
                    </View>
                </View>
            </View>

            {/* Buttons */}
            <View style={theme.card.buttonRow}>
                <ButtonTextView style={theme.card.buttonTextView_minor}
                    onPress={() => nav.ViewContactListContactsPage.open({ contactList: item })}
                    text="View or Edit Contacts" />
                <ButtonTextView style={theme.card.buttonTextView_major}
                    onPress={() => setIsEditing(!isEditing)}
                    text="Edit" />
            </View>
            {isEditing && (
                <EditContactList {...props} item={item} 
                    onCancelEdit={() => setIsEditing(false)} onItemChanged={onItemChanged} 
                    onDeleted={() => nav.ViewContactListsPage.open()}
                />
            )}
        </View>
    );
};


export const CreateContactListPage = (props: { store: Store }) => {
    const { store } = props;
    const { api, nav, theme } = store;
    const { loading, error, doWork } = useAutoLoadingError();

    return (
        <MainLayout store={store}>
            {loading && <ActivityIndicator size="large" />}
            {error && <ErrorBox error={error} />}
            <EditContactList store={store} onItemChanged={(x) => nav.ViewContactListPage.open({ contactList: x }, { replaceInHistory: true })} />
        </MainLayout>
    );
};

export const ViewContactListPage = (props: { store: Store, args?: { contactList: ContactListModel }, argsRoute?: { contactListId: string } }) => {
    const { store } = props;
    const { api, nav, theme } = store;
    const { loading, error, doWork } = useAutoLoadingError();
    const contactListData = useLoadData(doWork, async () => {
        const contactList = props.args?.contactList ?? await api.getContactList(props.argsRoute?.contactListId!);
        return {
            contactList,
        };
    }, () => [props.args]);

    const contactList = contactListData.data?.contactList;

    return (
        <MainLayout store={store}>
            <View style={theme.nav.row}>
                <NavLinks.ViewContactLists store={store} style={store.theme.nav.breadcrumbButton} />
                <NavLinks.ViewContactList store={store} style={store.theme.nav.breadcrumbButton} itemRef={getReferenceOrUndefined(contactList)} />
            </View>
            <View style={theme.page.titleView}>
                <Text style={theme.page.titleText}>View List</Text>
                <View style={theme.page.titleUnderlineView}>
                </View>
            </View>
            {loading && <ActivityIndicator size="large" />}
            {error && <ErrorBox error={error} />}
            {contactList && (<ContactListItem store={store} item={contactList} />)}
        </MainLayout>
    );
};


export const ViewContactListsPage = (props: { store: Store }) => {
    const { store } = props;
    const { api, theme } = store;
    const { loading, error, doWork } = useAutoLoadingError();
    const contactListsData = useLoadData(doWork, () => api.getContactLists());

    return (
        <MainLayout store={store}>
            <View style={theme.nav.row}>
                <NavLinks.ViewContactLists store={store} style={store.theme.nav.breadcrumbButton} />
            </View>
            <CampaignCard store={store}>
                <TextView style={theme.card.cardTitle} text={'Manage List'} />

                <List
                    style={theme.list}
                    items={contactListsData.data ?? []}
                    loading={loading}
                    error={error}
                    extractKey={x => x.id}
                    extractSearchKey={x => [x.name, x.description, x.keywords]}
                    extractSortKey={x => [x.name]}
                    renderItem={x => <ContactListItem store={store} item={x.item} onItemChanged={x.onItemChanged} />}
                    renderSearchShortcuts={({ onChangeSearch }) => <ViewKeywords store={store} onSelectKeyword={onChangeSearch} />}
                />
            </CampaignCard>
        </MainLayout>
    );
};

