import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, Platform } from 'react-native';
import { CampaignModel_Sweepstakes, CampaignEdit_Sweepstakes, CampaignStateModel_Sweepstakes, ContactModel } from '../../../data-types/ModelTypes';
import { Store } from '../../store/Store';
import { CampaignCard, } from './EditCampaignComponents';
import { theme } from '../../style/Theme';
import { TextView, ButtonTextView, OptionField, TextField, NumberTextField } from '../../components/TextView';
import { MessageTemplateFieldEditorV2 } from './components/MessageTemplateEditor';
import { MainLayout } from '../../components/Layout';
import { AppError } from '../../../utils/Errors';
import { useAutoLoadingError } from '../../../utils/Hooks';
import { getReferenceFromId, getReference, Reference } from '../../../data-types/SimpleDataTypes';
import { Timestamp, DateOnly } from '../../../utils/Time';
import { EditContactListKeywords } from '../ContactLists/EditContactList';
import { CampaignEdit } from '../../../data-types/TypeTransformations';
import { Loading } from '../../../lib/controls-react/loading';
import { ErrorBox } from '../../../lib/controls-react/error-box';
import { MessageTemplateField } from '../../../logic/campaigns/campaignFieldDefinitions';
import { formatPhoneNumber_UsaCanada } from '../../../utils/PhoneNumbers';
import { NavLinks } from '../Common/NavLinks';
import { shuffle, distinct } from '../../../utils/Arrays';
import { saveFileAs } from '../../../utils/FileSaver';
import { DateFieldEditor } from './components/DateRangeFieldsEditor';
import { DatePicker } from '../../components/DateTimePicker';
import { Validation_DateAfterOrOnToday, Validation_PositiveInteger } from '../../../utils/Validation';
import { OptInMessageControl } from './components/OptInMessageControl';
import { CampaignPreviewSaveArea } from './components/CampaignPreviewSaveArea';
import { CampaignPhoneNumber } from './components/CampaignPhoneNumber';
import { useAlertModal } from '../../components/AlertModal';
import { Gsm } from '../../../utils/Gsm';


type TCampaign = CampaignModel_Sweepstakes;
type TCampaignEdit = CampaignEdit_Sweepstakes;

export const EditCampaignPage_Sweepstakes = (props: {
    store: Store,
    args?: { campaign: TCampaign }, argsRoute?: { campaignId: string }
}) => {
    return <EditCampaignPage_Sweepstakes_Inner {...props} />
}

const CampaignResultViewer = ({
    store,
    campaignValue,
}: {
    store: Store,
    campaignValue: TCampaignEdit,
}) => {
    // Save Campaign
    const { loading, error, doWork } = useAutoLoadingError();

    const saveCampaign = () => {
        if (!campaignValue.isValid) {
            throw new AppError('Cannot save invalid campaign - this should not be enabled');
        }
        doWork(async (stopIfObsolete) => {
            // Get existing contact list
            const contactList = campaignValue.contactList?.id ? await store.api.loadContactList(getReferenceFromId(campaignValue.contactList.id)).valueOrLoad() : undefined;
            const contactList_edit = campaignValue.contactList;

            // Update or create Contact List
            const result = !contactList ? await store.api.getOrCreateContactList(contactList_edit)
                : await store.api.setContactListData(contactList, contactList_edit);
            stopIfObsolete();

            // Save contact list
            const campaignObj: CampaignEdit<TCampaign> = {
                // New campaigns should use a null id
                id: campaignValue.id ?? null as any,
                kind: 'sweepstakes',

                name: campaignValue.name,
                description: campaignValue.description,

                fromPhoneNumber: campaignValue.fromPhoneNumber!,
                endDate: campaignValue.endDate,

                maxEntriesPerParticipant: campaignValue.maxEntriesPerParticipant,
                messages: {
                    welcomeMessage: {
                        id: campaignValue.welcomeMessage.id ?? null as any,
                        text: campaignValue.welcomeMessage.text,
                        additionalTexts: campaignValue.welcomeMessage.additionalTexts,
                        imageUrl: campaignValue.welcomeMessage.imageUrl,
                        linkUrl: campaignValue.welcomeMessage.linkUrl,
                        // This will be set
                        campaign: { id: null } as any,
                        campaignPath: 'messages.message',
                        campaignRole: 'WelcomeMessage',
                    },
                    optInMessage: campaignValue.optInMessage ? {
                        id: campaignValue.optInMessage.id ?? null as any,
                        text: campaignValue.optInMessage.text,
                        additionalTexts: campaignValue.optInMessage.additionalTexts,
                        imageUrl: campaignValue.optInMessage.imageUrl,
                        linkUrl: campaignValue.optInMessage.linkUrl,
                        // This will be set
                        campaign: { id: null } as any,
                        campaignPath: 'messages.optInMessage',
                        campaignRole: 'OptInMessage',
                    } : undefined,
                    optInConfirmationMessage: campaignValue.optInConfirmationMessage ? {
                        id: campaignValue.optInConfirmationMessage.id ?? null as any,
                        text: campaignValue.optInConfirmationMessage.text,
                        additionalTexts: campaignValue.optInConfirmationMessage.additionalTexts,
                        imageUrl: campaignValue.optInConfirmationMessage.imageUrl,
                        linkUrl: campaignValue.optInConfirmationMessage.linkUrl,
                        // This will be set
                        campaign: { id: null } as any,
                        campaignPath: 'messages.optInConfirmationMessage',
                        campaignRole: 'OptInConfirmationMessage',
                    } : undefined,
                },
                instantWinPrizes: campaignValue.instantWinPrizes.map((x, i) => ({
                    prizeId: x.prizeId,
                    prizeName: x.prizeName,
                    prizeLimit: x.prizeLimit,
                    winChancePoolSize: x.winChancePoolSize,
                    winMessage: {
                        id: x.winMessage.id ?? null as any,
                        text: x.winMessage.text,
                        additionalTexts: x.winMessage.additionalTexts,
                        imageUrl: x.winMessage.imageUrl,
                        linkUrl: x.winMessage.linkUrl,
                        // This will be set
                        campaign: { id: null } as any,
                        campaignPath: `instantWinPrizes.winMessage[${i}]`,
                        campaignRole: 'InstantWinPrizeMessage',
                    }
                })),
                toContactLists: [getReferenceFromId(result.id)] as any,
            };
            if (!campaignObj.status?.createdTime) {
                campaignObj.status = {
                    isActive: true,
                    createdTime: Timestamp.now(),
                    lastActivityTime: Timestamp.now(),
                    nextActivityTime: Timestamp.now(),
                };
            }
            await store.api.saveCampaign(campaignObj);

            stopIfObsolete();
            showConfirmation();
        });
    };
    const { AlertHost, showAlert: showConfirmation } = useAlertModal({
        title: `Campaign Saved`,
        message: `Your campaign has been saved.`,
        buttons: [
            { text: 'OK', onPress: () => { store.nav.ActiveCampaignsPage.open(); } },
        ]
    });

    return (
        <>
            <CampaignPreviewSaveArea
                store={store}
                campaign={campaignValue}
                campaignMessages={[
                    { title: 'Message', message: campaignValue.welcomeMessage },
                    { title: 'Double Opt-In Message', message: campaignValue.optInMessage },
                    { title: 'Double Opt-In Confirmation Message', message: campaignValue.optInConfirmationMessage },
                ]}
                saveCampaign={saveCampaign}
                isDisabled={!campaignValue.isValid} loading={loading} error={error} />
            <AlertHost useFixed={true} />
        </>
    );
}

const welcomMessageTemplate = MessageTemplateField({
    templateId: 'welcomMessage',
    label: 'Welcome Message',
    hint: 'What message should be sent when someone signs up for the sweepstakes?',
    placeholder: 'Enter the Sweepstakes Welcome Message',
    imageMode: 'optional', linkMode: 'optional',
    supportsMultipleMessages: true,
});

const instantWinPrizeMessageTemplate = (prizeId: string) => MessageTemplateField({
    templateId: 'instantWinPrizeMessage' + prizeId,
    label: 'Automatic Instant Win - Prize Message',
    hint: 'What message should be sent if someome wins the prize (with a possible link to redeem the prize)?',
    placeholder: 'Enter the "You Won!" message',
    imageMode: 'optional', linkMode: 'optional',
    supportsMultipleMessages: true,
});

const EditCampaignPage_Sweepstakes_Inner = ({
    store,
    args,
    argsRoute,
}: {
    store: Store,
    args?: { campaign: TCampaign },
    argsRoute?: { campaignId: string },
}) => {

    const newCampaign = {
        isValid: false,
        status: 'new',
        id: undefined,
        kind: 'sweepstakes',
        name: '',
        description: '',
        contactList: {
            keywords: '',
        },
        endDate: DateOnly.today(),
        welcomeMessage: {
            text: '',
            additionalTexts: [],
        },
        optInMessage: undefined,
        optInConfirmationMessage: undefined,
        maxEntriesPerParticipant: 1,
        instantWinPrizes: [],
    } as TCampaignEdit;
    const [campaign, setCampaign] = useState(null as null | TCampaignEdit);

    const [fromPhoneNumberOptions, setFromPhoneNumberOptions] = useState([] as { value: string, label: string }[]);

    // Load Campaign
    const { loading, error, doWork } = useAutoLoadingError();
    useEffect(() => {

        doWork(async (stopIfObsolete) => {

            // Get Phone
            const phoneNumbers = await store.api.getAccountPhoneNumbers();
            stopIfObsolete();

            setFromPhoneNumberOptions(phoneNumbers.map(x => ({ value: x.id, label: `${formatPhoneNumber_UsaCanada(x.phoneNumber)}` })));
            const fromPhoneNumber = getReferenceFromId(phoneNumbers[0].id);

            if (!args && !argsRoute) {
                setCampaign({ ...newCampaign, fromPhoneNumber });
                return;
            }

            const campaign_loaded = args?.campaign
                || argsRoute?.campaignId && await store.api.loadCampaign(getReferenceFromId(argsRoute.campaignId)).valueOrLoad() as TCampaign
                || null;
            if (!campaign_loaded) {
                setCampaign({ ...newCampaign, fromPhoneNumber });
                return;
            }
            stopIfObsolete();

            const contactLists = await store.api.loadItemsContactLists(campaign_loaded.toContactLists).valueOrLoad();
            const welcomeMessage = await store.api.loadMessageTemplate(campaign_loaded.messages.welcomeMessage).valueOrLoad();
            const optInMessage = campaign_loaded.messages.optInMessage ? await store.api.loadMessageTemplate(campaign_loaded.messages.optInMessage).valueOrLoad() : undefined;
            const optInConfirmationMessage = campaign_loaded.messages.optInConfirmationMessage ? await store.api.loadMessageTemplate(campaign_loaded.messages.optInConfirmationMessage).valueOrLoad() : undefined;
            const prizes = await Promise.all(campaign_loaded.instantWinPrizes.map(async (x, i) => {
                const winMessage = await store.api.loadMessageTemplate(x.winMessage).valueOrLoad();
                const prize: TCampaignEdit['instantWinPrizes'][0] = {
                    prizeId: x.prizeId,
                    prizeName: x.prizeName,
                    prizeLimit: x.prizeLimit,
                    winChancePoolSize: x.winChancePoolSize,
                    winMessage,
                };
                return prize;
            }));
            stopIfObsolete();


            setValidity({});
            setCampaign({
                isValid: true,
                status: campaign_loaded.status?.isActive ? 'active' : 'inactive',
                id: campaign_loaded.id,
                kind: 'sweepstakes',
                name: campaign_loaded.name,
                description: campaign_loaded.description,
                fromPhoneNumber: campaign_loaded.fromPhoneNumber,
                contactList: contactLists[0],
                endDate: campaign_loaded.endDate,
                maxEntriesPerParticipant: campaign_loaded.maxEntriesPerParticipant,
                welcomeMessage: {
                    text: welcomeMessage.text,
                    additionalTexts: welcomeMessage.additionalTexts,
                    imageUrl: welcomeMessage.imageUrl,
                    linkUrl: welcomeMessage.linkUrl,
                },
                optInMessage: optInMessage ? {
                    text: optInMessage.text,
                    additionalTexts: optInMessage.additionalTexts,
                    imageUrl: optInMessage.imageUrl,
                    linkUrl: optInMessage.linkUrl,
                } : undefined,
                optInConfirmationMessage: optInConfirmationMessage ? {
                    text: optInConfirmationMessage.text,
                    additionalTexts: optInConfirmationMessage.additionalTexts,
                    imageUrl: optInConfirmationMessage.imageUrl,
                    linkUrl: optInConfirmationMessage.linkUrl,
                } : undefined,
                instantWinPrizes: prizes,
            });
        });

    }, [args, argsRoute]);

    const [validity, setValidity] = useState({
        keywords: false,
        name: false,
        message: false,
        optIn: true,
    } as { [key: string]: boolean });


    if (!campaign) {
        return (
            <MainLayout store={store}>
                {loading && <Loading loading={loading} />}
                {error && <ErrorBox error={error} />}
            </MainLayout>
        );
    }

    const updateCampaign = (update: () => { key: string, isValid: boolean }) => {
        const result = update();

        const newValidity = { ...validity, [result.key]: result.isValid };
        campaign.isValid = Object.values(newValidity).every(x => x);

        setValidity(newValidity);
        setCampaign({ ...campaign });
    };

    const addPrize = () => {
        const prizeId = 'prize_' + Timestamp.now() + '_' + Math.random();
        campaign.instantWinPrizes.push({
            prizeId,
            prizeName: '',
            prizeLimit: 1,
            winChancePoolSize: 1000,
            winMessage: { text: '', additionalTexts: [] },
        });
        setValidity(s => ({
            ...s,
            [prizeId + '.prizeName']: false,
            [prizeId + '.winMessage']: false,
        }));
        setCampaign({ ...campaign, isValid: false });
    };
    const removePrize = (i: number) => {
        const prizeId = campaign.instantWinPrizes[i].prizeId;
        campaign.instantWinPrizes.splice(i, 1);

        const newValidity = Object.keys(validity).filter(k => !k.startsWith(prizeId)).reduce((out, k) => { out[k] = validity[k]; return out; }, {} as typeof validity);
        campaign.isValid = Object.values(newValidity).every(x => x);
        setValidity(newValidity);
        setCampaign({ ...campaign });
    };

    // Render
    return (
        <MainLayout store={store}>
            <CampaignCard store={store}>
                <TextView style={theme.card.cardTitle} text={campaign.id ? `Sweepstakes Campaign: '${campaign.name}'` : "Create Sweepstakes Campaign"} />
                <TextView style={theme.card.cardSummary} text="1. A customer must send a text message to your campaign number with one of the opt-in keywords." />
                <TextView style={theme.card.cardSummary} text="2. The customer will receive the Sweepstakes Welcome message." />
                <TextView style={theme.card.cardSummary} text="3. If there are any Automatic Instant-Win Prizes, the customer will receive a message if they win." />
                <TextView style={theme.card.cardSummary} text="At any time, you can access the list of participants to do a random drawing or to contact the winners." />

                {!loading && (
                    <>
                        <CampaignPhoneNumber fromPhoneNumberOptions={fromPhoneNumberOptions} />
                    </>
                )}

                {loading && <Loading loading={loading} />}
                {error && <ErrorBox error={error} />}
                {!loading && (
                    <>
                        <EditContactListKeywords
                            preventDuplicates='campaign'
                            label={`Campaign Keyword(s)`}
                            hint={`This is the word that your customer will send to your phone number to enter your sweepstakes. You can choose more than one keyword if you want. You might choose to do this to track the effectiveness of your marketing. Separate multiple keywords with a comma between each.`}
                            store={store}
                            value={campaign.contactList.keywords}
                            initialValue={campaign.contactList.keywords}
                            onChange={x => updateCampaign(() => {
                                campaign.contactList.keywords = x.keywords;
                                return { key: 'keywords', isValid: campaign.contactList.keywords && !x.hasDuplicate || false };
                            })} />

                        <TextField style={theme.card.textField}
                            field={{
                                __type: 'ui', label: 'Campaign Name', isRequired: true,
                                hint: 'It is best to name the campaign so that you have a record of the promotion.',
                            }}
                            value={campaign.name}
                            onChangeValue={x => updateCampaign(() => {
                                campaign.name = x?.value ?? '';
                                return { key: 'name', isValid: x?.isValid ?? false };
                            })} />
                        <TextField style={theme.card.textField}
                            field={{
                                __type: 'ui', label: 'Campaign Notes', isRequired: false,
                                hint: 'This field will help you recall the details of the campaign.',
                            }}
                            value={campaign.description}
                            onChangeValue={x => updateCampaign(() => {
                                campaign.description = x?.value ?? '';
                                return { key: 'description', isValid: true };
                            })} />

                        <MessageTemplateFieldEditorV2
                            style={store.theme.card.messageTemplateField}
                            field={welcomMessageTemplate}
                            value={campaign.welcomeMessage ?? {}}
                            onChangeValue={x => updateCampaign(() => {
                                campaign.welcomeMessage = x.message;
                                return { key: 'message', isValid: x.isValid };
                            })}
                            store={store}
                        />

                        <OptInMessageControl
                            store={store}
                            isOptional={false}
                            value={campaign.optInMessage ? { message: campaign.optInMessage?.text ?? '', confirmationMessage: campaign.optInConfirmationMessage?.text ?? '' } : null}
                            onChangeMessage={x => updateCampaign(() => {
                                campaign.optInMessage = x ? { text: x.message, additionalTexts: [] } : undefined;
                                campaign.optInConfirmationMessage = x ? { text: x.confirmationMessage, additionalTexts: [] } : undefined;

                                // Required
                                const isValid = (Gsm.validation(true, () => 'sms', false).validate(campaign.optInMessage?.text ?? '').isValid
                                    && Gsm.validation(true, () => 'sms', false).validate(campaign.optInConfirmationMessage?.text ?? '').isValid);
                                return { key: 'optIn', isValid };
                            })}
                        />

                        <DateFieldEditor
                            fieldKey="endDate"
                            Control={DatePicker}
                            store={store}
                            field={{
                                endDate: {
                                    __type: 'ui',
                                    isRequired: true,
                                    label: 'Date to End Campaign',
                                    hint: 'Enter the date then the sweepstakes should end.',
                                    defaultValue: () => DateOnly.today(),
                                    validation: Validation_DateAfterOrOnToday(),
                                    isMinDateToday: true,
                                },
                            }}
                            value={{ endDate: { value: campaign.endDate, isValid: true } }}
                            onChangeValue={(x) => {
                                updateCampaign(() => {
                                    campaign.endDate = x.endDate?.value ?? DateOnly.today();
                                    return { key: 'endDate', isValid: x.endDate?.value ? x.endDate?.isValid ?? false : true };
                                });
                            }}
                        />

                        <NumberTextField style={theme.card.textField}
                            field={{
                                __type: 'ui', label: 'Max Entries Per Participant', isRequired: true,
                                hint: 'How many times can a single phone number enter to win?',
                                validation: Validation_PositiveInteger(),
                            }}
                            value={campaign.maxEntriesPerParticipant}
                            onChangeValue={x => updateCampaign(() => {
                                campaign.maxEntriesPerParticipant = x?.value ?? 1;
                                return { key: 'maxEntries', isValid: x?.isValid ?? false };
                            })} />

                        {campaign.instantWinPrizes.map((x, i) => (
                            <React.Fragment key={x.prizeId}>
                                <View style={store.theme.card.view_white}>
                                    <TextView style={store.theme.card.cardTitle} text={`Instant Win Prize ${i + 1}`} />
                                    <InstantWinPrizeEditor
                                        store={store}
                                        prize={x} prizeIndex={i}
                                        updateCampaign={updater => updateCampaign(() => updater(campaign))} />
                                    <View style={theme.card.actionArea}>
                                        <View style={theme.card.buttonRow}>
                                            <View style={theme.card.buttonSpacer} />
                                            <ButtonTextView style={theme.card.buttonTextView_minor} text="Remove Prize" onPress={() => removePrize(i)} />
                                        </View>
                                    </View>
                                </View>
                            </React.Fragment>
                        ))}

                        <View style={theme.card.actionArea}>
                            <ButtonTextView style={theme.card.buttonTextView_minor} text="Add Instant Win Prize" onPress={addPrize} />
                        </View>


                        {/* <Text>
                            {JSON.stringify(validity)}
                        </Text>
                        <Text>
                            {JSON.stringify(campaign)}
                        </Text> */}

                        <CampaignResultViewer store={store} campaignValue={campaign} />
                    </>
                )}
            </CampaignCard>
        </MainLayout>
    );
};

const InstantWinPrizeEditor = ({
    store,
    prize,
    updateCampaign,
    prizeIndex,
}: {
    store: Store,
    prize: TCampaignEdit['instantWinPrizes'][0],
    updateCampaign: (updater: (campaign: TCampaignEdit) => { key: string, isValid: boolean }) => void,
    prizeIndex: number,
}) => {
    return (
        <>
            <TextField style={theme.card.textField}
                field={{
                    __type: 'ui', label: 'Prize Name', isRequired: true,
                    hint: 'Displayed with the prize winners',
                }}
                value={prize.prizeName}
                onChangeValue={x => updateCampaign((c) => {
                    c.instantWinPrizes[prizeIndex].prizeName = x?.value ?? '';
                    return { key: c.instantWinPrizes[prizeIndex].prizeId + '.prizeName', isValid: !!x?.value };
                })} />
            <MessageTemplateFieldEditorV2
                style={store.theme.card.messageTemplateField}
                field={instantWinPrizeMessageTemplate(prizeIndex + '')}
                value={prize.winMessage ?? {}}
                onChangeValue={x => updateCampaign((c) => {
                    c.instantWinPrizes[prizeIndex].winMessage = x.message ?? { text: '' };
                    return { key: c.instantWinPrizes[prizeIndex].prizeId + '.winMessage', isValid: !!x.isValid };
                })}
                store={store}
            />
            <NumberTextField style={theme.card.textField}
                field={{
                    __type: 'ui', label: 'Chance to Win (1 out of ___)', isRequired: true,
                    hint: 'What is the chance to win this prize for each entry?',
                    validation: Validation_PositiveInteger(),
                }}
                value={prize.winChancePoolSize || 1000}
                onChangeValue={x => updateCampaign((c) => {
                    c.instantWinPrizes[prizeIndex].winChancePoolSize = parseInt(x?.value + '') || 0;
                    return { key: c.instantWinPrizes[prizeIndex].prizeId + '.winChancePoolSize', isValid: c.instantWinPrizes[prizeIndex].winChancePoolSize > 0 };
                })} />
            <NumberTextField style={theme.card.textField}
                field={{
                    __type: 'ui', label: 'Prize Limit', isRequired: true,
                    hint: 'How many prizes are available?',
                    validation: Validation_PositiveInteger(),
                }}
                value={prize.prizeLimit || 1}
                onChangeValue={x => updateCampaign((c) => {
                    c.instantWinPrizes[prizeIndex].prizeLimit = parseInt(x?.value + '') || 0;
                    return { key: c.instantWinPrizes[prizeIndex].prizeId + '.prizeLimit', isValid: c.instantWinPrizes[prizeIndex].prizeLimit > 0 };
                })} />
        </>
    );
};




export const CampaignDetails_Sweepstakes = (props: { store: Store, campaign: TCampaign }) => {
    const { store, campaign } = props;
    // const campaignState = await props.store.  // Save Campaign
    const { loading, error, doWork } = useAutoLoadingError();

    const [campaignState, setCampaignState] = useState(null as null | CampaignStateModel_Sweepstakes);

    useEffect(() => {
        doWork(async (stopIfObsolete) => {
            const campaignState = await store.api.getCampaignState<CampaignStateModel_Sweepstakes>(getReference(campaign));
            stopIfObsolete();

            setCampaignState(campaignState ?? null);
        });
    }, []);

    // const saveCampaign = () => {
    //     if (!campaignValue.isValid) {
    //         throw new AppError('Cannot save invalid campaign - this should not be enabled');
    //     }
    //     doWork(async (stopIfObsolete) => {
    //         // Get existing contact list
    //         const contactList = campaignValue.contactList?.id ? await store.api.loadContactList(getReferenceFromId(campaignValue.contactList.id)).valueOrLoad() : undefined;
    //         const contactList_edit = campaignValue.contactList;

    //         // Update or create Contact List
    //         const result = !contactList ? await store.api.createContactList(contactList_edit)
    //             : await store.api.setContactListData(contactList, contactList_edit);
    //         stopIfObsolete();

    const formatPrizeName = (prizeId: string) => {
        const p = campaign.instantWinPrizes.find(x => x.prizeId === prizeId);
        if (!p) { return prizeId; }

        return p.prizeName ?? `Prize ${campaign.instantWinPrizes.indexOf(p) + 1}`;
    };

    const [tab, setTab] = useState('winners' as 'winners' | 'entries' | 'randomDraw');

    return (
        <View>
            {loading && <Loading loading={loading} />}
            {error && <ErrorBox error={error} />}

            <View style={store.theme.grid.row}>
                <View style={store.theme.grid.cell}>
                    <TouchableOpacity onPress={() => setTab('winners')} >
                        <View style={tab === 'winners' ? theme.card.tab_selectionActive : theme.card.tab_selectionInactive}>
                            <TextView style={store.theme.card.tabTextView} text={'Winners'} />
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={store.theme.grid.cell}>
                    <TouchableOpacity onPress={() => setTab('entries')} >
                        <View style={tab === 'entries' ? theme.card.tab_selectionActive : theme.card.tab_selectionInactive}>
                            <TextView style={store.theme.card.tabTextView} text={'Entries'} />
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={store.theme.grid.cell}>
                    <TouchableOpacity onPress={() => setTab('randomDraw')} >
                        <View style={tab === 'randomDraw' ? theme.card.tab_selectionActive : theme.card.tab_selectionInactive}>
                            <TextView style={store.theme.card.tabTextView} text={'Random Drawing'} />
                        </View>
                    </TouchableOpacity>
                </View>
            </View>

            {tab === 'winners' && (
                <>
                    <TextView style={theme.card.cardSectionTitle} text='Winners' />
                    {campaignState?.wonPrizes.map(x => (
                        <View key={x.timestamp} style={store.theme.grid.row}>
                            <View style={store.theme.grid.cell}>
                                <Text style={store.theme.grid.dataText}>{formatPrizeName(x.prizeId)}</Text>
                            </View>
                            <View style={store.theme.grid.cell}>
                                <Text style={store.theme.grid.dataText}>{Timestamp.formatTimestamp(x.timestamp)}</Text>
                            </View>
                            <View style={store.theme.grid.cell}>
                                <NavLinks.ViewContact style={store.theme.grid.dataLink} store={store} itemRef={x.contact} />
                            </View>
                        </View>
                    ))}
                </>
            )}
            {tab === 'entries' && (
                <>
                    <TextView style={theme.card.cardSectionTitle} text='Entries' />
                    {campaignState?.entries.map(x => (
                        <View key={x.timestamp} style={store.theme.grid.row}>
                            <View style={store.theme.grid.cell}>
                                <Text style={store.theme.grid.dataText}>{Timestamp.formatTimestamp(x.timestamp)}</Text>
                            </View>
                            <View style={store.theme.grid.cell}>
                                <NavLinks.ViewContact style={store.theme.grid.dataLink} store={store} itemRef={x.contact} />
                            </View>
                        </View>
                    ))}
                </>
            )}
            {tab === 'randomDraw' && (
                <DrawWinnersView store={store} entries={campaignState?.entries ?? []} />
            )}
        </View>
    );
}


export const DrawWinnersView = (props: { store: Store, entries: { contact: Reference<ContactModel> }[] }) => {
    const { store, entries } = props;

    const [winnerCount, setWinnerCount] = useState(10);
    const [randomWinners, setRandomWinners] = useState(null as null | Reference<ContactModel>[]);
    const drawRandomWinners = () => {

        const shuffled = shuffle(entries);
        const contacts = shuffled.map(x => x.contact);
        const contactsDistinct = distinct(contacts, x => x.id);

        setRandomWinners(contactsDistinct.slice(0, winnerCount));
    };

    const saveWinners = async () => {
        const contactNumbers = await Promise.all(randomWinners?.map(x => store.api.loadContact(x).valueOrLoad()) ?? []);
        const content = contactNumbers?.map((x, i) => `#${i},${formatPhoneNumber_UsaCanada(x.phoneNumber)},${x.firstName},${x.lastName}\n`).join('');
        saveFileAs(content, 'winners.csv');
    };

    // const campaignState = await props.store.  // Save Campaign
    return (
        <>
            <TextView style={store.theme.card.cardSectionTitle} text={'Random Drawing'} />
            <NumberTextField style={theme.card.textField}
                field={{
                    __type: 'ui', label: 'How many winners to draw?', isRequired: true,
                    validation: Validation_PositiveInteger(),
                }}
                value={winnerCount}
                onChangeValue={x => setWinnerCount(x?.value ?? 10)} />
            <View style={theme.grid.row}>
                <View style={theme.card.buttonSpacer} />
                <ButtonTextView style={theme.card.buttonTextView_minor} text="Draw Random Winners" onPress={drawRandomWinners} />
            </View>
            {!randomWinners?.length && (
                <View style={store.theme.grid.cell}>
                    <Text style={store.theme.grid.titleText}>{'No Winners Selected'}</Text>
                </View>
            )}
            {(randomWinners?.length ?? 0) > 0 && (
                <View style={store.theme.card.view_white}>
                    {randomWinners?.map((x, i) => (
                        <View key={x.id} style={store.theme.grid.row}>
                            <View style={store.theme.grid.cell}>
                                <Text style={store.theme.grid.dataText}>{`#${i + 1}`}</Text>
                            </View>
                            <View style={store.theme.grid.cell}>
                                <NavLinks.ViewContact style={store.theme.grid.dataLink} store={store} itemRef={x} />
                            </View>
                        </View>
                    ))}
                    <View style={theme.grid.row}>
                        <View style={theme.card.buttonSpacer} />
                        {Platform.OS === 'web' && randomWinners?.length && (
                            <ButtonTextView style={theme.card.buttonTextView_major} text="Save List" onPress={saveWinners} />
                        )}
                    </View>
                </View>
            )}
        </>
    );
};
