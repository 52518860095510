import React, { useRef } from 'react';
import { Store } from '../../store/Store';
import { useAutoLoadingError } from '../../../utils/Hooks';
import { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { CampaignModel, ContactCampaignStateModel, CampaignModel_Sweepstakes, CampaignModel_Standard, CampaignModel_Interactive } from '../../../data-types/ModelTypes';
import { MainLayout } from '../../components/Layout';
import { CampaignCard } from './EditCampaignComponents';
import { TextView, ButtonTextView } from '../../components/TextView';
import { capitalize } from '../../../utils/Strings';
import { List } from '../../components/List';
import { ContactListLinks, NavigateToCampaign } from '../Common/ReferenceLinks';
import { Timestamp } from '../../../utils/Time';
import { ApiPreview } from '../../../logic/campaigns/campaignPreview';
import { getReference, getReferenceFromId } from '../../../data-types/SimpleDataTypes';
import { AppError } from '../../../utils/Errors';
import { CampaignDetails_Sweepstakes } from './EditCampaign_Sweepstakes';
import { CampaignEnableDisableButton } from './components/CampaignDisable';
import { PhoneNumber } from 'aws-sdk/clients/sns';
import { formatPhoneNumber_UsaCanada } from '../../../utils/PhoneNumbers';
import { CampaignDetails_Stats } from './components/CampaignDetailsStats';

const getCampaignType = (kind: CampaignModel['kind']) => {
    switch (kind) {
        case 'interactive': return 'Interactive Campaign';
        case 'coupon': return 'Coupon Campaign';
        case 'sweepstakes': return 'Sweepstakes Campaign';
        case 'contest': return 'Contest Campaign';
        case 'drip': return 'Drip Campaign';
        case 'standard': return 'Broadcast Campaign';
        default: return 'Unknown' as never;
    }
};

const CampaignDetails = (props: { store: Store, item: CampaignModel }) => {

    if (props.item.kind === 'sweepstakes') {
        return (
            <>
                <CampaignDetails_Stats {...props} campaign={props.item} getMainMessage={c => c.messages.welcomeMessage} getContactLists={c => c.toContactLists} />
                <CampaignDetails_Sweepstakes {...props} campaign={props.item as CampaignModel_Sweepstakes} />
            </>
        );
    }
    if (props.item.kind === 'standard') {
        return <CampaignDetails_Stats {...props} campaign={props.item} getMainMessage={c => c.messages.message} getContactLists={c => c.toContactLists} />
    }
    if (props.item.kind === 'interactive') {
        return <CampaignDetails_Stats {...props} campaign={props.item} getMainMessage={c => c.messages.message} getContactLists={c => c.toContactLists} />
    }
    if (props.item.kind === 'coupon') {
        return <CampaignDetails_Stats {...props} campaign={props.item} getMainMessage={c => c.messages.message} getContactLists={c => c.toContactLists} />
    }

    return (
        <>
        </>
    );
}

const CampaignActions = (props: { store: Store, item: CampaignModel }) => {
    const { store, item } = props;
    const { theme } = store;

    const navToEditCampaign = () => {
        NavigateToCampaign(store.nav, item);
    };

    return (
        <View style={{}}>
            <View style={theme.card.buttonRow}>
                <View style={theme.card.buttonSpacer} />
                <CampaignEnableDisableButton store={store} campaign={item} onCampaignChanged={() => store.nav.ActiveCampaignsPage.open()} />
                <ButtonTextView style={theme.card.buttonTextView_minor} text={item.status?.isActive ? (item.kind === 'standard' ? 'View Campaign' : 'Edit Campaign') : 'View Completed Campaign'} onPress={navToEditCampaign} />
            </View>
        </View>
    );
}

const CampaignListItem = (props: { store: Store, item: CampaignModel }) => {
    const { store, item } = props;
    const { api, theme } = store;
    const x = props.item;
    const s = theme.grid;

    const [isExpanded, setIsExpanded] = useState(true);
    const toggleExpanded = () => {
        setIsExpanded(s => !s);
    };

    const onPress = () => {
        toggleExpanded();
        // NavigateToCampaign(store.nav, item);
    };

    const apiPreview: ApiPreview = {
        getMessageTemplate: (itemRef) => api.loadMessageTemplate(itemRef),
    };

    return (
        <View style={theme.card.view_white}>
            {/* <TouchableOpacity onPress={onPress}> */}
            <View style={s.row}>
                <View style={s.cell3}>
                    <Text style={s.titleText}>{x.name}</Text>
                    <Text style={s.descriptionText}>{x.description}</Text>
                </View>
                <View style={s.cell2}>
                    <Text style={s.dataText}>{getCampaignType(x.kind)}</Text>
                    {/* <ContactListLinks store={store} style={s.dataLink} listRef={x.toContactLists} /> */}
                </View>
                <View style={s.cell}>
                    {/* <Text style={s.dataText}>{Timestamp.formatTimestamp(x.status?.lastActivityTime)}</Text> */}
                    <Text style={s.dataText}>{Timestamp.formatTimestamp(x.status?.createdTime)}</Text>
                </View>
            </View>
            {/* </TouchableOpacity> */}
            {isExpanded && (
                <>
                    <View style={{ ...s.row, flexDirection: 'column' }}>
                        <CampaignActions {...props} />
                    </View>
                    <View style={{ ...s.row, flexDirection: 'column' }}>
                        <CampaignDetails {...props} />
                    </View>
                </>
            )}
            {/* {isExpanded && (
                <CampaignMessagesPreview store={props.store} style={theme.campaignMessagesPreview} campaignRef={getReference(props.item)} apiPreview={apiPreview} />
            )} */}
        </View>
    );
};

function CampaignListView(props: {
    store: Store,
    title: string,
    options: {
        shouldIncludeActive?: boolean,
        shouldIncludeInactive?: boolean,
    },
}) {
    const { api, theme } = props.store;
    const { loading, error, doWork } = useAutoLoadingError();

    // Load data
    const [campaigns, setCampaigns] = useState([] as CampaignModel[]);
    const [accountPhoneNumber, setAccountPhoneNumber] = useState(null as null | PhoneNumber);

    useEffect(() => {
        doWork(async (stopIfObsolete) => {
            const campaigns = await api.getCampaigns(props.options);
            const p = await api.getAccountPhoneNumbers();
            stopIfObsolete();

            setCampaigns(campaigns);
            setAccountPhoneNumber(p?.[0]?.phoneNumber);
        });
    }, [/* Don't reload */]);

    const s = theme.grid;

    return (
        <MainLayout store={props.store} options={{ isFullWidth: false }}>
            <CampaignCard store={props.store} options={{ shouldUseGrayBackground: true }}>
                <TextView style={theme.card.cardTitle} text={props.title} />

                {accountPhoneNumber && (
                    <TextView style={theme.card.cardSummary} text={'Your GatorText Phone Number: ' + formatPhoneNumber_UsaCanada(accountPhoneNumber)} />
                )}

                <List
                    style={theme.list}
                    items={campaigns ?? []}
                    loading={loading}
                    error={error}
                    extractKey={x => x.id}
                    extractSearchKey={x => [x.name, x.description]}
                    extractSortKey={x => [(Number.MAX_SAFE_INTEGER - (x.status?.lastActivityTime ?? 0)) + '']}
                    renderItem={({ item }) => (
                        <CampaignListItem store={props.store} item={item} />
                    )} />

            </CampaignCard>
        </MainLayout>
    );
}

export const ActiveCampaignsView = (props: { store: Store }) => (<CampaignListView store={props.store} title="Active Campaigns" options={{ shouldIncludeActive: true }} />);
export const CampaignHistoryView = (props: { store: Store }) => (<CampaignListView store={props.store} title="Campaign History" options={{ shouldIncludeInactive: true }} />);