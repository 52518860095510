import { ApiError } from "./Errors";

export function fetchWithTimeout(url: string, options: RequestInit, timeout = 30000): Promise<Response> {
    let timeoutId = 0 as any;
    const pTimeout: Promise<Response> = new Promise((_, reject) =>
        timeoutId = setTimeout(() => {
            const errorInfo = { isTimeout: true, message: 'Web Api Timeout', error: new ApiError('Web Api Timeout'), details: { url, options } };
            reject(errorInfo);
        }, timeout)
    );
    const pMain = (async () => {
        const result = await fetch(url, options).catch(err => { throw new ApiError('Fetch Error', { url }, err); });
        clearTimeout(timeoutId);
        return result;
    })();

    return Promise.race([pTimeout, pMain]);
}